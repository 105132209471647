import React, { useState, useEffect, useRef } from "react";
import { useParams } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ApiClient from "../services/api-client"
import Select from 'react-select'
import { CustomConfirmAlert as confirmAlert } from '../components/custom-confirm-alert';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import ProductClassificationsGrid from '../components/editable-aggrids/product-classifications-grid';
import MoreTaxesGrid from '../components/editable-aggrids/product-more-taxes-grid';
import FormLayout from '../components/form-layout'


export default function ProductAdd() {


    const { id } = useParams();
    const { t } = useTranslation();

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t("fieldRequired"))
            .max(80, t("maxLengthValidation", { maxlength: 80 })),
        price: Yup.string().required(t("fieldRequired"))
    });


    const [content, setContent] = useState({});
    const { register, getValues, control, setError, handleSubmit, setValue, reset, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const [companyInvoiceTypes, setCompanyInvoiceTypes] = useState([]);
    const [moreTaxesOptions, setMoreTaxesOptions] = useState([]);
    const [moreTaxesTypesOptions, setMoreTaxesTypesOptions] = useState([]);
    const prodClassificationGridRef = useRef();
    const prodMoreTaxesGridRef = useRef();
    const history = useHistory();
    const [price, setPrice] = useState("0.00");
    const [productClassifications, setProductClassifications] = useState([]);
    const [initiallyCollapsed, setInitiallyCollapsed] = useState({ collapsed: true, show: false });
    const [initiallyCollapsedMoreTaxes, setInitiallyCollapsedMoreTaxes] = useState({ collapsed: true, show: false });

    const handleChangePrice = e => {
        var newprice = e.target.value;
        newprice = newprice.replace(",", ".");
        setPrice(newprice)
        setValue("price", newprice)

        var values = getValues()
        var _vat = values.vat.label == "-" ? 0 : values.vat.label;
        var b = parseFloat((parseFloat(_vat) + 100) / 100).toFixed(3);
        var priceWithVat = b * newprice;
        if (values.vat.value === "VAT_EMPTY") {
            priceWithVat = newprice;
        }
        setValue("priceWithVat", parseFloat(priceWithVat).toFixed(2))
    }



    const handleChangePriceWithVat = e => {
        var newpriceWithVat = e.target.value;
        newpriceWithVat = newpriceWithVat.replace(",", ".");

        setValue("priceWithVat", newpriceWithVat)
        var values = getValues()
        var _vat = values.vat.label == "-" ? 0 : values.vat.label;
        var b = parseFloat((parseFloat(_vat) + 100) / 100).toFixed(3);

        var price = parseFloat(newpriceWithVat) / b;

        setValue("price", parseFloat(price).toFixed(2))
    }

    const handleBlurPrice = e => {
        var num = parseFloat(price);
        var cleanNum = num.toFixed(2);
        setPrice(cleanNum)
        setValue("price", cleanNum)
    }
    const handleBlurPriceWithVat = e => {
        var num = parseFloat(e.target.value);
        var cleanNum = num.toFixed(2);
        setValue("priceWithVat", cleanNum)
    }

    const onCategoryChange = val => {
        setValue("category", val)
        var current_data = prodClassificationGridRef.current.getData();
        if (current_data || current_data.length == 0) {
            prodClassificationGridRef.current.setData(
                prodClassificationGridRef.current.transformData(val.categoriesClassifications)
            );
        }
    }

    const onVatChange = val => {
        setValue("vat", val)
        var values = getValues()
        var priceWithVat = parseFloat(values.price);
        if (val.value !== "VAT_EMPTY") {
            priceWithVat = (parseFloat(values.vat.label) * parseFloat(values.price)) / 100 + parseFloat(values.price);
        }
        setValue("priceWithVat", parseFloat(priceWithVat).toFixed(2))
        console.log(val)
        if (val.value === "VAT_0") {
            document.getElementsByClassName("vatCategoryException")[0].style.display = "unset";
        }
        else {
            document.getElementsByClassName("vatCategoryException")[0].style.display = "none";
        }

    }

    const onSubmit = (prod, e) => {
        //clone object
        var product=JSON.parse(JSON.stringify(prod));
        e.preventDefault();
        var company = JSON.parse(localStorage.getItem("company"));
        var taxesHasError = prodMoreTaxesGridRef.current.hasError();
        var entriesHasError = prodClassificationGridRef.current.hasError();
        if (entriesHasError || taxesHasError) {
            toast.error(t("entriesErrors"), 5000)
            return;
        }

        product.company = { id: company.id };
        if (id != undefined) {
            product.id = id;
        }
        product.measurementUnit = product.measurementUnit.value;
        product.vat = product.vat.value;
        if (product.category && product.category != "") {
            product.category = { id: product.category.value };
        }
        else {
            delete product.category
        }
        if (product.vatCategoryException) {
            product.vatCategoryException = product.vatCategoryException.value;
        }
        if (product.vatCategoryException == "") {
            delete product.vatCategoryException;
        }

        var entries = prodClassificationGridRef.current.getData();

        var _taxes_entries = [];
        var taxes_entries = prodMoreTaxesGridRef.current.getData();
        taxes_entries.forEach(e => {
            _taxes_entries.push({
                id: e.id,
                amount: e.amount,
                myDataMoreTax: e.myDataMoreTax.type == "DEDUCTION" ? null : e.myDataMoreTax.value,
                deduction: e.myDataMoreTax.type == "DEDUCTION" ? { id: e.myDataMoreTax.id } : null,
                type: e.myDataMoreTax.type
            })
        })
        product.productClassifications = entries;
        product.productMoreTaxes = _taxes_entries;
        // let params = new URLSearchParams(product).toString();
        console.debug(product)
        confirmAlert({
            title: t("submitConfirm"),
            message: t("areYouSure"),
            buttons: [
                {
                    label: t('Yes'),
                    className: "accept",
                    onClick: () => ApiClient.saveCompanyProduct(company.id, product).then(

                        response => {
                            if (id == undefined) {
                                reset()
                                history.push("/")
                                history.push("/product");
                            }
                            toast.success(t("saveSucceded"))
                            console.log(response.data)
                            if (id != undefined) {
                                if (response.data.productClassifications > 0) {
                                    prodClassificationGridRef.current.setData(
                                        prodClassificationGridRef.current.transformData(response.data.productClassifications)
                                    );
                                }
                                if (response.data.productMoreTaxes > 0) {
                                    prodMoreTaxesGridRef.current.setData(
                                        prodMoreTaxesGridRef.current.transformData(response.data.productMoreTaxes, moreTaxesTypesOptions, moreTaxesOptions)
                                    );
                                }
                            }
                        },
                        error => {
                            toast.error("Error: " + error.response.data.message)
                            console.error(error.response.data)
                        }
                    )
                },
                {
                    label: t('No')
                }
            ]
        });
    }



    useEffect(() => {
        let mounted = true;
        var companyId = JSON.parse(localStorage.getItem("company")).id;

        ApiClient.getProductView(companyId).then(response => {

            var invtypes = response.data.companyInvoiceTypes;
            var _invtypes = []
            if (invtypes) {
                invtypes.forEach(element => {
                    _invtypes.push({
                        value: element.id,
                        label: t(element.name),
                        myDataInvoiceType: element.myDataInvoiceType,
                    });
                });
            }


            setCompanyInvoiceTypes(_invtypes);


            var data = response.data.measurementUnits;
            var options = [];
            data.forEach(element => {
                options.push({
                    value: element,
                    label: t(element),
                });
            });

            var dataVats = response.data.vats;
            var optionsVats = [];
            dataVats.forEach(element => {
                optionsVats.push({
                    value: element.id,
                    label: element.value,
                });
            });


            var dataVatExceptionCategories = response.data.myDataVatExceptionCategories;
            var optionVatExceptionCategories = [];
            dataVatExceptionCategories.forEach((val, key) => {
                optionVatExceptionCategories.push({
                    value: val,
                    label: (key + 1) + ") " + t(val),
                });
            });


            var optionProductCategories = [];
            var dataProductCategories = response.data.productCategories;
            dataProductCategories.forEach(element => {
                optionProductCategories.push({
                    value: element.id,
                    label: element.name,
                    categoriesClassifications: element.productCategoryClassifications
                });
            });


            var myDataMoreTaxesOptions = [];
            var myDataMoreTaxesData = response.data.myDataMoreTaxes;
            myDataMoreTaxesData.forEach(element => {
                myDataMoreTaxesOptions.push({
                    value: element.type == "DEDUCTION" ? element.id : element.uid,
                    label: element.type == "DEDUCTION" ? element.name : t("TAX_" + element.uid),
                    type: element.type,
                    key: element.key,
                    id: element.id,
                    amountValue: element.amountValue,
                    isSubtracted: element.isSubtracted
                });
            });

            var moreTaxesTypesOptions = [];
            var myDataMoreTaxesTypes = response.data.myDataMoreTaxesTypes;
            myDataMoreTaxesTypes.forEach(element => {
                moreTaxesTypesOptions.push({
                    value: element,
                    label: t(element)
                });
            });

            setMoreTaxesOptions(myDataMoreTaxesOptions);
            setMoreTaxesTypesOptions(moreTaxesTypesOptions);

            if (id != undefined) {
                ApiClient.getCompanyProduct(companyId, id).then(
                    response => {
                        if (mounted) {
                            if (response.data == "") {
                                history.push("/")
                                history.push("/product")
                                toast.error("Product not found: ")
                                return;
                            }

                            var prod = response.data;
                            setContent({ product: null, measurementUnits: options, vats: optionsVats, optionVatExceptionCategories: optionVatExceptionCategories, optionProductCategories: optionProductCategories });
                            setValue("name", prod.name);
                            if (prod.category) {
                                setValue("category", { value: prod.category.id, label: prod.category.name, label: prod.category.name, categoriesClassifications: prod.category.productCategoryClassifications });
                            }
                            setValue("vat", { value: prod.vat.id, label: prod.vat.value });
                            setValue("price", parseFloat(prod.price).toFixed(2));
                            setPrice(parseFloat(prod.price).toFixed(2));

                            if (prod.vatCategoryException) {
                                setValue("vatCategoryException", { value: prod.vatCategoryException, label: t(prod.vatCategoryException) });
                            }


                            setValue("measurementUnit", { value: prod.measurementUnit, label: t(prod.measurementUnit) });

                            // setValue("description", prod.description);


                            var values = getValues()
                            var _vat = values.vat.label == "-" ? 0 : values.vat.label;
                            var b = parseFloat((parseFloat(_vat) + 100) / 100).toFixed(3);
                            var priceWithVat = b * prod.price;
                            setValue("priceWithVat", parseFloat(priceWithVat).toFixed(2))

                            if (prod.vat.id == "VAT_0") {
                                document.getElementsByClassName("vatCategoryException")[0].style.display = "unset";
                            }
                            else {
                                document.getElementsByClassName("vatCategoryException")[0].style.display = "none";
                            }
                            prodClassificationGridRef.current.setData(
                                prodClassificationGridRef.current.transformData(prod.productClassifications)
                            );
                            prodMoreTaxesGridRef.current.setData(
                                prodMoreTaxesGridRef.current.transformData(prod.productMoreTaxes, moreTaxesTypesOptions, myDataMoreTaxesOptions)
                            );
                            if (prod.productClassifications.length > 0) {
                                setInitiallyCollapsed({ collapsed: false, show: true });
                            }
                            if (prod.productMoreTaxes.length > 0) {
                                setInitiallyCollapsedMoreTaxes({ collapsed: false, show: true });
                            }


                        }


                    },
                    error => {
                        console.error(error.response.data);
                        toast.error("Error: " + error.response.data.message);
                    }
                );
            }
            else {
                reset();
                setContent({ product: null, measurementUnits: options, vats: optionsVats, optionVatExceptionCategories: optionVatExceptionCategories, optionProductCategories: optionProductCategories })
                setValue("vat", { value: "VAT_24", label: "24" })
                setValue("measurementUnit", { value: "PIECES", label: t("PIECES") })
                setValue("priceWithVat", "0.00")



            }
        });
        return () => mounted = false;
    }, []);

    const onDeleteClick = e => {
        e.preventDefault();
        var companyId = JSON.parse(localStorage.getItem("company")).id;



        confirmAlert({
            title: t("submitConfirm"),
            message: t("areYouSureToDelete"),
            buttons: [
                {
                    label: t('Yes'),
                    onClick: () => ApiClient.deleteCompanyProduct(companyId, id).then(
                        response => {
                            history.push("/products");
                            toast.success(t("deleteSucceded"))

                        },
                        error => {
                            console.error(error.response.data);
                            toast.error("Error: " + error.response.data.message);
                        }
                    )
                },
                {
                    label: t('No')
                }
            ]
        });

    }



    var deleteBtn = "";
    if (id != undefined) {
        deleteBtn = <button onClick={onDeleteClick} className="btn btn-danger deletebtn">
            {t("Delete")}
        </button>
    }

    return (
        <div className="container" style={{ padding: 0 }}>
            <form autoComplete="off" id="product_form" onSubmit={handleSubmit(onSubmit)}>
                <FormLayout
                    title={t('productAdd')}
                    headerRightContent={
                        <a className="btn btn-skin header-btn" onClick={function () { history.push("/products") }}>
                            {t("productsSearch")}
                            <i className="fa fa-search"></i>
                        </a>

                    }
                    icon={
                        <i className="fa fa-cube  skin"></i>
                    }
                    footer={
                        <div className="form-group text-center">
                            <button type="submit" className="btn btn-skin">
                                {t("Save")}
                            </button>
                            {deleteBtn}
                        </div>
                    }
                >


                    <div className="row">
                        <div className="col-md-4  form-group required">
                            <label className="control-label inputlabel">{t("ProductName")}</label>
                            <input
                                name="name"
                                type="text"
                                {...register('name', { required: true, maxLength: 64 })}
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                            />
                            <div className="invalid-feedback">{errors.name?.message}</div>
                        </div>

                        <div className="col-md-2 selectInputContainer form-group required">
                            <label className="control-label inputlabel">{t("measUnit")}</label>


                            <Controller
                                render={({ field }) => (
                                    <Select {...field}
                                        options={content.measurementUnits}
                                        className={`form-control ${errors.measurementUnit ? 'is-invalid' : ''}`}
                                        placeholder={t('Select')}

                                    >

                                    </Select>
                                )}
                                control={control}
                                rules={{ required: true }}
                                name="measurementUnit"
                                defaultValue=""
                            />


                            <div className="invalid-feedback">{errors.measurementUnit?.message}</div>

                            {/* <div className="invalid-feedback">{measurementUnit.code?.message}</div> */}
                        </div>

                        <div className="col-md-2 selectInputContainer form-group required">
                            <label className="control-label inputlabel">{t("Vat")}%</label>
                            <Controller
                                render={({ field }) => (
                                    <Select {...field} onChange={onVatChange}
                                        options={content.vats}
                                        className={`form-control ${errors.vat ? 'is-invalid' : ''}`}
                                    >

                                    </Select>
                                )}
                                control={control}
                                rules={{ required: true }}
                                name="vat"
                                defaultValue=""
                            />


                            <div className="invalid-feedback">{errors.vat?.message}</div>

                            {/* <div className="invalid-feedback">{measurementUnit.code?.message}</div> */}
                        </div>

                        <div className="col-md-2  form-group required">
                            <label className="control-label inputlabel">{t("Price")}</label>
                            <input
                                name="price"
                                type="text"
                                {...register('price')}
                                onChange={e => handleChangePrice(e)} onBlur={e => handleBlurPrice(e)} value={price}
                                // defaultValue={content.product!=null?content.product.price:""}
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                className={`form-control ${errors.price ? 'is-invalid' : ''}`}
                            />
                            <div className="invalid-feedback">{errors.price?.message}</div>


                        </div>

                        <div className="col-md-2  form-group">
                            <label className="control-label inputlabel">{t("priceWithVat")}</label>
                            <input
                                name="priceWithVat"
                                type="text"
                                {...register('priceWithVat')}

                                onChange={e => handleChangePriceWithVat(e)} onBlur={e => handleBlurPriceWithVat(e)}
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                className={`form-control ${errors.priceWithVat ? 'is-invalid' : ''}`}
                            />
                            <div className="invalid-feedback">{errors.priceWithVat?.message}</div>


                        </div>

                        <div className="col-md-4 selectInputContainer form-group vatCategoryException" style={{ display: "none" }}>
                            <label className="control-label inputlabel">{t('vatCategoryException')}
                                {/* <i data-tip data-for="myDataInfo" className="fa fa-x2 fa-info-circle infoicon" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                            <ReactTooltip className="skin-tooltip"html={true} id="myDataInfo" place="top" effect="solid">
                                {t("withHoldTaxesInfo")}
                            </ReactTooltip> */}
                            </label>
                            <Controller
                                render={({ field }) => (
                                    <Select {...field}
                                        isClearable={true} options={content.optionVatExceptionCategories}
                                        className={`form-control ${errors.vatCategoryException ? 'is-invalid' : ''}`}
                                        placeholder={t('Select')}
                                    >

                                    </Select>
                                )}
                                control={control}
                                name="vatCategoryException"
                                defaultValue=""
                            />


                            <div className="invalid-feedback">{errors.withHoldTax?.message}</div>
                        </div>

                        <div><br></br></div>

                        <div className="accordion productAccordion" id="classificationAccordion">
                            <div className="accordion-item" style={{ border: "none", padding: 0, backgroundColor: "#eee" }}>
                                <h2 className="accordion-header" id="productClassificationsHeading">
                                    <a className={initiallyCollapsed.collapsed ? "accordion-button nonhilight collapsed" : "accordion-button nonhilight"} style={{ backgroundColor: "#eee" }} href="#" data-bs-toggle="collapse" data-bs-target="#classificationCollapse" aria-expanded="false" aria-controls="classificationCollapse">
                                        <p>{t("productClassifications")}</p>

                                    </a>
                                </h2>

                                <div id="classificationCollapse" className={initiallyCollapsed.show ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby="productClassificationsHeading" data-bs-parent="#classificationAccordion">
                                    <div className="accordion-body" style={{ padding: 4 }}>

                                        <div className="col-md-12">

                                            <div className="row">
                                                <div className="col-md-8">
                                                    <p className="classificationTemplatesMess">{t("classificationNote")}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <p className="classificationTemplatesMess">{t("classificationTemplatesMess")} <a href="../productCategory">{t("here")}</a></p>
                                                    <div className="selectInputContainer categorySelect form-group">
                                                        <Controller
                                                            render={({ field }) => (
                                                                <Select {...field} onChange={onCategoryChange}
                                                                    isClearable={true} options={content.optionProductCategories}
                                                                    className={`form-control ${errors.category ? 'is-invalid' : ''}`}
                                                                    placeholder={t('Select')}
                                                                >

                                                                </Select>
                                                            )}
                                                            control={control}
                                                            name="category"
                                                            defaultValue=""
                                                        />

                                                    </div>
                                                </div>
                                            </div>



                                            <ProductClassificationsGrid
                                                ref={prodClassificationGridRef}
                                                companyInvoiceTypes={companyInvoiceTypes}
                                                productClassifications={productClassifications}
                                            >

                                            </ProductClassificationsGrid>
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>

                        <div><br></br></div>

                        <div className="accordion productAccordion" id="taxesMoreAccordion">
                            <div className="accordion-item" style={{ border: "none", padding: 0, backgroundColor: "#eee" }}>
                                <h2 className="accordion-header" id="taxesMoreAccordionHeading">
                                    <a className={initiallyCollapsedMoreTaxes.collapsed ? "accordion-button nonhilight collapsed" : "accordion-button nonhilight"} style={{ backgroundColor: "#eee" }} href="#" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                        <p>{t("moreTaxes")}</p>
                                    </a>
                                </h2>
                                <div id="collapseOne" className={initiallyCollapsedMoreTaxes.show ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby="taxesMoreAccordionHeading" data-bs-parent="#taxesMoreAccordion">
                                    <div className="accordion-body" style={{ padding: 4 }}>
                                        <div className="row">
                                            <div className="col-md-12">

                                                <MoreTaxesGrid
                                                    ref={prodMoreTaxesGridRef}
                                                    moreTaxesTypesOptions={moreTaxesTypesOptions}
                                                    moreTaxesOptions={moreTaxesOptions}
                                                >

                                                </MoreTaxesGrid>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </FormLayout>

            </form>
        </div>
    );
}