import React, { useEffect, useState } from 'react';
import '../styles/login.css';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom"
import { useAlert } from 'react-alert'
import ApiClient from "../services/api-client"
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom'
import logo from '../img/timologic_login.png';





function ForgotPasswordForm(props) {

    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const alert = useAlert()
    const { t } = useTranslation();


    const handleInputChange = (event) => {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        switch (inputName) {
            case "email": {
                setEmail(inputValue);
                break;
            }
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        ApiClient.resetPassword(email).then(
            (response) => {
                var response = response.data;
                toast.success(t("lookUpEmail", { email: email }));
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();


            }
        );
    }


    return (
        < >
            <div>
                <div className="link_logo">
                    <img
                        src={logo}
                        alt="timologic myDATA app"
                        className="profile-img-card"
                    />
                    <img />
                </div>
            </div>
            <div className="alert alert-info" role="alert">
                <p> {t("ResetEmailMess")}</p>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="form-item">
                    <input type="email" name="email"
                        className="form-control" placeholder="Email"
                        value={email} onChange={handleInputChange} required />
                </div>
                <hr></hr>
                <div className="form-item">
                    <button type="submit" className="btn btn-block btn-skin">{t("ResetEmail")}</button>
                    <hr></hr>
                    <p><Link to="/">{t("Back")}</Link></p>
                </div>
            </form>
        </>
    );
}

export default function ForgotPassword(props) {
    const { t, i18n } = useTranslation();

    return (
        <div className="login-register-container">
            <div className="signup-container container">
                <div className="row">
                    <div className="offset-md-4 col-md-4">
                        <div className="signup-content row">
                            <ForgotPasswordForm {...props} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
