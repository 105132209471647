import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Error extends Component {
    constructor(props) {
        super(props)
        this.state = {
            code: ""
        };


    }

    componentDidMount() {
        var _code= this.getUrlParameter('code');
        var _message ="Unknown Error";

        switch(_code){
            case "403":{
                _message="Unauthorized";
            }
        }
        this.setState({
            code: _code,
            message: _message
        })
    }

    getUrlParameter(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');

        var results = regex.exec(this.props.location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };


    render() {
        return (
            <div className="page-not-found">
                <h1 className="title">
                    {this.state.code}
                </h1>
                <div className="desc">
                    {this.state.message}
                </div>
                <Link to="/"><button className="go-back-btn btn btn-primary" type="button">Go Back</button></Link>
            </div>
        );
    }
}

export default Error;