import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import ApiClient from "../services/api-client"
import Select from 'react-select'
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom"
import ProductCategoryClassificationsGrid from '../components/editable-aggrids/product-category-classifications-grid';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormLayout from '../components/form-layout'

export default function ProductCategoryAdd() {


    const { id } = useParams();
    const { t } = useTranslation();
    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t("fieldRequired")).max(150, t("maxLengthValidation", { maxlength: 64 })),
    });

    const [content, setContent] = useState({});
    const [global, setGlobal] = useState(false);
    const { register, control, handleSubmit, setValue, reset, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });
    const history = useHistory();
    const [companyInvoiceTypes, setCompanyInvoiceTypes] = useState([]);
    const prodClassificationGridRef = useRef();
    const [productCategoryClassifications, setproductCategoryClassifications] = useState([]);


    const onSubmit = category => {

        var entriesHasError = prodClassificationGridRef.current.hasError();
        if (entriesHasError) {
            toast.error(t("entriesErrors"), 5000)
            return;
        }

        var company = JSON.parse(localStorage.getItem("company"));
        category.company = { id: company.id };
        if (id != undefined) {
            category.id = id;
        }

        category.productCategoryClassifications = prodClassificationGridRef.current.getData();
        category.global = global;
        debugger
        confirmAlert({
            title: t("submitConfirm"),
            message: t("areYouSure"),
            buttons: [
                {
                    label: t('Yes'),
                    onClick: () => ApiClient.SaveProductCategory(category).then(
                        response => {
                            if (id === undefined) {
                                history.push("/")
                                history.push("/productCategory");
                            }
                            toast.success(t("saveSucceded"))
                            console.debug(response.data)

                        },
                        error => {
                            toast.error("Error: " + error.response.data.message)
                            console.error(error.response.data)
                        }
                    )
                },
                {
                    label: t('No'),
                    onClick: () => {

                    }
                }
            ]
        });



    }



    useEffect(() => {
        var companyId = JSON.parse(localStorage.getItem("company")).id;
        ApiClient.getCompanyInvoiceTypes(companyId).then(
            response => {
                if (response) {
                    var invtypes = response.data;
                    var _invtypes = []
                    invtypes.forEach(element => {
                        _invtypes.push({
                            value: element.id,
                            label: t(element.name),
                            myDataInvoiceType: element.myDataInvoiceType,
                        });
                    });
                    console.log(response.data)
                    setCompanyInvoiceTypes(_invtypes);
                }



                if (id != undefined) {
                    ApiClient.getCompanyProductCategory(companyId, id).then(
                        response => {
                            if (response.data == "") {
                                history.push("/")
                                history.push("/productCategory")
                                toast.error("productCategory not found: ")
                                return;
                            }

                            var prodCategory = response.data;
                            setValue("name", prodCategory.name);
                            setGlobal(prodCategory.global)
                            prodClassificationGridRef.current.setData(
                                prodClassificationGridRef.current.transformData(prodCategory.productCategoryClassifications)
                            );

                        },
                        error => {
                            toast.error("Error: " + error.response.data.error)
                            console.error(error.response.data)
                        }
                    );
                }
                else {
                    reset();

                }


            },
            error => {
                setContent(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString())
            }
        );
    }, []);

    // watch();
    return (
        /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
        <div className="container">
            <form id="productCategory_form" onSubmit={handleSubmit(onSubmit)}>

                <FormLayout
                    title={t("classificationsOfProductsAdd")}
                    headerRightContent={
                        <button className="btn btn-skin header-btn" href="" onClick={function () { history.push("/productCategories") }}>
                            {t("classificationsOfProductsSearch")}  <i className="fa fa-search"></i>
                        </button>
                    }
                    footer={
                        <div className="form-group text-center">
                            <button type="submit" className="btn btn-skin">
                                {t("Save")}
                            </button>
                            {/* {deleteBtn} */}
                        </div>
                    }
                >

                    <div className="alert alert-primary" role="alert">
                        <div className="col-md-12">

                            {t("productCategoriesInfo")}

                        </div>
                        <div><br></br></div>
                        <div className="col-md-12">
                            {t("productCategoriesInfo2")}
                        </div>
                    </div>


                    <div className="row">


                        <div className="col-md-4  form-group">
                            <label>{t("nameClassification")}</label>
                            <input
                                name="name"
                                type="text"
                                placeholder={t("placeholderInput")}
                                {...register('name', { required: true, maxLength: 100 })}
                                className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                            />
                            <div className="invalid-feedback">{errors.name?.message}</div>
                        </div>

                    </div>
                    <div><br></br></div>
                    <h5>{t("productClassifications2")}</h5>
                    <ProductCategoryClassificationsGrid ref={prodClassificationGridRef}
                        companyInvoiceTypes={companyInvoiceTypes}
                        productCategoryClassifications={productCategoryClassifications}
                    >

                    </ProductCategoryClassificationsGrid>
                </FormLayout>
            </form>
        </div>
    );
}