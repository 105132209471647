import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ApiClient from "../services/api-client"
import { useTranslation } from 'react-i18next';
import VivaFooter from '../img/viva_footer.png';
import { getCurrentUser } from '../util/AuthUtils';
import { toast } from 'react-toastify';
import { CustomConfirmAlert as confirmAlert } from '../components/custom-confirm-alert';
import { useHistory } from "react-router-dom";
import { VIVA_VIEW_TRANSACTION } from '../util/constants';
import Modal from 'react-bootstrap/Modal';
import ActivateLicence from "./activate_licence";
import MyDataLogo from "../img/mydata.png";

export default function Paymemnt() {



    const { register, formState: { errors }, getValues } = useForm();
    const { t } = useTranslation();
    const history = useHistory();
    const [showLicenceKeyModal, setShowLicenceKeyModal] = useState(false);



    useEffect(() => {
        let mounted = true;
        getCurrentUser()
            .then(response => {
                if (response == undefined) {
                    localStorage.removeItem("accessToken")
                }
                else {
                    localStorage.setItem("user", JSON.stringify(response.user))
                    if (response.companies && response.companies.length == 1) {
                        localStorage.setItem("company", JSON.stringify(response.companies[0]))
                    }
                }
                let errorOnPayment = localStorage.getItem("errorOnPayment")
                if (errorOnPayment) {
                    toast.error(errorOnPayment, { autoClose: 5000 })
                    localStorage.removeItem("errorOnPayment")
                }
            }).catch(error => {
                console.error(error)
            });

        return () => mounted = false;

    }, []);
    const createOrder = (e, packId) => {
        var paymentMethod = document.getElementById("paymentMethod").value;

        if (paymentMethod == "0") {
            ApiClient.createReccuringPaymentOrder(packId).then(
                (response) => {
                    if (response.data.Success) {
                        localStorage.setItem("packId", packId);
                        window.location = VIVA_VIEW_TRANSACTION + "/web/checkout?ref=" + response.data.OrderCode;
                    }
                },
                error => {
                    console.error(
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString())
                }
            )
        }
        else {
            ApiClient.createPaymentOrder(packId).then(
                (response) => {
                    if (response.data.Success) {
                        localStorage.setItem("packId", packId);
                        window.location = VIVA_VIEW_TRANSACTION + "/web/checkout?ref=" + response.data.OrderCode + "&paymentMethod=" + paymentMethod;
                    }
                },
                error => {
                    console.error(
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString())
                }
            )
        }

    }

    const CreateVivaOrder = () => {
        ApiClient.createVivaOrder().then(
            (response) => {
                var resp = JSON.parse(response.data.response);
                if (resp.Success) {
                    localStorage.setItem("packId", response.data.packId);
                    window.location = VIVA_VIEW_TRANSACTION + "/web/checkout?ref=" + resp.OrderCode;
                }
                else {
                    console.error(resp)
                }
            },
            error => {
                console.error(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString())
            }
        )
    }

    const CreateTransaction = () => {
        ApiClient.createUserTransaction({}).then(
            (response) => {
                var resp = response.data;
                if (resp.Success) {
                    toast.success("Your payment has complete")
                    window.location.reload();
                }
                else {
                    toast.error(resp.ErrorText)
                }

            },
            error => {
                console.error(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString())
            }
        )
    }

    const cancelSubscription = () => {



        confirmAlert({
            title: t("submitConfirm"),
            message: t("confirmCancelSubscription"),
            closeOnEscape: false,
            closeOnClickOutside: false,
            buttons: [
                {
                    label: "Ok",
                    onClick: () => {

                        ApiClient.cancelSubscription().then(
                            (response) => {
                                console.log(response)
                                localStorage.removeItem("user");
                                localStorage.removeItem("accessToken");
                                localStorage.removeItem("wizard");
                                window.location = "login";
                            },
                            error => {
                                console.error(
                                    (error.response &&
                                        error.response.data &&
                                        error.response.data.message) ||
                                    error.message ||
                                    error.toString())
                            }
                        )
                    },
                },
                {
                    label: t('Cancel')
                }
            ]
        });
    }

    const insertLicenceKey = () => {
        setShowLicenceKeyModal(true)
    }

    const goBack = () => {
        history.goBack();
    }

    const choosePackageContainer = <div className="container text-center">
        <br></br><br></br><br></br>
        <h2>Η δοκιμαστική σας περίοδος έληξε</h2>
        <br></br>
        <h4>Ανανεώστε τη συνδρομή σας με τα παρακάτω πακέτα</h4>
        <br></br>

        <section id="pricing" className="pricing">
            <div className="container" data-aos="fade-up">



                <ul className="nav justify-content-center nav nav-tabs" id="myTab" role="tablist">

                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="monthy-packs-tab" data-bs-toggle="tab" data-bs-target="#monthy-packs" type="button" role="tab" aria-controls="monthy-packs" aria-selected="true">{t("monthlyPacks")}</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="yearly-packs-tab" data-bs-toggle="tab" data-bs-target="#yearly-packs" type="button" role="tab" aria-controls="yearly-packs" aria-selected="false">{t("yearlyPacks")}<sup><span className="badge bg-success yearlyPercentDiscount">-18%</span></sup></button>
                    </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                    <br></br>
                    <div className="tab-pane fade" id="yearly-packs" role="tabpanel" aria-labelledby="yearly-packs-tab">
                        <div className="row justify-content-center">
                            Ο παρακάτω τιμές αναφέροντε σε 12 μήνες συνδρομή
                        </div>
                        <div><br></br></div>
                        <div className="row">

                            <div className="col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="100">
                                <div className="pricing-box">

                                    <div className="price"><sup></sup><span></span></div>
                                    <ul>
                                        <li>Σύνδεση με το <img className="gfd" alt="myDATA ΑΑΔΕ" src={MyDataLogo} width="17%"></img> </li>
                                        <li>Εως και <b>25</b> τιμολόγια/μήνα</li>

                                    </ul>

                                    <button className="btn btn-lg btn-skin payvivabtn" onClick={e => createOrder(e, "PACKAGE_5")}>Ξεκινήστε με 4.16 €</button>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="100">
                                <div className="pricing-box">

                                    <div className="price"><sup></sup><span></span></div>
                                    <ul>
                                        <li>Σύνδεση με το <img className="gfd" alt="myDATA ΑΑΔΕ" src={MyDataLogo} width="17%"></img> </li>
                                        <li>Εως και <b>200</b> τιμολόγια/μήνα</li>

                                    </ul>

                                    <button className="btn btn-lg btn-skin payvivabtn" onClick={e => createOrder(e, "PACKAGE_6")}>Ξεκινήστε με 6.66 €</button>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="100">
                                <div className="pricing-box">

                                    <div className="price"><sup></sup><span></span></div>
                                    <ul>
                                        <li>Σύνδεση με το <img className="gfd" alt="myDATA ΑΑΔΕ" src={MyDataLogo} width="17%"></img> </li>
                                        <li>Εως και <b>500</b> τιμολόγια/μήνα</li>

                                    </ul>

                                    <button className="btn btn-lg btn-skin payvivabtn" onClick={e => createOrder(e, "PACKAGE_7")}>Ξεκινήστε με 9.25 €</button>
                                </div>
                            </div>

                            <div><br></br></div>

                            <div className="col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="100">
                                <div className="pricing-box">

                                    <div className="price"><sup></sup><span></span></div>
                                    <ul>
                                        <li>Σύνδεση με το <img className="gfd" alt="myDATA ΑΑΔΕ" src={MyDataLogo} width="17%"></img> </li>
                                        <li>Εως και <b>24000</b> τιμολόγια/μήνα</li>

                                    </ul>

                                    <button className="btn btn-lg btn-skin payvivabtn" onClick={e => createOrder(e, "PACKAGE_8")}>Ξεκινήστε με 16.66 €</button>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="100">
                                <div className="pricing-box">

                                    <div className="price"><sup></sup><span></span></div>
                                    <ul>
                                        <li>Σύνδεση με το <img className="gfd" alt="myDATA ΑΑΔΕ" src={MyDataLogo} width="17%"></img> </li>
                                        <li>Απεριόριστα τιμολόγια</li>

                                    </ul>

                                    <button className="btn btn-lg btn-skin payvivabtn" onClick={e => createOrder(e, "PACKAGE_9")}>Ξεκινήστε με 25.00 €</button>
                                </div>
                            </div>

                        </div>



                    </div>

                    <div className="tab-pane fade   show active" id="monthy-packs" role="tabpanel" aria-labelledby="monthy-packs-tab">
                        <div className="row">

                            <div className="col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="100">
                                <div className="pricing-box">

                                    <div className="price"><sup></sup><span></span></div>
                                    <ul>
                                        <li>Σύνδεση με το <img className="gfd" alt="myDATA ΑΑΔΕ" src={MyDataLogo} width="17%"></img> </li>
                                        <li>Εως και <b>25</b> τιμολόγια/μήνα</li>

                                    </ul>

                                    <button className="btn btn-lg btn-skin payvivabtn" onClick={e => createOrder(e, "PACKAGE_0")}>Ξεκινήστε με 4.99</button>


                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="100">
                                <div className="pricing-box">

                                    <div className="price"><sup></sup><span></span></div>
                                    <ul>
                                        <li>Σύνδεση με το <img className="gfd" alt="myDATA ΑΑΔΕ" src={MyDataLogo} width="17%"></img> </li>
                                        <li>Εως και <b>200</b> τιμολόγια/μήνα</li>

                                    </ul>

                                    <button className="btn btn-lg btn-skin payvivabtn" onClick={e => createOrder(e, "PACKAGE_1")}>Ξεκινήστε με 7.99 €</button>

                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="100">
                                <div className="pricing-box">

                                    <div className="price"><sup></sup><span></span></div>
                                    <ul>
                                        <li>Σύνδεση με το <img className="gfd" alt="myDATA ΑΑΔΕ" src={MyDataLogo} width="17%"></img> </li>
                                        <li>Εως και <b>500</b> τιμολόγια/μήνα</li>

                                    </ul>

                                    <button className="btn btn-lg btn-skin payvivabtn" onClick={e => createOrder(e, "PACKAGE_2")}>Ξεκινήστε με 10.99 €</button>
                                </div>
                            </div>

                            <div><br></br></div>

                            <div className="col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="100">
                                <div className="pricing-box">

                                    <div className="price"><sup></sup><span></span></div>
                                    <ul>
                                        <li>Σύνδεση με το <img className="gfd" alt="myDATA ΑΑΔΕ" src={MyDataLogo} width="17%"></img> </li>
                                        <li>Εως και <b>2000</b> τιμολόγια/μήνα</li>

                                    </ul>

                                    <button className="btn btn-lg btn-skin payvivabtn" onClick={e => createOrder(e, "PACKAGE_3")}>Ξεκινήστε με 19.99 €</button>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="100">
                                <div className="pricing-box">

                                    <div className="price"><sup></sup><span></span></div>
                                    <ul>
                                        <li>Σύνδεση με το <img className="gfd" alt="myDATA ΑΑΔΕ" src={MyDataLogo} width="17%"></img> </li>
                                        <li>Απεριόριστα τιμολόγια</li>

                                    </ul>

                                    <button className="btn btn-lg btn-skin payvivabtn" onClick={e => createOrder(e, "PACKAGE_4")}>Ξεκινήστε με 29.99 €</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </section>
        <div><br></br></div>

        <div className="offset-md-5 col-md-2" >
            <label>Τρόποι πληρωμής</label>
            <select id="paymentMethod" defaultValue="0" className="form-select">
                <option value="0">ΚΑΡΤΑ (προτεινόμενος)</option>
                <option value="3">ΜΕΤΡΗΤΑ</option>
                <option value="4">e-banking</option>
            </select>
        </div>
        Εαν έχετε κλειδί ενεργοποίησης παρακαλώ πατήστε <a href="#" onClick={insertLicenceKey}>εδώ</a>

        <hr></hr>
        <div> Στις τιμές δεν περιλαμβάνεται Φ.Π.Α.</div>
        <hr></hr>
        <div className="row">
            <div className="col-md-12">
                <small>
                    Γίνονται δεκτές πιστωτικές ή χρεωστικές κάρτες Visa , Mastercard η American express,μετρητά η E-banking οποιασδήποτε τράπεζας. Για να διασφαλιστεί η απόλυτη ασφάλεια των ηλεκτρονικών σας συναλλαγών αυτές γίνονται, με ανακατεύθυνση, μέσα από το σύστημα ασφαλείας τoυ αδειοδοτημένου από την Τράπεζα της Ελλάδος ιδρύματος πληρωμών Viva Payments η οποία εγγυάται ένα ασφαλές περιβάλλον συναλλαγών στο κορυφαίο σύστημα ηλεκτρονικών πληρωμών. Τα δεδομένα της πιστωτικής σας κάρτας δεν αποθηκεύονται στον διακομιστή μας.
                </small>
            </div>
        </div>
        <div className="row">
            <a href="https://timologic.gr/blog/tropoi-pliromis/" target="_blank">Τρόποι πληρωμής</a>
        </div>
        <div className="text-center"> <img style={{ width: '40%' }} src={VivaFooter} alt="Viva" /></div>
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showLicenceKeyModal}
            keyboard={false}
        >
            <Modal.Header >
                <Modal.Title>{t("ActivateLicence")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <ActivateLicence></ActivateLicence>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-skin" onClick={function () { setShowLicenceKeyModal(false) }}>{t("cancel")}</button>
            </Modal.Footer>
        </Modal>
    </div>

    const paymentContainer =
        <div className="container-fluid">
            <div className="container text-center">
                <br></br><br></br><br></br><br></br><br></br><br></br>
                <h2>{t("paySubscription")}</h2>
                <br></br>
                <h5>{t("subscriptionHasEndUp")}</h5>
                <p>{t("subscriptionHasEndDown")}</p>
                <p>Για οποιαδήποτε πληροφορία ή απορία μπορείτε να επικοινωνείτε με το τμήμα εξυπηρέτησης πελατών στο τηλέφωνο 210 4441685 να σας παρέχουμε λεπτομέρειες για τους <a href="https://timologic.gr/blog/tropoi-pliromis/" target="_blank">τρόπους πληρωμών</a> ή να ανοίξετε ένα Support Ticket <a href="https://timologic.gr/blog/support/" target="_blank">ΕΔΩ</a></p>
                <button className="btn btn-lg btn-skin payvivabtn" onClick={CreateTransaction}>{t("repeatTransaction")}</button>
                <div><br></br></div>
                <div className="offset-md-4 col-lg-4 col-md-6">
                    <button className="btn btn-lg btn-skin payvivabtn" onClick={CreateVivaOrder}>{t("payWithNewCard")}</button>
                </div>
                <div><br></br></div>
                <div>
                    Αν θέλετε να αλλάξετε τύπο  συνδρομής  (απο ετήσια σε μηνιαία και το αντίστροφο) πατήστε <a href="startSubscription">εδώ</a>
                </div>
                <div><hr></hr></div>  <div><br></br></div>
                <button className="btn btn-sm btn-danger payvivabtn" onClick={cancelSubscription}>{t("cancelSubscription")}</button>
                <div><br></br></div>
                <div className="row">
                    <div className="col-md-12">
                        <small>
                            Γίνονται δεκτές πιστωτικές ή χρεωστικές κάρτες Visa , Mastercard η American express,μετρητά η E-banking οποιασδήποτε τράπεζας. Για να διασφαλιστεί η απόλυτη ασφάλεια των ηλεκτρονικών σας συναλλαγών αυτές γίνονται, με ανακατεύθυνση, μέσα από το σύστημα ασφαλείας τoυ αδειοδοτημένου από την Τράπεζα της Ελλάδος ιδρύματος πληρωμών Viva Payments η οποία εγγυάται ένα ασφαλές περιβάλλον συναλλαγών στο κορυφαίο σύστημα ηλεκτρονικών πληρωμών. Τα δεδομένα της πιστωτικής σας κάρτας δεν αποθηκεύονται στον διακομιστή μας.
                        </small>
                    </div>
                </div>




            </div>
            <div className="text-center"> <img style={{ width: '40%' }} src={VivaFooter} alt="Viva" /></div>
        </div>

    const noActionContainer = <div className="container text-center">
        <br></br><br></br><br></br><br></br><br></br><br></br>
        <h3><button onClick={goBack} className="btn btn-skin">{t("GoBack")}</button></h3>
        <br></br>

    </div>


    const container = JSON.parse(localStorage.getItem("user")).lastTransactionDate == null ? choosePackageContainer : (JSON.parse(localStorage.getItem("user")).userStatus == "ACTIVE" ? noActionContainer : paymentContainer)

    return (
        container
    );
}