import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from "react";
import ApiClient from "../services/api-client"
import InvoiceSimpleView from '../components/sales/invoice-simple-view.component';
import { useTranslation } from 'react-i18next';
import BlockUi from 'react-block-ui';
import { toast } from 'react-toastify';



export default function AadeSaleSearchByMark() {

    const { t } = useTranslation();
    const [invoice, setInvoice] = useState();
    const [blockuiLoading, setBlockuiLoading] = useState(false);

    const searchOnMydata=()=>{
        setBlockuiLoading(true)
        var mark=document.getElementById("searchByMark").value.trim();
        var companyId = JSON.parse(localStorage.getItem("company")).id;

        ApiClient.getTransmittedDoc(companyId,mark).then(
            response => {
                if (response != undefined) {
                    if(response.data.responseType.statusCode=="Failure"){
                        var errors=response.data.responseType.errors.error;
                        errors.forEach(error => {
                            console.error(error);
                            toast.error(error.message)
                            setBlockuiLoading(false)
                        });
                    }
                    else{
                        var inv=response.data.requestedDoc.invoicesDoc
                        console.log(inv)
                        setInvoice(inv);
                        setBlockuiLoading(false)
                    }

                    
                
                }
            },
            error => {
                setBlockuiLoading(false)
                console.error(error.data)
            }
        )
    }

    return (
        <BlockUi tag="div" blocking={blockuiLoading}>
            <div className="row">
                <div className="offset-md-4 col-md-4">
                    <p className="d-flex text-center">
                        <input id="searchByMark" placeholder={t('Search')} className="form-control" />
                        <button className="btn btn-skin" onClick={searchOnMydata}>{t("Search")}</button>
                    </p>
                   
                </div>

            </div>
            <div className="row">
                <InvoiceSimpleView invoice={invoice}></InvoiceSimpleView>
            </div>
       </BlockUi>
    );
}


