import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment'

export default (props) => {
  props.reactContainer.classList.add('sales-aggrid-tooltip');

  const { t } = useTranslation();

  const data = useMemo(
    () => props.api.getDisplayedRowAtIndex(props.rowIndex).data,
    []
  );

  return (
    <div className="sales-aggrid-tooltip" style={{ backgroundColor: props.color || 'white' }}  >
      <div className="container sales-aggrid-tooltip-inner">

    
        <div className="d-flex bd-highlight">
          <div className="p-1 flex-grow-1 bd-highlight"><b >{t("InvoiceType")}:</b></div>
          <div className="p-1 bd-highlight"><span >{data.invoiceType}</span></div>
        </div>
        <div className="d-flex bd-highlight">
          <div className="p-1 flex-grow-1 bd-highlight"><b>{t("Customer")}:</b></div>
          <div className="p-1 bd-highlight"><span>{data.customerName} ({data.customerTaxPayerId})</span></div>
        </div>
        <div className="d-flex bd-highlight">
          <div className="p-1 flex-grow-1 bd-highlight"><b >{t("issuerDate")}:</b></div>
          <div className="p-1 bd-highlight"><span>{ moment(data.invoiceDate).format('DD/MM/YYYY hh:mm')}</span></div>
        </div>
        <div className="d-flex bd-highlight">
          <div className="p-1 flex-grow-1 bd-highlight"><b >{t("TotalWithoutVat")}:</b></div>
          <div className="p-1 bd-highlight"><span>{data.totalWithoutVat}</span></div>
        </div>
        <div className="d-flex bd-highlight">
          <div className="p-1 flex-grow-1 bd-highlight"><b >{t("TotalWithVat")}:</b></div>
          <div className="p-1 bd-highlight"><span>{data.totalWithVat}</span></div>
        </div>
        <div className="d-flex bd-highlight">
          <div className="p-1 flex-grow-1 bd-highlight"><b >{t("TotalPayment")}:</b></div>
          <div className="p-1 bd-highlight"><span>{data.totalPayment}</span></div>
        </div>
        <div className="d-flex bd-highlight">
          <div className="p-1 flex-grow-1 bd-highlight"><b >{t("Status")}:</b></div>
          <div className="p-1 bd-highlight"><span> {data.mark?data.cancelationMark?<span className="tooltip-canceled-status">{t("canceled")}</span>:<span className="tooltip-sended-status">{t("sended")}</span>:<span className="tooltip-not-sended-status">{t("notInMyData")}</span>}</span></div>
        </div>
      </div>
    </div>
  );
};