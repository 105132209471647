import React, {  useState, useEffect,  forwardRef, useRef, useImperativeHandle } from "react";
import ApiClient from "../../services/api-client"
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import ReactDOM from 'react-dom';
import { useHistory } from "react-router-dom"
import Select from 'react-select'
import { CustomConfirmAlert as confirmAlert} from '../custom-confirm-alert';
import { useTranslation } from 'react-i18next';
import {  toast } from 'react-toastify';


const ActionsRenderer = forwardRef((props, ref) => {


    const [disabled, setDisabled] = useState(false);
    const { t} = useTranslation();

    const handleInputChange = (e) => {
        e.preventDefault();
        let rowData = [];
        props.agGridReact.api.forEachNode(node => rowData.push(node.data));
        confirmAlert({
            title: t("submitConfirm"),
            message: t("areYouSure"),
            buttons: [
                {
                    label: t('Yes'),
                    onClick: () => {
                        if(props.data.id){
                            var companyId=JSON.parse(localStorage.getItem("company")).id;
                            ApiClient.deleteCompanySeries(companyId,props.data.id).then(
                                response => {
                                    rowData.splice(props.rowIndex, 1)
                                    props.agGridReact.api.setRowData(rowData)
                                    toast.success(t("deleteSucceded"))
            
                                },
                                error => {
                                    console.error(error.response)
                                    toast.error("Error: " + error.response.data.message)
                                }
                            )
                        }
                        else{
                            rowData.splice(props.rowIndex, 1)
                            props.agGridReact.api.setRowData(rowData)
                        }

                    }
                },
                {
                    label: t('No'),
                    onClick: () => {
                    }
                }
            ]
        });


    }
    useImperativeHandle(ref, () => {
        var rowNode = props.agGridReact.api.getRowNode(props.rowIndex);
        var countDisplayedRows = props.agGridReact.api.getDisplayedRowCount() - 1;
        if (props.rowIndex == countDisplayedRows) {
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }
        return {
            refresh(params) {

            }
        };
    });
    return (
        <span><button onClick={handleInputChange} disabled={disabled} className="btn btn-sm btn-danger circle-btn"><i className="fa fa-times"></i></button></span>
        // <span className="editableCell">{branch != null && branch.label != undefined ? branch.label : <span className="addtext"></span>}</span>
    );
});


const IntValRenderer = forwardRef((props, ref) => {


    const [name, setName] = useState(props.value);

    useImperativeHandle(ref, () => {
        return {
            refresh(params) {
                var val = props.getValue();
                if (val != undefined) {
                    setName(val);
                }

            }
        };
    });
    return (
        <span className="editableCell">{name != undefined ? name : <div><span className="addtext"></span><div className="addtextafter"><i className="fa fa-edit"></i></div></div>}</span>

    );
});



const SimpleTextRenderer = forwardRef((props, ref) => {


    const [name, setName] = useState(props.value);

    useImperativeHandle(ref, () => {
        return {
            refresh(params) {
                var val = props.getValue();
                if (val != undefined) {
                    setName(val);
                }

            }
        };
    });
    return (
        <span className="editableCell">{name != undefined ? name : <div><span className="addtext"></span><div className="addtextafter"><i className="fa fa-edit "></i></div></div>}</span>

    );
});


const BranchRenderer = forwardRef((props, ref) => {


    const [branch, setBranch] = useState(props.value);

    useImperativeHandle(ref, () => {
        return {
            refresh(params) {
                var val = props.getValue();
                if (val != undefined) {
                    var rowNode = props.agGridReact.api.getRowNode(props.rowIndex);
                    setBranch(val);

                }

            }
        };
    });
    // var rowNode=props.agGridReact.api.getRowNode(props.rowIndex);
    // var oldval=rowNode.data.product!=undefined?rowNode.data.product.value:null;
    return (
        <span className="editableCell">{branch != null && branch.label != undefined ? branch.label : <div><span className="addtext"></span><div className="addtextafter"><i className="fa fa-edit "></i></div></div>}</span>

    );
});


const IntValEditor = forwardRef((props, ref) => {


    const [selectedVal, setSelectedVal] = useState(props.value);
    const [editing, setEditing] = useState(true);
    const refContainer = useRef(null);
    useEffect(() => {
        focus();
    }, []);

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return selectedVal != null ? selectedVal : props.value;
            },

            isPopup() {
                return true;
            }
        };
    });

    useEffect(() => {
        if (!editing) {
            props.api.stopEditing();
        }
    }, [editing]);

    const focus = () => {
        window.setTimeout(() => {
            let container = ReactDOM.findDOMNode(refContainer.current);
            if (container) {
                container.focus();
            }
        });
    };


    const onInputChange = val => {
        if (Number.isInteger(parseInt(val.target.value))) {
            setSelectedVal(parseInt(val.target.value).toString());
        }

    }



    return (

        <input type="text" onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
            }
        }} pattern="[0-9]*" className="ag-cell-inline-editing"
            defaultValue={selectedVal} ref={refContainer}
            onChange={onInputChange}>
        </input>
    );
});


const BranchEditor = forwardRef((props, ref) => {


    const [selectedVal, setSelectedVal] = useState(props.value);
    const [editing, setEditing] = useState(true);
    const refContainer = useRef(null);
    useEffect(() => {
        focus();
       
    }, []);

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return selectedVal != null ? selectedVal : { value: props.value, value: props.label };
            },

            isPopup() {
                return true;
            }
        };
    });

    useEffect(() => {
        if (!editing) {
            props.api.stopEditing();
        }
    }, [editing]);

    const focus = () => {
        window.setTimeout(() => {
            let container = ReactDOM.findDOMNode(refContainer.current);
            if (container) {
                container.focus();
            }
        });
    };


    const onSelectChange = val => {
        setSelectedVal(val);
        setTimeout(function () {
            props.api.stopEditing();
            props.node.setDataValue("branch", val);

        })
    }
    const onMouseOver = e => {
        refContainer.current.setState({ isOpen: true })
        let container = ReactDOM.findDOMNode(e.target).parentNode.getElementsByTagName("input")[0];
        if (container != undefined) {
            container.focus();
        }
    }

    return (<div style={{ width: props.column.actualWidth + 'px' }}>

        <span onMouseOver={onMouseOver} className="selectCell">
            <Select
                autosize={true}
                menuIsOpen={true}
                defaultValue={selectedVal}
                onChange={onSelectChange}
                onChangeValue={onSelectChange}
                options={props.colDef.cellRendererParams.options} ref={refContainer}>
            </Select>
        </span>
    </div>
    );
});

const InvoiceTypeSeriesEditableGrid = forwardRef((props, ref) => {

    const [content, setContent] = React.useState(0);
    const history = useHistory();
    const [gridApi, setGridApi] = useState(null);
    const rowStyle = { background: 'white' };
    const { t } = useTranslation();
    const [hasError, setHasError] = useState(false);


    const onGridReady = params => {
        setGridApi(params.api);

        var data = params.api.gridOptionsWrapper.gridOptions;
        if (data != undefined && data.length > 0) {
            data.push({ name: undefined, id: undefined, branch: { value: undefined, label: undefined }, startNumber: undefined })
            params.api.setRowData(data)
        }
        else {
            data = [];
            data.push({ name: undefined, id: undefined, branch: { value: undefined, label: undefined }, startNumber: undefined })
            params.api.setRowData(data)
        }

    };


    const isValid = (nodeData) => {
        return nodeData.name != ""
            && nodeData.name != undefined
            && nodeData.startNumber != undefined
            && nodeData.branch != undefined
            && nodeData.branch.value != undefined;
    }

    // set background colour on even rows again, this looks bad, should be using CSS classes
    const getRowStyle = params => {

        console.log(params.api.rowModel.rowsToDisplay.length )
        if (params.node.rowIndex == params.api.rowModel.rowsToDisplay.length - 1) {
            return { background: '#d2f6fac7' };
        }
        else {
            if (!isValid(params.node.data)) {
                setHasError(true)
                return { background: '#ffb09c' };
            }
            else {
                setHasError(false)
                return { background: 'white' };
            }

        }
    };

    const onRowDataUpdated = (params) => {
        // var rowData=[];
        // gridApi.forEachNode(node => rowData.push(node.data));
        // rowData=rowData.pop();
        // props.onUpdateData(rowData)
    }

    const onCellValueChanged = (params) => {

        // params.node.rowIndex
        var nodeIndex = params.node.rowIndex;
        var rowNode = gridApi.getRowNode(nodeIndex);
        var countDisplayedRows = gridApi.getDisplayedRowCount() - 1;
        if (nodeIndex == countDisplayedRows && params.newValue != undefined) {
           
            if (params.column != undefined) {
                var colid = params.column.colId;
                var branchOptions=gridApi.columnController.columnDefs[2].cellRendererParams.options;

                switch (colid) {
                    case "name": {
                        debugger;
                        rowNode.setDataValue("startNumber","0");
                        if(branchOptions.length==1){
                            rowNode.setDataValue("branch", {value:branchOptions[0].value,label:branchOptions[0].label});
                        }
    
                       
                        break;
                    }
                    case "startNumber": {
                        if(branchOptions.length==1){
                            rowNode.setDataValue("branch", {value:branchOptions[0].value,label:branchOptions[0].label});
                        }
                       
                        break;
                    }
                    
                }
    
            }
            addRow(nodeIndex);
    
        }



    }

    const addRow = () => {

        var data = { name:undefined,startNumber:undefined, branch: { value: undefined, label: undefined } };
        let rowData = [];
        gridApi.forEachNode(node => rowData.push(node.data));
        rowData.push(data)
        gridApi.setRowData(rowData)
        var countDisplayedRows = gridApi.getDisplayedRowCount() - 1;
        gridApi.ensureIndexVisible(countDisplayedRows, 'top');
    }

    useImperativeHandle(ref, () => ({

        getData() {
            var items = [];
            gridApi.forEachNode(node => {
                items.push(node.data);
            });
            items.pop();
            return items
        },
        hasError() {
            return hasError;
        },
        setData(data) {
            var newseries = [];
            data.forEach(element => {
                var _d = {
                    value: element.branch.id,
                    label: element.branch.name
                }
                element.branch = _d;
                element.startNumber = element.startNumber;
                newseries.push(element);
            });

            newseries.push({ name: undefined, id: undefined, branch: { value: undefined, label: undefined }, startNumber: undefined });
            gridApi.setRowData(newseries)

        }

    }));


    return (

        <div className="row">
            <div style={{ width: '100%', height: '100%' }}>

                <div className="ag-theme-alpine editableSmGrid seriesGrid" style={{ height: 240, width: '100%' }}>
                    <AgGridReact
                        onGridReady={onGridReady}
                        rowStyle={rowStyle}
                        getRowStyle={getRowStyle}
                        style={{ width: '100%', height: '300px' }}
                        onCellValueChanged={onCellValueChanged}
                        stopEditingWhenGridLosesFocus={true}
                        borders={true}
                        singleClickEdit={true}
                        rowData={content.series}
                        defaultColDef={{
                            flex: 1,
                            resizable: false,
                            sortable: false,
                            wrapText: true,
                            suppressMovable:true,
                        }}
                        frameworkComponents={{
                            branchEditor: BranchEditor,
                            branchRenderer: BranchRenderer,
                            actionsRenderer: ActionsRenderer,
                            simpleTextRenderer: SimpleTextRenderer,
                            intValRenderer: IntValRenderer,
                            intValEditor: IntValEditor
                        }}
                    >
                        <AgGridColumn flex={1} minWidth={200} headerClass="required" editable={true} cellRenderer="simpleTextRenderer" headerName={t("SeriesName")}  field="name"></AgGridColumn>
                        <AgGridColumn flex={1} minWidth={200} headerClass="required" editable={true} headerName={t("startNumber")} cellRenderer="intValRenderer" cellEditor="intValEditor" field="startNumber"></AgGridColumn>
                        <AgGridColumn flex={2} minWidth={250} headerClass="required"  cellRendererParams={{ options: props.optionBranches}} cellRenderer="branchRenderer" cellEditor="branchEditor" editable={true} headerName={t("Branch")} field="branch"></AgGridColumn>
                        <AgGridColumn flex={1} minWidth={100} headerName={t("Actions")} cellRenderer="actionsRenderer" field="action"></AgGridColumn>

                    </AgGridReact>
                </div>

            </div>
        </div>
    );
});

export default InvoiceTypeSeriesEditableGrid 


