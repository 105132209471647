import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import ApiClient from "../../services/api-client"
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { useHistory } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import moment from 'moment'

const ActionsRenderer = forwardRef((props, ref) => {

  useImperativeHandle(ref, () => {
    return {
      refresh(params) {

      }
    };

  });
  const onUserEdit = () => {
    console.log(props.data)
    console.log("==============================")
    console.log("USER COMPANIES")
    console.log("-----------------")
    props.data.companies.forEach(element => {
      console.log(element.name + " - " + element.taxpayerId)
    });
    console.log("-----------------")
    console.log("==============================")
  }
  return (

    <span><button className="btn btn-sm btn-warning" onClick={onUserEdit}><i className="fa fa-edit"></i></button></span>
  );
});

const DateRenderer = forwardRef((props, ref) => {

  const [val, setVal] = useState(props.value);

  useImperativeHandle(ref, () => {
    return {
      refresh(params) {
        var val = props.getValue();
        if (val != undefined) {
          setVal(val);
        }
      }
    };

  });
  return (

    <span>{val != undefined ? moment(val).format('DD/MM/YYYY hh:mm') : ""}</span>
  );
});





const CompaniesRenderer = forwardRef((props, ref) => {

  const [val, setVal] = useState(props.value);

  useImperativeHandle(ref, () => {
    return {
      refresh(params) {
        var val = props.getValue();
        if (val != undefined) {
          setVal(val);
        }
      }
    };

  });

  const getCompanies = () => {
    var rows = [];
    props.data.companies.forEach(e => {
      var tr = <tr><td>{e.name}</td><td>-</td><td>{(e.taxpayerId)}</td></tr>
      rows.push(tr)
    })
    return <table>{rows}</table>
  }

  return (

    <span>{getCompanies()}</span>
  );
});


const BooleanRenderer = forwardRef((props, ref) => {

  const [val, setVal] = useState(props.value);

  useImperativeHandle(ref, () => {
    return {
      refresh(params) {
        var val = props.getValue();
        if (val != undefined) {
          setVal(val);
        }
      }
    };

  });
  return (

    <span>{val ? "NO" : "YES"}</span>
  );
});
export default function UserSearch() {


  const [content, setContent] = useState([]);
  const history = useHistory();
  const [gridApi, setGridApi] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {

    ApiClient.getUsers().then(
      response => {
        setContent(response.data);

      },
      error => {
        setContent(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString())
      }
    );

  }, []);

  const pagination = true;
  const paginationPageSize = 10;


  const onGridReady = params => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
    window.addEventListener('resize', function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit();
      });
    });

    params.api.sizeColumnsToFit();
  };
  const onQuickFilterChanged = e => {
    gridApi.setQuickFilter(e.target.value);
  };
  const onClickPay = data => {
    alert(data);
  }


  return (

    <div className="container-fluid">
      <header className="jumbotron">
        <h3>{t("Users")}</h3>

      </header>
      <div style={{ width: '100%', height: '100%' }}>
        <div className="row">
          <div className="offset-md-4 col-md-4">
            <p className="text-center">
              <input className="form-control" placeholder={t("Search")} onChange={onQuickFilterChanged} />
            </p>
          </div>
          {/* <div className="offset-md-1 col-md-3 addmodelBtnContainer">
                        <button class="btn btn-skin" href="" onClick={function () { return history.push("/invoicetype"); }}>
                            {t("invtypeAdd")}  <i className="fa  fa-plus"></i>
                        </button>
                    </div> */}
        </div>

        <div
          id="myGrid"
          style={{
            height: '100%',
            width: '100%',
          }}
          className="ag-theme-alpine-dark"
        >
          <div className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
            <AgGridReact
              pagination={pagination}
              paginationPageSize={paginationPageSize}
              onGridReady={onGridReady}
              style={{ width: '100%', height: '100%;' }}
              rowData={content}
              defaultColDef={{
                flex: 1,
                resizable: true,
                wrapText: true,
                autoHeight: true,
              }}
              frameworkComponents={{
                actionsRenderer: ActionsRenderer,
                dateRenderer: DateRenderer,
                booleanRenderer: BooleanRenderer,
                companiesRenderer: CompaniesRenderer
              }}
            >
              <AgGridColumn flex={3} minWidth={300} headerName={t("Name")} sortable={true} filter="agTextColumnFilter" field="name"></AgGridColumn>
              <AgGridColumn flex={3} minWidth={300} headerName={t("Email")} sortable={true} filter="agTextColumnFilter" field="email"></AgGridColumn>
              <AgGridColumn flex={3} minWidth={300} headerName={t("transactionId")} sortable={true} filter="agTextColumnFilter" field="transactionId"></AgGridColumn>
              <AgGridColumn flex={2} minWidth={200} headerName={t("creationDate")} cellRenderer="dateRenderer" sortable={true} filter="agTextColumnFilter" field="creationDate"></AgGridColumn>
              <AgGridColumn flex={2} minWidth={200} headerName={t("lastTransactionDate")} cellRenderer="dateRenderer" sortable={true} filter="agTextColumnFilter" field="lastTransactionDate"></AgGridColumn>
              <AgGridColumn flex={1} minWidth={100} headerName={t("daysRemaining")} sortable={true} filter="agTextColumnFilter" field="daysRemaining"></AgGridColumn>
              <AgGridColumn flex={1} minWidth={100} headerName={t("monthInvoiceCounter")} sortable={true} filter="agTextColumnFilter" field="monthInvoiceCounter"></AgGridColumn>
              <AgGridColumn flex={1} minWidth={100} headerName={t("wizard")} cellRenderer="booleanRenderer" sortable={true} filter="agTextColumnFilter" field="wizard"></AgGridColumn>
              <AgGridColumn flex={1} minWidth={100} headerName={t("affiliate")} sortable={true} filter="agTextColumnFilter" field="affiliate"></AgGridColumn>
              <AgGridColumn flex={1} minWidth={400} headerName={t("companies")} cellRenderer="companiesRenderer" sortable={true} filter="agTextColumnFilter" field="companies"></AgGridColumn>

              <AgGridColumn flex={1} minWidth={100} headerName={t("Actions")} cellRenderer="actionsRenderer" onClickPay={onClickPay} field="actions"></AgGridColumn>

            </AgGridReact>
          </div>

        </div>
      </div>
    </div>
  );
}


