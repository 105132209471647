import React, { useEffect, useRef, useState } from "react";
import { useParams } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import ApiClient from "../services/api-client"
import Select from 'react-select'
import { CustomConfirmAlert as confirmAlert } from '../components/custom-confirm-alert';
import { useHistory } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import CustomerContactsGrid from '../components/editable-aggrids/customer-contacts-grid'
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import BlockUi from 'react-block-ui';
import FormLayout from '../components/form-layout'
import Tippy from '@tippyjs/react';

export default function CustomerAdd() {


    const { id } = useParams();

    const [content, setContent] = useState(0);
    const [contacts, setContactsData] = useState({});
    const { t } = useTranslation();
    const [blockuiLoading, setBlockuiLoading] = useState(false);

    const customerContactsGridRef = useRef();

    const validationSchema = Yup.object().shape({

        name: Yup.string().required(t("fieldRequired"))
            .max(80, t("maxLengthValidation", { maxlength: 80 })),
        subjectField: Yup.string().max(128, t("maxLengthValidation", { maxlength: 128 })),
        // taxpayerId: Yup.string().nullable().transform((curr, orig) => orig === '' ? null : curr).matches(/^[0-9]+$/, "Must be only digits")
        //     .min(9, 'Must be exactly 9-10 digits')
        //     .max(10, 'Must be exactly 9-10 digits'),
        taxpayerId: Yup.string().nullable(),
    });


    const { register, getValues, control, handleSubmit, setValue, reset, methods, formState: { errors }, clearErrors } = useForm({
        resolver: yupResolver(validationSchema)
    });
    const history = useHistory();

    const transformCustomer = (customer) => {
        var company = JSON.parse(localStorage.getItem("company"));
        customer.company = { id: company.id };
        if (id != undefined) {
            customer.id = id;
        }
        if (customer.taxOffice != undefined) {
            if (customer.taxOffice.value == undefined) {
                customer.taxOffice = null;
            }
            else {
                customer.taxOffice = { id: customer.taxOffice.value }
            }
        }
        if (customer.category != undefined) {
            customer.category = { id: customer.category.value }
        }
        if (customer.country != undefined) {
            customer.country = customer.country.value
        }
        customer.taxpayerId = customer.taxpayerId == "" ? null : customer.taxpayerId;
        customer.contacts = customerContactsGridRef.current.getData();
        return customer;
    }

    const onSubmit = (cust, e) => {

        e.preventDefault();

        var customer=JSON.parse(JSON.stringify(cust));

        var company = JSON.parse(localStorage.getItem("company"));

        var entriesHasError = customerContactsGridRef.current.hasError() && customerContactsGridRef.current.getData().length > 0;

        if (entriesHasError) {
            toast.error(t("entriesErrors"), 5000)
            return;
        }
        var _customer = transformCustomer(customer);

        confirmAlert({
            title: t("submitConfirm"),
            message: t("areYouSure"),
            buttons: [
                {
                    label: t('Yes'),
                    className: "accept",
                    onClick: () => ApiClient.saveCompanyCustomer(company.id, _customer).then(
                        response => {

                            if (id === undefined) {
                                // e.target.reset();
                                // reset();
                                // setValue("taxOffice",null)
                                history.push("/")
                                history.push("/customer");
                                document.getElementsByClassName("innerContainerMain")[0].scrollTo(0, 0);

                            }
                            toast.success(t("saveSucceded"))

                        },
                        error => {
                            console.error(error.response);
                            toast.error("Error: " + error.response.data.message);
                        }
                    )
                },
                {
                    label: t('No')
                }
            ]
        });
    }

    const taxOfficeOnChange = val => {
        setValue("taxOffice", val);
        clearErrors("taxOffice");
    }
    const onDeleteClick = e => {
        var companyId = JSON.parse(localStorage.getItem("company")).id;
        e.preventDefault();
        confirmAlert({
            title: t("submitConfirm"),
            message: t("areYouSureToDelete"),
            buttons: [
                {
                    label: t('Yes'),
                    onClick: () => ApiClient.deleteCompanyCustomer(companyId, id).then(
                        response => {
                            history.push("/customers");
                            toast.success(t("deleteSucceded"))

                        },
                        error => {
                            toast.error("Error: " + error.data)
                            console.error(error.data)
                        }
                    )
                },
                {
                    label: t('No'),
                    onClick: () => { return }
                }
            ]
        });

    }

    useEffect(() => {
        let mounted = true;

        var companyId = JSON.parse(localStorage.getItem("company")).id;
        ApiClient.getCustomerView(companyId).then(
            response => {

                var _data = response.data;
                var tos = _data.taxOffices;
                var cos = _data.countries;
                var taxOffices = []
                tos.forEach(element => {
                    var to = {
                        value: element.id,
                        label: element.name
                    }
                    taxOffices.push(to);
                });

                var countries = []
                var DefaultCountry;
                cos.forEach(element => {
                    var to = {
                        value: element.alpha2,
                        label: t(element.alpha2)
                    }
                    countries.push(to);
                    if (element.alpha2 == "GR") {
                        DefaultCountry = to
                    }
                });


                if (id != undefined) {

                    ApiClient.getCompanyCustomer(companyId, id).then(
                        response => {

                            if (mounted) {
                                var cust = response.data;

                                if (response.data == "") {
                                    history.push("/")
                                    history.push("/customer")
                                    toast.error("Customer not found: ")
                                }


                                var customerContacts = [];
                                if (cust.contacts != null) {
                                    cust.contacts.forEach(element => {

                                        var _d = {
                                            id: element.id,
                                            name: element.name,
                                            email: element.email,
                                            phone: element.phone,
                                        }

                                        customerContacts.push(_d)
                                    });
                                }


                                setContent({
                                    customer: cust,
                                    taxOffices: taxOffices,
                                    countries: countries,
                                    contacts: customerContacts,
                                });

                                customerContactsGridRef.current.setData(customerContacts)

                                setValue("name", cust.name)
                                setValue("code", cust.code)
                                setValue("taxpayerId", cust.taxpayerId)
                                setValue("address", cust.address)
                                setValue("city", cust.city)
                                setValue("number", cust.number)
                                setValue("balance", parseFloat(cust.balance).toFixed(2))
                                setValue("postalCode", cust.postalCode)
                                setValue("subjectField", cust.subjectField)
                                if (cust.country != null) {
                                    setValue("country", { value: cust.country.alpha2, label: t(cust.country.alpha2) })
                                }


                                if (cust.category != null) {
                                    setValue("category", { value: cust.category.id, label: cust.category.name })

                                }
                                if (cust.taxOffice != null) {
                                    setValue("taxOffice", { value: cust.taxOffice.id, label: cust.taxOffice.name })

                                }
                            }
                        },
                        error => {
                            toast.error("Error: " + error.response.data.message)
                            console.error(error.response)
                        }
                    );
                }
                else {
                    reset();
                    if (mounted) {
                        setContent({ customer: null, countries: countries, taxOffices: taxOffices, contacts: [{ id: undefined, name: undefined, address: undefined }] });
                    }

                    setValue("balance", "0.00")
                    setValue("country", DefaultCountry)
                }
            },
            error => {
                toast.error("Error: " + error.data)
                console.error(error.data)
            }
        );
        return () => mounted = false;
    }, []);
    var deleteBtn = "";
    if (id != undefined) {
        deleteBtn = <button onClick={onDeleteClick} className="btn btn-danger deletebtn">
            {t("Delete")}
        </button>
    }
    
    const onClickFetchTaxPayerId = () => {
        var company = JSON.parse(localStorage.getItem("company"));
        var AFMcalledfor = getValues().taxpayerId;
        var AFMcalledby = company.taxpayerId;
        setBlockuiLoading(true)
        ApiClient.checkAfm(AFMcalledfor, AFMcalledby).then(
            response => {
                try {
                    var res = response.data.Body.rgWsPublicAfmMethodResponse;

                    var error = res.pErrorRec_out;
                    if (error) {
                        if (error.errorCode != null) {
                            toast.error(error.errorDescr);
                            setBlockuiLoading(false)
                            return;
                        }
                    }
    
    
                    var basic_rec = res.RgWsPublicBasicRt_out;
                    var firm_act_tab = res.arrayOfRgWsPublicFirmActRt_out;
    
                    setValue("name", basic_rec.onomasia);
                    setValue("address", basic_rec.postalAddress);
                    if (firm_act_tab) {
                        if (Array.isArray(firm_act_tab.RgWsPublicFirmActRtUser)) {
                            setValue("subjectField", firm_act_tab.RgWsPublicFirmActRtUser[0].firmActDescr);
                        }
                        else {
                            setValue("subjectField", firm_act_tab.RgWsPublicFirmActRtUser.firmActDescr);
    
                        }
                    }
                    setValue("postalCode", basic_rec.postalZipCode);
                    setValue("city", basic_rec.postalAreaDescription);
                    setValue("number", basic_rec.postalAddressNo);
                    setValue("taxOffice", { "value": basic_rec.doy, "label": basic_rec.doyDescr });
                    setBlockuiLoading(false)
                }
                catch(e){
                    setBlockuiLoading(false)
                }
               
            },
            error => {
                if (error.response) {
                    toast.error(error.message + ": " + error.response.data.message)
                    console.error(error.response.data.message);
                }

                setBlockuiLoading(false)
            }
        )
    }

    const onBalanceChange = (e) => {

        var newbalance = e.target.value ? e.target.value : 0.00;
        setValue("balance", parseFloat(newbalance).toFixed(2))

    }

    return (
        /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
        <BlockUi tag="div" blocking={blockuiLoading}>

            <div className="container" style={{padding:0}}> 
                <form autoComplete="off" id="customer_form" onSubmit={handleSubmit(onSubmit)}>

                    <FormLayout title={t('customerAdd')}

                        headerRightContent={
                            <div className="offset-md-9 col-md-3 addmodelBtnContainer">
                                <a className="btn btn-skin header-btn" onClick={function () { history.push("/customers") }}>
                                    {t("customerSearch")}  <i className="fa fa-search"></i>
                                </a>
                            </div>
                        }
                        icon={
                            <i className="fa fa-user-circle-o skin"></i>
                        }
                        footer={
                            <div className="form-group text-center">
                                <button type="submit" className="btn btn-skin">
                                    {t("Save")}
                                </button>
                                {deleteBtn}
                            </div>
                        }
                    >
                        <div className="row">
                            <div className="col-md-4  form-group required">
                                <label   className="control-label inputlabel">{t("Name")}</label>
                                <input
                                    name="name"
                                    type="text"
                                    autoComplete="no"
                                    {...register('name', { required: true, maxLength: 30 })}
                                    className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                />
                                <div className="invalid-feedback">{errors.name?.message}</div>
                            </div>


                            <div className="col-md-4 form-group">
                                <label className="control-label inputlabel">{t("taxpayerId")}</label>
                                <div className="input-group mb-4">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text fetchTaxPayerId" onClick={onClickFetchTaxPayerId}><i className="fa fa-search"></i></span>
                                    </div>
                                    <input
                                        // maxLength={10}
                                        name="taxpayerId"
                                        type="text"
                                        {...register('taxpayerId')}
                                        className={`form-control ${errors.taxpayerId ? 'is-invalid' : ''}`}
                                    />
                                    <div className="invalid-feedback">{errors.taxpayerId?.message}</div>
                                </div>
                            </div>


                            <div className="col-md-4 selectInputContainer form-group required">
                                <label className="control-label inputlabel">{t("Country")}</label>
                                <Controller
                                    render={({ field }) => (
                                        <Select {...field}
                                            options={content.countries}
                                            className={`form-control  ${errors.country ? 'is-invalid' : ''}`}
                                            placeholder={t('Select')}
                                        >

                                        </Select>
                                    )}
                                    control={control}
                                    rules={{ maxLength: 100 }}
                                    name="country"
                                    defaultValue=""
                                />
                                <div className="invalid-feedback">{errors.country?.message}</div>
                            </div>

                            <div className="col-md-4 selectInputContainer form-group">
                                <label className="control-label inputlabel">{t("taxOffice")}</label>
                                <Controller
                                    render={({ field }) => (
                                        <Select {...field}
                                            isClearable
                                            options={content.taxOffices}
                                            onChange={taxOfficeOnChange}
                                            className={`form-control  ${errors.taxOffice ? 'is-invalid' : ''}`}
                                            placeholder={t('Select')}
                                        >
                                        </Select>
                                    )}
                                    control={control}
                                    rules={{ maxLength: 100 }}
                                    name="taxOffice"
                                    defaultValue=""
                                />
                                <div className="invalid-feedback">{errors.taxOffice?.message}</div>
                            </div>

                            <div className="col-md-4  form-group">
                                <label className="control-label inputlabel">{t("subjectField")}</label>
                                <input
                                    name="subjectField"
                                    type="text"
                                    {...register('subjectField', { maxLength: 128 })}
                                    className={`form-control ${errors.subjectField ? 'is-invalid' : ''}`}
                                />
                                <div className="invalid-feedback">{errors.subjectField?.message}</div>
                            </div>


                            <div className="col-md-2  form-group">


                                <label className="form-check-label inputlabel">{t("Balance")}                                  
                                    <Tippy trigger="click" content={<span className="tooltip-skin">{t("balanceInfo")}</span>}>
                                        <i className="fa fa-x2 fa-info-circle infoicon"></i>
                                    </Tippy>
                                </label>

                                <input
                                    name="balance"
                                    type="text"
                                    {...register('balance')}
                                    pattern="[+-]?\d+(?:[.,]\d+)?"
                                    onBlur={e => onBalanceChange(e)}
                                    className={`form-control ${errors.balance ? 'is-invalid' : ''}`}
                                />
                                <div className="invalid-feedback">{errors.balance?.message}</div>
                            </div>

                            <div><br></br></div>

                            <div className="col-md-2  form-group">
                                <label className="control-label inputlabel">{t("City")}</label>
                                <input
                                    name="city"
                                    type="text"
                                    {...register('city', { maxLength: 64 })}
                                    className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                                />
                                <div className="invalid-feedback">{errors.city?.message}</div>
                            </div>

                            <div className="col-md-6  form-group">
                                <label className="control-label inputlabel">{t("Address")}</label>

                                <input
                                    name="address"
                                    autoComplete="no"
                                    type="text"
                                    {...register('address', { maxLength: 64 })}
                                    className={`form-control ${errors.address ? 'is-invalid' : ''}`}
                                />
                                <div className="invalid-feedback">{errors.address?.message}</div>
                            </div>

                            <div className="col-md-2  form-group">
                                <label className="control-label inputlabel">{t("Number")}</label>
                                <input
                                    name="number"
                                    // type="number"
                                    {...register('number', { maxLength: 8 })}
                                    className={`form-control ${errors.number ? 'is-invalid' : ''}`}
                                />
                                <div className="invalid-feedback">{errors.number?.message}</div>
                            </div>

                            <div className="col-md-2  form-group">
                                <label className="control-label inputlabel">{t("PostalCode")}</label>
                                <input
                                    name="postalCode"
                                    type="text"
                                    {...register('postalCode', { maxLength: 10 })}
                                    className={`form-control ${errors.postalCode ? 'is-invalid' : ''}`}
                                />
                                <div className="invalid-feedback">{errors.postalCode?.message}</div>
                            </div>

                        </div>

                        <div><br></br></div>
                        <div>

                            <CustomerContactsGrid ref={customerContactsGridRef}></CustomerContactsGrid>
                        </div>
                    </FormLayout>

                </form>

            </div>
        </BlockUi>
    );
}