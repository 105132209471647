import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import ApiClient from "../services/api-client"
import Select, { components, GroupProps } from 'react-select';
import { CustomConfirmAlert as confirmAlert } from '../components/custom-confirm-alert';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom"
import InvoiceTypeSeriesEditableGrid from '../components/editable-aggrids/invoicetype-series-grid'
import InvoiceTypeClassificationsGrid from '../components/editable-aggrids/invoicetype-classifications-grid'
import Commons from '../util/commons'
import FormLayout from '../components/form-layout'

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

export default function InvoiceTypeSeriesAdd() {


    const { id } = useParams();
    const { t } = useTranslation();
    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t("fieldRequired")),
        myDataInvoiceType: Yup.object().required(t("fieldRequired")),
    });

    const [content, setContent] = useState({});
    const { register, control, handleSubmit, setValue, reset, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });
    const history = useHistory();
    const prodClassificationGridRef = useRef();
    const invoiceTypeSeriesEditableGridRef = useRef();

    const [myDataInvoiceType, setMyDataInvoiceType] = useState();
    const [incomeClassCategories, setIncomeClassCategories] = useState();
    const [optionBranches, setOptionBranches] = useState([]);



    function formatModel(invoicetype) {

        var companyId = JSON.parse(localStorage.getItem("company"));
        var _series = [];
        var seriesData = invoiceTypeSeriesEditableGridRef.current.getData();

        seriesData.forEach(item => {
            if (item.name !== undefined && item.name !== "") {
                var ser = {
                    id: item.id,
                    name: item.name,
                    branch: { id: item.branch.value },
                    startNumber: item.startNumber,
                }
                _series.push(ser);
            }
        })
        var _invoiceTypeClassifications = prodClassificationGridRef.current.getData();
        var newInvType = {
            name: invoicetype.name,
            company: companyId,
            myDataInvoiceType: invoicetype.myDataInvoiceType.value,
            series: _series,
            invoiceTypeClassifications: _invoiceTypeClassifications
        };
        if (id !== undefined) {

            newInvType.id = id;
        }


        return newInvType;
    }

    const onSubmit = (invoicetype, e) => {
        e.preventDefault()
        var companyId = JSON.parse(localStorage.getItem("company")).id;
        var entriesHasError = invoiceTypeSeriesEditableGridRef.current.hasError() && invoiceTypeSeriesEditableGridRef.current.getData().length > 0;
        var entriesHasError2 = prodClassificationGridRef.current.hasError() && prodClassificationGridRef.current.getData().length > 0;

        if (entriesHasError || entriesHasError2) {
            toast.error(t("entriesErrors"), 5000)
            return;
        }
        var invtype = formatModel(invoicetype);
        console.debug(invtype);
        confirmAlert({
            title: t("submitConfirm"),
            message: t("areYouSure"),
            buttons: [
                {
                    label: t('Yes'),
                    onClick: () => ApiClient.saveCompanyInvoiceType(companyId,invtype).then(
                        response => {
                            if (id === undefined) {
                                history.push("/")
                                history.push("/invoiceType");
                            }
                            toast.success(t("saveSucceded"))
                            console.debug(response.data)
                            if (Commons.getDefaults("invoiceType")) {
                                // var _invtype=Commons.getDefaults().invoiceType;
                                // if(_invtype){
                                //     var _id = Commons.getDefaults().invoiceType.value;
                                //     if (id == _id) {
                                //         Commons.setDefault("invoiceType", undefined);
                                //         Commons.setDefault("series", undefined);
                                //     }
                                // }
                                Commons.setDefault("invoiceType", undefined);
                                Commons.setDefault("series", undefined);
                            }


                        },
                        error => {
                            toast.error("Error: " + error.response.data.message)
                            console.error(error.response.data)
                        }
                    )
                },
                {
                    label: t('No'),
                    onClick: () => {

                    }
                }
            ]
        });



    }

    const onDeleteClick = e => {
        e.preventDefault();
        confirmAlert({
            title: t('submitConfirm'),
            message: t('areYouSureToDelete'),
            buttons: [
                {
                    label: t('Yes'),
                    onClick: () => ApiClient.deleteInvoiceType(id).then(
                        response => {
                            history.push("/invoicetypes");
                            toast.success(t("deleteSucceded"))

                        },
                        error => {
                            toast.error("Error: " + error.data)
                            console.error(error.data)
                        }
                    )
                },
                {
                    label: t('No')
                }
            ]
        });

    }
    useEffect(() => {
        var companyId = JSON.parse(localStorage.getItem("company")).id;



        ApiClient.getCompanyBranches(companyId).then(
            response => {
                var _data = response.data;
                var optionBranches = []
                _data.forEach(element => {
                    var _d = {
                        value: element.id,
                        label: element.name,
                        model: element,
                    }
                    optionBranches.push(_d)
                });
                setOptionBranches(optionBranches)
            },
            error => {
                setContent(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString())
            }
        );


        ApiClient.getMyDataInvoiceTypes().then(response => {
            var data = response.data;
            var optionsExpenses = [];
            var optionsIncomes = [];
            var options = [];

            data.forEach(element => {
              
                var _enumVal="invoice"+element.key.replace(".","_");
                var postfix=" ("+element.myDataKey+")";                
                if(element.isExpense){
                    optionsExpenses.push({
                        value: _enumVal,
                        label:t(_enumVal) +postfix,
                    });
                }
                else{
                    optionsIncomes.push({
                        value: _enumVal,
                        label:t(_enumVal) +postfix,
                    });
                }
            });

            options.push({
                options: optionsIncomes,
                label:t("InvoiceIncomes"),
            });

            options.push({
                options: optionsExpenses,
                label:t("InvoiceExpenses"),
            });

            if (id !== undefined) {
                ApiClient.getCompanyInvoiceType(companyId, id).then(
                    response => {

                        var type = response.data;
                       

                        setContent({ invoicetype: response.data, myDataInvoiceTypes: options })
                        setValue("name", type.name)
                        setValue("myDataInvoiceType", { value: type.myDataInvoiceType, label: t(type.myDataInvoiceType) +" ("+type.myDataInvoiceTypeKey+")"})
                        setMyDataInvoiceType(type.myDataInvoiceType)

                        var asExpenses = Commons.isOnGroup(type.myDataInvoiceType, "isExpense") || type.selfPricing


                        getIncomeClassificationCategoriesOfInvoiceType(type.myDataInvoiceType, asExpenses)
                        setTimeout(function () {
                            invoiceTypeSeriesEditableGridRef.current.setData(type.series);
                            debugger;
                            prodClassificationGridRef.current.setData(prodClassificationGridRef.current.transformData(type.invoiceTypeClassifications))
                        }, 100)
                    },
                    error => {
                        toast.error("Error: " + error.response.data.message)
                        console.error(error.response.data)
                    }
                );
            }
            else {

                reset();
                setContent({ invoicetype: null, myDataInvoiceTypes: options })
            }
        });
    }, [id]);
    var deleteBtn = "";
    if (id !== undefined) {
        deleteBtn = <button onClick={onDeleteClick} className="btn btn-danger deletebtn">
            {t("Delete")}
        </button>
    }



    const getIncomeClassificationCategoriesOfInvoiceType = (myDataInvoiceType, expenses) => {
        var expenses = Commons.isOnGroup(myDataInvoiceType, "isExpense")
        ApiClient.getClassificationCategoriesOfMyDataInvoice(myDataInvoiceType, expenses).then(response => {
            var data = response.data;
            var _optionIncClassCategories = [];
            data.forEach(element => {
                var _classificationCategory;
                var _classificationValues;
                _classificationCategory = element.classificationCategory;
                _classificationValues = element.classificationValues;
                var prefix = _classificationCategory.replace("CATEGORY_", "") + ") ";
                _optionIncClassCategories.push({
                    value: _classificationCategory,
                    label: prefix + t(_classificationCategory),
                    classificationValues: _classificationValues,
                });
            });
            prodClassificationGridRef.current.toogleCheckbox();
            setIncomeClassCategories(_optionIncClassCategories);
        },
            error => {
                console.error(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString())
            }
        );
    }

    const onInvoiceTypeChange = (val) => {
        if (id == undefined) {
            var ind=val.label.indexOf(" (")
            var name=val.label.substring(0,ind).trim();
            setValue("name", name);
            setValue("myDataInvoiceType", val)
            setMyDataInvoiceType(val.value)
            getIncomeClassificationCategoriesOfInvoiceType(val.value, false)

        }
        else {
            getIncomeClassificationCategoriesOfInvoiceType(content.myDataInvoiceType.value, false)
        }
    }

   
    return (
        <div className="container" style={{padding:0}}>
            <form id="invoice_type_form" onSubmit={handleSubmit(onSubmit)}>
                <FormLayout
                    headerRightContent={
                        <a className="btn btn-skin header-btn" onClick={function () { history.push("/invoicetypes") }}>
                            {t("invtypeSearch")}  <i className="fa fa-search"></i>
                        </a>
                    }
                    footer={
                        <div className="form-group text-center">
                            <button type="submit" className="btn btn-skin">
                                {t("Save")}
                            </button>
                            {/* {deleteBtn} */}
                        </div>
                    }
                    title={t('invtypeAdd')}
                >
                    <div className="row">


                        <div className="col-md-4 form-group selectInputContainer">
                            <label>{t("Type")}</label>
                            <Controller
                                render={({ field }) => (
                                    <Select {...field} onChange={onInvoiceTypeChange}
                                        inputId="invoicetype-react-select"
                                        options={content.myDataInvoiceTypes}
                                        className={`form-control ${errors.myDataInvoiceType ? 'is-invalid' : ''}`}
                                        isDisabled={id != null}
                                        placeholder={t("placeholderSelect")}
                                    >

                                    </Select>
                                )}
                                control={control}
                                rules={{ required: true }}
                                name="myDataInvoiceType"

                                defaultValue=""
                            />


                            <div className="invalid-feedback">{errors.myDataInvoiceType?.message}</div>
                        </div>
                        <div className="col-md-4  form-group">
                            <label>{t("Name")}</label>
                            <input
                                name="name"
                                type="text"
                                disabled={id != null}
                                {...register('name', { required: true, maxLength: 100 })}
                                className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                            />
                            <div className="invalid-feedback">{errors.name?.message}</div>
                        </div>

                    </div>
                    <div><br></br></div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="alert alert-primary small-info" role="alert">
                                <p>{t("seriesNote")}</p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="row">
                                <div className="offset-md-4 col-md-4">
                                    <h5 className="text-center">
                                        {t("invTypeSeries")}
                                    </h5>
                                </div>
                            </div>
                            <InvoiceTypeSeriesEditableGrid
                                ref={invoiceTypeSeriesEditableGridRef}
                                optionBranches={optionBranches}
                                invoiceTypeId={id}>

                            </InvoiceTypeSeriesEditableGrid>
                        </div>
                    </div>
                    <div><br></br></div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="alert alert-primary small-info" role="alert">
                                <p>{t("classificationInvoiceNote")}</p>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="row">
                                <div className="offset-md-4 col-md-4">
                                    <h5 className="text-center">
                                        {t("invoiceTypeClassifications")}
                                    </h5>
                                </div>
                            </div>
                            <InvoiceTypeClassificationsGrid
                                ref={prodClassificationGridRef}
                                myDataInvoiceType={myDataInvoiceType}
                                incomeClassCategories={incomeClassCategories}
                                setIncomeClassCategories={setIncomeClassCategories}
                            ></InvoiceTypeClassificationsGrid>
                        </div>
                    </div>
                    <div><br></br></div>
                </FormLayout>
            </form>

            <div><br></br></div>
        </div>
    );
}