import React, { useState, useEffect, useRef } from "react";
import { useParams } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import ApiClient from "../services/api-client"
import Select from 'react-select'
import { CustomConfirmAlert as confirmAlert } from '../components/custom-confirm-alert';
import { useHistory } from "react-router-dom";
import BranchesEditableGrid from '../components/editable-aggrids/company-branches-grid';
import { useTranslation } from 'react-i18next';
import default_logo from '../img/default.png';
import { useAlert } from 'react-alert'
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import MyDataHelpModal from "../components/modals/mydata-help.component";
import MyDataSubscriptionModal from "../components/modals/mydata-subscription-modal";
import FormLayout from '../components/form-layout'
import BlockUi from 'react-block-ui';
import Tippy from '@tippyjs/react';

import Commons from '../util/commons'


export default function CompanyWithBranchesAdd() {


    const { id } = useParams();
    const { t } = useTranslation();
    const refMyDataHelp = useRef();
    const refMySubscriptionModal = useRef();

    const params = new URLSearchParams(window.location.search);
    let stripeParam = params.get('stripe');
    const branchEditableGrid = useRef();

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t("fieldRequired"))
            .max(80, t("maxLengthValidation", { maxlength: 80 })),
        taxpayerId: Yup.string().required(t("fieldRequired"))
            .max(10, t("maxLengthValidation", { maxlength: 10 })),
        gemh: Yup.string().nullable().transform((curr, orig) => orig === '' ? null : curr).min(11, t("minLengthValidation", { minlength: 11 }))
            .max(12, t("maxLengthValidation", { maxlength: 12 })),
    });

    const [content, setContent] = useState({});



    const { register, getValues, control, handleSubmit, setValue, reset, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });
    const history = useHistory();
    const [logoPath, setLogoPath] = useState(default_logo);
    const [logoFile, setLogoFile] = useState();
    const alert = useAlert()
    const [blockuiLoading, setBlockuiLoading] = useState(false);


    function saveCompany(company) {
        var entriesHasError = branchEditableGrid.current.hasError() && branchEditableGrid.current.getData().length > 0;
        var hasNoEntries = branchEditableGrid.current.getData().length == 0;
        if (entriesHasError) {
            toast.error(t("entriesErrors"), 5000)
            return;
        }

        if (hasNoEntries) {
            toast.error(t("atLeastOneBrancheRequired"), 5000)
            return;
        }

        if (id != undefined) {
            company.id = id;
        }
        if (company.taxOffice && company.taxOffice.value) {
            company.taxOffice = { id: company.taxOffice.value }
        }
        else {
            delete company.taxOffice;
        }
        var _series = [];
        var branchData = branchEditableGrid.current.getData();

        branchData.forEach(item => {

            var ser = {
                id: item.id,
                name: item.name,
                address: item.address,
                branchNumber:item.branchNumber,
                email: item.email,
                phone: item.phone,
                subjectField: item.subjectField
            }
            _series.push(ser);
        })

        company.branches = _series;


        if (company.myDataUserId) {
            company.myDataUserId = company.myDataUserId.trim();
        }
        if (company.myDataToken) {
            company.myDataToken = company.myDataToken.trim();
        }



        confirmAlert({
            title: t("submitConfirm"),
            message: t("areYouSure"),
            buttons: [
                {
                    label: t('Yes'),
                    onClick: () => {
                        setBlockuiLoading(true);
                        ApiClient.saveCompany(company).then(
                            response => {

                                if (id === undefined) {
                                    history.push("/")
                                    history.push("/usercompany");
                                }
                                var newCompany = response.data;
                                var _selectedCompanyId = JSON.parse(localStorage.getItem("company")).id;
                                if (newCompany.id == _selectedCompanyId) {
                                    localStorage.setItem("company", JSON.stringify(newCompany));
                                    Commons.setDefault("branch", undefined);
                                    Commons.setDefault("invoiceType", undefined);
                                    Commons.setDefault("series", undefined);

                                }
                                toast.success(t("saveSucceded"))
                                document.body.scrollTo(0, 0);
                                setBlockuiLoading(false);

                            },
                            error => {
                                toast.error(error.message + ": " + error.response.data.message)
                                console.error(error.response);
                                setBlockuiLoading(false);
                            }
                        )
                    }

                },
                {
                    label: t('No')
                }
            ]
        });
    }

    const onSubmit = (company, e) => {
        e.preventDefault()
        saveCompany(company);

    }

    const myDATASubscriptionCallback = (data) => {
        setValue("myDataUserId", data.username)
        setValue("myDataToken", data.subscriptionKey)

        if (data.username && data.subscriptionKey) {
            // var company=getValues();
            // saveCompany(company);
            toast.warn(t("myDATAKeysFetchedSuccesfully"))

        }
    }

    const onDeleteClick = e => {
        e.preventDefault();
        confirmAlert({
            title: t('submitConfirm'),
            message: t('areYouSureToDelete'),
            buttons: [
                {
                    label: t('Yes'),
                    onClick: () => ApiClient.deleteCompany(id).then(
                        response => {
                            history.push("/usercompanies");
                            toast.success(t("deleteSucceded"))

                        },
                        error => {
                            toast.error("Error: " + error.data)
                            console.error(error.data)
                        }
                    )
                },
                {
                    label: t('No')
                }
            ]
        });

    }
    useEffect(() => {


        var _selectedCompanyId = JSON.parse(localStorage.getItem("company")).id;

        ApiClient.getCompanyView(_selectedCompanyId).then(
            response => {

                var _data = response.data;
                var tos = _data.taxOffices;
                var taxOffices = []
                tos.forEach(element => {
                    var to = {
                        value: element.id,
                        label: element.name
                    }
                    taxOffices.push(to);
                })


                if (id != undefined) {

                    ApiClient.getCompany(id).then(
                        response => {

                            var comp = response.data;
                            console.log(comp);
                            var companyBranches = [];
                            comp.branches.forEach(element => {

                                var _d = {
                                    id: element.id,
                                    name: element.name,
                                    address: element.address,
                                    branchNumber:element.branchNumber,
                                    email: element.email,
                                    subjectField: element.subjectField,
                                    phone: element.phone,
                                    logo: element.logo ? element.logo + "?" + Date.now() : process.env.PUBLIC_URL + '/default.png',
                                    model: element
                                }

                                companyBranches.push(_d)
                            });

                            setContent({ company: comp, taxOffices: taxOffices })
                            setValue("name", comp.name)
                            setValue("taxpayerId", comp.taxpayerId)
                            setValue("gemh", comp.gemh)

                            setValue("myDataUserId", comp.myDataUserId)
                            setValue("myDataToken", comp.myDataToken)

                            setValue("vivaQuickPay", comp.vivaQuickPay)
                            setValue("stripeAccountID", comp.stripeAccountID)
                            setValue("accountantEmail", comp.accountantEmail)

                            setValue("webhook", comp.webhook)
                            setValue("logoPath", comp.logoPath)
                            if (comp.logoPath) {
                                //clear old random query arg
                                var lp = comp.logoPath.split("?")[0]
                                var imageUrl = lp + "?" + Math.random();
                                setLogoPath(imageUrl)

                            }

                            branchEditableGrid.current.setData(companyBranches)


                            // fetch(imageUrl).then(res => {
                            //     res.arrayBuffer().then(buf => {
                            //       const file = new File([buf], 'image_data_url.jpg', { type: 'image/jpeg' })
                            //       setInitFiles([file])
                            //     })
                            //   })




                            if (comp.taxOffice != null) {
                                setValue("taxOffice", { value: comp.taxOffice.id, label: comp.taxOffice.name })
                            }

                            if(stripeParam){
                                localStorage.setItem("company",JSON.stringify(comp));
                            }


                        },
                        error => {
                            toast.error("Error: " + error.response.data.message)
                            console.error(error.response.data)
                        }
                    );
                }
                else {
                    reset();
                    setContent({ company: null, branches: [{ id: undefined, name: undefined, address: undefined }], taxOffices: taxOffices })
                }

            });


    }, [id]);


    var deleteBtn = "";
    if (id != undefined) {
        deleteBtn = <button onClick={onDeleteClick} className="btn btn-danger deletebtn">
            {t("Delete")}
        </button>
    }

    const testMyDataKeys = (e) => {
        e.preventDefault();
        var userId = getValues().myDataUserId;
        var token = getValues().myDataToken;
        var taxpayerId = getValues().taxpayerId;
        setBlockuiLoading(true);
        ApiClient.testMyDataKeys(id, userId, token,taxpayerId).then(
            response => {
                if (response.data) {
                    toast.success(t("rightKeys"))
                }
                else {
                    toast.error(t("wrongKeys"))
                }
                setBlockuiLoading(false);
            },
            error => {
                toast.error(error.message + ": " + error.response.data.message)
                console.error(error.response);
                setBlockuiLoading(false);
            }
        )
    }
    const createStripeAccount = (e) => {
        e.preventDefault();
        var _selectedCompanyId = JSON.parse(localStorage.getItem("company")).id;
        ApiClient.createStripeAccount(_selectedCompanyId).then(
            response => {
                debugger
                window.location.href = response.data;
            },
            error => {
                toast.error(error.message + ": " + error.response.data.message)
                console.error(error.response);
                setBlockuiLoading(false);
            }
        )
    }

    return (
        /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
        <BlockUi tag="div" blocking={blockuiLoading}>
            <div className="container-fluid" style={{ padding: 0 }}>
                <form id="company_form" onSubmit={handleSubmit(onSubmit)}>
                    <FormLayout
                        // headerRightContent={
                        //     <a className="btn btn-skin header-btn" onClick={function () { history.push("/usercompanies") }}>
                        //         {t("userCompanies")}  <i className="fa fa-search"></i>
                        //     </a>
                        // }
                        footer={
                            <div className="form-group text-center">
                                <button type="submit" className="btn btn-skin btn-lg">
                                    {t("Save")}
                                </button>
                                {/* {deleteBtn} */}
                            </div>
                        }
                        title={id ? t('companyUpdate') : t('companyAdd')}
                    >

                        <div className="row">
                            <div className="col-md-3  form-group required">
                                <label className="control-label">{t('CompanyName')}</label>
                                <input
                                    name="name"
                                    type="text"
                                    {...register('name', { required: true, maxLength: 64 })}
                                    className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                />
                                <div className="invalid-feedback">{errors.name?.message}</div>
                            </div>
                            <div className="col-md-3  form-group required">
                                <label className="control-label">{t('taxpayerId')}</label>
                                <input
                                    name="taxpayerId"
                                    type="text"
                                    maxLength="10"
                                    {...register('taxpayerId', { required: true, maxLength: 10 })}
                                    className={`form-control ${errors.taxpayerId ? 'is-invalid' : ''}`}
                                />
                                <div className="invalid-feedback">{errors.taxpayerId?.message}</div>
                            </div>

                            <div className="col-md-3 selectInputContainer form-group">
                                <label>{t("taxOffice")}</label>
                                <Controller
                                    render={({ field }) => (
                                        <Select {...field}
                                            options={content.taxOffices} inputId="taxoffice-react-select"
                                            className={`form-control ${errors.taxOffices ? 'is-invalid' : ''}`}
                                            placeholder={t('Select')}
                                        >

                                        </Select>
                                    )}
                                    control={control}
                                    rules={{ required: false }}
                                    name="taxOffice"
                                    defaultValue=""
                                />

                                <div className="invalid-feedback">{errors.taxOffice?.message}</div>

                            </div>
                            <div className="col-md-3  form-group">
                                <label className="control-label">{t('gemh')}</label>
                                <input
                                    name="gemh"
                                    type="text"
                                    {...register('gemh', { maxLength: 12, minLength: 11 })}
                                    className={`form-control ${errors.gemh ? 'is-invalid' : ''}`}
                                />
                                <div className="invalid-feedback">{errors.gemh?.message}</div>
                            </div>
                            {/* <div className="col-md-4  form-group">
                            <label>{t("companyLogo")}<span style={{ fontSize: 12 }}> ({t("suggestedDims")}: 340x60 px)</span></label>
                            <img className="img-thumbnail" style={{ width: 340, height: 60 }}
                                src={logoPath} onClick={showDropZone}
                                alt="new"></img>
                        </div> */}
                        </div>

                        <div><br></br></div>
                        {
                            id ? <div className="row text-right justify-content-end"><p><small>{t("newCompanyChoice")}<span><a href="/usercompanies">{t("here")}</a></span></small></p></div> : ""
                        }
                        <div><br></br></div>
                        <BranchesEditableGrid ref={branchEditableGrid}></BranchesEditableGrid>
                        <div><br></br></div>

                        <div className="alert alert-primary small-info" role="alert">
                            <p>{t("mydataInfo")}</p>
                            <div className="text-center">

                                {/* <a className="btn btn-skin" target="_blank" href="https://www1.aade.gr/saadeapps2/bookkeeper-web">{t("createMyDataApiKeys")}</a> */}

                                <a style={{ margin: 6 }} className="btn btn-skin" href="#" id="help" onClick={function () { refMySubscriptionModal.current.open(); }}>{t("createMyDataApiKeysAuto")}</a>

                                <a style={{ margin: 6 }} className="btn btn-skin" href="#" id="help" onClick={function () { refMyDataHelp.current.open(); }}>{t("createMyDataApiKeysNonAuto")}</a>

                            </div>
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="offset-md-3 col-md-3  form-group">
                                        <label className="control-label">{t("mydataUserId")}</label>


                                        <input
                                            name="myDataUserId"
                                            type="text"
                                            {...register('myDataUserId')}
                                            className={`form-control ${errors.myDataUserId ? 'is-invalid' : ''}`}
                                        />
                                        <div className="invalid-feedback">{errors.myDataUserId?.message}</div>

                                    </div>

                                    <div className="col-md-3  form-group">

                                        <label className="control-label">{t('mydataToken')}
                                            <Tippy trigger="click" content={t("mydataTokenInfo")}>
                                                <i className="fa fa-x2 fa-info-circle infoicon"></i>
                                            </Tippy>
                                        </label>

                                        <input
                                            name="myDataToken"
                                            type="text"
                                            {...register('myDataToken')}
                                            className={`form-control ${errors.myDataToken ? 'is-invalid' : ''}`}
                                        />
                                        <div className="invalid-feedback">{errors.myDataToken?.message}</div>

                                    </div>
                                    <div className="col-md-2 form-group">
                                        <br></br>

                                        <button className="btn btn-skin" onClick={testMyDataKeys}>{t("testKeysBtnLabel")}</button>
                                    </div>

                                </div>
                            </div>


                        </div>

                        <div className="row">
                            <div className="col-md-4  form-group">
                                <div className="card extra-company-field-card">

                                    <label className="control-label">{t('accountantEmail')}
                                        <Tippy trigger="click" content={t("accountantEmailInfo")}>
                                            <i className="fa fa-x2 fa-info-circle infoicon"></i>
                                        </Tippy>
                                    </label>

                                    <input
                                        name="accountantEmail"
                                        {...register('accountantEmail')}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>

                            <div className="col-md-4  form-group">
                                <div className="card extra-company-field-card">                                  
                                    <label className="control-label">{t('vivaQuickPay')}
                                        <Tippy trigger="click" content={t("vivaQuickPayInfo")}>
                                            <i className="fa fa-x2 fa-info-circle infoicon"></i>
                                        </Tippy>
                                    </label>

                                    <input
                                        name="vivaQuickPay"
                                        {...register('vivaQuickPay')}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>


                            <div className="col-md-4  form-group">
                                <div className="card extra-company-field-card">

                                    <label className="control-label">{t('stripeConnect')}
                                        <Tippy trigger="click" content={t("stripePaymentInfo")}>
                                            <i className="fa fa-x2 fa-info-circle infoicon"></i>
                                        </Tippy>
                                    </label>

                                    <div className="d-flex flex-row bd-highlight mb-0">

                                        <input
                                            name="stripeAccountID"
                                            disabled={true}
                                            type="text"
                                            {...register('stripeAccountID', { maxLength: 12, minLength: 11 })}
                                            className={`form-control ${errors.stripeAccountID ? 'is-invalid' : ''}`}
                                        />
                                        <button style={{ width: 300 }} onClick={e => createStripeAccount(e)} className="btn btn-success">{t("stripeConnect")}</button>
                                    </div>

                                </div>
                            </div>

                            <div className="col-md-4  form-group">
                                <div className="card extra-company-field-card">
                                    <label className="control-label">{t('webHook')}{t("forDevs")}
                                        <Tippy trigger="click" content={t("webHookInfo")}>
                                            <i className="fa fa-x2 fa-info-circle infoicon"></i>
                                        </Tippy>
                                    </label>

                                    <input
                                        name="webhook"
                                        {...register('webhook', { required: true, maxLength: 10 })}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>

                        <MyDataHelpModal ref={refMyDataHelp} ></MyDataHelpModal>
                        <MyDataSubscriptionModal setBlockuiLoading={setBlockuiLoading} myDATASubscriptionCallback={myDATASubscriptionCallback} ref={refMySubscriptionModal}></MyDataSubscriptionModal>
                        <div><br></br></div>

                    </FormLayout>


                </form>
            </div>
        </BlockUi>
    );
}