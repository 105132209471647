import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import ApiClient from "../services/api-client"
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import moment from 'moment'
import { VIVA_VIEW_TRANSACTION } from '../util/constants';
import { CustomConfirmAlert } from '../components/custom-confirm-alert';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { toast } from "react-toastify";



const ActionsRenderer = forwardRef((props, ref) => {


  const handleInputChange = (e) => {
    e.preventDefault();
    var rowNode = props.agGridReact.api.getRowNode(props.rowIndex);
    console.error(rowNode.data)
    var url = VIVA_VIEW_TRANSACTION + "/web/receipt?tid=" + rowNode.data.transactionId
    console.log(url)
    window.open(url, '_blank').focus();
  }
  useImperativeHandle(ref, () => {
    return {
      refresh(params) {

      }
    };
  });
  return (
    <span><button onClick={handleInputChange} className="btn btn-sm btn-warning"><i className="fa fa-edit"></i></button></span>
    // <span className="editableCell">{branch != null && branch.label != undefined ? branch.label : <span className="addtext"></span>}</span>
  );
});


const ActionsViewInvoiceRenderer = forwardRef((props, ref) => {


  const handleInputChange = (e) => {
    e.preventDefault();
    var rowNode = props.agGridReact.api.getRowNode(props.rowIndex);
    console.error(rowNode.data)
    var url = "mydata/" + rowNode.data.invoiceUid
    console.log(url)
    window.open(url, '_blank').focus();
  }
  useImperativeHandle(ref, () => {
    return {
      refresh(params) {

      }
    };
  });
  return (
    <span><button onClick={handleInputChange} className="btn btn-sm btn-warning"><i className="fa fa-edit"></i></button></span>
    // <span className="editableCell">{branch != null && branch.label != undefined ? branch.label : <span className="addtext"></span>}</span>
  );
});


const DateRenderer = forwardRef((props, ref) => {

  const [val, setVal] = useState(props.value);

  useImperativeHandle(ref, () => {
    return {
      refresh(params) {
        var val = props.getValue();
        if (val !== undefined) {
          setVal(val);
        }
      }
    };

  });
  return (

    <span>{val ? moment(val).format('DD/MM/YYYY hh:mm') : ""}</span>
  );
});

export default function Profile(props) {
  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     // redirect: null,
  //     userReady: false,
  //     currentUser: { email: "" }
  //   };
  // }

  const [currentUser, setCurrentUser] = useState({});
  const { setValue } = useForm();
  const [rowData, setRowData] = useState([]);
  const [userInvoicesRowData, setUserInvoicesRowData] = useState([]);



  const [gridApi, setGridApi] = useState(null);
  const pagination = true;
  const paginationPageSize = 10;
  const { t } = useTranslation();

  useEffect(() => {

    var usr = JSON.parse(localStorage.getItem("user"));
    setCurrentUser(usr)
    setValue("userId", usr.myDataUserId);
    setValue("token", usr.myDataToken);
    ApiClient.getUserTransactions().then(
      response => {
        if (response) {
          setRowData(response.data)
        }
      },
      error => {
        console.error(error.data)
      }
    )

    ApiClient.getUserInvoicesFromTimologic().then(
      response => {
        if (response) {
          console.log(response.data)
          setUserInvoicesRowData(response.data)
        }
      },
      error => {
        console.error(error.data)
      }
    )
    // if (this.state.redirect) {
    //   return <Redirect to={this.state.redirect} />
    // }
  }, []);


  const onGridReady = params => {
    setGridApi(params.api);
    // params.api.sizeColumnsToFit();
    window.addEventListener('resize', function () {
      setTimeout(function () {
        // params.api.sizeColumnsToFit();
      });
    });

    // params.api.sizeColumnsToFit();
  };
  const onQuickFilterChanged = e => {
    gridApi.setQuickFilter(e.target.value);
  };



  const cancelAccount = () => {


    CustomConfirmAlert({
      title: t("submitConfirm"),
      message: t("confirmCancelAccount"),
      closeOnEscape: false,
      closeOnClickOutside: false,
      buttons: [
        {
          label: t("Ok"),
          onClick: () => {

            ApiClient.cancelAccount().then(
              (response) => {
                console.log(response)
                localStorage.removeItem("user");
                localStorage.removeItem("accessToken");
                localStorage.removeItem("wizard");
                window.location = "login";
              },
              error => {
                console.error(
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString())
              }
            )
          },
        },
        {
          label: t('cancel')
        }
      ]
    });


  }

  const cancelSubscription = () => {


    CustomConfirmAlert({
      title: t("submitConfirm"),
      message: t("confirmCancelSubscription"),
      closeOnEscape: false,
      closeOnClickOutside: false,
      buttons: [
        {
          label: t("Ok"),
          onClick: () => {

            ApiClient.cancelSubscription().then(
              (response) => {
                toast.success(t("subscriptionCanceled"))
              },
              error => {
                console.error(
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString())
              }
            )
          },
        },
        {
          label: t('cancel')
        }
      ]
    });


  }

  const toogleApiKeyVisibility = () => {
    var x = document.getElementById("api_key_input");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  return (
    <div className="container">
      <div>
        <header className="jumbotron">
          <h3>
            <strong>{currentUser.name}</strong>
          </h3>
        </header>
        <p>
          <strong>Email:</strong>{" "}
          {currentUser.email}
        </p>
        <div className="col-md-4">
          <strong>ApiKey:</strong>{" "}

          <div className="input-group mb-3 ">
            <input id="api_key_input" className="form-control" defaultValue={currentUser.apiKey} type="password" />
            <div className="input-group-append">
              <button className="input-group-text form-control" onClick={toogleApiKeyVisibility}><i className="fa fa-eye"></i></button>
            </div>
          </div>
        </div>
        {/* <strong>Authorities:</strong>
        <ul>
          {currentUser.roles &&
            currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
        </ul> */}




        <Tabs
          defaultActiveKey="userInvoicesFromTimologic"
          id="uncontrolled-tab-example"
          className="mb-3"
        >

          <Tab eventKey="userInvoicesFromTimologic" title={t("userInvoicesFromTimologic")}>


            <div className="row ">
              <div style={{ width: '100%', height: '100%' }}>
                <div className="row" style={{ display: 'none' }}>
                  <div className="offset-md-4 col-md-4">
                    <p className="text-center">
                      <input placeholder={t("Search")} className="form-control" onChange={onQuickFilterChanged} />
                    </p>
                  </div>

                </div>

                <div
                  id="myGrid"
                  style={{
                    height: '100%',
                    width: '100%',
                  }}
                  className="ag-theme-alpine-dark"
                >
                  <div className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
                    <AgGridReact
                      pagination={pagination}
                      paginationPageSize={paginationPageSize}
                      onGridReady={onGridReady}
                      style={{ width: '100%', height: '100%;' }}
                      rowData={userInvoicesRowData}
                      frameworkComponents={{
                        dateRenderer: DateRenderer,
                        actionsViewInvoiceRenderer: ActionsViewInvoiceRenderer
                      }}
                    >
                      <AgGridColumn flex={2} headerName={t("issuerDate")} cellRenderer="dateRenderer" sortable={true} filter="agTextColumnFilter" field="invoiceDate"></AgGridColumn>
                      <AgGridColumn flex={1} headerName={t("Actions")} cellRenderer="actionsViewInvoiceRenderer" field="action"></AgGridColumn>

                    </AgGridReact>
                  </div>
                </div>
              </div>
            </div>


          </Tab>


          <Tab eventKey="userTransactions" title={t("userTransactions")}>


            <div className="row ">

              <div style={{ width: '100%', height: '100%' }}>
                <div className="row" style={{ display: 'none' }}>
                  <div className="offset-md-4 col-md-4">
                    <p className="text-center">
                      <input placeholder={t("Search")} className="form-control" onChange={onQuickFilterChanged} />
                    </p>
                  </div>

                </div>

                <div
                  id="myGrid"
                  style={{
                    height: '100%',
                    width: '100%',
                  }}
                  className="ag-theme-alpine-dark"
                >
                  <div className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
                    <AgGridReact
                      pagination={pagination}
                      paginationPageSize={paginationPageSize}
                      onGridReady={onGridReady}
                      style={{ width: '100%', height: '100%;' }}
                      rowData={rowData}
                      frameworkComponents={{
                        dateRenderer: DateRenderer,
                        actionsRenderer: ActionsRenderer
                      }}
                    >
                      <AgGridColumn flex={2} headerName={t("transactionId")} sortable={true} filter="agTextColumnFilter" field="transactionId"></AgGridColumn>
                      <AgGridColumn flex={2} headerName={t("transactionDate")} cellRenderer="dateRenderer" sortable={true} filter="agTextColumnFilter" field="transactionDate"></AgGridColumn>
                      <AgGridColumn flex={1} headerName={t("Amount")} sortable={true} filter="agTextColumnFilter" field="amount"></AgGridColumn>
                      <AgGridColumn flex={1} headerName={t("monthInvoiceCounter")} sortable={true} filter="agTextColumnFilter" field="monthInvoiceCounter"></AgGridColumn>
                      <AgGridColumn flex={1} headerName={t("Actions")} cellRenderer="actionsRenderer" field="name"></AgGridColumn>

                    </AgGridReact>
                  </div>
                </div>
              </div>
            </div>

          </Tab>


        </Tabs>


      </div>
      <div><br></br></div>  <div><hr></hr></div>
      <div><br></br></div>

      <div className="row text-center">
        <div className="offset-md-3 col-md-3">
          <button className="btn btn-sm btn-danger" onClick={cancelSubscription}>{t("cancelSubscription")}</button>
        </div>
        <div className="col-md-3">
          <button className="btn btn-sm btn-danger" onClick={cancelAccount}>{t("cancelAccount")}</button>
        </div>
      </div>
    </div>
  );
}
