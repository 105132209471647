import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from '../components/CheckoutForm';
import { useTranslation } from 'react-i18next';
import { STRIPE_PUBLISHABLE_KEY } from '../util/constants';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.


function StripePayment() {
    const { t } = useTranslation();


    const params = new URLSearchParams(window.location.search);
    let secret = params.get('secret');
    let amount = params.get('amount');
    let accountId = params.get('accountId');


    const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY, {
        stripeAccount: accountId,
        locale: "el"
    });


    const options = {
        // pass the client secret from the previous step
        clientSecret: secret,
        // Fully customizable with the Appearance API
        appearance: {/*...*/ },
    };

    return (
        <div className="container">
            <br></br>
            <p>{t("youHaveToPayMess")}: {amount}&euro;</p>
            <Elements stripe={stripePromise} options={options}>
                <CheckoutForm />
            </Elements>
        </div>

    );
};

export default StripePayment;