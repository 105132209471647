import React, { useState, useEffect, useRef } from "react";
import ApiClient from "../services/api-client"
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactJson from 'react-json-view'
import IFramePrintTemplate from '../components/iframe-print-template.component';
import InvoiceSimpleView from '../components/sales/invoice-simple-view.component';
import Logo from '../img/timologic.png';
import timologicFooter from "../img/timologic_login.png"
import Slider from 'rc-slider';
import reactCSS from 'reactcss'



export default function InvoiceView(props) {


    const [sentjson, setSentJson] = useState({});
    const { t } = useTranslation();
    const { invoiceUid } = useParams();
    const iframePrintTemplate = useRef();
    const [sheight, setSheight] = useState(window.innerHeight - 140);
    const [template, setTemplate] = useState(false);
    const [zoomValue, setZoomValue] = useState(100);

    const onClickPrint = () => {
        setTimeout(function () {
            iframePrintTemplate.current.printFrame()
        })
    }

    useEffect(() => {




        ApiClient.getInvoiceViewByInvoiceUid(invoiceUid).then(
            response => {
                if (response != undefined) {
                    if (response.data != "") {

                        var _company = response.data.saleInstance.company;
                        var _branchId = response.data.saleInstance.branchId
                        ApiClient.getOpenPageTemplateSettings(_company.id, _branchId).then(templateResponse => {
                            
                            setTemplate(templateResponse.data);
                            var psale=iframePrintTemplate.current.getPrintSale2(response.data.saleInstance);
                            if(templateResponse.data){
                                psale.IBAN=templateResponse.data.iban;
                                psale.MOREINFO=templateResponse.data.moreInfo;
                            }
                            
                            iframePrintTemplate.current.setPrintSale(psale);
                            var json = JSON.parse(response.data.body);
                            var instance = response.data.saleInstance;
                            if (json.invoice) {
                                json.invoice[0].cancelledByMark = instance.cancellationMark;
                            }
                            setSentJson(json);

                        },
                            error => {
                                console.error(
                                    (error.response &&
                                        error.response.data &&
                                        error.response.data.message) ||
                                    error.message ||
                                    error.toString())
                            }
                        );
                       

                    }
                    else {
                        document.getElementById("mainViewContainer").style.display = 'none';
                        document.getElementById("notExistMess").style.display = 'unset';
                    }
                }
            },
            error => {
                console.error(error.data)
            }
        )
    }, []);
    const hangleZoomChange = (val) => {
        setZoomValue(val);
        var el = document.getElementById('designer-frame-container');
        var h = (el.scrollHeight - el.clientHeight) / 2;
        var w = (el.scrollWidth - el.clientWidth) / 2;

        document.getElementById('designer-frame-container').scrollTop = h;
        document.getElementById('designer-frame-container').scrollLeft = w;
    }
    const styles = reactCSS({
        'default': {
            colorMain: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: template.mainColor,
            },
            colorSecondary: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: template.secondaryColor,
            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                position: 'absolute',
                zIndex: '2',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });
    window.addEventListener('resize', function () {
        setTimeout(function () {
            // params.api.sizeColumnsToFit();
            setSheight(window.innerHeight - 140);
        }, 300);
    });



    return (






        <div className="container-fluid">
            <div className="row">

                <nav style={{ position: "sticky", padding: 0, top: 0, backgroundColor: "#013289", zIndex: 1 }}>


                    <div className="nav  nav-tabs" id="nav-tab" role="tablist">
                        <a className="navbar-brand" href="/">   <img width="120px" style={{ marginLeft: 4 }} height="auto" className="img-responsive" src={Logo} alt="logo" /></a>
                        <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true"><i className="fa fa-eye"></i></button>

                        <button className="nav-link" id="details-tab" data-bs-toggle="tab" data-bs-target="#nav-details" type="button" role="tab" aria-controls="nav-details" aria-selected="false"><i className="fa fa-info-circle"></i></button>

                        <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false"><i className="fa fa-code"></i></button>

                        <button onClick={onClickPrint} className="btn btn-skin " href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <i className="fa fa-print"></i>
                        </button>
                    </div>

                </nav>
                <div className="tab-content" id="nav-tabContent" style={{ position: "relative",  padding:0}}>
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">


                        <div className="text-center" >
                            <span><small>zoom</small></span>
                            <div className="row" style={{ paddingLeft: 40, paddingRight: 40, paddingBottom: 10 }}>
                                <div className="offset-md-3 col-md-6">
                                    <Slider
                                        min={40}
                                        max={100}
                                        value={zoomValue}
                                        onChange={hangleZoomChange}
                                        onAfterChange={hangleZoomChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="container-fluid text-center view_invoice_frame_container" id="designer-frame-container" style={{ height: sheight, width: "100%", overflowX: "auto", overflowY: "auto" }}>
                            <IFramePrintTemplate style={{ height: '297mm', width: "800px" , transform: 'scale(' + (zoomValue / 100) + ')'}} extracss={template.css} template={template} ref={iframePrintTemplate} ></IFramePrintTemplate>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="nav-details" role="tabpanel" aria-labelledby="nav-details-tab">
                        <div>
                            <InvoiceSimpleView invoice={sentjson}></InvoiceSimpleView>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                        <div className="innerViewContainer" style={{ textAlign: "left" }}>
                            <ReactJson theme="monokai" src={sentjson} />
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ display: "none" }} id="notExistMess">
                <div className="alert alert-danger" role="alert">
                    <h3>No invoice with invoiceUid: {invoiceUid}</h3>
                </div>
            </div>
        </div>
    );
}
