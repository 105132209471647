import React, { useEffect, useState } from 'react';
import '../styles/login.css';
import { ACCESS_TOKEN ,SPECIAL_ENGLISH_AFFILIATE} from '../util/constants';
import { login } from '../util/AuthUtils';
import { getCurrentUser, cleancookies } from '../util/AuthUtils';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom"
import { Link } from 'react-router-dom'
import { useAlert } from 'react-alert'
import LoginRegisterLayout from '../components/login-register-layout';
import myDATALogo from '../img/mydata.png';





function LoginForm(props) {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const alert = useAlert()
    const { t } = useTranslation();


    const handleInputChange = (event) => {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        switch (inputName) {
            case "email": {
                setEmail(inputValue);
                break;
            }
            case "password": {
                setPassword(inputValue);
                break;
            }
        }

        console.log(event.target.name)

        // this.setState({
        //     [inputName]: inputValue
        // });
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const loginRequest = {
            email: email,
            password: password
        }

        props.setBlockuiLoading(true)

        event.preventDefault();


        login(loginRequest)
            .then(loginResp => {
                localStorage.setItem(ACCESS_TOKEN, loginResp.accessToken);
                getCurrentUser()
                    .then(user => {
                        if (user.user.cleanCookies) {
                            cleancookies().then(resp => {
                                localStorage.removeItem("company")
                                localStorage.removeItem("defaults")
                                localStorage.setItem("user", JSON.stringify(user.user))
                                if (user.companies && user.companies.length == 1) {
                                    localStorage.setItem("company", JSON.stringify(user.companies[0]))
                                }
                                history.push("/home");
                                props.setBlockuiLoading(false)
                            }).catch(error => {

                                console.error(error);
                                localStorage.setItem("user", JSON.stringify(user))
                                history.push("/home");
                            });

                        }
                        else {
                            localStorage.setItem("user", JSON.stringify(user.user))
                            if (user.companies && user.companies.length == 1) {
                                localStorage.setItem("company", JSON.stringify(user.companies[0]))
                            }
                            history.push("/home");
                        }

                    }).catch(error => {
                        setLoading(false)
                    });


            }).catch(error => {
                console.error(error)
                alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
    }


    return (

        <form onSubmit={handleSubmit}>
            <div className="login-form-item">
                <input type="email" name="email"
                    className="form-control" placeholder="Email"
                    value={email} onChange={handleInputChange} required />
            </div>

            <div className="login-form-item">
                <input type="password" name="password"
                    className="form-control" placeholder={t("Password")}
                    value={password} onChange={handleInputChange} required />
            </div>
            <hr></hr>
            <div className="form-item">
                <button type="submit" className="btn btn-block btn-skin">{t("Connect")}</button>
            </div>
        </form>

    );
}

export default function Login(props) {
    const { t, i18n } = useTranslation();
    const [blockuiLoading, setBlockuiLoading] = useState(false);

    useEffect(() => {
        let params = (new URL(window.location)).searchParams;
        let aff = params.get('aff');
        if (aff == SPECIAL_ENGLISH_AFFILIATE) {
            i18n.changeLanguage("gb")
        }
        // If the OAuth2 login encounters an error, the user is redirected to the /login page with an error.
        // Here we display the error and then remove the error query parameter from the location.
        // if (window.location.state && window.location.state.error) {
        //     setTimeout(() => {
        //         console.error(window.location.state.error)
        //         alert(window.location.state.error);
        //         props.history.replace({
        //             pathname: window.location.pathname,
        //             state: {}
        //         });
        //     }, 100);
        // }
    }, []);

    const getRegisterUrl = () => {
        var url = "/register";

        let params = (new URL(window.location)).searchParams;
        let aff = params.get('aff');
        if (aff) {
            url += "?aff=" + aff;
        }
        return url;
    }

    if (props.authenticated) {
        return;

        // <Redirect
        //     to={{
        //         pathname: "/home",
        //         state: { from: window.location }
        //     }} />;
    }
    else {
        return (
            // <BlockUi loader={<Loader active type='square-spin' />} tag="div" blocking={blockuiLoading}>
                <LoginRegisterLayout footer={
                    <div className="row">
                        <div><br></br></div>
                        <small>{t("myDataCompatibleMessage")} <img style={{ width: 80, marginTop: 10 }} src={myDATALogo}></img>.  </small>
                    </div>
                }>
                    <div className="divider">{t("or")}</div>

                    <LoginForm {...props} setBlockuiLoading={setBlockuiLoading} />
                    <br></br>
                    <span className="signup-link">{t("newUserQuestionMess")}<br></br> {t("makeRegisterMess")} <b>{t("FREE")}</b> {t("byClickingLink")} <br></br> <Link to={getRegisterUrl()}>{t("here")}!</Link></span>

                </LoginRegisterLayout>
            // </BlockUi>
        );
    }

}
