import React from "react";
import { useTranslation } from 'react-i18next';
import moment from 'moment'

export default function InvoiceSimpleView(props) {
    const { t } = useTranslation();
    const mainRenderer = (invoice) => {
        if (invoice && invoice.invoice) {
            var _inv = invoice.invoice[0];


            return <span><h4>{_inv.mark}</h4><h4 style={{ color: "red" }}>{_inv.cancelledByMark}</h4></span>;
        }
    }

    const issuerRenderer = (invoice) => {
        if (invoice && invoice.invoice) {
            var _inv = invoice.invoice[0];
            var table = <table className="table">
                <tbody>
                    <tr><td>{t("issuerVat")}: </td><td>{_inv.issuer ? _inv.issuer.vatNumber : ""} </td></tr>
                    <tr><td>{t("Country")}: </td><td>{_inv.issuer ? _inv.issuer.country : ""} </td></tr>
                </tbody>
            </table>

            return table;
        }
    }


    const invoiceHeaderRenderer = (invoice) => {
        if (invoice && invoice.invoice) {
            var _inv = invoice.invoice[0];
            var intype = "invoice" + _inv.invoiceHeader.invoiceType.replace(".", "_")
            var table = <table className="table">
                <tbody>
                    <tr><td>{t("InvoiceType")}: </td><td>{t(intype)} </td></tr>
                    <tr><td>{t("Series")}: </td><td>{_inv.invoiceHeader.series} </td></tr>
                    <tr><td>{t("invoiceNumber")}: </td><td>{_inv.invoiceHeader.aa} </td></tr>
                    <tr><td>{t("issuerDate")}: </td><td>{moment(_inv.invoiceHeader.issueDate).format('DD/MM/YYYY HH:mm')} </td></tr>
                </tbody>
            </table>

            return table;
        }
    }
    const counterPartRenderer = (invoice) => {
        if (invoice && invoice.invoice) {
            var _inv = invoice.invoice[0];
            var table = <table className="table">
                <tbody>
                    <tr><td>{t("counterPartVat")}: </td><td>{_inv.counterpart ? _inv.counterpart.vatNumber : ""} </td></tr>
                    <tr><td>{t("Country")}: </td><td>{_inv.counterpart ? _inv.counterpart.country : ""} </td></tr>
                </tbody>
            </table>

            return table;
        }
    }

    const invoicePaymentMethodsRenderer = (invoice) => {
        if (invoice && invoice.invoice) {
            var _inv = invoice.invoice[0];

            var _rows = [];
            if (_inv.paymentMethods) {
                var counter = 0;
                _inv.paymentMethods.paymentMethodDetails.forEach(pm => {
                    var tr = <tr key={counter++}><td>{t("PaymentMethod")}: </td><td>{t("PM_" + pm.type)} </td><td>{pm.amount} </td></tr>
                    _rows.push(tr);
                })
            }

            return <table className="table"><tbody>{_rows}</tbody></table>;
        }
    }


    const saleMoreTaxesRenderer = (invoice) => {
        if (invoice && invoice.invoice) {
            var taxesTotals = invoice.invoice[0].taxesTotals;
            var rows = [];
            
            if (taxesTotals  && taxesTotals.taxes  && taxesTotals.taxes.length > 0) {
                taxesTotals.taxes.forEach(tax => {

                    var taxType = null;
                    if (tax.taxType == 1) {
                        taxType = "WITHHOLD_TAXES";
                    }
                    else if (tax.taxType == 2) {
                        taxType = "FEES";
                    }
                    else if (tax.taxType == 3) {
                        taxType = "OTHER_TAXES";
                    }
                    else if (tax.taxType == 4) {
                        taxType = "STUMP_DUTY";
                    }
                    else if (tax.taxType == 5) {
                        taxType = "DEDUCTION";
                    }

                    var taxCategory = "TAX_" + tax.taxCategory;

                    if (taxType == "DEDUCTION") {
                        taxCategory = null;
                    }
                    var taxMap="TAX_MAP_"+tax.taxType +"_"+tax.taxCategory;

                    var tmaptr=t(taxMap);

                    var row = <tr><td>{t(taxType)} </td><td>{t(tmaptr)} </td><td>{tax.taxAmount}</td></tr>
                    rows.push(row)
                })
            }


            var table = <table className="table">
                <tbody>
                    {rows}
                </tbody>
            </table>

            return table;
        }
    }


    const invoiceSummaryRenderer = (invoice) => {
        if (invoice && invoice.invoice) {
            var _inv = invoice.invoice[0];

            var _rows = [];
            if (_inv.invoiceSummary) {
                _rows.push(<tr key="totalNetValue"><td>{t("totalNetValue")}: </td><td>{_inv.invoiceSummary.totalNetValue} </td></tr>);
                _rows.push(<tr key="totalVatAmount"><td>{t("totalVatAmount")}: </td><td>{_inv.invoiceSummary.totalVatAmount} </td></tr>);
                _rows.push(<tr key="totalWithheldAmount"><td>{t("totalWithheldAmount")}: </td><td>{_inv.invoiceSummary.totalWithheldAmount} </td></tr>);
                _rows.push(<tr key="totalFeesAmount"><td>{t("totalFeesAmount")}: </td><td>{_inv.invoiceSummary.totalFeesAmount} </td></tr>);
                _rows.push(<tr key="totalStampDutyAmount"><td>{t("totalStampDutyAmount")}: </td><td>{_inv.invoiceSummary.totalStampDutyAmount} </td></tr>);
                _rows.push(<tr key="totalOtherTaxesAmount"><td>{t("totalOtherTaxesAmount")}: </td><td>{_inv.invoiceSummary.totalOtherTaxesAmount} </td></tr>);
                _rows.push(<tr key="totalDeductionsAmount"><td>{t("totalDeductionsAmount")}: </td><td>{_inv.invoiceSummary.totalDeductionsAmount} </td></tr>);
                _rows.push(<tr key="totalGrossValue"><td>{t("totalGrossValue")}: </td><td>{_inv.invoiceSummary.totalGrossValue} </td></tr>);
            }

            return <table className="table"><tbody>{_rows}</tbody></table>;
        }
    }

    const detailsRenderer = (invoice) => {

        if (invoice && invoice.invoice) {


            var thead = <thead><tr><td><b>{t("invoiceLine")}</b></td><td><b>{t("netvalue")}</b></td><td><b>{t("Vat")}</b></td><td><b>{t("saleEntryClassifications")}</b></td><td><b>{t("moreTaxes")}</b></td></tr></thead>
            var _inv = invoice.invoice[0];
            var productRows = [];
            var counter = 0;
            _inv.invoiceDetails.forEach(element => {
                var classificationRows = [];
                var classificationCategory;
                var classificationType;
         

                if (element.expensesClassification && element.expensesClassification.length > 0) {
                    element.expensesClassification.forEach(ec => {
                        classificationCategory = ec.classificationCategory;
                        classificationType = ec.classificationType;
                        var tr = <tr key={counter++}><td>{t(classificationCategory)}({classificationCategory})</td><td>{t(classificationType)}({classificationType})</td><td>{parseFloat(ec.amount).toFixed(2)}</td></tr>
                        classificationRows.push(tr);
                    })

                }
                else {
                    element.incomeClassification.forEach(ec => {
                        classificationCategory = ec.classificationCategory;
                        classificationType = ec.classificationType;
                        var tr = <tr key={counter++}><td>{t(classificationCategory)}({classificationCategory})</td><td>{t(classificationType)}({classificationType})</td><td>{parseFloat(ec.amount).toFixed(2)}</td></tr>
                        classificationRows.push(tr);
                    })
                }
                var _trs = [];
                if (element.withheldPercentCategory) {
                    var withheldPercentCategory =t("TAX_MAP_1_"+element.withheldPercentCategory);
                    var tr = <tr key={counter++}><td>{t("WITHHOLD_TAXES")}</td><td>{t(withheldPercentCategory)}</td><td>{parseFloat(element.withheldAmount).toFixed(2)}</td></tr>
                    _trs.push(tr);
                }
                if (element.otherTaxesPercentCategory) {
                    var otherTaxesPercentCategory = t("TAX_MAP_3_"+element.otherTaxesPercentCategory);
                    var tr = <tr key={counter++}><td>{t("OTHER_TAXES")}</td><td>{t(otherTaxesPercentCategory)}</td><td>{parseFloat(element.otherTaxesAmount).toFixed(2)}</td></tr>
                    _trs.push(tr);
                }
                if (element.stampDutyPercentCategory) {
                    var stampDutyPercentCategory =t("TAX_MAP_4_"+element.stampDutyPercentCategory);
                    var tr = <tr key={counter++}><td>{t("STUMP_DUTY")}</td><td>{t(stampDutyPercentCategory)}</td><td>{parseFloat(element.stampDutyAmount).toFixed(2)}</td></tr>
                    _trs.push(tr);
                }
                if (element.feesPercentCategory) {
                    var feesPercentCategory = t("TAX_MAP_2_"+element.feesPercentCategory);
                    var tr = <tr key={counter++}><td>{t("FEES")}</td><td>{t(feesPercentCategory)}</td><td>{element.feesAmount}</td></tr>
                    _trs.push(tr);
                }
                if (element.deductionsAmount) {
                    var tr = <tr key={counter++}><td>{t("DEDUCTION")}</td><td></td><td>{element.deductionsAmount}</td></tr>
                    _trs.push(tr);
                }

                var _tr = <tr key={counter++}><td>{element.lineNumber} ({element.lineComments})</td><td>{parseFloat(element.netValue).toFixed(2)}</td><td>{element.vatAmount}</td><td><table className="table table-bordered  table-sm details_child_table"><tbody>{classificationRows}</tbody></table></td><td><table className="table table-sm table-bordered more_tax_child_table"><tbody>{_trs}</tbody></table></td></tr>
                productRows.push(_tr);

            });
            var tbody = <tbody>{productRows}</tbody>;
            return <div className="table-responsive"><table className="table details_table table-bordered  table-sm">{thead}{tbody}</table></div>
        }
    }


    return (

        <div className="invoice-viewcontent-container">
            <div className="invoice-viewcontent">
                <div id="invoice-content">
                    <div className="row">
                        {mainRenderer(props.invoice)}
                    </div>

                    <div className="container-fluid" style={{ padding: 10 }}>
                        <div className="row">
                            <div className="col-xl-3 card-simple-view-parent">
                                <div className="card card-simple-view">
                                    <div className="card-header">
                                        invoiceHeader
                                    </div>
                                    <div className="card-body">
                                        {invoiceHeaderRenderer(props.invoice)}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 card-simple-view-parent">
                                <div className="card card-simple-view">
                                    <div className="card-header">
                                        issuer
                                    </div>
                                    <div className="card-body">
                                        {issuerRenderer(props.invoice)}
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-2 card-simple-view-parent">
                                <div className="card card-simple-view">
                                    <div className="card-header">
                                        counterpart
                                    </div>
                                    <div className="card-body">
                                        {counterPartRenderer(props.invoice)}
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-2 card-simple-view-parent">
                                <div className="card card-simple-view">
                                    <div className="card-header">
                                        paymentMethods
                                    </div>
                                    <div className="card-body">
                                        {invoicePaymentMethodsRenderer(props.invoice)}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 card-simple-view-parent">
                                <div className="card card-simple-view">
                                    <div className="card-header">
                                        invoiceSummary
                                    </div>
                                    <div className="card-body">
                                        {invoiceSummaryRenderer(props.invoice)}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-12 card-simple-view-parent">
                                <div className="card card-simple-view">
                                    <div className="card-header">
                                        invoiceDetails
                                    </div>
                                    <div className="card-body">
                                        {detailsRenderer(props.invoice)}
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-xl-3 card-simple-view-parent">
                                <div className="card card-simple-view">
                                    <div className="card-header">
                                        taxesTotals
                                    </div>
                                    <div className="card-body">
                                        {saleMoreTaxesRenderer(props.invoice)}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </div>
    );
}