import React ,{useState} from "react";
import { useTranslation } from 'react-i18next';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AadeTransmittedDocsSearch from '../components/sales/aade-transmitted-docs';
import AadeRequestDocsSearch from '../components/sales/aade-request-docs';
import ApiClient from "../services/api-client"
import BlockUi from 'react-block-ui';

export default function AadeSaleSearch() {
    const { t } = useTranslation();
    const [blockuiLoading, setBlockuiLoading] = useState(false);

    const blockUi = (block) => {
        setBlockuiLoading(block)
    }
    const synchronizeCompany = ()=>{
        setBlockuiLoading(true)
        var companyId=JSON.parse(localStorage.getItem("company")).id;
        ApiClient.synchronizeCompany(companyId).then(
            response => {

                setBlockuiLoading(false)
            },
            error => {
                console.error(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString());
                    setBlockuiLoading(false)
            }
        );
    }
    return (
        <BlockUi tag="div" blocking={blockuiLoading}>
        <div>
           <div className="row justify-content-end">
           <button style={{width:160}} className="btn btn-skin"  onClick={synchronizeCompany}>Synchronize All</button>
           </div>
            <Tabs
                defaultActiveKey="transmittedDocs"
                id="uncontrolled-tab-example"
                className="mb-3"
            >
                <Tab eventKey="transmittedDocs" title={t("transmittedDocs")}>
                    <AadeTransmittedDocsSearch></AadeTransmittedDocsSearch>
                </Tab>
                <Tab eventKey="requestDocs" title={t("requestDocs")}>
                    <AadeRequestDocsSearch></AadeRequestDocsSearch>
                </Tab>

            </Tabs>

        </div>
        </BlockUi>
    );
}


