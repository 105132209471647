import React, { useState, useEffect, useRef } from "react";
import ApiClient from "../services/api-client"
import { useHistory } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import CompanyDeductionsGrid from '../components/editable-aggrids/company-deductions-grid';
import FormLayout from '../components/form-layout'
import { useForm, Controller } from "react-hook-form";
import BlockUi from 'react-block-ui';
import { CustomConfirmAlert as confirmAlert } from '../components/custom-confirm-alert';
import { toast } from 'react-toastify';


export default function CompanyDeduction() {

    const [content, setContent] = useState([]);
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const deductionsGridRef = useRef();
    const [blockuiLoading, setBlockuiLoading] = useState(false);


    const {  handleSubmit, formState: { errors } } = useForm();
   
    const onSubmit = deduction => {
        var deductions=deductionsGridRef.current.getData();
        console.log(deductions)
        var company = JSON.parse(localStorage.getItem("company"));
        var _deductions=[];
        deductions.forEach(e=>{
            e.company={
                id:company.id
            }
            _deductions.push(e);
        })
        confirmAlert({
            title: t("submitConfirm"),
            message: t("areYouSure"),
            buttons: [
                {
                    label: t('Yes'),
                    onClick: () => {
                        setBlockuiLoading(true);
                        ApiClient.saveDeductions(company.id,_deductions).then(
                            response => {
                                console.log(response.data);
                                toast.success(t("saveSucceded"))
                                setBlockuiLoading(false);

                            },
                            error => {
                                toast.error(error.message + ": " + error.response.data.message)
                                console.error(error.response);
                                setBlockuiLoading(false);
                            }
                        )
                    }

                },
                {
                    label: t('No')
                }
            ]
        });

    }

    useEffect(() => {

        var companyId = JSON.parse(localStorage.getItem("company")).id;
        ApiClient.getCompanyDeductions(companyId).then(
            response => {
                console.log(response.data)
                deductionsGridRef.current.setData(response.data);
            },
            error => {
                setContent(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString())
            }
        );

    }, []);


    return (

        <div className="container" style={{padding:0}}>
        <form id="Deductions_form" onSubmit={handleSubmit(onSubmit)}>

            <FormLayout
                title={t("Deductions")}
                footer={
                    <div className="form-group text-center">
                        <button type="submit" className="btn btn-skin">
                            {t("Save")}
                        </button>
                        {/* {deleteBtn} */}
                    </div>
                }
            >

                <div className="alert alert-primary small-info" role="alert">
                    <div className="col-md-12">
                        {t("DeductionsInfo")}
                    </div>
                </div>
                <CompanyDeductionsGrid ref={deductionsGridRef} ></CompanyDeductionsGrid>
            </FormLayout>
        </form>
    </div>

    );
}


