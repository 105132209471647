import React, { useState, useImperativeHandle, forwardRef } from "react";

import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import mydata_help1 from '../../img/mydata_help1.jpg';
import mydata_help2 from '../../img/mydata_help2.jpg';
import mydata_help3 from '../../img/mydata_help3.jpg';
import mydata_help4 from '../../img/mydata_help4.jpg';
import mydata_help5 from '../../img/mydata_help5.jpg';
import mydata_help6 from '../../img/mydata_help6.jpg';
import mydata_help7 from '../../img/mydata_help7.jpg';
import mydata_help8 from '../../img/mydata_help8.jpg';


const MyDataHelpModal = forwardRef((props, ref) => {

    const { t } = useTranslation();
    const [showHelpMyData, setShowHelpMyData] = useState(false);


    useImperativeHandle(ref, () => ({

        open() {
            setShowHelpMyData(true)
        },
        close() {
            setShowHelpMyData(false)
        },

    }));
    const handleCloseHelpMyData = () => {
        setShowHelpMyData(false);
    }

    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showHelpMyData}
            backdrop="static"
            keyboard={false}
            scrollable={true}

        >
            <Modal.Header >
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container-fluid">
                    <h3 className="text-center"> Οδηγίες σύνδεσης myData</h3>
                    <div className="card">
                        <div className="card-header">
                            <h5>
                                1. Κάνετε είσοδο στην πλατφόρμα myDATA της ΑΑΔΕ με τους κωδικούς του taxisnet. Σύνδεσμος εφαρμογής: <a target="_blank" href="https://www1.aade.gr/saadeapps2/bookkeeper-web">https://www1.aade.gr/saadeapps2/bookkeeper-web</a>
                            </h5>
                        </div>
                        <div className="card-body">
                            <img className="img-thumbnail"
                                src={mydata_help1}
                                alt="new"></img>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h5>
                                2. Επιλέξτε "Εγγραφή στο myDATA REST API"
                            </h5>
                        </div>
                        <div className="card-body">

                            <img className="img-thumbnail"
                                src={mydata_help2}
                                alt="new"></img>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h5>
                                3. Επιλέξτε "Νέα εγγραφή"
                            </h5>
                        </div>
                        <div className="card-body">

                            <img className="img-thumbnail"
                                src={mydata_help3}
                                alt="new"></img>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h5>
                                4. Συμπληρώστε Όνομα χρήστη, Κωδικό, Εmail και επιλέξτε "Προσθήκη εγγραφής"
                            </h5>
                        </div>
                        <div className="card-body">
                            <img className="img-thumbnail"
                                src={mydata_help4}
                                alt="new"></img>
                        </div>
                    </div>


                    <div className="card">
                        <div className="card-header">
                            <h5>
                                5. Σε περίπτωση επιτυχημένης εγγραφής, δημιουργείται ο Κωδικός API και το Όνομα Χρήστη
                            </h5>
                        </div>
                        <div className="card-body">
                            <img className="img-thumbnail"
                                src={mydata_help6}
                                alt="new"></img>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-header">
                            <h5>
                                6. Καταχώρηση Στοιχείων myDATA στο TIMOLOGIC
                            </h5>
                        </div>
                        <div className="card-body">
                            <img className="img-thumbnail"
                                src={mydata_help7}
                                alt="new"></img>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-header">
                            <h5>
                                7. Ενεργοποίηση myData στην έκδοση παραστατικού
                            </h5>
                        </div>
                        <div className="card-body">
                            <img className="img-thumbnail"
                                src={mydata_help8}
                                alt="new"></img>
                        </div>
                    </div>

                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-skin" onClick={handleCloseHelpMyData}>ok</button>
            </Modal.Footer>
        </Modal>


    );
}
);

export default MyDataHelpModal;
