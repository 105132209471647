import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom"
import { useAlert } from 'react-alert'
import ApiClient from "../services/api-client"
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom'

export default function ActivateLicenceForm(props) {

    const [licenceKey, setLicenceKey] = useState("");
    const { t } = useTranslation();


    const handleInputChange = (event) => {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        switch (inputName) {
            case "licenceKey": {
                setLicenceKey(inputValue);
                break;
            }
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        ApiClient.activateUserByLicenceKey(licenceKey).then(
            (response) => {
                var response = response.data;
                console.log(response)
                toast.success(t("ActivationSuccess"));
                setTimeout(function(){
                    window.location.reload()
                },1000)
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                    toast.error(resMessage);

            }
        );
    }



    return (
        <div className="activation-licence-container">
            <div className="activation-licence row">
                <div className="activation-licence container-fluid text-center">
                    <div className="alert alert-info" role="alert">
                        <p> {t("Activate")}</p>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="form-item">
                            <input type="licenceKey" name="licenceKey"
                                className="form-control" placeholder="licenceKey"
                                value={licenceKey} onChange={handleInputChange} required />
                        </div>
                        <hr></hr>
                        <div className="form-item">
                            <button type="submit" className="btn btn-block btn-skin">{t("Activate")}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

