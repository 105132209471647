import React from "react";
import Select, { components } from 'react-select'
import CreatableSelect from 'react-select/creatable';
import { AsyncPaginate, wrapMenuList,withAsyncPaginate } from "react-select-async-paginate";
import useMobileDetect from 'use-mobile-detect-hook';



const formatOptionLabel = ({ ...data }, { context }, props) => {

    var keys = Object.keys(data);
    keys = keys.filter(item => item !== "value" && item !== "model")
    var cols = [];
    if (props.asyncPaginate) {
        cols = props.columns;
    }
    else {
        cols = props.options ? props.options.columns : [];
    }

    const tifOptions = cols.map((col) =>
        <div key={col.field} className={col.flex ? "p-1 col-" + col.flex : "p-1 col"}>{col.asLabel ? data["label"] : data[col.field]}</div>
    )
    if (context === "value") {
        //when model has code attribute, then code attribute as default else label attribute
        return <div>{data["label"]}</div>;
    } else if (context === "menu") {
        return (
            <div className="row optionSelectTableRow">
                {tifOptions}
            </div>
        );
    }
};

const getOptionLabel = ({ option }) => {

    var keys = Object.keys(option);
    var str = "";
    keys.forEach((k) => {
        if (k != "value") {
            str += option[k] + " ";
        }

    });
    return str;
};


const { MenuList } = components;

const CustomMenuList = ({ selectProps, ...props }) => {

    var headerElement = [];
    selectProps.columns.forEach(col => {
        headerElement.push(<div key={col.field} className={col.flex ? "selectTableHeader p-1 col-" + col.flex : "p-1 col"}>{col.header}</div>);
    });

    var btns = [];
    if (selectProps.footer) {
        var index = 0;
        selectProps.footer.buttons.forEach(btn => {
            var classname = btn.className + " react-select-shortcut";
            var key = "footerbtn_" + index;
            index++;
            btns.push(<button key={key} onClick={function (e) { e.preventDefault(); btn.onClick() }} className={classname}><i className={btn.icon}></i></button>);
        });

    }
    // console.log(props)
    return (
        <div className="selectTableDropDown">
            <div className="container-fluid">
                <div className="col-md-12">
                    <div className="row headerRow">
                        {headerElement}
                    </div>
                </div>
            </div>
            <MenuList className="selectTableMenuList" {...props} selectProps={selectProps} />
            {selectProps.footer ?
                <div className="d-flex justify-content-end react-select-footer">
                    <div className="btn-group">
                        {btns}
                    </div>
                </div> : ""}

        </div>
    );
};

const defaultAdditional = {
    page: 1
};
const detectMobile = useMobileDetect();

const getWidth=(width)=>{
    return detectMobile.isDesktop() ?(width == undefined ? '100%' : width):"100%"; 

}


const CreatableAsyncPaginate = withAsyncPaginate(CreatableSelect) ;
class SelectTable extends React.Component {

    constructor(props) {
        super(props);
        window.selectRef = React.createRef();
    }


    state = {
        selectedOption: this.props.defaultValue,
    };

    handleChange = value => {

        if (value == null) {
            this.setValue("");
            this.props.onChangeValue("");
        }
        else {
            this.setValue(value.label);
            this.props.onChangeValue(value);
        }

    };

    hangleInputChange = (value, input) => {
        this.props.onInputChange(value, input);
    }
    hangleOnBlur = value => {
        this.props.onBlur(value);
    }

    setValue = value => {
        this.setState(prevState => ({
            select: {
                ...prevState.select,
                value
            }
        }));
    };

    hangleAsyncInputChange = (value) => {
        this.setValue(value);
    }


    render() {

        // const customStyles = {
        //     menu: (provided, state) => ({
        //         ...provided,
        //         minWidth: 250,
        //         border: '4px #333',
        //         zIndex: 9999
        //     }),
        //     control: (provided, state) => ({
        //         ...provided,
        //         background: '#fff',
        //         borderColor: '#9e9e9e',
        //         minHeight: '30px',
        //         height: '30px',
        //         boxShadow: state.isFocused ? null : null,
        //     }),
        //     valueContainer: (provided, state) => ({
        //         ...provided,
        //         height: '30px',
        //         padding: '0 6px'
        //     }),

        //     input: (provided, state) => ({
        //         ...provided,
        //         margin: '0px',
        //     }),
        //     indicatorSeparator: state => ({
        //         display: 'none',
        //     }),
        //     indicatorsContainer: (provided, state) => ({
        //         ...provided,
        //         height: '30px',
        //     })
        // };


       

        if (this.props.asyncPaginate) {
            if (this.props.createTable) {
                return (
               
                    <CreatableAsyncPaginate
    
                        value={this.props.selectedValue}
                        loadOptions={(q, prevOptions, { page }) => this.props.loadOptions(q, prevOptions, { page }, this.props)}
                        additional={defaultAdditional}
                        onChange={this.handleChange}
                        onInputChange={this.hangleInputChange}
                        columns={this.props.columns ? this.props.columns : []}
                        getOptionLabel={(option) => { return getOptionLabel({ option }) }}
                        formatOptionLabel={({ ...data }, { context }) => { return formatOptionLabel({ ...data }, { context }, this.props) }}
                        // isOptionDisabled={(option) => option.disabled}
                        loadOptionsOnMenuOpen={true}
                        inputValue={this.props.inputValue}
                        styles={{
                            menu: (provided, state) => ({
                                ...provided,
                                width: getWidth(this.props.width),
                                maxWidth: this.props.width,
                                border: '4px #333',
                                zIndex: 9999
                            }),
                            indicatorsContainer: (provided, state) => ({
                                ...provided,
                                padding: '0px',
                            }),
                            placeholder: (provided, state) => ({
                                ...provided,
                                fontSize: '12px',
                            }),
                        }}
                        isClearable
                        placeholder={this.props.placeholder}
                        inputId={this.props.inputId}
                        openOnFocus={true}
                        menuIsOpen={this.props.menuIsOpen}
                        isOpen={true}
                        className={this.props.className}
                        maxMenuHeight={200}
                        components={{
                            MenuList: wrapMenuList(CustomMenuList),
                        }}
                    />
                )
            }
            else{
                return (
               
                    <AsyncPaginate
    
                        value={this.props.value}
                        loadOptions={(q, prevOptions, { page }) => this.props.loadOptions(q, prevOptions, { page }, this.props)}
                        additional={defaultAdditional}
                        onChange={this.handleChange}
                        onInputChange={this.hangleAsyncInputChange}
                        defaultValue={this.props.defaultValue}
                        columns={this.props.columns ? this.props.columns : []}
                        getOptionLabel={(option) => { return getOptionLabel({ option }) }}
                        formatOptionLabel={({ ...data }, { context }) => { return formatOptionLabel({ ...data }, { context }, this.props) }}
                        // isOptionDisabled={(option) => option.disabled}
                        loadOptionsOnMenuOpen={true}
                        styles={{
                            menu: (provided, state) => ({
                                ...provided,
                                width: getWidth(this.props.width),
                                maxWidth: this.props.width,
                                border: '4px #333',
                                zIndex: 9999
                            }),
                            indicatorsContainer: (provided, state) => ({
                                ...provided,
                                padding: '0px',
                            }),
                            placeholder: (provided, state) => ({
                                ...provided,
                                fontSize: '12px',
                            }),
                        }}
                        isMulti={this.props.isMulti}
                        inputId={this.props.inputId}
                        placeholder={this.props.placeholder}
                        openOnFocus={true}
                        isClearable={this.props.isClearable}
                        menuIsOpen={this.props.menuIsOpen}
                        isOpen={true}
                        className={this.props.className}
                        maxMenuHeight={200}
                        components={{
                            MenuList: wrapMenuList(CustomMenuList),
                        }}
                    />
                )
            }
            
        }
        else {
            if (this.props.createTable) {
                return (
                    <CreatableSelect

                        onChange={this.handleChange}
                        onInputChange={this.hangleInputChange}
                        inputValue={this.props.inputValue}
                        defaultValue={this.props.defaultValue}
                        // menuPortalTarget={document.body}
                        isClearable
                        isMulti={this.props.isMulti}
                        inputId={this.props.inputId}
                        options={this.props.options ? this.props.options.data : []}
                        columns={this.props.options ? this.props.options.columns : []}
                        getOptionLabel={(option) => { return getOptionLabel({ option }) }}
                        formatOptionLabel={({ ...data }, { context }) => { return formatOptionLabel({ ...data }, { context }, this.props) }}
                        onKeyDown={(e) => {
                            // if (e.key === "Enter") {
                            //     var menuIsOpen = window.selectRef.current.state.menuIsOpen; 
                            //     if (!menuIsOpen) { e.preventDefault() };
                            // }
                        }}
                        ref={window.selectRef}
                        // isOptionDisabled={(option) => option.disabled}
                        styles={{
                            menu: (provided, state) => ({
                                ...provided,
                                width: getWidth(this.props.width),
                                maxWidth: this.props.width,
                                border: '4px #333',
                                zIndex: 9999
                            }),
                            indicatorsContainer: (provided, state) => ({
                                ...provided,
                                padding: '0px',
                            }),
                            placeholder: (provided, state) => ({
                                ...provided,
                                fontSize: '12px',
                            }),
                        }}
                        // footer={this.props.footer ? this.props.footer : null}
                        value={this.props.selectedValue}
                        placeholder={this.props.placeholder}
                        openOnFocus={true}
                        menuIsOpen={this.props.menuIsOpen}
                        isOpen={true}
                        maxMenuHeight={200}
                        className={this.props.className}
                        isDisabled={this.props.isDisabled}
                        components={{
                            MenuList: CustomMenuList,
                        }}

                    />
                );
            }
            else {
                return (

                    <Select

                        onChange={this.handleChange}
                        defaultValue={this.props.defaultValue}
                        // menuPortalTarget={document.body}
                        value={this.props.selectedValue}
                        onKeyDown={(e) => { if (e.key === "Enter") { var menuIsOpen = window.selectRef.current.state.menuIsOpen; if (!menuIsOpen) { e.preventDefault() }; } }}
                        ref={window.selectRef}
                        options={this.props.options ? this.props.options.data : []}
                        columns={this.props.options ? this.props.options.columns : []}
                        getOptionLabel={(option) => { return getOptionLabel({ option }) }}
                        formatOptionLabel={({ ...data }, { context }) => { return formatOptionLabel({ ...data }, { context }, this.props) }}
                        // isOptionDisabled={(option) => option.disabled}
                        isDisabled={this.props.isDisabled}
                        styles={{
                            menu: (provided, state) => ({
                                ...provided,
                                width: getWidth(this.props.width),
                                border: '4px #333',
                                minWidth: 300,
                                zIndex: 9999
                            }),
                            indicatorsContainer: (provided, state) => ({
                                ...provided,
                                padding: '0px',
                            }),
                            placeholder: (provided, state) => ({
                                ...provided,
                                fontSize: '12px',
                            }),
                        }}
                        inputId={this.props.inputId}
                        openOnFocus={true}
                        menuIsOpen={this.props.menuIsOpen}
                        isOpen={true}
                        className={this.props.className}
                        maxMenuHeight={200}
                        components={{
                            MenuList: CustomMenuList,
                        }}
                    />
                );
            }
        }



    }
}

export default SelectTable;