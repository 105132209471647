import React, { useEffect, useState, useRef } from "react";
import { useParams } from 'react-router-dom';
import ApiClient from "../services/api-client"
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import MyDataHelpModal from "../components/modals/mydata-help.component";
import Commons from '../util/commons'

import {
  ResponsiveContainer,
  BarChart,
  Bar, Cell,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";

export default function Dashboard() {



  const [content, setContent] = useState({});
  const { t, i18n } = useTranslation();
  const refMyDataHelp = useRef();
  const [notifications, setNotifications] = useState([]);
  const [hiddenTotals, setHiddenTotals] = useState(false);

  const chartCardContainer1=useRef();
  const chartCardContainer2=useRef();

  const totalsLastMonth=useRef();
  const avePricePerDay=useRef();

  

  useEffect(() => {
    var comp = JSON.parse(localStorage.getItem("company"));
    var _ht = Commons.getDefaults().hideTotals ? Commons.getDefaults().hideTotals : false;
    setHiddenTotals(_ht)
    if(_ht){
      chartCardContainer1.current.style.display="none"
      chartCardContainer2.current.style.display="none"
    }
    else{
      chartCardContainer1.current.style.display="flex"
      chartCardContainer2.current.style.display="flex"
    }
    if (comp != null) {
      ApiClient.getCompanyDashboard(comp.id).then(
        response => {
          if (response != undefined) {
            var _data = response.data;
            var totalWithVat = _data.totalWithVat;
            totalWithVat = totalWithVat == null ? 0 : totalWithVat;
            setContent({
              averageLasMonthPerDay: _data.averageLasMonthPerDay,
              companyName: comp.name,
              subjectField: comp.subjectField,
              taxOffice: comp.taxOffice ? comp.taxOffice.name : "",
              taxpayerId: comp.taxpayerId,
              countSaleInstances: _data.countSaleInstances,
              countProducts: _data.countProducts,
              countCustomers: _data.countCustomers,
              totalWithVat: parseFloat(totalWithVat).toFixed(2),
              chartData: _data.chartData,
              chartMonthData: _data.chartMonthData,
              userRemainingDays: _data.userRemainingDays
            })
          }
        },
        error => {
          console.error(error.data)
        }
      )
      ApiClient.getNotifications().then(
        response => {
          if (response != undefined) {
            var _data = response.data;
            var els = [];
            var specialEl = myDataHelpInfo();
            if (specialEl) {
              els.push(specialEl);
            }
            var counter = 0;
            _data.forEach(element => {
              var status = element.status.toLowerCase();
              var key = "liel_" + counter;
              var cls = "list-group-item  list-group-item-" + status;
              var linkhere = element.link ? <a target="_blank" style={{ cursor: "pointer" }} href={element.link}>{t("clickHere")}</a> : "";
              var mess = <span><span>{element.message}</span><span className="d-flex flex-row-reverse">{linkhere}</span></span>
              var el = <li key={key} className={cls}>{mess}</li>
              els.push(el);
              counter++;
            });

            setNotifications(els);
          }
        },
        error => {
          console.error(error.data)
        }
      )
    }
  }, []);

  const companyUrl = () => {
    var comp = JSON.parse(localStorage.getItem("company"));
    if (comp) {
      return "usercompany/" + comp.id;
    }

  }

  const myDataHelpInfo = () => {
    var comp = JSON.parse(localStorage.getItem("company"));
    var _href = comp ? "/usercompany/" + comp.id : null;
    if (comp) {
      console.log(comp.myDataUserId)
      if (comp.myDataUserId == undefined || comp.myDataUserId == "" || comp.myDataToken == undefined || comp.myDataToken == "") {
        return (

          <li href="#" className="list-group-item list-group-item-action list-group-item-warning">
            <p>{t("mydataInfo2_1")}<a target="_blank" href={_href}>{t("mydataInfo2_2")}</a>{t("mydataInfo2_3")}</p>
          </li>
        );
      }
    }
  }

  const hideTotals = (e) => {
    var faIcon = e.currentTarget.querySelector("i");
    if (faIcon) {
      if (faIcon.getAttribute("class") == "fa fa-eye") {
        avePricePerDay.current.innerHTML = "***"
        totalsLastMonth.current.innerHTML = "***"
        faIcon.setAttribute("class", "fa fa-eye-slash");
        Commons.setDefault("hideTotals", true)
        chartCardContainer1.current.style.display="none"
        chartCardContainer2.current.style.display="none"

      }
      else {
        avePricePerDay.current.innerHTML = content.averageLasMonthPerDay
        totalsLastMonth.current.innerHTML = content.totalWithVat
        faIcon.setAttribute("class", "fa fa-eye");
        Commons.setDefault("hideTotals", false)
       
        chartCardContainer1.current.style.display="flex"
        chartCardContainer2.current.style.display="flex"
      }
    }

  }
  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <div className="container-fluid salesContainer">


      <div className="row">

        <div className="col-md-3">
          <div className="card dashboardCard">
            <div className="card-header">
              <div className="d-flex">
                <div className="flex-1 overflow-hidden">
                  <h4 className="mb-2">{content.companyName}</h4>
                  <h5 className="mb-0">{content.subjectField}</h5>
                  <h5 className="mb-0">{t("taxpayerId")}: {content.taxpayerId} {t("taxOffice")}: {content.taxOffice}</h5>
                </div>
                <div className="text-primary">
                  <i className="ri-stack-line font-size-24"></i>
                  {content.userRemainingDays <=3 && content.userRemainingDays >0 ?<span style={{color:"white"}}>{t("userRemainingDays",{userRemainingDays:content.userRemainingDays})} </span>:<></>} 
                </div>
              </div>
            </div>
            <div className="border-top py-3 card-body">
              <div className="text-truncate">

                <span className="ms-2">
                  <Link to={companyUrl}>{t("EditCompany")}  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>


        <div className="col-md-3">
          <div className="card dashboardCard">
            <div className="card-header">
              <div className="d-flex">
                <div className="flex-1 overflow-hidden">
                  <h5 className="mb-2">{t("noOfSales")}</h5>
                  <h4 className="mb-0">{content.countSaleInstances}</h4>
                </div>
                <div className="text-primary">
                  <i className="ri-stack-line font-size-24"></i>
                </div>
              </div>
            </div>
            <div className="border-top py-3 card-body">
              <div className="text-truncate">

                <span className="ms-2">
                  <Link to="/sale">{t("InvoicePublication")}</Link>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card dashboardCard">
            <div className="card-header">
              <div className="d-flex">
                <div className="flex-1 overflow-hidden">
                  <h5 className="mb-2">{t("noOfCustomers")}</h5>
                  <h4 className="mb-0">{content.countCustomers}</h4>
                </div>
                <div className="text-primary">
                  <i className="ri-store-2-line font-size-24"></i>
                </div>
              </div>
            </div>
            <div className="border-top py-3 card-body">
              <div className="text-truncate">
                <span className="ms-2"><Link to="/customers">{t("mycustomers")}</Link></span>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-md-2">
          <div className="card dashboardCard">
            <div className="card-header">
              <div className="d-flex">
                <div className="flex-1 overflow-hidden">
                  <h5 className="mb-2">{t("noProductsServices")}</h5>
                  <h4 className="mb-0">{content.countProducts}</h4>
                </div>
                <div className="text-primary">
                  <i className="ri-store-2-line font-size-24"></i>
                </div>
              </div>
            </div>
            <div className="border-top py-3 card-body">
              <div className="text-truncate">
                <span className="badge badge-soft-success font-size-11 me-1">
                  <Link to="/products">{t("myProductsServices")}</Link>
                </span>
              </div>
            </div>
          </div>
        </div> */}

        <div className="col-md-3">
          <div className="card dashboardCard">
            <div className="card-header">
              <div className="d-flex">
                <div className="flex-1 overflow-hidden">
                  <h5 className="mb-2">{t("totalsLastMonth")}</h5>
                  <h4 ref={totalsLastMonth} id="totalsLastMonth" className="mb-0">{hiddenTotals ? "***" : content.totalWithVat}</h4>
                </div>
                <div className="text-primary">
                  <i className="ri-briefcase-4-line font-size-24"></i>
                </div>
              </div>
            </div>

            <div className="border-top py-3 card-body">
              <div className="text-truncate">
                <span className="ms-2">{t("avePricePerDay")}: <span ref={avePricePerDay} id="avePricePerDay"><b>{hiddenTotals ? "***" : content.averageLasMonthPerDay}</b> </span></span>
                <button className="btn btn-skin" style={{ float: "right" }} onClick={hideTotals}>{hiddenTotals ? <i className="fa fa-eye-slash"></i> : <i className="fa fa-eye"></i>}</button>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div ref={chartCardContainer1} className="row chart-card-container">
        <div className="col-md-8">
          <div className="card chart-card" >
            <ResponsiveContainer
              height={400}

            // aspect={4.0 / 1.0}
            >
              <BarChart margin={{ top: 0, left: -20, right: 0, bottom: 0 }} width={500} height={40} data={content.chartMonthData}>
                <XAxis dataKey="month_year" />
                <YAxis
                  // tick={{ dx: 25 }}
                  // angle={-45}
                  // textAnchor="end"
                  type="number" dataKey={(v) => parseFloat(v.totals)} allowDataOverflow={true} domain={[0, dataMax => ((dataMax + dataMax / 10).toFixed(0))]} />
                <Tooltip />
                <Legend />
                <Bar dataKey="totals" barSize={20} fill="#8884d8" name={t("totalsPerMonthCY")} />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card chart-card" style={{ padding: 0 }}>
            <div className={"card-header card-header-skin"}>

              <h5><i className="fa fa-bullhorn" style={{ marginRight: 10 }}></i> {t("LatestNotifications")}</h5>
            </div>
            <div className={"card-body  overflow-auto"} style={{ maxHeight: 336, padding: 0 }}>
              <ol className="list-group list-group-numbered">
                {notifications}
              </ol>
            </div>

          </div>
          {/* <div>
            <hr></hr>
            <form data-min-no-for-search="2" data-result-box-max-height="400" data-form-id="1097" className="is-search-form is-form-style is-form-id-1097 is-ajax-search" action="https://timologic.gr/blog/" method="get" role="search">
              <div className="d-flex">
                <label htmlFor="is-search-input-1097" style={{width:"100%"}}>
                  <input type="search" id="is-search-input-1097" name="s" className="is-search-input form-control form-control-lg" style={{width:"100%"}} placeholder="Κανε αναζητηση εδώ .." autoComplete="off"></input>
                  <span className="is-loader-image" style={{ display: 'none',backgroundImage:`url('https://timologic.gr/blog/wp-content/plugins/add-search-to-menu/public/images/spinner.gif')` }}></span>
                </label>
                <button type="submit" className="is-search-submit btn"><span className="is-screen-reader-text"><i className="fa fa-2x fa-search"></i></span><span className="is-search-icon"></span></button>
                <div id="is-ajax-search-result-1097" className="is-ajax-search-result"></div>
              </div>
            </form>
          </div> */}
        </div>

      </div>

      <div ref={chartCardContainer2} className="row chart-card-container">
        <div className="col-md-12">
          <div className="card chart-card" >
            <ResponsiveContainer height={400} >
              <LineChart
                data={content.chartData}
                margin={{ top: 0, left: -20, right: 0, bottom: 0 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="invoiceDate" />
                <YAxis
                  // tick={{ dx: 25 }}
                  // angle={-45}
                  // textAnchor="end"
                  type="number" dataKey={(v) => parseFloat(v.totalsPerDay)} allowDataOverflow={true} domain={[0, dataMax => ((dataMax + dataMax / 10).toFixed(0))]} />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="totalsPerDay"
                  stroke="#8884d8"
                  name={t("totalsPerDay")}
                // activeDot={{ r: 8 }}
                />
                {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      <MyDataHelpModal ref={refMyDataHelp}></MyDataHelpModal>

    </div>
  );
}