import React from "react";

var QRCode = require('qrcode.react');

const default_color = "#013289"

class Template2xA5Component extends React.Component {



    createEntries = (entries) => {
        var rows = [];


        var rowlimit = 5;


        if (entries != undefined) {
            var diff = rowlimit - entries.length;
            var emptyEnries = [];
            if (diff > 0) {
                for (var i = 0; i < diff - 1; i++) {
                    var emptyEntry = {
                        id: "empty_" + i,
                        NAME_LIST: "",
                        QUANTITY_LIST: "",
                        MU_LIST: "",
                        UP_LIST: "",
                        DISCOUNT: "",
                        VAT_LIST: "",
                        PRICE_NOVAT_LIST: "",
                        PRICE_LIST: ""
                    }
                    emptyEnries.push(emptyEntry);
                }
            }

            entries = entries.concat(emptyEnries);
            entries.forEach(entry => {
                var row = <tr key={"entry_" + entry.id+"_"+Math.floor(Math.random() * 10000)} className="products">
                    <td>
                        <span className="item_name">
                            <p><small>{entry.NAME_LIST}</small></p>
                        </span>
                    </td>
                    <td>
                        <span className="item_quantity">
                            <p>{entry.QUANTITY_LIST}</p>
                        </span>
                    </td>
                    <td>
                        <span className="item_mu">
                        <p> {this.props.t(entry.MU_LIST, { lng: this.props.sale.TEMPLATE_LANG })}</p>
                        </span>
                    </td>
                    <td>
                        <span className="item_unit_price">
                            <p>{entry.UP_LIST}</p>
                        </span>
                    </td>
                    <td>
                        <span className="item_discount">
                            <p>{entry.DISCOUNT}</p>
                        </span>
                    </td>
                    <td>
                        <span className="item_vat">
                            <p>{entry.VAT_LIST}</p>
                        </span>
                    </td>
                    <td>
                        <span className="item_price_novat">
                            <p>{entry.PRICE_NOVAT_LIST}</p>
                        </span>
                    </td>
                    <td>
                        <span className="item_price_withvat">
                            <p>{entry.PRICE_LIST}</p>
                        </span>
                    </td>
                </tr>
                rows.push(row)
            });
        }


        return (rows);
    }

    getCompanyLogo = () => {
        var _comp = JSON.parse(localStorage.getItem("company"));
        var logopath = _comp ? _comp.logoPath : "";
        return logopath + "?" + Date.now();
    }

    qrcodeValue = (invoiceUid) => {
        var _host = window.location.host

        return _host + "/mydata/" + invoiceUid
    }

    createInvoiceBox = (sale) => {
        return (
            <div className="invoice-box" key={"box_" + Math.floor(Math.random() * 10000)} >
                <div className="top_table">
                    <table>
                        <tbody>
                            <tr className="top">
                                <td>
                                    <table className="header_table">
                                        <tbody>
                                            <tr>
                                                <td className="logo_container">
                                                    <img alt="" src={sale.LOGO + "?" + Date.now()} className="logo_img"></img>
                                                </td>

                                                <td className="issuer_container">
                                                    <span className="issuer_name template_skin">{sale.ISSUER_NAME}</span>
                                                    <p> <span className="issuer_subjectField template_skin ">{sale.ISSUER_SUBJECTFIELD}</span></p>
                                                    <p><span className="issuer_address template_skin ">{sale.ISSUER_ADDRESS}</span></p>

                                                    <p>
                                                        <span className=" template_skin ">{this.props.t("TAXPAYER_ID")}: </span><span className="issuer_vat template_skin ">{sale.ISSUER_VAT}</span>
                                                        &nbsp;
                                                        <span className=" template_skin ">{this.props.t("DOY")}: </span> <span className="issuer_doy template_skin ">{sale.ISSUER_DOY}</span>

                                                    </p>
                                                    <p>
                                                        <span className=" template_skin ">{this.props.t("PHONE")}: </span> <span className="issuer_phone template_skin ">{sale.ISSUER_PHONE}</span>
                                                        &nbsp;
                                                        <span className=" template_skin ">{this.props.t("EMAIL")}: </span> <span className="issuer_email template_skin ">{sale.ISSUER_EMAIL}</span>
                                                    </p>
                                                    {/* <p className="gemh_issuer_p template_skin "> <span className=" template_skin ">ΑΡ.ΓΕΜΗ: </span> <span className="issuer_gemh">{sale.ISSUER_GEMH}</span></p> */}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="main_info">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <table className="main_info_table">
                                            <tbody>
                                                <tr className="heading">
                                                    <td>
                                                        <p>{this.props.t("INVOICE_TYPE")}</p>
                                                    </td>

                                                    <td>
                                                        <p>{this.props.t("NUMBER")}</p>
                                                    </td>
                                                    <td>
                                                        <p>{this.props.t("SHORT_DATE")}</p>
                                                    </td>
                                                    {/* <td>
                                                        <p>ΩΡΑ</p>
                                                    </td> */}
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span className="invoice_type">{sale.INVOICE_TYPE}</span>
                                                    </td>

                                                    <td>
                                                        <span className="invoice_number">{sale.INVOICE_NUMBER}</span>
                                                    </td>
                                                    <td>
                                                        <span className="invoice_date"> {sale.INVOICE_DATE}</span>
                                                    </td>
                                                    {/* <td>
                                                        <span className="invoice_time"> {sale.INVOICE_TIME}</span>
                                                    </td> */}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <table className="information_table">
                        <tbody>
                            <tr>
                                <td className="information_td_left">
                                    <div className="information left">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="template_skin bold">
                                                        <span> {this.props.t("CP_NAME")}</span>
                                                    </td>
                                                    <td className="info_value">
                                                        <span>: </span>
                                                        <span className="counterparty_name">{sale.CP_NAME}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="template_skin bold">
                                                        <span> {this.props.t("TAXPAYER_ID")}</span>
                                                    </td>
                                                    <td className="info_value">
                                                        <span>: </span>
                                                        <span className="counterparty_vat">{sale.CP_VAT}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="template_skin bold">
                                                        <span> {this.props.t("CP_SUBJECT_FIELD")}</span>
                                                    </td>
                                                    <td className="info_value">
                                                        <span>: </span>
                                                        <span className="counterparty_activity">{sale.CP_SUBJECT_FIELD}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="template_skin bold">
                                                        <span> {this.props.t("DOY")}</span>
                                                    </td>
                                                    <td className="info_value">
                                                        <span>: </span>
                                                        <span className="counterparty_doy">{sale.CP_TAXOFFICE}</span>
                                                    </td>
                                                </tr>
                                                <tr className="blank_row">
                                                    <td>&nbsp;</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                                <td> </td>
                                <td className="information_td_right">
                                    <div className="information right">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="template_skin bold">
                                                        <span> {this.props.t("CP_PAYTYPE", { lng: sale.TEMPLATE_LANG })}</span>
                                                    </td>
                                                    <td className="info_value">
                                                        <span>: </span>
                                                        <span className="counterparty_paytype">{this.props.t(sale.CP_PAYTYPE, { lng: sale.TEMPLATE_LANG })}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="template_skin bold">
                                                        <span> {this.props.t("CP_CITY")}</span>
                                                    </td>
                                                    <td className="info_value">
                                                        <span>: </span>
                                                        <span className="counterparty_city">{sale.CP_CITY}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="template_skin bold">
                                                        <span> {this.props.t("CP_ADDRESS")}</span>
                                                    </td>
                                                    <td className="info_value">
                                                        <span>: </span>
                                                        <span className="counterparty_address">{sale.CP_ADDRESS}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="template_skin bold">
                                                        <span> {this.props.t("SEND_PLACE")}</span>
                                                    </td>
                                                    <td className="info_value">
                                                        <span>: </span>
                                                        <span className="send_place">{sale.SEND_PLACE}</span>
                                                    </td>
                                                </tr>
                                                {
                                                    sale.VEHICLE_NUMBER ?
                                                        <tr>
                                                            <td className="template_skin bold">
                                                                <span>ΑΡΙΘΜΟΣ ΟΧΗΜΑΤΟΣ</span>
                                                            </td>
                                                            <td className="info_value">
                                                                <span>: </span>
                                                                <span className="vahicle_number">{sale.VEHICLE_NUMBER}</span>
                                                            </td>
                                                        </tr> : <></>
                                                }
                                                <tr className="blank_row">
                                                    <td>&nbsp;</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="product_container">
                    <table className="product_table">
                        <tbody>
                            <tr className="heading">
                                <td className="description_head_td">
                                    <p>{this.props.t("PROD_SERV")}</p>
                                </td>
                                <td className="quantity_head_td">
                                    <p>{this.props.t("QUANTITY")}</p>
                                </td>
                                <td className="mu_head_td">
                                    <p> {this.props.t("MUN")}</p>
                                </td>
                                <td className="up_head_td">
                                    <p> {this.props.t("UNIT_PRICE")}</p>
                                </td>
                                <td className="disc_head_td">
                                    <p> {this.props.t("DISCOUNT")}</p>
                                </td>
                                <td className="vat_head_td">
                                    <p>  {this.props.t("VAT")} %</p>
                                </td>
                                <td className="pricenovat_head_td">
                                    <p>  {this.props.t("PRICE_WITHOUT_VAT")}</p>
                                </td>
                                <td className="price_head_td">
                                    <p> {this.props.t("PRICE_WITH_VAT")}</p>
                                </td>
                            </tr>
                            {this.createEntries(sale.entries)}
                        </tbody>
                    </table>
                </div>
                <div className="footer_container">


                    <table className="footer_table">
                        <tbody>
                            <tr>
                                <td className="qrcodeimg_container">
                                    <span className="qrcode_img">

                                        <QRCode
                                            size={100}
                                            bgColor={"#ffffff"}
                                            fgColor={"#000000"}
                                            level={"L"}
                                            includeMargin={false}
                                            renderAs={"svg"}
                                            value={this.qrcodeValue(sale.INVOICEUID)}
                                        // imageSettings={{
                                        //     height: 24,
                                        //     width: 24,
                                        //     excavate: true,
                                        // }}
                                        />
                                    </span>
                                </td>
                                <td>
                                    <div className="cont_notation">
                                        <span className="template_skin bold">{this.props.t("NOTES")}</span>
                                        <div className="cont_notation_inner">
                                            <span className="notes">{sale.NOTES}</span>
                                        </div>
                                    </div>

                                </td>

                                <td>
                                    {/* <div className="count_totals_container">
                                        <span>{this.props.t("TOTALITEMS")}: </span>
                                        <span className="count_total_prods">
                                            {sale.TOTALCOUNTITEMS}
                                        </span>
                                    </div> */}
                                    <div className="total_container">



                                        <div className="totals">
                                            <table className="totals_table">
                                                <tbody>
                                                    <tr>
                                                        <td className="text-left">
                                                            <p>{this.props.t("TOTALVALUE")}</p>
                                                        </td>
                                                        <td className="text-right">
                                                            <p><span className="total_nodiscount">{sale.TOTALNODISCOUNT}</span> </p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-left">
                                                            <p>{this.props.t("DISCOUNT")}</p>

                                                        </td>
                                                        <td className="text-right">
                                                            <p><span className="total_discount">{sale.TOTALDISCOUNT}</span></p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-left">
                                                            <p>{this.props.t("VATVALUE")}</p>
                                                        </td>
                                                        <td className="text-right">
                                                            <p><span className="total_withoutvat">{sale.TOTALVAT}</span> </p>
                                                        </td>
                                                    </tr>

                                                    {/* <tr>
                                                        <td className="text-left">
                                                            <p>ΣΥΝΟΛΟ ΧΩΡΙΣ ΦΠΑ</p>
                                                        </td>
                                                        <td className="text-right">
                                                            <p><span className="total_withoutvat">{sale.TOTALWITHOUTVAT}</span> </p>
                                                        </td>
                                                    </tr> */}
                                                    <tr>
                                                        <td className="text-left">
                                                            <p>{this.props.t("MORETAXES")}</p>
                                                        </td>
                                                        <td className="text-right">
                                                            <p><span className="total_whtaxes">{sale.TOTALTAXES}</span> </p>
                                                        </td>
                                                    </tr>
                                                    {/* <tr>
                                                        <td className="text-left">
                                                            <p>ΣΥΝΟΛΟ ΜΕ ΠΑΡΑΚΡΑΤΗΣΗ</p>
                                                        </td>
                                                        <td className="text-right">
                                                            <p><span className="amounttotal">{sale.TOTALWITHVAT}</span> </p>
                                                        </td>
                                                    </tr> */}
                                                    {/* <tr className="blank_row bordered">
                                                        <td className="text-left">&nbsp;</td>
                                                    </tr> */}
                                                    <tr >
                                                        <td className="text-left finalprice">
                                                            <p>{this.props.t("PAYAMOUNT")}</p>
                                                        </td>
                                                        <td className="text-right finalpricevalue">
                                                            <p><span className="totalpayment">{sale.TOTALPAYMENT}</span> </p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div className="total_funny_box"></div>
                                        </div>


                                    </div>

                                </td>

                            </tr>
                        </tbody>
                    </table>
                    <div className="mydata_footer">
                        <span className="mydata_sign">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <span>uid: </span> <span className="uid_sign">{sale.INVOICEUID}</span>
                                        </td>
                                        <td>
                                            <span>mark:</span>   <span className="mark_sign">{sale.INVOICEMARK}</span>
                                        </td>
                                        <td>
                                            {sale.CANCELATIONMARK != null ? <span><span>cancelationMark:</span><span className="mark_sign">{sale.CANCELATIONMARK}</span></span> : ""}
                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                        </span>
                        <span className="sender_sign">
                            <br></br>
                            {this.props.t("CREATEDBY")}
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    createPages = (sale) => {
        var pages = [];

        var i, j, groupEntries, chunk = 5;
        if (sale.INVOICE_ENTRIES != undefined) {

            for (i = 0, j = sale.INVOICE_ENTRIES.length; i < j; i += chunk) {
                groupEntries = sale.INVOICE_ENTRIES.slice(i, i + chunk);
                var groupSale = sale;
                groupSale.entries = groupEntries;
                var page = this.createPage(groupSale);
                pages.push(page);
                // do whatever
            }
        }

        return pages;

    }

    createPage = () => {
        return <>
            <div id="template_page" className="page template_page page_top">
                {this.createInvoiceBox(this.props.sale)}
            </div>
            <div id="template_page2" className="page template_page page_bottom">
                {this.createInvoiceBox(this.props.sale)}
            </div>
        </>
    }

    getMainStyle=()=>{
        if(this.props.mainStyle){
            return <style>{this.props.mainStyle}</style>
        }
    }

    getExtraCss=()=>{
        if(this.props.extracss){
            return <style>{this.props.extracss}</style>
        }
    }

    render() {
        return <div className="a4_container">
            <link type="text/css" rel="stylesheet" href="../template2.css" />
            <link href="https://fonts.googleapis.com/css?family=Open+Sans&family=Ubuntu&family=GFS+Neohellenic" rel="stylesheet" type="text/css"></link>
            {this.getMainStyle()}
            {this.getExtraCss()}
            {this.createPages(this.props.sale)}
        </div>;
    }

}
export default Template2xA5Component;