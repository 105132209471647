import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import ApiClient from "../../services/api-client"
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from "react-hook-form";
import BlockUi from 'react-block-ui';
import { toast } from 'react-toastify';
import Select from 'react-select'
import Tippy from '@tippyjs/react';
import useMobileDetect from 'use-mobile-detect-hook';


const Wizard = forwardRef((props, ref) => {

    const { t } = useTranslation();
    const { register, getValues, control, trigger, clearErrors, setValue, formState: { errors } } = useForm();
    const [blockuiLoading, setBlockuiLoading] = useState(false);
    const [content, setContent] = useState({});
    const [fetchTaxisInfoExplanationMessVisible, setFetchTaxisInfoExplanationMessVisible] = useState(true);
    const detectMobile = useMobileDetect();


    useImperativeHandle(ref, () => ({
        getValues() {
            trigger();
            return getValues();
        },
        getErrors() {
            return { errors };
        }
    }));


    useEffect(() => {
        trigger();
        setTimeout(function () {
            clearErrors();
        });

        ApiClient.getMyDataInvoiceTypes().then(response => {
            var data = response.data;
            var options = [];
            data.forEach(element => {
                var _enumVal = "invoice" + element.key.replace(".", "_");
                var postfix = " (" + element.myDataKey + ")";
                options.push({
                    value: _enumVal,
                    label: t(_enumVal) + postfix,
                });
            });


            setContent(content => ({
                ...content,
                ...{ myDataInvoiceTypes: options }
            }));

        });

        ApiClient.getCompanyView().then(
            response => {

                var _data = response.data;
                var tos = _data.taxOffices;
                var taxOffices = []
                tos.forEach(element => {
                    var to = {
                        value: element.id,
                        label: element.name
                    }
                    taxOffices.push(to);
                })


                setContent(content => ({
                    ...content,
                    ...{ taxOffices: taxOffices }
                }));

            });
    }, []);




    const createRandomTaxPayerId = () => {
        var min = 1000000;
        var max = 9999999;
        var AFMcalledfor = Math.floor(Math.random() * (max - min + 1)) + min;
        AFMcalledfor = "000" + AFMcalledfor.toString()
        setValue("taxpayerId", AFMcalledfor);
        clearErrors("taxpayerId")
    }
    const updateInvType = (vals) => {
        setValue("myDataInvoiceType", vals)
        trigger("myDataInvoiceType")
    };

    const update = (e) => {
        if (e.target == undefined) {
            setValue("taxOffice", { label: e.label, value: e.value });
        }
        else {
            setValue(e.target.name, e.target.value);
            trigger(e.target.name)
        }
    };

    const translateMyDataInvoiceTypes = types => {
        var optionspaymentTypes = [];
        if (types != undefined) {
            types.forEach(element => {
                optionspaymentTypes.push({
                    value: element.value,
                    label: t(element.label),
                });
            });

        }
        return optionspaymentTypes
    }
    const onClickFetchTaxPayerId = () => {
        var company = JSON.parse(localStorage.getItem("company"));
        var AFMcalledfor = getValues().taxpayerId;
        var AFMcalledby = "126235377";
        setBlockuiLoading(true)
        ApiClient.checkAfm(AFMcalledfor, AFMcalledby).then(
            response => {
                try {
                    var res = response.data.Body.rgWsPublicAfmMethodResponse;

                    var error = res.pErrorRec_out;
                    if (error) {
                        if (error.errorCode != null) {
                            toast.error(error.errorDescr);
                            setBlockuiLoading(false)
                            return;
                        }
                    }


                    var basic_rec = res.RgWsPublicBasicRt_out;
                    var firm_act_tab = res.arrayOfRgWsPublicFirmActRt_out;

                    setValue("name", basic_rec.onomasia);
                    var address = basic_rec.postalAddress

                    if (basic_rec.postalAddressNo && basic_rec.postalAddressNo.trim() != "0") {
                        address += " " + basic_rec.postalAddressNo.trim();
                    }

                    setValue("address", address);
                    if (firm_act_tab) {
                        if (Array.isArray(firm_act_tab.RgWsPublicFirmActRtUser)) {
                            setValue("subjectField", firm_act_tab.RgWsPublicFirmActRtUser[0].firmActDescr);
                        }
                        else {
                            setValue("subjectField", firm_act_tab.RgWsPublicFirmActRtUser.firmActDescr);

                        }
                    }
                    setValue("postalCode", basic_rec.postalZipCode);
                    setValue("city", basic_rec.postalAreaDescription);
                    setValue("number", basic_rec.postalAddressNo);
                    setValue("taxOffice", { "value": basic_rec.doy, "label": basic_rec.doyDescr });
                    setBlockuiLoading(false)
                } catch (error) {
                    console.error(error)
                    setBlockuiLoading(false)
                }
            },
            error => {
                if (error.response) {
                    toast.error(error.message + ": " + error.response.data.message)
                    console.error(error.response.data.message);
                }
                setBlockuiLoading(false)
            }
        )
    }

    const hidefetchTaxisInfoExplanationMess = () => {
        setFetchTaxisInfoExplanationMessVisible(false);
    }


    return (
        <div className='container navWizard'>
            <div className='row'>
                <div className='col-md-12 wizard-inner-container'>
                    <BlockUi tag="div" blocking={blockuiLoading}>
                        <div className="container-fluid">

                            <h5>{t("companyAdd")}</h5>

                            <div className="alert alert-primary small-info" role="alert">
                                <ul style={{padding:10}}>                                   
                                    <li>{t("CompanyWizardInfo1")}</li>
                                    <li>{t("CompanyWizardInfo2")}</li>
                                </ul>
                                {t("CompanyWizardInfo3")}
                            </div>


                            <div className="row">

                                <div className="col-md-4 form-group required">
                                    <label className="control-label">{t("taxpayerId")}</label>

                                    <div className="input-group mb-4">
                                        {/* <div className="input-group-prepend">
                                            <span className="input-group-text fetchTaxPayerId" onClick={onClickFetchTaxPayerId}><i className="fa fa-search"></i></span>
                                        </div> */}
                                        <input
                                            name="taxpayerId"
                                            type="text"
                                            maxLength={10}
                                            {...register('taxpayerId', { required: true, maxLength: 10 })}
                                            onChange={update}
                                            placeholder={t("placeholderInput")}
                                            className={`form-control ${errors.taxpayerId ? 'is-invalid' : ''}`}
                                        />
                                        <div className="input-group-append">
                                            <Tippy interactive={true} placement={detectMobile.isDesktop() ? "right" : "bottom"} trigger="click" visible={fetchTaxisInfoExplanationMessVisible} content={<span className="tooltip-skin">{t("fetchTaxisInfoExplanationMess")}<span style={{backgroundColor:'transparent',marginLeft:10,cursor:'pointer'}} onClick={hidefetchTaxisInfoExplanationMess} ><i style={{color:'white'}} className='fa fa-times'></i></span> </span>}>
                                                <   span className="input-group-text fetchTaxPayerId" onClick={onClickFetchTaxPayerId}><i className="fa fa-search"></i></span>
                                            </Tippy>
                                            {/* <span style={{height:40, backgroundColor: "#ffc107", cursor: "pointer" }} id="random_taxpayerId_inpt" className="input-group-text" onClick={createRandomTaxPayerId}><i className="fa fa-random"></i></span> */}
                                        </div>
                                        <div className="invalid-feedback">{errors.taxpayerId?.message}</div>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                            <hr></hr>
                            <div className="row">

                                <div className="col-md-4  form-group required">
                                    <label className="control-label">{t("CompanyName")}</label>
                                    <input
                                        name="name"
                                        type="text"
                                        maxLength={64}
                                        {...register('name', { required: true, maxLength: 64 })}
                                        placeholder={t("placeholderInput")}
                                        onChange={update}
                                        className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                    />
                                    <div className="invalid-feedback">{errors.name?.message}</div>
                                </div>




                                <div className="col-md-4 form-group required">
                                    <label className="control-label">{t("Address")}</label>
                                    <input
                                        name="address"
                                        type="text"
                                        autoComplete="no"
                                        maxLength={64}
                                        {...register('address', { required: true, maxLength: 64 })}
                                        placeholder={t("placeholderInput")}
                                        onChange={update}
                                        className={`form-control ${errors.address ? 'is-invalid' : ''}`}
                                    />
                                    <div className="invalid-feedback">{errors.address?.message}</div>
                                </div>

                                <div className="col-md-4 selectInputContainer form-group">
                                    <label>{t("taxOffice")}</label>


                                    <Controller
                                        render={({ field }) => (
                                            <Select {...field}
                                                options={content.taxOffices}
                                                className={`form-control ${errors.taxOffices ? 'is-invalid' : ''}`}
                                                onChange={update}
                                                placeholder={t("placeholderSelect")}
                                            >

                                            </Select>
                                        )}
                                        control={control}
                                        rules={{ required: false }}
                                        name="taxOffice"
                                        defaultValue=""
                                    />

                                    <div className="invalid-feedback">{errors.taxOffice?.message}</div>

                                </div>

                                <div className="col-md-6 form-group">
                                    <label>{t("subjectField")}</label>
                                    <input
                                        name="subjectField"
                                        type="text"
                                        maxLength={150}
                                        {...register('subjectField', { maxLength: 150 })}
                                        placeholder={t("placeholderInput")}
                                        onChange={update}
                                        className={`form-control ${errors.subjectField ? 'is-invalid' : ''}`}
                                    />
                                    <div className="invalid-feedback">{errors.subjectField?.message}</div>
                                </div>





                            </div>
                            <br></br>
                            <h5>{t("invtypesAdd")}</h5>
                            <div className="alert alert-primary small-info" role="alert">
                                <p>{t("invoiceWizardInfo")}</p>
                            </div>

                            <div className="row">
                                <div className="col-md-6 selectInputContainer form-group required">
                                    <label className="control-label">{t("invtypes")}</label>
                                    <Controller
                                        render={({ field }) => (
                                            <Select {...field}
                                                options={translateMyDataInvoiceTypes(content.myDataInvoiceTypes)}
                                                isMulti
                                                inputId="choose-invtype-multiselect"
                                                onChange={updateInvType}
                                                placeholder={t("placeholderSelect")}
                                                className={`form-control ${errors.myDataInvoiceType ? 'is-invalid' : ''}`}
                                            >

                                            </Select>
                                        )}
                                        control={control}

                                        rules={{ required: true }}
                                        name="myDataInvoiceType"
                                        defaultValue=""
                                    />

                                    {/* <i data-tip data-for="registerTip" className="fa fa-x2 fa-info-circle" data-bs-toggle="tooltip" data-bs-placement="top">
                           
                        </i>

                        <ReactTooltip className="skin-tooltip"id="registerTip" place="top" effect="solid">
                            {t("invoiceWizardInfo")}
                        </ReactTooltip> */}
                                </div>
                            </div>
                            <br></br>

                        </div>
                    </BlockUi>
                </div>
            </div>
        </div>
    );
});

export default Wizard;
