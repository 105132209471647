import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from "react";
import { toast } from 'react-toastify';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useForm} from "react-hook-form";
import ApiClient from "../../services/api-client"
import { CustomConfirmAlert as confirmAlert} from '../custom-confirm-alert';


const ActionsRenderer = forwardRef((props, ref) => {

    const [disabled, setDisabled] = useState(false);

    const { t} = useTranslation();

    const hangleDeleteRow = (e) => {
        e.preventDefault();

        let rowData = [];
        props.agGridReact.api.forEachNode(node => rowData.push(node.data));
        var companyId = JSON.parse(localStorage.getItem("company")).id;

        confirmAlert({
            title: t("submitConfirm"),
            message: t("areYouSure"),
            buttons: [
                {
                    label: t('Yes'),
                    onClick: () => {
                        var id = props.data.id;
                        if (id != undefined) {
                            ApiClient.deleteCompanyDeduction(companyId,id).then(
                                response => {
                                    rowData.splice(props.rowIndex, 1)
                                    props.agGridReact.api.setRowData(rowData)
                                    props.colDef.cellRendererParams.onRemoveRow();
                                    toast.success(t("deleteSucceded"))

                                },
                                error => {
                                    toast.error("Error: " + error.data)
                                    console.error(error.data)
                                }
                            )
                        }
                        else {
                            rowData.splice(props.rowIndex, 1)
                            props.agGridReact.api.setRowData(rowData)
                            props.colDef.cellRendererParams.onRemoveRow();
                        }

                    }
                },
                {
                    label: t('No'),
                    onClick: () => {
                    }
                }
            ]
        });

    }

    useImperativeHandle(ref, () => {
        var countDisplayedRows = props.agGridReact.api.getDisplayedRowCount() - 1;
        if (props.rowIndex == countDisplayedRows) {
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }
        return {
            refresh(params) {

            }
        };
    });
    return (
        <span>
            <button onClick={hangleDeleteRow} disabled={disabled} className="btn btn-sm btn-danger circle-btn"><i className="fa fa-times"></i></button>
        </span>
    );
});

const DoubleValRenderer = forwardRef((props, ref) => {


    const [val, setVal] = useState(props.value);
    

    useImperativeHandle(ref, () => {
        return {
            refresh(params) {
                var val = props.getValue();
                if (val !== undefined) {
                    var _val;
                    if (props.colDef.cellRendererParams && props.colDef.cellRendererParams.precision) {
                        _val = parseFloat(val.replace(",", ".")).toFixed(props.colDef.cellRendererParams.precision);
                    }
                    else {
                        _val = parseFloat(val.replace(",", ".")).toFixed(2);
                    }

                    setVal(_val);
                    props.node.setDataValue(props.column.colId, _val);

                }

            }
        };
    });
    if (props.colDef.field == "price") {
        return <span className="editableCell">{val != null && val != "" ? parseFloat(val).toFixed(2) : <div><span className="addtext"></span><div className="addtextafter">...</div></div>}</span>

    }
    else {
        return <span className="editableCell">{val && val != "" ? parseFloat(val).toFixed(2) : <div><span className="addtext"></span><div className="addtextafter">...</div></div>}</span>

    }
});


const CheckboxRenderer = forwardRef((props, ref) => {


    const [checked, setChecked] = useState(props.value?props.value:false);
    const [disabled, setDisabled] = useState(false);
    const { t, i18n } = useTranslation();

    const onChangeStatus = (e) => {
        
        props.node.setDataValue(props.column.colId, e.target.checked);
    }
    useImperativeHandle(ref, () => {
        return {
            refresh(params) {
                var val = props.getValue();
                if (val != undefined) {
                    setChecked(val);
                }

            }
        };
    });
    return <span className="editableCell"><input onChange={onChangeStatus} disabled={disabled} type="checkbox" checked={checked}></input></span>
});



const CompanyDeductionsGrid = forwardRef((props, ref) => {
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const { t, i18n } = useTranslation();
    const { formState: { errors }, trigger } = useForm();
    const [hasError, setHasError] = useState(false);

    const rowStyle = { background: 'white' };


    useImperativeHandle(ref, () => ({

        transformData(data){
         
        },
        getData() {
            var items = [];
            var _items = [];
            gridApi.forEachNode(node => {
                items.push(node.data);
            });
            items.pop();
            return items;
        },
        hasError() {
            return hasError;
        },
        setData(data) {
            if (data != undefined) {
                var newdata = [];
                data.forEach(e => {
                    newdata.push(e)
                })
                newdata.push({})
                gridApi.setRowData(newdata)
            }
            else {
                data = [];
                data.push({})
                gridApi.setRowData(data)
            }
        }

    }));


    const isValid = (nodeData) => {
        return nodeData.data.name!= ""
            && nodeData.data.amountValue != undefined
            && nodeData.data.amountValue != ""
            && nodeData.data.name != undefined

    }


    const onGridReady = params => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        window.addEventListener('resize', function () {
            setTimeout(function () {
                // params.api.sizeColumnsToFit();

            });
        });
        var data = props.deductions;
        console.error(data)
        if (data != undefined) {
            data.push({ name: undefined ,amount: undefined })
            params.api.setRowData(data)
        }
        else {
            data = [];
            data.push({ name: undefined ,amount: undefined })
            params.api.setRowData(data)
        }
    };

    const addRow = () => {

        // var _data = gridApi.gridOptionsWrapper.gridOptions.rowData;
        var data = { name: undefined ,amount: undefined };

        let rowData = [];
        gridApi.forEachNode(node => rowData.push(node.data));

        rowData.push(data)
        gridApi.setRowData(rowData)


        var countDisplayedRows = gridApi.getDisplayedRowCount() - 1;
        gridApi.ensureIndexVisible(countDisplayedRows, 'top');
    }


    const onCellValueChanged = (params) => {
        // params.node.rowIndex
        var nodeIndex = params.node.rowIndex;
        var countDisplayedRows = gridApi.getDisplayedRowCount() - 1;
        console.log(params.newValue)
        if (nodeIndex == countDisplayedRows && params.newValue != undefined) {
            addRow(nodeIndex);
        }

        var items = [];
        gridApi.forEachNode(node => {
            items.push(node);
        });
        items.pop();
    }
  
    const getRowStyle = params => {


        if (params.node.rowIndex == params.api.rowModel.rowsToDisplay.length - 1) {
            return {
                background: '#d2f6fac7'
            };

        }
        else {
            if (!isValid(params.node)) {
                setHasError(true)
                return { background: '#ffb09c' };
            }
            else {
                setHasError(false)
                if (params.node.data.global) {
                    return { background: '#eee' };
                }
                else {
                    return { background: 'white' };
                }
            }
        }
    };

    const onRemoveRow = () => {
        var rowData = [];
        if (gridApi != undefined) {
            gridApi.forEachNode(node => rowData.push(node.data));
            rowData.pop();
        }
    }
  
    return (
        <div className="row">

            <div className="col-md-12">
                <div className="ag-theme-alpine editableGrid deductionGrid" style={{

                    width: '100%',
                }}>
                    <AgGridReact
                        onGridReady={onGridReady}
                        rowStyle={rowStyle}
                        getRowStyle={getRowStyle}
                        style={{ width: '100%', height: '300px' }}
                        stopEditingWhenGridLosesFocus={true}
                        borders={true}
                        defaultColDef={{ resizable: true }}
                        suppressRowTransform={true}
                        singleClickEdit={true}
                        skipHeaderOnAutoSize={true}
                        onCellValueChanged={onCellValueChanged}
                        frameworkComponents={{
                            actionsRenderer: ActionsRenderer,
                            doubleValRenderer:DoubleValRenderer,
                            checkboxRenderer: CheckboxRenderer
                        }}
                    >

                        <AgGridColumn flex={2}  minWidth={200} headerName={t("Name")} options={props.companyInvoiceTypes} editable={true}  field="name"></AgGridColumn>
                        <AgGridColumn flex={1} minWidth={150} headerName={t("percent")} cellRenderer="checkboxRenderer" field="isPercent"></AgGridColumn>
                        <AgGridColumn flex={1} minWidth={150} headerName={t("isSubtracted")} cellRenderer="checkboxRenderer" field="isSubtracted"></AgGridColumn>
                        <AgGridColumn flex={1} minWidth={100} headerName={t("Amount")} cellRenderer="doubleValRenderer" editable={true} field="amountValue"></AgGridColumn>
                        <AgGridColumn flex={1}  minWidth={100} headerName={t("Actions")} cellRenderer="actionsRenderer" cellRendererParams={{ onRemoveRow: onRemoveRow }} field="name"></AgGridColumn>

                    </AgGridReact>
                </div>
            </div>
        </div>
    );
});
export default CompanyDeductionsGrid 