import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from "react";
import { toast } from 'react-toastify';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import Select from 'react-select'
import { useForm } from "react-hook-form";
import ApiClient from "../../services/api-client"
import { CustomConfirmAlert as confirmAlert} from '../custom-confirm-alert';

const ActionsRenderer = forwardRef((props, ref) => {

    const [disabled, setDisabled] = useState(false);

    const { t} = useTranslation();

    const hangleDeleteRow = (e) => {
        e.preventDefault();

        let rowData = [];
        props.agGridReact.api.forEachNode(node => rowData.push(node.data));

        confirmAlert({
            title: t("submitConfirm"),
            message: t("areYouSure"),
            buttons: [
                {
                    label: t('Yes'),
                    onClick: () => {
                        var id = props.data.id;
                        if (id != undefined) {
                            ApiClient.deleteProductMoreTaxes(id).then(
                                response => {
                                    rowData.splice(props.rowIndex, 1)
                                    props.agGridReact.api.setRowData(rowData)
                                    props.colDef.cellRendererParams.onRemoveRow();
                                    toast.success(t("deleteSucceded"))

                                },
                                error => {
                                    toast.error("Error: " + error.data)
                                    console.error(error.data)
                                }
                            )
                        }
                        else {
                            rowData.splice(props.rowIndex, 1)
                            props.agGridReact.api.setRowData(rowData)
                            props.colDef.cellRendererParams.onRemoveRow();
                        }

                    }
                },
                {
                    label: t('No'),
                    onClick: () => {
                    }
                }
            ]
        });

    }

    useImperativeHandle(ref, () => {
        var countDisplayedRows = props.agGridReact.api.getDisplayedRowCount() - 1;
        if (props.rowIndex == countDisplayedRows) {
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }
        return {
            refresh(params) {

            }
        };
    });
    return (
        <span>
            <button onClick={hangleDeleteRow} disabled={disabled} className="btn btn-sm btn-danger circle-btn"><i className="fa fa-times"></i></button>
        </span>
    );
});
const doubleValRenderer = forwardRef((props, ref) => {


    const [val, setVal] = useState(props.value);
    

    useImperativeHandle(ref, () => {
        return {
            refresh(params) {
                var val = props.getValue();
                if (val != undefined && val!="") {
                    var _val = parseFloat(val.toString().replace(",", ".")).toFixed(2);
                    setVal(_val);
                    props.node.setDataValue(props.column.colId, _val);
                }

            }
        };
    });
    if (props.colDef.field == "price") {
        return <span className="editableCell">{val != null && val != "" ? parseFloat(val).toFixed(2) : <div><span className="addtext"></span><div className="addtextafter">...</div></div>}</span>

    }
    else {
        return <span className="editableCell">{val && val != "" ? parseFloat(val).toFixed(2) : <div><span className="addtext"></span><div className="addtextafter">...</div></div>}</span>

    }
});
const MyDataMoreTaxesTypeRenderer = forwardRef((props, ref) => {
    const [mu, setMU] = useState(props.value);
    useImperativeHandle(ref, () => {
        return {
            refresh(params) {
                var val = props.getValue();
                if (val != undefined) {
                    setMU(val);
                }

            }
        };
    });
    return (
        <span className="editableCell">{mu ? mu.label : <div><span className="addtext"></span><div className="addtextafter">...</div></div>}</span>

    );
});
const MyDataMoreTaxesTypeEditor = forwardRef((props, ref) => {
    const [selectedVal, setSelectedVal] = useState(props.value);
    const [editing, setEditing] = useState(true);
    const refContainer = useRef(null);


    useEffect(() => {
        focus();
    }, []);

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return selectedVal != null ? selectedVal : props.value;
            },

            isPopup() {
                return true;
            }
        };
    });

    useEffect(() => {
        if (!editing) {
            props.api.stopEditing();
        }
    }, [editing]);

    const focus = () => {
        window.setTimeout(() => {
            let container = ReactDOM.findDOMNode(refContainer.current);
            if (container) {
                container.focus();
            }
        });
    };

    const { t } = useTranslation();

    const onSelectChange = val => {
        setSelectedVal(val);
        var old_val=props.node.data.type?props.node.data.type.value:undefined;
        
        setTimeout(function () {
            props.api.stopEditing();
            console.log(props.colDef.cellRendererParams)
            var type = val.value
            var allTaxes = props.colDef.cellRendererParams.moreTaxesOptions;

            let groupTaxes = allTaxes.reduce((r, a) => {
                r[a.type] = [...r[a.type] || [], a];
                return r;
            }, {});

            var typeTaxes = groupTaxes[type];
            var new_type_options = []
            typeTaxes.forEach(element => {
                new_type_options.push({
                    value: "TAX_" + element.value,
                    label: element.type=="DEDUCTION"?element.label:t("TAX_" + element.value),
                    amountValue: element.amountValue,
                    uid: element.type=="DEDUCTION"?element.id:element.uid,
                    type: element.type,
                    id: element.id
                });
            });
            setTimeout(function () {
                props.node.setDataValue("myDataMoreTaxes", new_type_options);
                if(old_val!=val.value){
                    props.node.setDataValue("myDataMoreTax", { value: undefined, label: undefined });
                }
    
                props.node.setDataValue("type", val);
                props.node.setDataValue("amount","");
            })
        })

    }
    const onMouseOver = e => {
        refContainer.current.setState({ isOpen: true })
        let container = ReactDOM.findDOMNode(e.target).parentNode.getElementsByTagName("input")[0];
        if (container != undefined) {
            container.focus();
        }
    }


    return (<div style={{ width: props.column.actualWidth + 'px' }}>

        <span onMouseOver={onMouseOver} className="selectCell">
            <Select
                autosize={true}
                menuIsOpen={true}
                defaultValue={selectedVal}
                onChange={onSelectChange}
                onChangeValue={onSelectChange}
                options={props.colDef.cellRendererParams.options} ref={refContainer}>
            </Select>
        </span>
    </div>
    );
});
const MoreTaxesRenderer = forwardRef((props, ref) => {
    const [mu, setMU] = useState(props.value);
    useImperativeHandle(ref, () => {
        return {
            refresh(params) {
                var val = props.getValue();
                if (val != undefined) {
                    setMU(val);
                }

            }
        };
    });
    return (
        <span className="editableCell">{mu ? mu.label : <div><span className="addtext"></span><div className="addtextafter">...</div></div>}</span>

    );
});
const MoreTaxesEditor = forwardRef((props, ref) => {
    const [selectedVal, setSelectedVal] = useState(props.value);
    const [editing, setEditing] = useState(true);
    const refContainer = useRef(null);


    useEffect(() => {
        focus();
    }, []);

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return selectedVal != null ? selectedVal : props.value;
            },

            isPopup() {
                return true;
            }
        };
    });


    useEffect(() => {
        if (props.data.type == undefined) {
            props.api.stopEditing();
        }
    }, [props.data.myDataMoreTaxesType]);

    useEffect(() => {
        if (!editing) {

            props.api.stopEditing();
        }
    }, [editing]);

    const focus = () => {
        window.setTimeout(() => {
            let container = ReactDOM.findDOMNode(refContainer.current);
            if (container) {
                container.focus();
            }
        });
    };

    const { t } = useTranslation();

    const onSelectChange = val => {
        setSelectedVal(val);
        setTimeout(function () {
            props.api.stopEditing();
            setTimeout(function () {
                props.node.setDataValue("myDataMoreTax", val);

            },100)
          
        })

    }
    const onMouseOver = e => {
        refContainer.current.setState({ isOpen: true })
        let container = ReactDOM.findDOMNode(e.target).parentNode.getElementsByTagName("input")[0];
        if (container != undefined) {
            container.focus();
        }
    }


    return (<div style={{ width: props.column.actualWidth + 'px' }}>

        <span onMouseOver={onMouseOver} className="selectCell">
            <Select
                autosize={true}
                menuIsOpen={true}
                defaultValue={selectedVal}
                onChange={onSelectChange}
                onChangeValue={onSelectChange}
                options={props.node.data.myDataMoreTaxes} ref={refContainer}>
            </Select>
        </span>
    </div>
    );
});
const AmountEditor = forwardRef((props, ref) => {
    const [selectedVal, setSelectedVal] = useState(props.value);
    const [editing, setEditing] = useState(true);
    const refContainer = useRef(null);


    useEffect(() => {
        focus();
    }, []);

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return selectedVal != null ? selectedVal : props.value;
            },

            isPopup() {
                return true;
            }
        };
    });


    useEffect(() => {
        if (props.data.myDataMoreTax == undefined) {
            props.api.stopEditing();
        }
        else {
            if (props.data.myDataMoreTax.amountValue) {
                if (props.data.myDataMoreTax.amountValue.includes("%")) {
                    props.api.stopEditing();
                }
            }
        }
    }, [props.data.myDataMoreTaxes]);

    useEffect(() => {
        if (!editing) {

            props.api.stopEditing();
        }
    }, [editing]);

    const focus = () => {
        window.setTimeout(() => {
            let container = ReactDOM.findDOMNode(refContainer.current);
            if (container) {
                container.focus();
                container.select();
            }
        });
    };

    const onChange = (e) => {
        setSelectedVal(e.target.value)
        props.node.setDataValue("amount", e.target.value);
       
    }

    return (<div style={{ width: props.column.actualWidth + 'px' }}>

        <span className="selectCell">
            <input className="form-control"
                value={selectedVal}
                onChange={onChange}
                ref={refContainer}
            >
            </input>
        </span>
    </div>
    );
});

const MoreTaxesGrid = forwardRef((props, ref) => {
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const { t, i18n } = useTranslation();
    const { formState: { errors }, trigger } = useForm();
    const [hasError, setHasError] = useState(false);

    const rowStyle = { background: 'white' };


    useImperativeHandle(ref, () => ({

        transformData(data, moreTaxesTypesOptions, myDataMoreTaxesOptions){
            var newData = [];
            data.forEach(el => {
                var cats = [];
                var moreTaxesOptions = [];
                myDataMoreTaxesOptions.forEach(element => {
                    cats.push({
                        value: element.value,
                        label: element.type=="DEDUCTION"?element.label:t("TAX_" + element.value),
                        type: element.type,
                        key: element.key,
                        uid: element.uid,
                        amountValue: element.amountValue,
                        isSubtracted:element.isSubtracted
                    })
                });
                moreTaxesTypesOptions.forEach(element => {
    
                    moreTaxesOptions.push({
                        value: element.value,
                        label: t(element.label)
                    })
                });
                let groupTaxes = cats.reduce((r, a) => {
                    r[a.type] = [...r[a.type] || [], a];
                    return r;
                }, {});
    
              
                if(el.deduction){
                    el.myDataMoreTax={
                        type:"DEDUCTION",
                        id:el.deduction.id,
                        isSubtracted: el.type == "DEDUCTION" ?el.deduction.isSubtracted:null,
                    }
                }
    
                var typeTaxes = groupTaxes[el.myDataMoreTax.type];
                var new_type_options = []
                typeTaxes.forEach(element => {
                    new_type_options.push({
                        value: element.type=="DEDUCTION"? element.value:"TAX_" + element.value,
                        label: element.type=="DEDUCTION"?element.label:t("TAX_" + element.value),
                        amountValue: element.amountValue,
                        uid: element.uid,
                        id: element.type=="DEDUCTION"? element.value:null,
                        isSubtracted: element.type == "DEDUCTION" ?element.isSubtracted:null,
                    });
                });

                var fel = {
                    id: el.id,
                    type: { value: el.myDataMoreTax.type, label: t(el.myDataMoreTax.type) },
                    myDataMoreTax: {
                        id:el.deduction?el.deduction.id:null,
                        uid:el.myDataMoreTax.uid, 
                        amountValue: el.myDataMoreTax.amountValue, 
                        value: "TAX_" + el.myDataMoreTax.uid, 
                        label: el.deduction?el.deduction.name: t("TAX_" + el.myDataMoreTax.uid),
                        isSubtracted: el.deduction?el.deduction.isSubtracted:null,
                    },
                    myDataMoreTaxesTypes: moreTaxesOptions,
                    myDataMoreTaxes: new_type_options,
                    amount: el.amount,
                }
                newData.push(fel)
            });
            return newData;
        },
        getData() {
            var items = [];
            var _items = [];
            gridApi.forEachNode(node => {
                items.push(node.data);
            });
            items.pop();
            items.forEach(element => {
                element.myDataMoreTax.type=element.type.value
                var obj = {
                    "id": element.id,
                    "myDataMoreTax": element.myDataMoreTax,
                    "amount": element.amount,
                    
                }
                _items.push(obj);

            });
            return _items;
        },
        hasError() {
            return hasError;
        },
        setData(data) {
            if (data != undefined) {
                data.push({ myDataMoreTax: { value: undefined, label: undefined }, id: undefined, amount: undefined })
                gridApi.setRowData(data)
            }
            else {
                data = [];
                data.push({ myDataMoreTax: { value: undefined, label: undefined }, id: undefined, amount: undefined })
                gridApi.setRowData(data)
            }
        }

    }));


    const isValid = (nodeData) => {
        return nodeData.data.type.value != ""
            && nodeData.data.type.value != undefined
            && nodeData.data.myDataMoreTax.value != undefined
            && nodeData.data.myDataMoreTax.value != ""
    }


    const onGridReady = params => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        window.addEventListener('resize', function () {
            setTimeout(function () {
                // params.api.sizeColumnsToFit();

            });
        });
        var data = props.moreTaxes;
        if (data != undefined) {
            data.push({ myDataMoreTax: { value: undefined, label: undefined }, type: { value: undefined, label: undefined } })
            params.api.setRowData(data)
        }
        else {
            data = [];
            data.push({ myDataMoreTax: { value: undefined, label: undefined }, type: { value: undefined, label: undefined } })
            params.api.setRowData(data)
        }
    };

    const addRow = () => {

        var data = { myDataMoreTax: { value: undefined, label: undefined }, type: { value: undefined, label: undefined } }

        let rowData = [];
        gridApi.forEachNode(node => rowData.push(node.data));

        rowData.push(data)
        gridApi.setRowData(rowData)


        var countDisplayedRows = gridApi.getDisplayedRowCount() - 1;
        gridApi.ensureIndexVisible(countDisplayedRows, 'top');
    }
    const toastId = useRef(null);
    const onCellValueChanged = (params) => {
        // params.node.rowIndex
        var nodeIndex = params.node.rowIndex;
        var countDisplayedRows = gridApi.getDisplayedRowCount() - 1;
        if (nodeIndex == countDisplayedRows && params.newValue != undefined) {
            addRow(nodeIndex);
        }

        var items = [];
        gridApi.forEachNode(node => {
            items.push(node);
        });
        items.pop();
        var cond = false;
        items.forEach(node => {
            if (params.node.data.type && nodeIndex != node.rowIndex) {
                cond = params.node.data.type.value == node.data.type.value;
                if (cond) {
                    var row_dom = document.querySelector(`.taxesGrid .ag-center-cols-container >  div[row-index="${params.node.rowIndex}"][role="row"]`)
                    row_dom.style.backgroundColor = '#ffb09c'
                    setTimeout(function () {
                        if (!toast.isActive(toastId.current)) {
                            toastId.current = toast.error(t("duplicateMyDataMoreTaxType"),{
                                toastId:"pmt_toastId"
                            });
                        }
                        setHasError(true)
                    }, 100)
        
                }
                return;
            }
        });        

    }

    const getRowStyle = params => {


        if (params.node.rowIndex == params.api.rowModel.rowsToDisplay.length - 1) {
            return {
                background: '#d2f6fac7'
            };

        }
        else {
            if (!isValid(params.node)) {
                setHasError(true)
                return { background: '#ffb09c' };
            }
            else {
                setHasError(false)
                if (params.node.data.global) {
                    return { background: '#eee' };
                }
                else {
                    return { background: 'white' };
                }
            }
        }
    };

    const onRemoveRow = () => {
        var rowData = [];
        if (gridApi != undefined) {
            gridApi.forEachNode(node => rowData.push(node.data));
            rowData.pop();
        }
    }

    return (
        <div className="row">

            <div className="col-md-12">
                <div className="ag-theme-alpine editableSmGrid taxesGrid" style={{

                    width: '100%',
                }}>
                    <AgGridReact
                        onGridReady={onGridReady}
                        rowStyle={rowStyle}
                        getRowStyle={getRowStyle}
                        style={{ width: '100%', height: '300px' }}
                        onCellValueChanged={onCellValueChanged}
                        stopEditingWhenGridLosesFocus={true}
                        borders={true}
                        defaultColDef={{
                            flex: 1,
                            resizable: false,
                            sortable: false,
                            wrapText: true,
                            suppressMovable:true,
                        }}
                        suppressRowTransform={true}
                        singleClickEdit={true}
                        skipHeaderOnAutoSize={true}
                        frameworkComponents={{
                            myDataMoreTaxesTypeRenderer: MyDataMoreTaxesTypeRenderer,
                            myDataMoreTaxesTypeEditor: MyDataMoreTaxesTypeEditor,
                            moreTaxesRenderer: MoreTaxesRenderer,
                            moreTaxesEditor: MoreTaxesEditor,
                            doubleValRenderer: doubleValRenderer,
                            actionsRenderer: ActionsRenderer,
                            amountEditor: AmountEditor
                        }}
                    >

                        {/* suppressSizeToFit={true}  */}
                        <AgGridColumn hide={true} field="myDataMoreTaxes"></AgGridColumn>
                        <AgGridColumn flex={3} minWidth={200} headerName={t("myDataMoreTaxesType")} cellRendererParams={{ moreTaxesOptions: props.moreTaxesOptions, options: props.moreTaxesTypesOptions }} editable={true} cellEditor="myDataMoreTaxesTypeEditor" cellRenderer="myDataMoreTaxesTypeRenderer" field="type"></AgGridColumn>
                        <AgGridColumn flex={3} minWidth={200} headerName={t("tax")} editable={true} cellEditor="moreTaxesEditor" cellRenderer="moreTaxesRenderer" field="myDataMoreTax"></AgGridColumn>
                        <AgGridColumn flex={1} minWidth={100} headerName={t("Amount")} cellRenderer="doubleValRenderer" cellEditor="amountEditor" editable={true} field="amount"></AgGridColumn>
                        <AgGridColumn flex={1} minWidth={100} headerName={t("Actions")} cellRenderer="actionsRenderer" cellRendererParams={{ onRemoveRow: onRemoveRow }} field="name"></AgGridColumn>

                    </AgGridReact>




                </div>
            </div>
            <div><br /></div>

        </div>
    );
});
export default MoreTaxesGrid 