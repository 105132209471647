import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from "react";
import ApiClient from "../services/api-client"
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom"
import { CustomConfirmAlert as confirmAlert } from '../components/custom-confirm-alert';
import moment from 'moment'
import BlockUi from 'react-block-ui';
import { toast } from 'react-toastify';
import CustomDateComponent from '../components/sales/customDateComponent.js';
import Modal from 'react-bootstrap/Modal';
import ReactTooltip from "react-tooltip";
import SelectTable from '../components/select-table'
import { useForm, Controller } from "react-hook-form";
import IFramePrintTemplate from '../components/iframe-print-template.component';
import MyDataLogo from "../img/mydata.png";
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import Commons from '../util/commons'
import AggridSalesTooltip from '../components/sales/aggridSalesTooltip'
import useMobileDetect from 'use-mobile-detect-hook';
import Select, { components } from "react-select";
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import Tippy from '@tippyjs/react';
import { API_BASE_URL } from '../util/constants';
import AG_GRID_LOCALE_GR from '../locales/gr/aggrid-translation.js';
import AG_GRID_LOCALE_EN from '../locales/gb/aggrid-translation.js';
import ReCAPTCHA from "react-google-recaptcha";
import { ACCESS_TOKEN, RECAPTCHA_KEY } from '../util/constants';


const COMPANY = JSON.parse(localStorage.getItem("company"));

// window.screen.orientation.lock("portrait");

const AllFieldsRenderer = forwardRef((props, ref) => {
    const { t } = useTranslation();

    const [borderClass, setBorderClass] = useState("sales-grid-row");

    useImperativeHandle(ref, () => {
        return {
            refresh(params) {

            }
        };

    });


    useEffect(() => {
        if (props.node.data != null) {
            var addcls = props.node.data ? props.node.data.cancellationMark ? "row-canceled-status" : props.node.data.mark ? "row-sended-status" : "row-not-sended-status" : "";
            setBorderClass("sales-grid-row " + addcls);

        }
    }, [props.node])



    const rowData = <div className="container sales-aggrid-tooltip-inner">


        <div className="d-flex bd-highlight">
            <div className="p-1 flex-grow-1 bd-highlight"><b >{t("InvoiceType")}:</b></div>
            <div className="p-1 bd-highlight"><span >{props.data ? props.data.invoiceType : ""}</span></div>
        </div>
        <div className="d-flex bd-highlight">
            <div className="p-1 flex-grow-1 bd-highlight"><b>{t("Customer")}:</b></div>
            <div className="p-1 bd-highlight"><span>{props.data ? props.data.customerName : ""} ({props.data ? props.data.customerTaxPayerId : ""})</span></div>
        </div>
        <div className="d-flex bd-highlight">
            <div className="p-1 flex-grow-1 bd-highlight"><b >{t("issuerDate")}:</b></div>
            <div className="p-1 bd-highlight"><span>{props.data ? moment(props.data.invoiceDate).format('DD/MM/YYYY') : ""}</span></div>
        </div>
        <div className="d-flex bd-highlight">
            <div className="p-1 flex-grow-1 bd-highlight"><b >{t("TotalWithoutVat")}:</b></div>
            <div className="p-1 bd-highlight"><span>{props.data ? Commons.formatNumber(props.data.totalWithoutVat) : ""}</span></div>
        </div>
        <div className="d-flex bd-highlight">
            <div className="p-1 flex-grow-1 bd-highlight"><b >{t("TotalWithVat")}:</b></div>
            <div className="p-1 bd-highlight"><span>{props.data ? Commons.formatNumber(props.data.totalWithVat) : ""}</span></div>
        </div>
        <div className="d-flex bd-highlight">
            <div className="p-1 flex-grow-1 bd-highlight"><b >{t("TotalPayment")}:</b></div>
            <div className="p-1 bd-highlight"><span>{props.data ? Commons.formatNumber(props.data.totalPayment) : ""}</span></div>
        </div>
        <div className="d-flex bd-highlight">
            <div className="p-1 flex-grow-1 bd-highlight"><b >{t("Status")}:</b></div>
            <div className="p-1 bd-highlight"><span> {props.data ? props.data.mark ? props.data.cancelationMark ? <span className="badge bg-danger">{t("canceled")}</span> : <span className="badge bg-success">{t("sended")}</span> : <span className="badge bg-secondary">{t("notInMyData")}</span> : ""}</span></div>
        </div>
    </div>;

    const inconsistencyIcon = props.node.data ? (props.node.data.hasInconsistency ? <Tippy trigger="click" content={t("incosistncyIconInfo")} ><i className='fa fa-exclamation-circle incosistncyIconInfo'></i></Tippy> : <></>) : <></>;

    return (

        <div className={borderClass}>

            <div className="d-flex bd-highlight allFieldsColumnRow">
                <div className="p-0 bd-highlight"><span >
                    <Tippy trigger="click" content={rowData}>
                        <i className="fa fa-info-circle" style={{ marginRight: 6 }}></i>
                    </Tippy>
                    <b>{props.data ? props.data.seriesNumber + " " : ""}</b>
                </span><span >{props.data ? props.data.invoiceType : ""}</span></div>
            </div>

            <div className="d-flex bd-highlight allFieldsColumnRow">
                <div className="p-0 bd-highlight"><span style={{ fontStyle: "italic" }}>{props.data ? props.data.customerName + " (" + props.data.customerTaxPayerId + ")" : ""}</span></div>
            </div>
            <div className="d-flex bd-highlight allFieldsColumnRow">
                <div className="p-0 flex-grow-1 bd-highlight"><span >{props.data ? moment(props.data.invoiceDate).format('DD/MM/YYYY') : ""}</span></div>
                {inconsistencyIcon}
                <div className="p-0 bd-highlight"><span> {props.data ? props.data.cancellationMark ? <span className="tooltip-canceled-status">{props.data.cancellationMark}</span> : props.data.mark ? <span className="tooltip-sended-status">{props.data.mark}</span> : <span className="tooltip-not-sended-status">{t("notInMyData")}</span> : ""}</span></div>

                {/* <div className="p-0 bd-highlight"><span> {props.data?props.data.cancelationMark?<span className="tooltip-canceled-status">{t("canceled")}</span>:<span className="tooltip-sended-status">{t("sended")}</span>:<span className="tooltip-not-sended-status">{t("notInMyData")}</span>}</span></div> */}
            </div>

            <div className="d-flex bd-highlight allFieldsColumnRow">
                <div className="p-0 flex-grow-1 bd-highlight"><span >{t("VatAmount")}: </span><span ><b>{props.data ? Commons.formatNumber((props.data.totalWithVat - props.data.totalWithoutVat).toFixed(2)) : 0.00}</b></span></div>
                <div className="p-0 bd-highlight"><span >{t("TotalPaymentMin")}: </span><span ><b>{props.data ? Commons.formatNumber(props.data.totalPayment.toFixed(2)) : 0.00}</b></span></div>
            </div>

            {/* <div className="d-flex bd-highlight allFieldsColumnRow">
                <table className="table table-borderless">
                    <thead >
                        <tr>
                            <th>{t("TotalWithVatMin")}</th> <th>{t("TotalWithoutVatMin")}</th> <th>{t("TotalPaymentMin")}</th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr>
                            <td>{props.data ? props.data.totalWithoutVat.toFixed(2) : 0.00}</td> <td>{props.data ? props.data.totalWithoutVat.toFixed(2) : 0.00}</td> <td>{props.data ? props.data.totalPayment.toFixed(2) : 0.00}</td>
                        </tr>
                    </tbody>
                </table>
            </div> */}

        </div >


    );
});



const LineInfoRenderer = forwardRef((props, ref) => {


    useImperativeHandle(ref, () => {
        return {
            refresh(params) {

            }
        };

    });

    return (
        <> <span><i className="fa fa-exclamation-circle"></i></span></>
    );
});

const MarkRenderer = forwardRef((props, ref) => {
    const [mark, setMark] = useState(props.value);
    const [cancellationMark, setCancellationMark] = useState("");
    const { t } = useTranslation();

    useImperativeHandle(ref, () => {
        return {
            refresh(params) {
                var val = props.getValue();
                if (val) {
                    setMark(val);
                }
            }
        };

    });
    // const cancellationMark = rowNode.data.cancellationMark != null ? rowNode.data.cancellationMark : ""

    useEffect(() => {
        if (props.node.data != null) {
            setCancellationMark(props.node.data.cancellationMark)

        }
    }, [props.node])


    const inconsistencyIcon = props.node.data ? (props.node.data.hasInconsistency ? <i title={t("incosistncyIconInfo")} className='fa fa-exclamation-circle incosistncyIconInfo'></i> : <></>) : <></>;

    const markCell = cancellationMark ?
        <span className="mark-span-container"><span className="cancelationMark-span">{cancellationMark}</span><span className="mark-span">{mark}</span>  </span>
        : mark ? <span className="mark-span-container">{mark}</span> : <span style={{ color: "#6c757d", fontSize: 14, fontStyle: "italic" }}>{t("notInMyData")}</span>

    return (
        <> <span>{inconsistencyIcon}</span><span>{markCell}</span></>
    );
});

const SeriesNumberRendener = forwardRef((props, ref) => {
    const [seriesNumber, setSeriesNumber] = useState(props.value);
    const [seriesElement, setSeriesElement] = useState("");
    const { t } = useTranslation();
    const [actionElement, setActionElement] = useState(<span></span>);
    const history = useHistory();


    useImperativeHandle(ref, () => {
        return {
            refresh(params) {
                var val = props.getValue();


                if (val) {
                    setSeriesNumber(val);
                }
            }
        };

    });

    const handleInputChange = (e) => {
        // e.preventDefault();
        var _data = props.data;
        ApiClient.getTemplateSettings(_data.company.id, _data.branchId).then(response => {
            var _template = response.data;
            var _temp = {
                branch: _data.branchId,
                template: _template.template,
                mainColor: _template.mainColor,
                secondaryColor: _template.secondaryColor,
                appFont: _template.appFont,
                css: _template.css,
                logo: _template.logo,
                iban: _template.iban,
                moreInfo: _template.moreInfo

            }

            props.colDef.cellRendererParams.setTemplate(_template);
            var saleInstance = props.colDef.cellRendererParams.getPrintSale(_data);
            saleInstance.LOGO = _temp.logo;
            saleInstance.IBAN = _temp.iban;
            saleInstance.MOREINFO = _temp.moreInfo;
            console.debug(saleInstance)
            props.colDef.cellRendererParams.onClickPrint(saleInstance)

        },
            error => {
                console.error(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString())
            }
        );

    }

    const handleCancel = (e) => {
        // e.preventDefault();
        var id = props.data.id;
        var companyId = COMPANY.id;

        var mess = props.data.mark ? t("cancelationConfirm0") + " " + t("cancelationConfirm1") : t("cancelationConfirm0");

        confirmAlert({
            title: t("submitConfirm"),
            message: mess,
            submessage: t("cancelationConfirm2"),
            buttons: [
                {
                    label: t('Yes'),
                    onClick: () => {
                        var rowNode = props.node;
                        const seriesNumber = props.data.seriesNumber;
                        props.colDef.cellRendererParams.blockUi(true)
                        ApiClient.cancelMyDataInvoice(companyId, id).then(
                            response => {

                                if (response.data.response[0].statusCode == "Success") {
                                    var cancelMark = response.data.response[0].cancellationMark;


                                    var mark = rowNode.data.mark;
                                    if (cancelMark) {
                                        var _htm = <span className="canceled-series-span"><span>{seriesNumber}</span></span>;
                                        rowNode.setDataValue("seriesNumber", _htm);
                                        rowNode.setDataValue("cancelationMark", cancelMark);
                                        // var cm=<span className="mark-span-container"><span className="cancelationMark-span">{cancelMark}</span><span className="mark-span">{mark}</span>  </span>
                                        rowNode.setDataValue("mark", mark);

                                        const actionel = <div>
                                            <Menu menuButton={<MenuButton><i className="fa fa-bars"></i></MenuButton>} transition>
                                                <MenuItem onClick={handleInputChange} ><i className="fa fa-print"></i>Print</MenuItem>
                                                <MenuItem onClick={hangleEdit} ><i className="fa fa-edit"></i>Edit</MenuItem>
                                                <MenuItem onClick={showEmailModal} ><i className="fa fa-envelope"></i>Send Email</MenuItem>
                                                <MenuItem onClick={goToPublic} ><i className="fa fa-eye"></i>View</MenuItem>
                                            </Menu>

                                        </div>
                                        setActionElement(actionel)
                                        // rowNode.gridApi.redrawRows()
                                        rowNode.gridApi.refreshInfiniteCache()
                                    }
                                    else {
                                        rowNode.gridApi.refreshInfiniteCache()
                                    }

                                    if (cancelMark) {
                                        toast.success(t("invoiceCanceled", { mark: cancelMark }), { autoClose: 5000 })
                                    }
                                    else {
                                        toast.success(t("invoiceCanceledNoCancelMark"), { autoClose: 5000 })
                                    }


                                }
                                else {
                                    var errors = response.data.response[0].errors.error;
                                    errors.forEach(e => {
                                        toast.error(e.message, { autoClose: 5000 })
                                    })
                                }

                                props.colDef.cellRendererParams.blockUi(false)

                            },
                            error => {
                                console.error(
                                    (error.response &&
                                        error.response.data &&
                                        error.response.data.message) ||
                                    error.message ||
                                    error.toString())
                                props.colDef.cellRendererParams.blockUi(false)
                            }
                        );
                    }
                },
                {
                    label: t('No'),
                    onClick: () => {
                    }
                }
            ]
        });



    }

    const showEmailModal = () => {
        var companyId = COMPANY.id;
        ApiClient.findCompanyCustomerContacts(companyId, props.data.customerTaxPayerId, props.data.customerName).then(
            response => {
                var contacts = response.data;
                var _contacts = []
                contacts.forEach(e => {
                    _contacts.push({
                        value: e.email,
                        name: e.name,
                        email: e.email,
                        label: e.email
                    })
                });


                var columns = [
                    {
                        header: t("Name"),
                        field: "name",
                        flex: 6,
                    },
                    {
                        header: t("Email"),
                        field: "email",
                        flex: 6,
                        asLabel: true

                    }
                ]



                var _company = COMPANY;
                if (_company.accountantEmail) {
                    _contacts.push({
                        value: _company.accountantEmail,
                        name: t("companyAccountant"),
                        email: _company.accountantEmail,
                        label: _company.accountantEmail
                    })
                }

                var opts = { data: _contacts, columns: columns };

                props.colDef.cellRendererParams.setShowEmailModal(true);
                props.colDef.cellRendererParams.setCustomerContacts(opts);
                props.colDef.cellRendererParams.setLineData(props.data);

            },
            error => {
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString())
            }
        );
    }




    const goToPublic = () => {
        var invoiceUid = props.data.invoiceUid;
        window.open("/mydata/" + invoiceUid, "_blank");
    }
    const hangleEdit = () => {
        debugger
        history.push("/sale/" + props.data.id);
    }
    const publishCredit = () => {
        history.push("/sale/" + props.data.id + "?credit=true");
    }
    useEffect(() => {


        if (props.node.data != null) {
            if (props.node.data.canceled) {
                setSeriesElement(<span className="seriesNumber canceled-series-symbol"><span>{props.node.data.seriesNumber}</span></span>)
            }
            else {
                setSeriesElement(<span className="seriesNumber"><span>{props.node.data.seriesNumber}</span></span>)
            }
        }
    }, [props.node])
    const cancelMenu = props.node.data ? (!props.node.data.canceled ?
        <MenuItem onClick={handleCancel}>
            <i className="fa fa-times"></i>{t("cancelInvoiceMenu")}
        </MenuItem>
        : "") : ""



    const inconsistencyIcon = props.node.data ? (props.node.data.hasInconsistency ? <i title={t("incosistncyIconInfo")} className='fa fa-exclamation-circle incosistncyIconInfo'></i> : <></>) : <></>;

    const publishMenu = props.node.data ? (props.node.data.myDataInvoiceType == "invoice1_1" || props.node.data.myDataInvoiceType == "invoice1_2" || props.node.data.myDataInvoiceType == "invoice1_3" || props.node.data.myDataInvoiceType == "invoice2_1" ?
        <MenuItem onClick={publishCredit} ><i className="fa fa-credit-card"></i>{t("creditInvoiceMenu")}</MenuItem>
        : "") : ""
    return (

        <span>
            {inconsistencyIcon}
            <Menu menuButton={<MenuButton>
                {seriesElement}
            </MenuButton>} transition>
                <MenuItem onClick={handleInputChange} ><i className="fa fa-print"></i>{t("printMenu")}</MenuItem>
                <MenuItem onClick={hangleEdit} ><i className="fa fa-edit"></i>{t("editMenu")}</MenuItem>
                <MenuItem onClick={showEmailModal} ><i className="fa fa-envelope"></i>{t("sendEmailMenu")}</MenuItem>
                <MenuItem onClick={goToPublic} ><i className="fa fa-eye"></i>{t("viewInvoiceMenu")}</MenuItem>
                {publishMenu}
                {cancelMenu}
            </Menu>
        </span>
    );
});


const TooltipRenderer = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const [value, setValue] = useState(props.value);


    useImperativeHandle(ref, () => {
        return {
            refresh(params) {
                var val = props.getValue();

                if (val) {
                    setValue(val);
                }
            }
        };

    });
    return (
        <>
            <span data-tip data-for="ccInfo22" data-bs-toggle="tooltip" data-bs-placement="bottom">  {value}

                {/* <i data-tip data-for="ccInfo" className="fa fa-x2 fa-info-circle infoicon" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                <ReactTooltip className="skin-tooltip" id="ccInfo" place="top" effect="solid">
                    {t("ccInfo2")}
                </ReactTooltip> */}

                <Tippy trigger="click" content={<span className="tooltip-skin">{t("ccInfo2")}</span>}>
                    <i className="fa fa-x2 fa-info-circle infoicon"></i>
                </Tippy>
            </span>

        </>
    );
});

const DateRendener = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const [date, setDate] = useState(moment(props.value).format('DD/MM/YYYY'));


    useImperativeHandle(ref, () => {
        return {
            refresh(params) {
                var val = props.getValue();

                if (val) {
                    setDate(val);
                }
            }
        };

    });

    const styleDate = (date) => {
        var _date = date.split(" ")[0]
        var _time = date.split(" ")[1]
        return <><strong>{_date}</strong> <small>{_time}</small></>
    }


    return (
        <span>  {styleDate(date)}</span>
    );
});

const ActionsRenderer = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const [actionElement, setActionElement] = useState(<span></span>);
    const history = useHistory();


    const goToPublic = () => {
        var invoiceUid = props.data.invoiceUid;
        window.open("/mydata/" + invoiceUid, "_blank");
    }

    const handlePdfDownload = () => {
        var _data = props.data;
        props.colDef.cellRendererParams.pdfDownload(_data)
    }
    const handleInputChange = (e) => {
        // e.preventDefault();
        var _data = props.data;
        ApiClient.getTemplateSettings(_data.company.id, _data.branchId).then(response => {
            var _template = response.data;
            var _temp = {
                branch: _data.branchId,
                template: _template.template,
                css: _template.css,
                logo: _template.logo,
                mainColor: _template.mainColor,
                secondaryColor: _template.secondaryColor,
                appFont: _template.appFont,
                iban: _template.iban,
                moreInfo: _template.moreInfo
            }

            props.colDef.cellRendererParams.setTemplate(_temp);
            var saleInstance = props.colDef.cellRendererParams.getPrintSale(_data);
            saleInstance.LOGO = _temp.logo;
            saleInstance.IBAN = _temp.iban;
            saleInstance.MOREINFO = _temp.moreInfo;

            console.debug(saleInstance)
            props.colDef.cellRendererParams.onClickPrint(saleInstance)

        },
            error => {
                console.error(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString())
            }
        );

    }
    const handleCancel = (e) => {
        // e.preventDefault();
        var id = props.data.id;
        var companyId = COMPANY.id;
        var mess = props.data.mark ? t("cancelationConfirm0") + " " + t("cancelationConfirm1") : t("cancelationConfirm0");

        confirmAlert({
            title: t("submitConfirm"),
            message: mess,
            submessage: t("cancelationConfirm2"),
            buttons: [
                {
                    label: t('Yes'),
                    onClick: () => {
                        var rowNode = props.node;
                        const seriesNumber = props.data.seriesNumber;
                        props.colDef.cellRendererParams.blockUi(true)
                        ApiClient.cancelMyDataInvoice(companyId, id).then(
                            response => {

                                if (response.data.response[0].statusCode == "Success") {
                                    var cancelMark = response.data.response[0].cancellationMark;


                                    var mark = rowNode.data.mark;
                                    if (cancelMark) {
                                        var _htm = <span className="seriesNumber canceled-series-symbol"><span>{seriesNumber}</span></span>;
                                        rowNode.setDataValue("seriesNumber", _htm);
                                        rowNode.setDataValue("cancelationMark", cancelMark);
                                        // var cm=<span className="mark-span-container"><span className="cancelationMark-span">{cancelMark}</span><span className="mark-span">{mark}</span>  </span>
                                        rowNode.setDataValue("mark", mark);

                                        const actionel = <div>
                                            <Menu menuButton={<MenuButton><i className="fa fa-bars"></i></MenuButton>} transition>
                                                <MenuItem onClick={handleInputChange} ><i className="fa fa-print"></i>Print</MenuItem>
                                                <MenuItem onClick={hangleEdit} ><i className="fa fa-edit"></i>Edit</MenuItem>
                                                <MenuItem onClick={showEmailModal} ><i className="fa fa-envelope"></i>Send Email</MenuItem>
                                                <MenuItem onClick={goToPublic} ><i className="fa fa-eye"></i>View</MenuItem>
                                            </Menu>

                                        </div>
                                        setActionElement(actionel)
                                        rowNode.gridApi.refreshInfiniteCache()
                                    }
                                    else {
                                        rowNode.gridApi.refreshInfiniteCache()
                                    }

                                    if (cancelMark) {
                                        toast.success(t("invoiceCanceled", { mark: cancelMark }), { autoClose: 5000 })
                                    }
                                    else {
                                        toast.success(t("invoiceCanceledNoCancelMark"), { autoClose: 5000 })
                                    }
                                }
                                else {
                                    var errors = response.data.response[0].errors.error;
                                    errors.forEach(e => {
                                        toast.error(e.message, { autoClose: 5000 })
                                    })
                                }

                                props.colDef.cellRendererParams.blockUi(false)

                            },
                            error => {
                                console.error(
                                    (error.response &&
                                        error.response.data &&
                                        error.response.data.message) ||
                                    error.message ||
                                    error.toString())
                                props.colDef.cellRendererParams.blockUi(false)
                            }
                        );
                    }
                },
                {
                    label: t('No'),
                    onClick: () => {
                    }
                }
            ]
        });



    }

    useImperativeHandle(ref, () => {
        return {
            refresh(params) {

            }
        };

    });

    const showEmailModal = () => {
        var companyId = COMPANY.id;
        ApiClient.findCompanyCustomerContacts(companyId, props.data.customerTaxPayerId, props.data.customerName).then(
            response => {
                var contacts = response.data;
                var _contacts = []
                contacts.forEach(e => {
                    _contacts.push({
                        value: e.email,
                        name: e.name,
                        email: e.email,
                        label: e.email
                    })
                });


                var columns = [
                    {
                        header: t("Name"),
                        field: "name",
                        flex: 6,
                    },
                    {
                        header: t("Email"),
                        field: "email",
                        flex: 6,
                        asLabel: true
                    }
                ]


                var _company = COMPANY;
                if (_company.accountantEmail) {
                    _contacts.push({
                        value: _company.accountantEmail,
                        name: t("companyAccountant"),
                        email: _company.accountantEmail,
                        label: _company.accountantEmail
                    })
                }



                var opts = { data: _contacts, columns: columns };

                props.colDef.cellRendererParams.setShowEmailModal(true);
                props.colDef.cellRendererParams.setCustomerContacts(opts);
                props.colDef.cellRendererParams.setLineData(props.data);

            },
            error => {
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString())
            }
        );
    }

    const hangleEdit = () => {
        history.push("/sale/" + props.data.id);
    }

    const publishCredit = () => {
        history.push("/sale/" + props.data.id + "?credit=true");
    }

    useEffect(() => {
        // 
        if (props.node.data != null) {
            var mark = props.node.data.mark;
            var cancelMark = props.node.data.cancellationMark;
            props.node.setDataValue("mark", mark);
            props.node.setDataValue("cancelationMark", cancelMark);

            const cancelMenu = !props.node.data.canceled ?
                <MenuItem onClick={handleCancel}>
                    <i className="fa fa-times"></i> {t("cancelInvoiceMenu")}
                </MenuItem>
                : ""
            const publishMenu = props.node.data ? (props.node.data.myDataInvoiceType == "invoice1_1" || props.node.data.myDataInvoiceType == "invoice1_2" || props.node.data.myDataInvoiceType == "invoice1_3" || props.node.data.myDataInvoiceType == "invoice2_1" ?
                <MenuItem onClick={publishCredit} ><i className="fa fa-credit-card"></i>{t("creditInvoiceMenu")}</MenuItem>
                : "") : ""
            const actionel = <div>
                <Menu menuButton={<MenuButton><i className="fa fa-bars"></i></MenuButton>} transition>
                    <MenuItem onClick={handleInputChange} ><i className="fa fa-print"></i>{t("printMenu")}</MenuItem>
                    {/* <MenuItem onClick={handlePdfDownload} ><i className="fa fa-file"></i>{t("pdfDownloadMenu")}</MenuItem> */}
                    <MenuItem onClick={hangleEdit} ><i className="fa fa-edit"></i>{t("editMenu")}</MenuItem>
                    <MenuItem onClick={showEmailModal} ><i className="fa fa-envelope"></i>{t("sendEmailMenu")}</MenuItem>
                    <MenuItem onClick={goToPublic} ><i className="fa fa-eye"></i>{t("viewInvoiceMenu")}</MenuItem>
                    {publishMenu}
                    {cancelMenu}
                </Menu>
            </div>
            setActionElement(actionel)
        }



    }, [props.node])

    return (

        <span>  {actionElement}</span>
    );
});

const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
}) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    // styles
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
        alignItems: "center",
        backgroundColor: bg,
        color: "inherit",
        display: "flex "
    };

    // prop assignment
    const props = {
        ...innerProps,
        onMouseDown,
        onMouseUp,
        onMouseLeave,
        style
    };

    return (
        <components.Option
            {...rest}
            isDisabled={isDisabled}
            isFocused={isFocused}
            isSelected={isSelected}
            getStyles={getStyles}
            innerProps={props}
        >
            <input type="checkbox" checked={isSelected} />
            {children}
        </components.Option>
    );
};



export default function SaleSearch() {

    const history = useHistory();
    const [gridApi, setGridApi] = useState(null);
    const { t, i18n } = useTranslation();
    const [printSale, setPrintSale] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [fastFilter, setFastFilter] = useState("");
    const [blockuiLoading, setBlockuiLoading] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [sendEmailIsDisabled, setSendEmailIsDisabled] = useState(true);
    const [inputContactValue, setInputContactValue] = useState("");
    const [selectedContactValue, setSelectedContactValue] = useState([]);

    const { control, setValue, getValues, formState: { errors } } = useForm();
    const [customerContacts, setCustomerContacts] = useState([]);
    const [selectedCustomerContacts, setSelectedCustomerContacts] = useState([]);
    const [lineData, setLineData] = useState({});
    const iframePrintTemplate = useRef();
    var to = null;
    const pagination = true;
    const paginationPageSize = 20;
    const [sheight, setSheight] = useState(window.innerHeight - 190);

    const [sendPaymentLink, setSendPaymentLink] = useState(false);
    const [template, setTemplate] = useState(false);
    const [dismissSalesAlert, setDismissSalesAlert] = useState(false);
    const detectMobile = useMobileDetect();
    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)
    const [selectedOptions, setSelectedOptions] = useState([]);
    const allOptions = [
        { value: "invoiceDate", label: t("DateTime") },
    ];

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [focusedInput, setFocusedInput] = useState();

    const [aggridLocal, setAggridLocal] = useState(AG_GRID_LOCALE_GR);


    const minSwipeDistance = 200

    const onTouchStart = (e) => {
        setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX)
    }

    const onTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientX)
    }

    const onTouchEnd = () => {
        var currentPage = gridApi.paginationGetCurrentPage();
        var lastpage = gridApi.paginationGetTotalPages();

        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance
        if (isLeftSwipe) {
            if (currentPage != lastpage) {
                gridApi.paginationGoToPage(currentPage + 1);
            }

        }
        else if (isRightSwipe) {
            if (currentPage != 0) {
                gridApi.paginationGoToPage(currentPage - 1);
            }

        }

    }

    const blockUi = (block) => {
        setBlockuiLoading(block)
    }


    var getPageObject = function () {
        var pageNo = gridApi.paginationGetCurrentPage();
        var sortBy = null;
        var order = "asc";
        var filterModel = gridApi.getFilterModel();
        console.log(filterModel)
        gridApi.columnController.columnApi.getColumnState().map((v) => {
            if (v.sort != null) {
                sortBy = v.colId;
                order = v.sort
            }
        });

        var simpleColumnFilters = [];
        Object.keys(filterModel).forEach(k => {
            var obj = {
                column: k,
                filter: filterModel[k].filter,
                dateFrom: filterModel[k].dateFrom,
                dateTo: filterModel[k].dateTo,
                filterType: filterModel[k].filterType,
                searchOperator: filterModel[k].type
            }
            if (obj.column == "seriesNumber") {
                obj.column = "series";
                obj.concatWith = "invoiceNumber";
            }
            simpleColumnFilters.push(obj);
        })


        var object = {
            "quickSearch": fastFilter,
            "pageNo": pageNo,
            "sortBy": sortBy,
            "order": order,
            "pageSize": paginationPageSize,
            "simpleColumnFilters": simpleColumnFilters
        }
        return object;
    }

    const getRowData = (params) => {
        var companyId = COMPANY.id;
        setTimeout(function () {
            gridApi.showLoadingOverlay();
        })
        var object = getPageObject();
        ApiClient.getCompanySaleInstancesPage(companyId, object).then(
            response => {

                const newSaleInstances = response.data.objectList.map(e => {
                    e.seriesNumber = e.series + e.invoiceNumber;
                    return e
                });

                params.successCallback(newSaleInstances, response.data.totalElements);

                if (newSaleInstances.length == 0) {
                    gridApi.showNoRowsOverlay();
                }
            },
            error => {
                gridApi.hideOverlay();
                console.error(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString())
            }
        );
    }


    useEffect(() => {

        if (gridApi) {
            const dataSource = {
                getRows: (params) => {
                    let mounted = true;
                    if (mounted) {
                        getRowData(params);
                    }
                    return () => mounted = false;
                }
            }
            let timer1 = setTimeout(function () {
                gridApi.setDatasource(dataSource);
            }, 1000);

            return () => {
                clearTimeout(timer1);
            };

        }
    }, [fastFilter]);




    useEffect(() => {

        var _defaults = Commons.getDefaults();
        if (_defaults) {
            var _dismissSalesAlert = Commons.getDefaults().dismissSalesAlert
            if (_dismissSalesAlert) {
                setDismissSalesAlert(true);
            }
        }

        if (gridApi) {
            // var element = document.getElementsByClassName("sales-mobile-table-container")[0]
            // element.addEventListener('touchstart', startSwipe);
            // document.addEventListener('touchend', endSwipe);
            // document.addEventListener('touchmove', detectMouse);
            const dataSource = {
                getRows: (params) => {
                    let mounted = true;
                    if (mounted) {
                        getRowData(params);
                    }
                    return () => mounted = false;
                }
            }
            if (i18n.language == "gr") {
                setAggridLocal(AG_GRID_LOCALE_GR);
            }
            else {
                setAggridLocal(AG_GRID_LOCALE_EN);
            }
            gridApi.setDatasource(dataSource);

        }
    }, [gridApi]);



    const onGridReady = params => {
        setGridApi(params.api);
        window.addEventListener('resize', function () {
            setTimeout(function () {
                // params.api.sizeColumnsToFit();
                setSheight(window.innerHeight - 190);
            }, 300);
        });
    };
    const onQuickFilterChanged = e => {
        console.log();
        gridApi.fastFilter = e.target.value;
        setFastFilter(e.target.value.trim());

    };
    const onClickPrint = data => {
        console.log(data)
        setPrintSale(data)
        iframePrintTemplate.current.print(data)
    }

    const onPaginationChanged = (val) => {
        var currentPage = val.api.paginationGetCurrentPage();
        setCurrentPage(currentPage);
        val.api.hideOverlay();

    }
    const dateFormatter = (params) => {
        if (params.data != undefined) {
            var dateAsString = moment(params.data.invoiceDate).format('DD/MM/YYYY')
            return dateAsString
        }
        return ""
    }
    const onChangeContact = data => {

        var recaptchaValue = recaptchaRef.current.getValue();

        setSelectedCustomerContacts(data);
        debugger
        if (data && data.length > 0 && recaptchaValue && recaptchaValue != "") {
            setSelectedContactValue(data)
            setSendEmailIsDisabled(false)
        }
        else {
            setSendEmailIsDisabled(true)
        }

    }
    const onInputChangeContact = (val, action) => {
        // setInputContactValue(val)
        // if (action.action != "input-blur" && action.action != "menu-close") {
        //     if(val==""){
        //         setSendEmailIsDisabled(true)
        //     }
        //     else{
        //         setInputContactValue(val)
        //         setSendEmailIsDisabled(false)
        //     }
        // }
        // else if (action.action == "menu-close") {
        //     if({inputContactValue}!=""){
        //         setSelectedContactValue([{label: inputContactValue, value: inputContactValue,name: inputContactValue}]);
        //     }

        //     setInputContactValue("")
        // }
        // else if (action.action == "set-value") {
        //     if({inputContactValue}!=""){
        //         setSelectedContactValue([{label: inputContactValue, value: inputContactValue,name: inputContactValue}]);
        //     }

        //     setInputContactValue("")
        // }

    }
    function getContactEmails(sale, sendPaymentLink) {
        var customerName = sale.customeName;
        var subject = t("emailSubject", { company: COMPANY.name });

        var contactEmails = [];
        selectedCustomerContacts.forEach(contact => {
            if (contact.email == undefined || contact.email == null) {
                contact.email = contact.value;
                contact.name = contact.value
            }
            // var body = "<p>" + t("emailBody1", { contactName: contact.name }) + "</p>";
            // body += "<p>" + t("emailBody2", { company: COMPANY.name }) + "</p>";
            // var link = "<a href='https://app.timologic.gr/mydata/" + sale.invoiceUid + "'>" + sale.invoiceType + " " + sale.series + sale.invoiceNumber + "</a>";
            // body += "<p>" + t("emailBody3", { link: link }) + "</p>";

            // if (sendPaymentLink && COMPANY.vivaQuickPay) {
            //     body += "<p>" + t("emailVivaPayMess") + "</p>";
            //     body += "<p class='viva-pay-btn-cont'><a class='viva-pay-btn' href='" + COMPANY.vivaQuickPay + "/" + sale.totalPayment + "'>" + t("payWithViva") + "</a></p>";
            // }
            // if (sendPaymentLink && COMPANY.stripeAccountID) {
            //     var amount = sale.totalWithVat;
            //     body += "<p>" + t("emailStripePayMess") + "</p>";
            //     body += "<p class='stripe-pay-btn-cont'><a class='stripe-pay-btn' href='" + API_BASE_URL + "/stripe/checkout/" + COMPANY.id + "?amount=" + amount + "&saleInstanceId=" + sale.id + "'>" + t("payWithTimologicPay") + "</a></p>";
            // }
            contactEmails.push({
                email: contact.email
            });
        })
        return contactEmails;

    }
    const getPrintSale = (sale) => {
        var saleClone = JSON.parse(JSON.stringify(sale));
        return iframePrintTemplate.current.getPrintSale2(saleClone)
    }
    const onSendEmail = () => {
        var contactEmails = getContactEmails(lineData, sendPaymentLink);
        document.getElementById("template").style.display = "unset";
        const ps = getPrintSale(lineData);

        setPrintSale(ps)
        setTimeout(function () {
            setBlockuiLoading(true)
            try {
                var store = false;
                var user = JSON.parse(localStorage.getItem("user"));
                // debugger
                // if (lineData.myDataInvoiceType == "invoice1_1" || lineData.myDataInvoiceType == "invoice2_1") {
                //     store = true;
                // }
                const recaptchaValue = recaptchaRef.current.getValue();
                iframePrintTemplate.current._sendEmail({
                    contacts: contactEmails,
                    store: store,
                    taxpayerId: lineData.company.taxpayerId,
                    companyId: lineData.company.id,
                    taxpayerId: lineData.company.taxpayerId,
                    fromEmail: user.email,
                    invoiceUid:lineData.invoiceUid,
                    captcha: recaptchaValue
                }, function (resp) {
                    setBlockuiLoading(false)
                    document.getElementById("template").style.display = "none";
                    setShowEmailModal(false);
                    if (resp.data.status == "SUCCESS") {
                        toast.success(t("emailSended"));
                    }
                    else {
                        toast.error(t("emailNotSended"));
                    }
                });
            }
            catch (e) {
                console.error(e)
                setBlockuiLoading(false)
            }
        })

    }
    const exportToExcel = (e) => {
        e.preventDefault();
        var companyId = COMPANY.id;
        setBlockuiLoading(true)
        var object = getPageObject();
        ApiClient.exportCompanySalesToExcel(companyId, object).then(
            response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'export.xlsx');
                document.body.appendChild(link);
                link.click();
                setBlockuiLoading(false)
            },
            error => {
                console.error(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString());
                setBlockuiLoading(false)
            }
        );
    }

    const getHeaderTemplate = () => {
        return <span><img style={{ width: 60 }} src={MyDataLogo}></img></span>
    }
    const pdfDownload = (_data) => {
        setPrintSale(_data)
        iframePrintTemplate.current.saveAsPDF(_data);
    }
    const hangleDismissSalesAlert = (_data) => {
        Commons.setDefault("dismissSalesAlert", true);
        document.getElementsByClassName("sales_mark_attention_message")[0].style.display = "none";
    }
    const sortByStyles = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                fontSize: 11,
            }
        }
    }

    var mouseOrigin;
    var isSwiping = false;
    var mouseRelease;
    var currentTask;
    var swipeMargin = 20;

    //STARTSWIPE
    const startSwipe = (evt) => {
        console.log("startSwi " + evt.target)
        mouseOrigin = evt.touches[0].clientX;
        currentTask = document.getElementsByClassName("sales-mobile-table-container")[0]
        isSwiping = true;


    }

    //ENDSWIPE
    const endSwipe = (evt) => {

        mouseOrigin = null;
        isSwiping = false;

        // var currentMousePosition = evt.changedTouches[0].clientX;

        // currentTask.style.transform= "translateX(-500px)";
        // currentTask.style.transition="all 1s" 
        // setTimeout(function(){
        //     // currentTask.style.transition=" all 0s" 
        //     // currentTask.style.transform= "translateX(500px)"
        //     currentTask.style.margin = 0;

        // },1000)


        currentTask.style.margin = 0;
        currentTask = null;
    }

    //DETECTMOUSE
    const detectMouse = (evt) => {
        var currentPage = gridApi.paginationGetCurrentPage();
        var lastpage = gridApi.paginationGetTotalPages();

        var currentMousePosition = evt.touches[0].clientX;
        var swipeDifference = Math.abs(mouseOrigin - currentMousePosition)
        if (isSwiping && currentTask && (swipeDifference > swipeMargin)) {

            if ((swipeDifference - swipeMargin) <= swipeMargin) {
                //no change, allows user to take no action
                currentTask.classList.remove("completing");
                currentTask.classList.remove("deleting");
                currentTask.style.margin = 0;
            }
            else if (mouseOrigin > currentMousePosition) {
                //swipe left    
                if (currentPage != lastpage) {
                    currentTask.classList.remove("completing");
                    currentTask.classList.add("deleting");
                    currentTask.style.marginLeft = -swipeDifference + "px";
                }

            }
            else if (mouseOrigin < currentMousePosition) {
                if (currentPage != 0) {
                    currentTask.classList.remove("completing");
                    currentTask.classList.add("deleting");
                    currentTask.style.marginLeft = -swipeDifference + "px";
                    currentTask.style.marginLeft = swipeDifference + "px";
                }

            }
        }
    }
    const recaptchaRef = useRef();

    const onChangeCaptcha = (value) => {
        // setAcceptTerms(event.target.checked);
        // const recaptchaValue = recaptchaRef.current.getValue();
        debugger
        if (value && value != "" && selectedCustomerContacts.length > 0) {
            setSendEmailIsDisabled(false)
        }
        else {
            setSendEmailIsDisabled(true)
        }
    }

    return (
        <BlockUi tag="div" blocking={blockuiLoading}>

            <div className="container-fluid" style={{ padding: 0 }}>
                <header className="jumbotron search_header">

                    {/* {!dismissSalesAlert ?
                        <div className="row sales_mark_attention_message">
                            <div className="alert alert-primary alert-dismissible fade show small-info" role="alert">
                                <div> {t("salesSearchInfo")}<span><a href="/presales">{t("salesSearchInfo2")}</a></span></div>
                                <button type="button" style={{ padding: 8 }} onClick={hangleDismissSalesAlert} className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>
                        </div> : <></>} */}


                    <div className="d-flex bd-highlight">
                        <div className="me-auto p-0 bd-highlight">
                            <h5 className="subtitle skin">{t("invoicesSearch")}
                                <Tippy trigger="click" content={t("salesSearchInfo")}>
                                    <i className="fa fa-info-circle" style={{ marginLeft: 6 }}></i>
                                </Tippy>
                            </h5>
                        </div>
                        {/* <div className="p-0 bd-highlight">
                            <button className="btn btn-skin" href="" onClick={function () { return history.push("/sale"); }}>
                                <span className="desktop-only">{t("PublicationNew")} </span> <i className="fa  fa-plus"></i>
                            </button>
                        </div> */}
                    </div>
                </header>
                <div style={{ width: '100%', height: '100%' }}>
                    <div className="row">
                        <div className="d-flex bd-highlight">
                            <div className="p-1 flex-grow-1 bd-highlight searchSalesInputContainer">
                                <input style={{ maxWidth: 400 }} placeholder={t('Search')} className="form-control" id="searchSalesInput" onChange={onQuickFilterChanged} />
                            </div>
                            <div className="p-1 bd-highlight">


                                <div style={{ position: "absolute", top: 0, right: 0, marginRight: "1%" }}>

                                    <div className="d-flex" style={{ position: "relative" }}>
                                        <div className="p-1 bd-highlight">
                                            <button className="btn btn-skin btn-sm" href="" onClick={function () { return history.push("/sale"); }}>
                                                <span className="desktop-only">{t("PublicationNew")} </span> <i className="fa  fa-plus"></i>
                                            </button>
                                        </div>
                                        <div className="p-1 bd-highlight">
                                            <button className="btn btn-skin btn-sm" title={t("exportToExcel")} onClick={exportToExcel}> <i className="fa fa-file-excel-o"></i></button>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="selectInputContainer">
                                    <Select
                                        className="column-choicer"
                                        styles={sortByStyles }
                                        placeholder={t("sortBy")}
                                        defaultValue={[]}
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        onChange={(options) => {
                                            if (Array.isArray(options)) {
                                                setSelectedOptions(options.map((opt) => opt.value));
                                            }
                                        }}
                                        options={allOptions}
                                        components={{
                                            Option: InputOption
                                        }}
                                    />
                                </div> */}

                            </div>
                            <div className="p-0 bd-highlight mobile-only">
                                <DateRangePicker
                                    startDate={startDate}
                                    small={true}
                                    daySize={36}
                                    startDateId="your_unique_start_date_id"
                                    endDate={endDate}
                                    endDateId="your_unique_end_date_id"
                                    isOutsideRange={() => false}
                                    withPortal={true}
                                    withFullScreenPortal={true}
                                    onDatesChange={({ startDate, endDate }) => {
                                        setStartDate(startDate);
                                        setEndDate(endDate);

                                        if (startDate && endDate) {
                                            gridApi.setFilterModel({
                                                "invoiceDate": {
                                                    "dateFrom": moment(startDate).format('YYYY-MM-DD'),
                                                    "dateTo": moment(endDate).format('YYYY-MM-DD'),
                                                    "type": "inRange",
                                                    "filterType": "date"
                                                }
                                            });
                                        }
                                    }}
                                    focusedInput={focusedInput}
                                    orientation="vertical"
                                    onFocusChange={(focusedInput) => {
                                        setFocusedInput(focusedInput);

                                    }
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    {
                        detectMobile.isDesktop() ?
                            <div
                                id="myGrid"
                                style={{ height: '100%', width: '100%' }}
                                className="ag-theme-alpine-dark sales-table-container"
                            >
                                <div className="ag-theme-alpine" style={{ height: sheight, width: '100%' }}>
                                    <AgGridReact
                                        pagination={pagination}
                                        paginationPageSize={paginationPageSize}
                                        onGridReady={onGridReady}
                                        // domLayout={'autoHeight'}
                                        style={{ width: '100%', height: '100%' }}
                                        // onRowDoubleClicked={(e) => goTo(e.data.invoiceUid)}
                                        // rowData={content}
                                        // domLayout={'autoHeight'}
                                        animateRows={true}

                                        suppressRowTransform={true}
                                        frameworkComponents={{
                                            actionsRenderer: ActionsRenderer,
                                            seriesNumberRendener: SeriesNumberRendener,
                                            markRenderer: MarkRenderer,
                                            dateRendener: DateRendener,
                                            tooltipRenderer: TooltipRenderer,
                                            agDateInput: CustomDateComponent,
                                            lineInfoRenderer: LineInfoRenderer,
                                            aggridSalesTooltip: AggridSalesTooltip
                                        }}

                                        overlayNoRowsTemplate={
                                            '<span style="padding: 10px;  color:#013289">' + t('noEntries') + '</span>'
                                        }
                                        overlayLoadingTemplate={
                                            '<span class="ag-overlay-loading-center"><img src="loading.gif">' + t('aggridPageLoadMess') + '</span>'
                                        }
                                        localeText={aggridLocal}
                                        onPaginationChanged={onPaginationChanged}
                                        rowModelType={'infinite'}
                                        cacheBlockSize={paginationPageSize}
                                        gridOptions={{ suppressMenuHide: true }}
                                        defaultColDef={{
                                            flex: 1,
                                            resizable: true,
                                            sortable: true,
                                            wrapText: true,
                                            suppressMovable: true,
                                            autoHeight: true,
                                            icons: {
                                                menu: '<i style="color:white" class="fa fa-search"/>',
                                            },
                                        }}
                                    >


                                        {/* <AgGridColumn
                                            // tooltipValueGetter={params => {
                                            //     if (params.data) {
                                            //         return <> <span>{t("invoiceType")}: </span><span>{params.data.invoiceType}</span>
                                            //             <span>{t("Customer")}: </span><span>{params.data.customerName}</span>
                                            //         </>
                                            //     }
                                            // }}
                                            // tooltipComponentParams={{invoiceData}}
                                            field=""
                                            maxWidth={40}
                                            cellRenderer="lineInfoRenderer"
                                            sortable={false}
                                            tooltipComponent="aggridSalesTooltip"
                                            tooltipField="invoiceType"
                                        >

                                        </AgGridColumn> */}

                                        <AgGridColumn field="id" sort="desc" hide={true}></AgGridColumn>

                                        <AgGridColumn flex={1} minWidth={120} headerName={t("Number")} cellRenderer="seriesNumberRendener" filterParams={{ buttons: ['reset', 'apply'] }} sortable={true} filter="agTextColumnFilter" field="seriesNumber"
                                            cellRendererParams={
                                                {
                                                    printSale: printSale,
                                                    onClickPrint: onClickPrint,
                                                    blockUi: blockUi,
                                                    setShowEmailModal: setShowEmailModal,
                                                    setCustomerContacts: setCustomerContacts,
                                                    setLineData: setLineData,
                                                    getPrintSale: getPrintSale,
                                                    setTemplate: setTemplate,
                                                    pdfDownload: pdfDownload
                                                }
                                            }
                                        ></AgGridColumn>
                                        <AgGridColumn minWidth={140} valueFormatter={dateFormatter} flex={2}
                                            headerName={t('IssueDate')} sortable={true} filter="agDateColumnFilter" field="invoiceDate" filterParams={{ buttons: ['reset', 'apply'], inRangeInclusive: true }}></AgGridColumn>

                                        <AgGridColumn flex={5} minWidth={200} headerName={t("Customer")} sortable={true} filter="agTextColumnFilter" filterParams={{ buttons: ['reset', 'apply'] }} field="customerName"></AgGridColumn>
                                        <AgGridColumn flex={2} minWidth={140} headerName={t("taxpayerId")} sortable={true} filter="agTextColumnFilter" filterParams={{ buttons: ['reset', 'apply'] }} field="customerTaxPayerId"></AgGridColumn>

                                        <AgGridColumn
                                            flex={5} minWidth={220}
                                            tooltipField="invoiceType"
                                            headerName={t("InvoiceType")}
                                            sortable={true}
                                            filter="agTextColumnFilter"
                                            filterParams={{ buttons: ['reset', 'apply'] }}
                                            field="invoiceType">
                                        </AgGridColumn>
                                        <AgGridColumn minWidth={100} valueFormatter={params => params.data != null ? params.data.totalWithoutVat.toFixed(2) : 0.00} flex={2} headerName={t("TotalWithoutVat")} sortable={true} filter="agNumberColumnFilter" filterParams={{ buttons: ['reset', 'apply'] }} field="totalWithoutVat"></AgGridColumn>
                                        <AgGridColumn minWidth={100} valueFormatter={params => params.data != null ? (params.data.totalWithVat - params.data.totalWithoutVat).toFixed(2) : 0.00} flex={2} headerName={t("VatAmount")} filterParams={{ buttons: ['reset', 'apply'] }} sortable={true} filter="agNumberColumnFilter" field="totalWithVat"></AgGridColumn>
                                        <AgGridColumn minWidth={100} valueFormatter={params => params.data != null ? params.data.totalPayment.toFixed(2) : 0.00} flex={2} headerName={t("TotalPayment")} filterParams={{ buttons: ['reset', 'apply'] }} sortable={true} filter="agNumberColumnFilter" field="totalPayment"></AgGridColumn>
                                        <AgGridColumn flex={2} minWidth={150} headerName={t("myDATA MARK")} cellRenderer="markRenderer" sortable={true} filter="agTextColumnFilter" filterParams={{ buttons: ['reset', 'apply'] }} field="mark"></AgGridColumn>
                                        <AgGridColumn hide={true} minWidth={150} field="cancelationMark"></AgGridColumn>
                                        <AgGridColumn flex={1} minWidth={100} headerName={t("Actions")} cellRendererParams={
                                            {
                                                printSale: printSale,
                                                onClickPrint: onClickPrint,
                                                blockUi: blockUi,
                                                setShowEmailModal: setShowEmailModal,
                                                setCustomerContacts: setCustomerContacts,
                                                setLineData: setLineData,
                                                getPrintSale: getPrintSale,
                                                setTemplate: setTemplate,
                                                pdfDownload: pdfDownload
                                            }
                                        } cellRenderer="actionsRenderer" field="action"></AgGridColumn>

                                    </AgGridReact>
                                </div>
                                <IFramePrintTemplate style={{ width: 800, height: "297mm", left: 0, top: 0, position: "fixed", zIndex: -100 }} extracss={template.css} template={template} ref={iframePrintTemplate} ></IFramePrintTemplate>

                            </div>
                            :

                            <div
                                id="myGrid"
                                onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}
                                style={{ height: '100%', width: '100%' }}
                                className="ag-theme-alpine-dark sales-mobile-table-container"
                            >
                                <div className="ag-theme-alpine" style={{ height: sheight, width: '100%' }}>
                                    <AgGridReact
                                        pagination={pagination}
                                        paginationPageSize={paginationPageSize}
                                        onGridReady={onGridReady}
                                        // domLayout={'autoHeight'}
                                        style={{ width: '100%', height: '100%' }}
                                        // onRowDoubleClicked={(e) => goTo(e.data.invoiceUid)}
                                        // rowData={content}
                                        // domLayout={'autoHeight'}
                                        animateRows={true}
                                        suppressCellSelection={true}
                                        suppressRowTransform={true}
                                        frameworkComponents={{
                                            actionsRenderer: ActionsRenderer,
                                            seriesNumberRendener: SeriesNumberRendener,
                                            markRenderer: MarkRenderer,
                                            dateRendener: DateRendener,
                                            tooltipRenderer: TooltipRenderer,
                                            agDateInput: CustomDateComponent,
                                            lineInfoRenderer: LineInfoRenderer,
                                            aggridSalesTooltip: AggridSalesTooltip,
                                            allFieldsRenderer: AllFieldsRenderer
                                        }}
                                        overlayNoRowsTemplate={
                                            '<span style="padding: 10px;  color:#013289">' + t('noEntries') + '</span>'
                                        }
                                        overlayLoadingTemplate={
                                            '<span className="ag-overlay-loading-center"><img src="loading.gif">' + t('aggridPageLoadMess') + '</span>'
                                        }
                                        rowHeight={86}
                                        onPaginationChanged={onPaginationChanged}
                                        rowModelType={'infinite'}
                                        cacheBlockSize={paginationPageSize}
                                        gridOptions={{ suppressMenuHide: true }}
                                        tooltipHideDelay={0}
                                        enableBrowserTooltips={false}
                                        defaultColDef={{
                                            sortable: true,
                                            wrapText: false,
                                            suppressHorizontalScroll: true,
                                            suppressMovable: true,
                                        }}
                                    >
                                        <AgGridColumn field="id" sort="desc" hide={true}></AgGridColumn>
                                        <AgGridColumn
                                            flex={5}
                                            headerName={t("")}
                                            sortable={true}
                                            cellRenderer="allFieldsRenderer"
                                            // tooltipComponent="aggridSalesTooltip"
                                            tooltipField="invoiceType"
                                            field="invoiceType">
                                        </AgGridColumn>
                                        <AgGridColumn hide={true} valueFormatter={dateFormatter}
                                            headerName={t('DateTime')} sortable={true} filter="agDateColumnFilter" field="invoiceDate" filterParams={{ inRangeInclusive: true }}></AgGridColumn>

                                        <AgGridColumn hide={true} headerName={t("myDATA MARK")} cellRenderer="markRenderer" sortable={true} filter="agTextColumnFilter" filterParams={{ buttons: ['reset', 'apply'] }} field="mark"></AgGridColumn>
                                        <AgGridColumn hide={true} field="cancelationMark"></AgGridColumn>
                                        <AgGridColumn flex={0.5} headerName={t("")} cellRendererParams={
                                            {
                                                printSale: printSale,
                                                onClickPrint: onClickPrint,
                                                blockUi: blockUi,
                                                setShowEmailModal: setShowEmailModal,
                                                setCustomerContacts: setCustomerContacts,
                                                setLineData: setLineData,
                                                getPrintSale: getPrintSale,
                                                setTemplate: setTemplate,
                                                pdfDownload: pdfDownload
                                            }
                                        } cellRenderer="actionsRenderer" field="action"></AgGridColumn>

                                    </AgGridReact>
                                </div>
                                <IFramePrintTemplate style={{ width: 800, height: "297mm", left: 0, top: 0, position: "fixed", zIndex: -100 }} extracss={template.css} template={template} ref={iframePrintTemplate} ></IFramePrintTemplate>

                            </div>

                    }
                </div>
            </div>
            <Modal
                aria-labelledby="contained-modal-title-vcenter"

                centered
                show={showEmailModal}
                dialogClassName="email-modal"
                backdrop="static"
                className="send-email-modal"
                keyboard={false}
            >
                <Modal.Header >
                    <Modal.Title>{t("sendEmail")}</Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <span className="totalConstraintInfo"></span>
                    <div className="row">
                        <div className="col-md-12 selectInputContainer form-group">
                            <label className="control-label inputlabel">{t("customerContacts")}
                                <i data-tip data-for="ccInfo" className="fa fa-x2 fa-info-circle infoicon" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                                <ReactTooltip className="skin-tooltip" id="ccInfo" place="top" effect="solid">
                                    {t("ccInfo2")}
                                </ReactTooltip>
                            </label>
                            <Controller
                                render={({ field }) => (
                                    <SelectTable {...field}
                                        inputId="contacts-react-select"
                                        onChange={onChangeContact}
                                        onChangeValue={onChangeContact}
                                        onInputChange={onInputChangeContact}
                                        // selectedValue={selectedContactValue}
                                        // inputValue={inputContactValue}
                                        isMulti={true}
                                        options={customerContacts}
                                        createTable={true}
                                        className={`form-control ${errors.customerContact ? 'is-invalid' : ''}`}
                                        placeholder={t('Select')}
                                        width={500}
                                    >

                                    </SelectTable>
                                )}
                                control={control}
                                name="customerContacts"
                                defaultValue=""
                            />
                            <br></br>
                            <input type="checkbox" onChange={e => setSendPaymentLink(e.target.checked)} name="sendPaymentLink" checked={sendPaymentLink} className="form-check-input" />{t("sendPaymentLink")}
                            <div className="form-item text-center recapctha-container">
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={RECAPTCHA_KEY}
                                    onChange={onChangeCaptcha}
                                    size="compact"
                                    hl="el"
                                />
                            </div>
                            <div className="invalid-feedback">{errors.customerContact?.message}</div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-skin btn-sm" onClick={function () { setSendEmailIsDisabled(true); setShowEmailModal(false) }}>{t("cancel")}</button>
                    <button id="onExtras" className="btn btn-skin btn-sm" disabled={sendEmailIsDisabled} onClick={onSendEmail}>{t("send")}</button>
                </Modal.Footer>
            </Modal>


        </BlockUi>
    );
}


