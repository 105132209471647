import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from "react";
import ApiClient from "../services/api-client"
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom"
import { CustomConfirmAlert as confirmAlert } from '../components/custom-confirm-alert';
import moment from 'moment'
import BlockUi from 'react-block-ui';
import { toast } from 'react-toastify';
import CustomDateComponent from '../components/sales/customDateComponent.js';
import Modal from 'react-bootstrap/Modal';
import ReactTooltip from "react-tooltip";
import SelectTable from '../components/select-table'
import { useForm, Controller } from "react-hook-form";
import IFramePrintTemplate from '../components/iframe-print-template.component';
import MyDataLogo from "../img/mydata.png";
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import Tippy from '@tippyjs/react';
import useMobileDetect from 'use-mobile-detect-hook';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';



const LineInfoRenderer = forwardRef((props, ref) => {


    useImperativeHandle(ref, () => {
        return {
            refresh(params) {

            }
        };

    });

    return (
        <> <span><i className="fa fa-exclamation-circle"></i></span></>
    );
});

const AllFieldsRenderer = forwardRef((props, ref) => {
    const { t } = useTranslation();

    const [borderClass, setBorderClass] = useState("sales-grid-row");

    useImperativeHandle(ref, () => {
        return {
            refresh(params) {

            }
        };

    });


    useEffect(() => {
        if (props.node.data != null) {
            var addcls = props.node.data ? props.node.data.cancellationMark ? "row-canceled-status" : props.node.data.mark ? "row-sended-status" : "row-not-sended-status" : "";
            setBorderClass("sales-grid-row " + addcls);

        }
    }, [props.node])



    const rowData = <div className="container sales-aggrid-tooltip-inner">


        <div className="d-flex bd-highlight">
            <div className="p-1 flex-grow-1 bd-highlight"><b >{t("InvoiceType")}:</b></div>
            <div className="p-1 bd-highlight"><span >{props.data ? props.data.invoiceType : ""}</span></div>
        </div>
        <div className="d-flex bd-highlight">
            <div className="p-1 flex-grow-1 bd-highlight"><b>{t("Customer")}:</b></div>
            <div className="p-1 bd-highlight"><span>{props.data ? props.data.customerName : ""} ({props.data ? props.data.customerTaxPayerId : ""})</span></div>
        </div>
        <div className="d-flex bd-highlight">
            <div className="p-1 flex-grow-1 bd-highlight"><b >{t("issuerDate")}:</b></div>
            <div className="p-1 bd-highlight"><span>{props.data ? moment(props.data.invoiceDate).format('DD/MM/YYYY') : ""}</span></div>
        </div>
        <div className="d-flex bd-highlight">
            <div className="p-1 flex-grow-1 bd-highlight"><b >{t("TotalWithoutVat")}:</b></div>
            <div className="p-1 bd-highlight"><span>{props.data ? props.data.totalWithoutVat : ""}</span></div>
        </div>
        <div className="d-flex bd-highlight">
            <div className="p-1 flex-grow-1 bd-highlight"><b >{t("TotalWithVat")}:</b></div>
            <div className="p-1 bd-highlight"><span>{props.data ? props.data.totalWithVat : ""}</span></div>
        </div>
        <div className="d-flex bd-highlight">
            <div className="p-1 flex-grow-1 bd-highlight"><b >{t("TotalPayment")}:</b></div>
            <div className="p-1 bd-highlight"><span>{props.data ? props.data.totalPayment : ""}</span></div>
        </div>
    </div>;


    return (

        <div className={borderClass}>

            <div className="d-flex bd-highlight allFieldsColumnRow">
                <div className="p-0 bd-highlight"><span >
                    <Tippy trigger="click" content={rowData}>
                        <i className="fa fa-info-circle" style={{ marginRight: 6 }}></i>
                    </Tippy>
                    <b>{props.data ? props.data.seriesNumber + " " : ""}</b>
                </span><span >{props.data ? props.data.invoiceType : ""}</span></div>
            </div>

            <div className="d-flex bd-highlight allFieldsColumnRow">
                <div className="p-0 bd-highlight"><span style={{ fontStyle: "italic" }}>{props.data ? props.data.customerName + " (" + props.data.customerTaxPayerId + ")" : ""}</span></div>
            </div>
            <div className="d-flex bd-highlight allFieldsColumnRow">
                <div className="p-0 flex-grow-1 bd-highlight"><span >{props.data ? moment(props.data.invoiceDate).format('DD/MM/YYYY') : ""}</span></div>
            </div>

            <div className="d-flex bd-highlight allFieldsColumnRow">
                <div className="p-0 flex-grow-1 bd-highlight"><span >{t("VatAmount")}: </span><span ><b>{props.data ? (props.data.totalWithVat - props.data.totalWithoutVat).toFixed(2) : 0.00}</b></span></div>
                <div className="p-0 bd-highlight"><span >{t("TotalPaymentMin")}: </span><span ><b>{props.data ? props.data.totalPayment.toFixed(2) : 0.00}</b></span></div>
            </div>
        </div >


    );
});



const SeriesNumberRendener = forwardRef((props, ref) => {
    const [seriesNumber, setSeriesNumber] = useState(props.value);
    const [canceledIcon, setCanceledIcon] = useState("");
    const { t } = useTranslation();
    const [actionElement, setActionElement] = useState(<span></span>);
    const history = useHistory();


    useImperativeHandle(ref, () => {
        return {
            refresh(params) {
                var val = props.getValue();


                if (val) {
                    setSeriesNumber(val);
                }
            }
        };

    });
    // const canceledIcon = props.node.data.canceled? <i className='fa fa-times-circle canceledInvoice'></i> : ""
    // const canceledIcon = rowNode.data.canceled ? <i className='fa fa-times-circle canceledInvoice'></i> : ""
    // console.error(rowNode)
    const handleInputChange = (e) => {
        // e.preventDefault();
        var _data = props.data;
        ApiClient.getTemplateSettings(_data.company.id, _data.branchId).then(response => {
            var _template = response.data;
            var _temp = {
                branch: _data.branchId,
                template: _template.template,
                css: _template.css,
                logo: _template.logo
            }

            props.colDef.cellRendererParams.setTemplate(_temp);
            var saleInstance = props.colDef.cellRendererParams.getPrintSale(_data);
            saleInstance.LOGO = _temp.logo;
            console.log(saleInstance)
            props.colDef.cellRendererParams.onClickPrint(saleInstance)

        },
            error => {
                console.error(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString())
            }
        );

    }

    const handleCancel = (e) => {
        // e.preventDefault();
        var id = props.data.id;
        var companyId = JSON.parse(localStorage.getItem("company")).id;

        confirmAlert({
            title: t("submitConfirm"),
            message: t("deletePreSaleConfirm"),
            buttons: [
                {
                    label: t('Yes'),
                    onClick: () => {
                        var rowNode = props.node;
                        const seriesNumber = props.data.seriesNumber;
                        props.colDef.cellRendererParams.blockUi(true)
                        ApiClient.cancelMyDataInvoice(companyId, id).then(
                            response => {

                                if (response.data.response[0].statusCode == "Success") {
                                    var cancelMark = response.data.response[0].cancellationMark;


                                    var mark = rowNode.data.mark;
                                    if (cancelMark) {
                                        var _htm = <span><span>{seriesNumber}</span><i className='fa fa-times-circle canceledInvoice'></i></span>;
                                        rowNode.setDataValue("seriesNumber", _htm);

                                        const actionel = <div>

                                            <Menu menuButton={<MenuButton><i className="fa fa-bars"></i></MenuButton>} transition>
                                                <MenuItem onClick={handleInputChange} ><i className="fa fa-print"></i>Print</MenuItem>
                                                <MenuItem onClick={hangleEdit} ><i className="fa fa-edit"></i>Edit</MenuItem>
                                                <MenuItem onClick={showEmailModal} ><i className="fa fa-envelope"></i>Send Email</MenuItem>
                                                <MenuItem onClick={handleCancel}>
                                                    <i className="fa fa-times"></i>{t("deletePreSaleMenu")}
                                                </MenuItem>
                                            </Menu>

                                        </div>
                                        setActionElement(actionel)
                                        // rowNode.gridApi.redrawRows()
                                        rowNode.gridApi.refreshInfiniteCache()
                                    }
                                    else {
                                        rowNode.gridApi.refreshInfiniteCache()
                                    }



                                    toast.success(t("preSaleDeleted"))
                                }
                                else {
                                    var errors = response.data.response[0].errors.error;
                                    errors.forEach(e => {
                                        toast.error(e.message, { autoClose: 5000 })
                                    })
                                }

                                props.colDef.cellRendererParams.blockUi(false)

                            },
                            error => {
                                console.error(
                                    (error.response &&
                                        error.response.data &&
                                        error.response.data.message) ||
                                    error.message ||
                                    error.toString())
                                props.colDef.cellRendererParams.blockUi(false)
                            }
                        );
                    }
                },
                {
                    label: t('No'),
                    onClick: () => {
                    }
                }
            ]
        });



    }

    const showEmailModal = () => {
        var companyId = JSON.parse(localStorage.getItem("company")).id;
        ApiClient.findCompanyCustomerContacts(companyId, props.data.customerTaxPayerId, props.data.customerName).then(
            response => {
                var contacts = response.data;
                var _contacts = []
                contacts.forEach(e => {
                    _contacts.push({
                        value: e.email,
                        name: e.name,
                        email: e.email,
                        label: e.email
                    })
                });


                var columns = [
                    {
                        header: t("Name"),
                        field: "name",
                        flex: 6,
                        // asLabel: true
                    },
                    {
                        header: t("Email"),
                        field: "email",
                        flex: 6
                    }
                ]



                var _company = JSON.parse(localStorage.getItem("company"));
                if (_company.accountantEmail) {
                    _contacts.push({
                        value: _company.accountantEmail,
                        name: t("companyAccountant"),
                        email: _company.accountantEmail,
                        label: _company.accountantEmail
                    })
                }

                var opts = { data: _contacts, columns: columns };

                props.colDef.cellRendererParams.setShowEmailModal(true);
                props.colDef.cellRendererParams.setCustomerContacts(opts);
                props.colDef.cellRendererParams.setLineData(props.data);

            },
            error => {
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString())
            }
        );
    }
    const hangleEdit = () => {
        debugger
        history.push("/sale/" + props.data.id);
    }

    useEffect(() => {
        if (props.node.data != null) {
            setCanceledIcon(
                props.node.data.canceled ? <i className='fa fa-times-circle canceledInvoice'></i> : "")

        }
    }, [props.node])

    return (

        <span>

            <Menu menuButton={<MenuButton>
                <span>{seriesNumber}</span>{canceledIcon}
            </MenuButton>} transition>
                <MenuItem onClick={handleInputChange} ><i className="fa fa-print"></i>{t("printMenu")}</MenuItem>
                <MenuItem onClick={hangleEdit} ><i className="fa fa-edit"></i>{t("publishMenu")}</MenuItem>
                <MenuItem onClick={showEmailModal} ><i className="fa fa-envelope"></i>{t("sendEmailMenu")}</MenuItem>
                <MenuItem onClick={handleCancel}>
                    <i className="fa fa-times"></i>{t("deletePreSaleMenu")}
                </MenuItem>
            </Menu>
        </span>
    );
});


const DateRendener = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const [date, setDate] = useState(moment(props.value).format('DD/MM/YYYY'));


    useImperativeHandle(ref, () => {
        return {
            refresh(params) {
                var val = props.getValue();

                if (val) {
                    setDate(val);
                }
            }
        };

    });

    const styleDate = (date) => {
        var _date = date.split(" ")[0]
        var _time = date.split(" ")[1]
        return <><strong>{_date}</strong> <small>{_time}</small></>
    }


    return (

        <span>  {styleDate(date)}</span>
    );
});

const ActionsRenderer = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const [actionElement, setActionElement] = useState(<span></span>);
    const history = useHistory();


    const goToPublic = () => {
        var invoiceUid = props.data.invoiceUid;
        window.open("/mydata/" + invoiceUid, "_blank");
    }

    const handleInputChange = (e) => {
        // e.preventDefault();
        var _data = props.data;
        ApiClient.getTemplateSettings(_data.company.id, _data.branchId).then(response => {
            var _template = response.data;
            var _temp = {
                branch: _data.branchId,
                template: _template.template,
                css: _template.css,
                logo: _template.logo,
                mainColor: _template.mainColor,
                secondaryColor: _template.secondaryColor,
                appFont: _template.appFont,
                iban: _template.iban
            }

            props.colDef.cellRendererParams.setTemplate(_temp);
            var saleInstance = props.colDef.cellRendererParams.getPrintSale(_data);
            saleInstance.LOGO = _temp.logo;
            saleInstance.IBAN = _temp.iban;
            console.log(saleInstance)
            props.colDef.cellRendererParams.onClickPrint(saleInstance)

        },
            error => {
                console.error(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString())
            }
        );

    }
    const handleCancel = (e) => {
        // e.preventDefault();
        var id = props.data.id;
        var companyId = JSON.parse(localStorage.getItem("company")).id;

        confirmAlert({
            title: t("submitConfirm"),
            message: t("deletePreSaleConfirm"),
            // submessage: t("deletePreSaleConfirm2"),
            buttons: [
                {
                    label: t('Yes'),
                    onClick: () => {
                        var rowNode = props.node;
                        const seriesNumber = props.data.seriesNumber;
                        props.colDef.cellRendererParams.blockUi(true)
                        ApiClient.cancelMyDataInvoice(companyId, id).then(
                            response => {

                                if (response.data.response[0].statusCode == "Success") {
                                    var cancelMark = response.data.response[0].cancellationMark;


                                    var mark = rowNode.data.mark;
                                    if (cancelMark) {
                                        var _htm = <span><span>{seriesNumber}</span><i className='fa fa-times-circle canceledInvoice'></i></span>;
                                        rowNode.setDataValue("seriesNumber", _htm);
                                        const actionel = <div>

                                            <Menu menuButton={<MenuButton><i className="fa fa-bars"></i></MenuButton>} transition>
                                                <MenuItem onClick={handleInputChange} ><i className="fa fa-print"></i>Print</MenuItem>
                                                <MenuItem onClick={hangleEdit} ><i className="fa fa-edit"></i>Edit</MenuItem>
                                                <MenuItem onClick={showEmailModal} ><i className="fa fa-envelope"></i>Send Email</MenuItem>
                                                <MenuItem onClick={handleCancel}>
                                                    <i className="fa fa-times"></i>{t("deletePreSaleMenu")}
                                                </MenuItem>
                                            </Menu>

                                        </div>
                                        setActionElement(actionel)
                                        // rowNode.gridApi.redrawRows()
                                        rowNode.gridApi.refreshInfiniteCache()
                                    }
                                    else {
                                        rowNode.gridApi.refreshInfiniteCache()
                                    }



                                    toast.success(t("preSaleDeleted"))
                                }
                                else {
                                    var errors = response.data.response[0].errors.error;
                                    errors.forEach(e => {
                                        toast.error(e.message, { autoClose: 5000 })
                                    })
                                }

                                props.colDef.cellRendererParams.blockUi(false)

                            },
                            error => {
                                console.error(
                                    (error.response &&
                                        error.response.data &&
                                        error.response.data.message) ||
                                    error.message ||
                                    error.toString())
                                props.colDef.cellRendererParams.blockUi(false)
                            }
                        );
                    }
                },
                {
                    label: t('No'),
                    onClick: () => {
                    }
                }
            ]
        });



    }

    useImperativeHandle(ref, () => {
        return {
            refresh(params) {

            }
        };

    });

    const showEmailModal = () => {
        var companyId = JSON.parse(localStorage.getItem("company")).id;
        ApiClient.findCompanyCustomerContacts(companyId, props.data.customerTaxPayerId, props.data.customerName).then(
            response => {
                var contacts = response.data;
                var _contacts = []
                contacts.forEach(e => {
                    _contacts.push({
                        value: e.email,
                        name: e.name,
                        email: e.email,
                        label: e.email
                    })
                });


                var columns = [
                    {
                        header: t("Name"),
                        field: "name",
                        flex: 6,
                        // asLabel: true
                    },
                    {
                        header: t("Email"),
                        field: "email",
                        flex: 6
                    }
                ]


                var _company = JSON.parse(localStorage.getItem("company"));
                if (_company.accountantEmail) {
                    _contacts.push({
                        value: _company.accountantEmail,
                        name: t("companyAccountant"),
                        email: _company.accountantEmail,
                        label: _company.accountantEmail
                    })
                }



                var opts = { data: _contacts, columns: columns };

                props.colDef.cellRendererParams.setShowEmailModal(true);
                props.colDef.cellRendererParams.setCustomerContacts(opts);
                props.colDef.cellRendererParams.setLineData(props.data);

            },
            error => {
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString())
            }
        );
    }

    const hangleEdit = () => {
        history.push("/sale/" + props.data.id + "?presale=true");
    }


    useEffect(() => {
        // 
        if (props.node.data != null) {

            const actionel = <div>

                <Menu menuButton={<MenuButton><i className="fa fa-bars"></i></MenuButton>} transition>
                    <MenuItem onClick={handleInputChange} ><i className="fa fa-print"></i>{t("printMenu")}</MenuItem>
                    <MenuItem onClick={hangleEdit} ><i className="fa fa-edit"></i>{t("publishMenu")}</MenuItem>
                    <MenuItem onClick={showEmailModal} ><i className="fa fa-envelope"></i>{t("sendEmailMenu")}</MenuItem>
                    <MenuItem onClick={handleCancel}>
                        <i className="fa fa-times"></i>{t("deletePreSaleMenu")}
                    </MenuItem>
                </Menu>
            </div>
            setActionElement(actionel)
        }



    }, [props.node])

    return (

        <span>  {actionElement}</span>
    );
});


export default function PreSaleSearch() {

    const history = useHistory();
    const [gridApi, setGridApi] = useState(null);
    const { t } = useTranslation();
    const [printSale, setPrintSale] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [fastFilter, setFastFilter] = useState("");
    const [blockuiLoading, setBlockuiLoading] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const { control, formState: { errors } } = useForm();
    const [customerContacts, setCustomerContacts] = useState([]);
    const [selectedCustomerContacts, setSelectedCustomerContacts] = useState([]);
    const [lineData, setLineData] = useState({});
    const iframePrintTemplate = useRef();
    var to = null;
    const pagination = true;
    const paginationPageSize = 20;
    const [sheight, setSheight] = useState(window.innerHeight - 190);
    const detectMobile = useMobileDetect();
    const [sendPaymentLink, setSendPaymentLink] = useState(false);
    const [template, setTemplate] = useState(false);
    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [focusedInput, setFocusedInput] = useState();

    const minSwipeDistance = 200

    const onTouchStart = (e) => {
        setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX)
    }

    const onTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientX)
    }

    const onTouchEnd = () => {
        var currentPage = gridApi.paginationGetCurrentPage();
        var lastpage = gridApi.paginationGetTotalPages();

        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance
        if (isLeftSwipe) {
            if (currentPage != lastpage) {
                gridApi.paginationGoToPage(currentPage + 1);
            }

        }
        else if (isRightSwipe) {
            if (currentPage != 0) {
                gridApi.paginationGoToPage(currentPage - 1);
            }

        }

    }

    const blockUi = (block) => {
        setBlockuiLoading(block)
    }

    var getPageObject = function () {
        var pageNo = gridApi.paginationGetCurrentPage();
        var sortBy = null;
        var order = "asc";
        var filterModel = gridApi.getFilterModel();
        gridApi.columnController.columnApi.getColumnState().map((v) => {
            if (v.sort != null) {
                sortBy = v.colId;
                order = v.sort
            }
        });


        var simpleColumnFilters = [];
        Object.keys(filterModel).forEach(k => {
            var obj = {
                column: k,
                filter: filterModel[k].filter,
                dateFrom: filterModel[k].dateFrom,
                dateTo: filterModel[k].dateTo,
                filterType: filterModel[k].filterType,
                searchOperator: filterModel[k].type
            }
            if (obj.column == "seriesNumber") {
                obj.column = "series";
                obj.concatWith = "invoiceNumber";
            }
            simpleColumnFilters.push(obj);
        })


        var object = {
            "quickSearch": fastFilter,
            "pageNo": pageNo,
            "sortBy": sortBy,
            "order": order,
            "pageSize": paginationPageSize,
            "simpleColumnFilters": simpleColumnFilters
        }
        return object;
    }

    const getRowData = (params) => {
        var companyId = JSON.parse(localStorage.getItem("company")).id;


        var object = getPageObject();


        ApiClient.getCompanyPreSaleInstancesPage(companyId, object).then(
            response => {

                var saleInstances = response.data.objectList;
                var newSaleInstances = [];
                saleInstances.forEach(element => {
                    var inst = element;
                    inst.seriesNumber = inst.series + inst.invoiceNumber;
                    newSaleInstances.push(inst);
                });

                params.successCallback(newSaleInstances, response.data.totalElements);
                if (newSaleInstances.length == 0) {
                    gridApi.showNoRowsOverlay();
                }
            },
            error => {
                console.error(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString())
            }
        );
    }


    useEffect(() => {
        if (gridApi) {
            const dataSource = {
                getRows: (params) => {
                    let mounted = true;
                    if (mounted) {
                        getRowData(params);
                    }
                    return () => mounted = false;
                }
            }
            let timer1 = setTimeout(function () {
                gridApi.setDatasource(dataSource);
            }, 1000);

            return () => {
                clearTimeout(timer1);
            };

        }
    }, [fastFilter]);


    useEffect(() => {
        if (gridApi) {
            const dataSource = {
                getRows: (params) => {
                    let mounted = true;
                    if (mounted) {
                        getRowData(params);
                    }
                    return () => mounted = false;
                }
            }

            gridApi.setDatasource(dataSource);
        }
    }, [gridApi]);

    const onGridReady = params => {
        setGridApi(params.api);
        // params.api.sizeColumnsToFit();
        window.addEventListener('resize', function () {
            setTimeout(function () {
                // params.api.sizeColumnsToFit();
                console.log(window.innerHeight - 190)
                setSheight(window.innerHeight - 190);
            }, 300);
        });

        // params.api.sizeColumnsToFit();
    };
    const onQuickFilterChanged = e => {
        console.log();
        gridApi.fastFilter = e.target.value;
        // gridApi.setQuickFilter(e.target.value);
        setFastFilter(e.target.value.trim());

    };
    const onClickPrint = data => {
        console.log(data)
        setPrintSale(data)
        iframePrintTemplate.current.print(data)
    }

    const goTo = invoiceUid => {
        history.push("/mydata/" + invoiceUid);
    }
    const onPaginationChanged = (val) => {
        var currentPage = val.api.paginationGetCurrentPage();
        setCurrentPage(currentPage)

    }
    const dateFormatter = (params) => {
        if (params.data != undefined) {
            var dateAsString = moment(params.data.invoiceDate).format('DD/MM/YYYY')
            return dateAsString
        }
        return ""
    }
    const onChangeContact = data => {
        setSelectedCustomerContacts(data);
    }
    const onInputChangeContact = (contact) => {

    }




    function getContactEmails(sale, sendPaymentLink) {
        var customerName = sale.customeName;
        var company = JSON.parse(localStorage.getItem("company"));
        var subject = t("emailSubject", { company: company.name });

        var contactEmails = [];
        selectedCustomerContacts.forEach(contact => {
            if (contact.email == undefined || contact.email == null) {
                contact.email = contact.value;
                contact.name = contact.value
            }
            var body = "<p>" + t("emailBody1", { contactName: contact.name }) + "</p>";
            body += "<p>" + t("emailBody2", { company: company.name }) + "</p>";
            var link = "<a href='https://app.timologic.gr/mydata/" + sale.invoiceUid + "'>" + sale.invoiceType + " " + sale.series + sale.invoiceNumber + "</a>";
            body += "<p>" + t("emailBody3", { link: link }) + "</p>";
            // body += "<p>" + t("emailBody4") + "</p>";
            var link2 = "<a href=" + company.vivaQuickPay + "/" + sale.totalPayment + ">" + t("here") + "</a>";
            if (sendPaymentLink && company.vivaQuickPay) {
                body += "<p>" + t("emailBody6", { link: link2 }) + "</p>";
            }
            // body += "<small>" + t("emailBody5") + "</small>";
            contactEmails.push({
                subject: subject,
                body: body,
                email: contact.email
            });
        })
        return contactEmails;

    }
    const getPrintSale = (sale) => {
        var saleClone = JSON.parse(JSON.stringify(sale));
        return iframePrintTemplate.current.getPrintSale2(saleClone)
    }
    const onSendEmail = () => {
        var contactEmails = getContactEmails(lineData, sendPaymentLink);
        document.getElementById("template").style.display = "unset";
        const ps = getPrintSale(lineData);

        setPrintSale(ps)
        setTimeout(function () {
            setBlockuiLoading(true)
            try {
                var store = false;
                var user=JSON.parse(localStorage.getItem("user"));
                if (lineData.myDataInvoiceType == "invoice1_1" || lineData.myDataInvoiceType == "invoice2_1") {
                    store = true;
                }
                iframePrintTemplate.current._sendEmail({ contacts: contactEmails, store: store, taxpayerId: lineData.company.taxpayerId , fromEmail: user.email }, function (resp) {
                    setBlockuiLoading(false)
                    document.getElementById("template").style.display = "none";
                    setShowEmailModal(false);
                    if (resp.data.status == "SUCCESS") {
                        toast.success(t("emailSended"));
                    }
                    else {
                        toast.error(t("emailNotSended"));
                    }
                });
            }
            catch (e) {
                console.error(e)
                setBlockuiLoading(false)
            }
        })

    }
    const exportToExcel = (e) => {
        e.preventDefault();
        var companyId = JSON.parse(localStorage.getItem("company")).id;
        setBlockuiLoading(true)
        var object = getPageObject();
        ApiClient.exportCompanySalesToExcel(companyId, object).then(
            response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'export.xlsx');
                document.body.appendChild(link);
                link.click();
                setBlockuiLoading(false)
            },
            error => {
                console.error(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString());
                setBlockuiLoading(false)
            }
        );
    }

    const getHeaderTemplate = () => {
        return <span><img style={{ width: 60 }} src={MyDataLogo}></img></span>
    }

    return (
        <BlockUi tag="div" blocking={blockuiLoading}>

            <div className="container-fluid" style={{ padding: 0 }}>
                <header className="jumbotron search_header">

                {/* <div> {t("PreSalesSearchInfo")}<span><a href="/sales">{t("PreSalesSearchInfo2")}</a></span></div> */}


                    <div className="d-flex bd-highlight">
                   
                        <div className="me-auto p-0 bd-highlight">
                            <h5 className="subtitle skin">{t("PreSalesSearchNav")}
                                <Tippy trigger="click" content={t("PreSalesSearchInfo")}>
                                    <i className="fa fa-info-circle" style={{ marginLeft: 6 }}></i>
                                </Tippy>
                            </h5>
                        </div>


                    </div>
                </header>
                <div style={{ width: '100%', height: '100%' }}>
                <div className="row">
                        <div className="d-flex bd-highlight">
                            <div className="p-1 flex-grow-1 bd-highlight searchSalesInputContainer">
                                <input style={{ maxWidth: 400 }} placeholder={t('Search')} className="form-control" id="searchSalesInput" onChange={onQuickFilterChanged} />
                            </div>
                            <div className="p-1 bd-highlight">


                                <div style={{ position: "absolute", top: 0, right: 0, marginRight: "1%" }}>

                                    <div className="d-flex" style={{ position: "relative" }}>
                                        <div className="p-1 bd-highlight">
                                            <button className="btn btn-skin btn-sm" href="" onClick={function () { return history.push("/sale"); }}>
                                                <span className="desktop-only">{t("PublicationNew")} </span> <i className="fa  fa-plus"></i>
                                            </button>
                                        </div>
                                        <div className="p-1 bd-highlight">
                                            <button className="btn btn-skin btn-sm" title={t("exportToExcel")} onClick={exportToExcel}> <i className="fa fa-file-excel-o"></i></button>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="selectInputContainer">
                                    <Select
                                        className="column-choicer"
                                        styles={sortByStyles }
                                        placeholder={t("sortBy")}
                                        defaultValue={[]}
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        onChange={(options) => {
                                            if (Array.isArray(options)) {
                                                setSelectedOptions(options.map((opt) => opt.value));
                                            }
                                        }}
                                        options={allOptions}
                                        components={{
                                            Option: InputOption
                                        }}
                                    />
                                </div> */}

                            </div>
                            <div className="p-0 bd-highlight mobile-only">
                                <DateRangePicker
                                    startDate={startDate}
                                    small={true}
                                    daySize={36}
                                    startDateId="your_unique_start_date_id"
                                    endDate={endDate}
                                    endDateId="your_unique_end_date_id"
                                    isOutsideRange={() => false}
                                    withPortal={true}
                                    withFullScreenPortal={true}
                                    onDatesChange={({ startDate, endDate }) => {
                                        setStartDate(startDate);
                                        setEndDate(endDate);

                                        if (startDate && endDate) {
                                            gridApi.setFilterModel({
                                                "invoiceDate": {
                                                    "dateFrom": moment(startDate).format('YYYY-MM-DD'),
                                                    "dateTo": moment(endDate).format('YYYY-MM-DD'),
                                                    "type": "inRange",
                                                    "filterType": "date"
                                                }
                                            });
                                        }
                                    }}
                                    focusedInput={focusedInput}
                                    orientation="vertical"
                                    onFocusChange={(focusedInput) => {
                                        setFocusedInput(focusedInput);

                                    }
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    {
                        detectMobile.isDesktop() ?
                    <div
                        id="myGrid"
                        style={{ height: '100%', width: '100%' }}
                        className="ag-theme-alpine-dark sales-table-container"
                    >
                        <div className="ag-theme-alpine" style={{ height: sheight, width: '100%' }}>
                            <AgGridReact
                                pagination={pagination}
                                paginationPageSize={paginationPageSize}
                                onGridReady={onGridReady}
                                // domLayout={'autoHeight'}
                                style={{ width: '100%', height: '100%' }}
                                // onRowDoubleClicked={(e) => goTo(e.data.invoiceUid)}
                                // rowData={content}
                                // domLayout={'autoHeight'}
                                animateRows={true}

                                suppressRowTransform={true}
                                frameworkComponents={{
                                    actionsRenderer: ActionsRenderer,
                                    seriesNumberRendener: SeriesNumberRendener,
                                    dateRendener: DateRendener,
                                    agDateInput: CustomDateComponent
                                }}
                                overlayNoRowsTemplate={
                                    '<span style="padding: 10px;  color:#013289">' + t('noEntries') + '</span>'
                                }
                                onPaginationChanged={onPaginationChanged}
                                rowModelType={'infinite'}
                                cacheBlockSize={paginationPageSize}
                                gridOptions={{ suppressMenuHide: true }}
                                defaultColDef={{
                                    flex: 1,
                                    resizable: true,
                                    sortable: true,
                                    wrapText: true,
                                    suppressMovable:true,
                                    autoHeight: true, icons: {
                                        menu: '<i style="color:white" class="fa fa-search"/>',
                                    },
                                }}
                            >
                                <AgGridColumn flex={2} minWidth={120} headerName={t("Number")} cellRenderer="seriesNumberRendener" sortable={true} filter="agTextColumnFilter" field="seriesNumber"
                                    cellRendererParams={
                                        {
                                            printSale: printSale,
                                            onClickPrint: onClickPrint,
                                            blockUi: blockUi,
                                            setShowEmailModal: setShowEmailModal,
                                            setCustomerContacts: setCustomerContacts,
                                            setLineData: setLineData,
                                            getPrintSale: getPrintSale,
                                            setTemplate: setTemplate
                                        }
                                    }
                                ></AgGridColumn>
                                <AgGridColumn flex={5} minWidth={220} headerName={t("InvoiceType")} sortable={true} filter="agTextColumnFilter" field="invoiceType"></AgGridColumn>
                                <AgGridColumn minWidth={150} valueFormatter={dateFormatter} flex={3} headerName={t('IssueDate')} sortable={true} filter="agDateColumnFilter" field="invoiceDate"  ></AgGridColumn>
                                <AgGridColumn flex={5} minWidth={250} headerName={t("Customer")} sortable={true} filter="agTextColumnFilter" field="customerName"></AgGridColumn>
                                <AgGridColumn flex={2} minWidth={150} headerName={t("taxpayerId")} sortable={true} filter="agTextColumnFilter" field="customerTaxPayerId"></AgGridColumn>
                                <AgGridColumn minWidth={100} valueFormatter={params => params.data != null ? params.data.totalWithoutVat.toFixed(2) : 0.00} flex={2} headerName={t("TotalWithoutVat")} sortable={true} filter="agNumberColumnFilter" field="totalWithoutVat"></AgGridColumn>
                                <AgGridColumn minWidth={100} valueFormatter={params => params.data != null ? params.data.totalWithVat.toFixed(2) : 0.00} flex={2} headerName={t("TotalWithVat")} sortable={true} filter="agNumberColumnFilter" field="totalWithVat"></AgGridColumn>
                                <AgGridColumn flex={1} minWidth={100} headerName={t("Actions")} cellRendererParams={
                                    {
                                        printSale: printSale,
                                        onClickPrint: onClickPrint,
                                        blockUi: blockUi,
                                        setShowEmailModal: setShowEmailModal,
                                        setCustomerContacts: setCustomerContacts,
                                        setLineData: setLineData,
                                        getPrintSale: getPrintSale,
                                        setTemplate: setTemplate
                                    }
                                } cellRenderer="actionsRenderer" field="action"></AgGridColumn>

                            </AgGridReact>
                        </div>
                        <IFramePrintTemplate template={template} ref={iframePrintTemplate} ></IFramePrintTemplate>
                    </div>:
                    
                    
                   
                    <div
                    id="myGrid"
                    onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}
                    style={{ height: '100%', width: '100%' }}
                    className="ag-theme-alpine-dark sales-mobile-table-container"
                >
                    <div className="ag-theme-alpine" style={{ height: sheight, width: '100%' }}>
                        <AgGridReact
                            pagination={pagination}
                            paginationPageSize={paginationPageSize}
                            onGridReady={onGridReady}
                            // domLayout={'autoHeight'}
                            style={{ width: '100%', height: '100%' }}
                            // onRowDoubleClicked={(e) => goTo(e.data.invoiceUid)}
                            // rowData={content}
                            // domLayout={'autoHeight'}
                            animateRows={true}
                            suppressCellSelection={true}
                            suppressRowTransform={true}
                            frameworkComponents={{
                                actionsRenderer: ActionsRenderer,
                                seriesNumberRendener: SeriesNumberRendener,
                                dateRendener: DateRendener,
                                agDateInput: CustomDateComponent,
                                lineInfoRenderer: LineInfoRenderer,
                                allFieldsRenderer: AllFieldsRenderer
                            }}
                            overlayNoRowsTemplate={
                                '<span style="padding: 10px;  color:#013289">' + t('noEntries') + '</span>'
                            }
                            overlayLoadingTemplate={
                                '<span className="ag-overlay-loading-center"><img src="loading.gif">' + t('aggridPageLoadMess') + '</span>'
                            }
                            rowHeight={86}
                            onPaginationChanged={onPaginationChanged}
                            rowModelType={'infinite'}
                            cacheBlockSize={paginationPageSize}
                            gridOptions={{ suppressMenuHide: true }}
                            tooltipHideDelay={0}
                            enableBrowserTooltips={false}
                            defaultColDef={{
                                sortable: true,
                                wrapText: false,
                                suppressHorizontalScroll: true,
                                suppressMovable: true,
                            }}
                        >
                            <AgGridColumn field="id" sort="desc" hide={true}></AgGridColumn>
                            <AgGridColumn
                                flex={5}
                                headerName={t("")}
                                sortable={true}
                                cellRenderer="allFieldsRenderer"
                                // tooltipComponent="aggridSalesTooltip"
                                tooltipField="invoiceType"
                                field="invoiceType">
                            </AgGridColumn>
                            <AgGridColumn hide={true} valueFormatter={dateFormatter}
                                headerName={t('DateTime')} sortable={true} filter="agDateColumnFilter" field="invoiceDate" filterParams={{ inRangeInclusive: true }}></AgGridColumn>

                            <AgGridColumn flex={0.5} headerName={t("")} cellRendererParams={
                                {
                                    printSale: printSale,
                                    onClickPrint: onClickPrint,
                                    blockUi: blockUi,
                                    setShowEmailModal: setShowEmailModal,
                                    setCustomerContacts: setCustomerContacts,
                                    setLineData: setLineData,
                                    getPrintSale: getPrintSale,
                                    setTemplate: setTemplate
                                }
                            } cellRenderer="actionsRenderer" field="action"></AgGridColumn>

                        </AgGridReact>
                    </div>
                    <IFramePrintTemplate style={{ width: 800, height: "297mm", left: 0, top: 0, position: "fixed", zIndex: -100 }} extracss={template.css} template={template} ref={iframePrintTemplate} ></IFramePrintTemplate>

                </div>

                            }
                </div>
            </div>
            <Modal
                aria-labelledby="contained-modal-title-vcenter"

                centered
                show={showEmailModal}
                dialogClassName="email-modal"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header >
                    <Modal.Title>{t("sendEmail")}</Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <span className="totalConstraintInfo"></span>
                    <div className="row">
                        <div className="col-md-12 selectInputContainer form-group">
                            <label className="control-label inputlabel">{t("customerContacts")}
                                <i data-tip data-for="ccInfo" className="fa fa-x2 fa-info-circle infoicon" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                                <ReactTooltip className="skin-tooltip" id="ccInfo" place="top" effect="solid">
                                    {t("ccInfo2")}
                                </ReactTooltip>
                            </label>

                            <Controller
                                render={({ field }) => (
                                    <SelectTable {...field}
                                        inputId="contacts-react-select"
                                        onChange={onChangeContact}
                                        onChangeValue={onChangeContact}
                                        onInputChange={onInputChangeContact}
                                        isMulti={true}
                                        options={customerContacts}
                                        createTable={true}
                                        className={`form-control ${errors.customerContact ? 'is-invalid' : ''}`}
                                        placeholder={t('Select')}
                                        width={500}
                                    >

                                    </SelectTable>
                                )}
                                control={control}
                                name="customerContacts"
                                defaultValue=""
                            />
                            <br></br>
                            <input type="checkbox" onChange={e => setSendPaymentLink(e.target.checked)} name="sendPaymentLink" checked={sendPaymentLink} className="form-check-input" />{t("Send Payment link")}

                            <div className="invalid-feedback">{errors.customerContact?.message}</div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-skin" onClick={function () { setShowEmailModal(false) }}>{t("cancel")}</button>
                    <button id="onExtras" className="btn btn-skin" onClick={onSendEmail}>{t("send")}</button>
                </Modal.Footer>
            </Modal>


        </BlockUi>
    );
}


