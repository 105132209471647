import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from "react";
import TemplateComponent from './templates/template.component';
import Template2xA5Component from './templates/template-2xa5.component';
import ThermalPrinterComponent from './templates/therm-printer-template.component';
import GlamourTemplateComponent from './templates/template-glamour.component';

import moment from 'moment'
import { useTranslation } from 'react-i18next';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ApiClient from "../services/api-client"
import { IFrame } from './iframe-component';
import Commons from '../util/commons';


const IframePrintTemplate = forwardRef((props, ref) => {

    const { i18n, t } = useTranslation();
    const templateRef = useRef();
    const [printSale, setPrintSale] = useState("");
    let agent = navigator.userAgent;
    const _print = () => {
        if (agent.includes("ANDROID_KEY")) {
            console.log("We are on Android")
            document.getElementById("template").contentWindow.print();
            var cw = document.getElementById("template").contentWindow;
            setTimeout(function () {
                window.Android.runPrintJob(cw.document.documentElement.innerHTML);
            }, 2000)
        }
        else if (agent.includes("APPLE_KEY")) {
            console.log("We are on IOS")
            document.getElementById("template").contentWindow.print();
            var cw = document.getElementById("template").contentWindow;
            setTimeout(function () {
                window.Apple.runPrintJob(cw.document.documentElement.innerHTML);
            }, 2000)
        }
        else {
            document.getElementById("template").contentWindow.print();
        }
    }

    const sendInvoiceEmailWithAttachment = (file, contacts, callback) => {
        let formData = new FormData();
        formData.append("file", file);
        formData.append("contacts", JSON.stringify(contacts));

        ApiClient.sendInvoiceEmailWithAttachment(formData).then(
            response => {
                callback(response);
            },
            error => {
                console.error(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString())
            }
        );
    }


    function toDataUrl(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    const getBase64FromUrl = async (url) => {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;
                resolve(base64data);
            }
        });
    }
    useEffect(() => {

        return
    }, [printSale]);

    function getTaxByType(saleInstanceMoreTaxes, taxType) {
        if (saleInstanceMoreTaxes) {
            var item = saleInstanceMoreTaxes.filter(obj => {
                if(obj.deduction && obj.deduction.type){
                    obj.myDataMoreTax = obj.deduction;
                }
                if(obj.amount ){
                    obj.myDataMoreTax.amount=obj.amount ;
                }
                return obj.myDataMoreTax.type === taxType
            })

            // var myDataMoreTaxStr="";
            // item.forEach(t=>{
            //     myDataMoreTaxStr+=t.myDataMoreTax+" ,"
            // })
            // myDataMoreTaxStr =myDataMoreTaxStr.replace(/,\s*$/, "");


            return item && item.length?item[0].myDataMoreTax:null
        }
    }

    useImperativeHandle(ref, () => ({
        getPrintSale(sale) {
            var vatExceptions = [];
            sale.entries.forEach(e => {
                if (e.vatCategoryException) {
                    vatExceptions.push(e.vatCategoryException.value);
                }
            });

            var withHoldTax=getTaxByType(sale.saleInstanceMoreTaxes,"WITHHOLD_TAXES");
            var otherTax=getTaxByType(sale.saleInstanceMoreTaxes,"OTHER_TAXES");

            if(sale.otherTaxCategory){
                otherTax=sale.otherTaxCategory
            }

            var stumpOfDutyTax=getTaxByType(sale.saleInstanceMoreTaxes,"STUMP_DUTY");

            var feesTax=getTaxByType(sale.saleInstanceMoreTaxes,"FEES");

            var deduction=getTaxByType(sale.saleInstanceMoreTaxes,"DEDUCTION");
           

            var vatExceptionsUnique = vatExceptions.filter((value, index, array) => array.indexOf(value) === index);

            var entries = []
            var printentries = [];
            var counter = 1;
            var countitems = 0;
            sale.entries.forEach(node => {
                console.log(node.product)
                if (node.quantity == "-") {
                    countitems += 1;
                }
                else {
                    countitems += parseFloat(node.quantity);
                }
                var printentry = {
                    id: counter++,
                    NAME_LIST: node.product.label,
                    QUANTITY_LIST: node.quantity,
                    MU_LIST: node.measurementUnit.value,
                    UP_LIST: Commons.formatNumber(node.unitPrice),
                    DISCOUNT: Commons.formatNumber(node.discount),
                    WHTAXES: node.taxes != undefined ? (node.taxes.withHoldTax != undefined ? node.taxes.withHoldTax.amountValue : "0.00") : "0.00",
                    VAT_LIST: node.vat.label,
                    PRICE_NOVAT_LIST: Commons.formatNumber(node.priceWithoutVat),
                    PRICE_LIST: Commons.formatNumber(node.priceWithVat),

                }
                entries.push(node);
                printentries.push(printentry);
            });
            var company = JSON.parse(localStorage.getItem("company"));
            var _ser_lbl = sale.series == null ? "" : sale.series.label;

            var _city = sale.city ? sale.city : "";

            var _postalcode = sale.postalCode ? sale.postalCode : "";

            var _customerAddressNumber = sale.addressNumber ? sale.addressNumber : "";
            var _customerAddress = sale.fulladdress ? sale.fulladdress : "";


            var currency = sale.currency;
            var printSale = {
                TEMPLATE_LANG: sale.templateLang ? sale.templateLang.toLowerCase() : i18n.language,
                LOGO: sale.branch.model ? sale.branch.model.logo : null,
                ISSUER_DOY: company.taxOffice ? company.taxOffice.name : "",
                ISSUER_GEMH: company.gemh ? company.gemh : "",
                INVOICEMARK: sale.INVOICEMARK,
                INVOICEUID: sale.INVOICEUID,
                ISSUER_PHONE: sale.branch.model ? sale.branch.model.phone : null,
                ISSUER_EMAIL: sale.branch.model ? sale.branch.model.email : null,
                ISSUER_SUBJECTFIELD: sale.branch.model ? sale.branch.model.subjectField : null,
                ISSUER_NAME: company.name,
                ISSUER_VAT: company.taxpayerId,
                ISSUER_ADDRESS: sale.branch.model ? sale.branch.model.address : null,
                VEHICLE_NUMBER: sale.vehicleNumber,
                CP_NAME: sale.customer ? sale.customer.label : "",
                CP_CODE: sale.customer ? (sale.customer.model != undefined ? sale.customer.model.code : "") : "",
                INVOICE_TYPE: sale.invoiceType.label,
                INVOICE_NUMBER: _ser_lbl + " " +sale.invoiceNumber,
                MYDATA_INVOICE_TYPE: sale.invoiceType.myDataInvoiceType,
                CP_VAT: sale.taxpayerId,
                INVOICE_DATE: moment(sale.invoiceDate).format('DD/MM/YYYY'),
                INVOICE_TIME: moment(sale.invoiceDate).format('LTS'),
                INVOICE_ENTRIES: printentries,
                TOTALNODISCOUNT: Commons.formatNumber(parseFloat(sale.totals.totalWithoutDiscount)),
                TOTALWITHOUTVAT: Commons.formatNumber(parseFloat(sale.totals.totalWithoutVat)),
                TOTALVAT: Commons.formatNumber(parseFloat(sale.totals.totalWithVat - sale.totals.totalWithoutVat).toFixed(2)),
                TOTALWITHVAT: Commons.formatNumber(sale.totals.totalWithVat),
                TOTALPAYMENT: Commons.formatNumberEuro(sale.totals.totalPayment),
                TOTALDISCOUNT: Commons.formatNumber(sale.totals.totalDiscount),
                TOTALTAXES: Commons.formatNumber(parseFloat(sale.totals.totalPayment - sale.totals.totalWithVat).toFixed(2)),
                CP_ADDRESS: _customerAddress + " " + _customerAddressNumber,
                CP_CITY: _city + " " + _postalcode,
                TOTALCOUNTITEMS: countitems,
                CP_PAYTYPE: sale.paymentType.value,
                CP_TAXOFFICE: sale.taxOffice ? sale.taxOffice.label : "",
                NOTES: sale.notes,
                SEND_PLACE: sale.sendPlace ? sale.sendPlace : t("ourPlace"),
                CP_SUBJECT_FIELD: sale.subjectField,
                TOTALOTHERTAXES: Commons.formatNumber(parseFloat(sale.totals.totalOtherTaxes)),
                TOTALWHTAXES: sale.totals.totalDeductionsTaxes?Commons.formatNumber(parseFloat(sale.totals.totalWithHoldTaxes-sale.totals.totalDeductionsTaxes)):Commons.formatNumber(parseFloat(sale.totals.totalWithHoldTaxes)),
                TOTALFEESTAXES: Commons.formatNumber(parseFloat(sale.totals.totalFeesTaxes)),
                TOTALSTUMPOFDUTYTAXES: Commons.formatNumber(parseFloat(sale.totals.totalStumpOfDutyTaxes)),
                TOTALDEDUCTIONTAXES: Commons.formatNumber(parseFloat(sale.totals.totalDeductionsTaxes?sale.totals.totalDeductionsTaxes:0)),
                CURRENCY: currency ? currency.value : "",
                TOTALPAYMENT_ON_CURRENCY: currency ? Commons.formatNumber(parseFloat(sale.totals.totalPayment * currency.rate).toFixed(2)) : "",
                SENDER_NAME: sale.senderName ? sale.senderName : "",
                SENDER_TAXPAYER_ID: sale.senderTaxPayerId ? sale.senderTaxPayerId : "",
                RECIPIENT_NAME: sale.recipientName ? sale.recipientName : "",
                RECIPIENT_TAXPAYER_ID: sale.recipientTaxPayerId ? sale.recipientTaxPayerId : "",
                VAT_EXCEPTION_CATEGORIES: vatExceptionsUnique,
                CORRELATIVE_INVOICE: sale.correlativeSale ? sale.correlativeSale.mark : "",
                IBAN: sale.iban,
                MOREINFO: sale.moreInfo,
                QRURL: sale.qrUrl,
                COUNTRY: sale.country?sale.country.value:"",
                WITHHOLD_TAXES:withHoldTax?withHoldTax.value:null,
                OTHER_TAXES:otherTax?otherTax.value:null,
                STUMP_DUTY:stumpOfDutyTax?stumpOfDutyTax.value:null,
                FEES:feesTax?feesTax.value:null,
                DEDUCTION:deduction?deduction.label:null,
                MOVE_PURPOSE:sale.movePurpose?t(sale.movePurpose.value):null,
                DISPATCH_DATE:sale.dispatchDate?moment(sale.dispatchDate).format('DD/MM/YYYY HH:mm'):null,
                saleInstanceMoreTaxes:sale.saleInstanceMoreTaxes
            }

            entries.pop();
            sale.entries = entries;


            return printSale;
        },
        getPrintSale2(sale) {
            var vatExceptions = [];
            sale.saleEntries.forEach(e => {
                vatExceptions.push(e.vatCategoryException);
            });

            var withHoldTax=getTaxByType(sale.saleInstanceMoreTaxes,"WITHHOLD_TAXES");
            var otherTax=getTaxByType(sale.saleInstanceMoreTaxes,"OTHER_TAXES");
            if(sale.otherTaxCategory){
                otherTax=sale.otherTaxCategory
            }

            var stumpOfDutyTax=getTaxByType(sale.saleInstanceMoreTaxes,"STUMP_DUTY");
            var feesTax=getTaxByType(sale.saleInstanceMoreTaxes,"FEES");
            var deduction=getTaxByType(sale.saleInstanceMoreTaxes,"DEDUCTION");

            var vatExceptionsUnique = vatExceptions.filter((value, index, array) => array.indexOf(value) === index);

            var entries = []
            var printentries = [];
            var counter = 1;
            var countitems = 0;
            sale.saleEntries.forEach(node => {
                countitems += parseFloat(node.quantity);
                var printentry = {
                    id: node.id,
                    CODE: node.productCode,
                    NAME_LIST: node.product.name,
                    QUANTITY_LIST: node.quantity,
                    MU_LIST: t(node.measurementUnit),
                    UP_LIST: Commons.formatNumber(node.unitPrice),
                    DISCOUNT: Commons.formatNumber(node.discount),
                    WHTAXES: node.myDataWithHoldTaxes != undefined ? node.myDataWithHoldTaxes.amountValue : "0.00",
                    VAT_LIST: node.vat.value,
                    PRICE_NOVAT_LIST: Commons.formatNumber(node.priceWithoutVat),
                    PRICE_LIST: Commons.formatNumber(node.priceWithVat),
                }
                entries.push(node);
                printentries.push(printentry);
            });
            var company = sale.company;

            var mark = sale.mark;

            var _city = sale.customerCity ? sale.customerCity : "";
            var _postalcode = sale.customerPostalCode ? sale.customerPostalCode : "";

            var cancelmark = sale.cancellationMark;
            var currency = sale.currency;


            var _customerAddressNumber = sale.customerAddressNumber ? sale.customerAddressNumber : "";
            var _customerAddress = sale.customerAddress ? sale.customerAddress : "";

            debugger

            var printSale = {
                TEMPLATE_LANG: sale.templateLang ? sale.templateLang.toLowerCase() : i18n.language,
                LOGO: sale.logo,
                ISSUER_DOY: company.taxOffice ? company.taxOffice.name : "",
                ISSUER_GEMH: company.gemh ? company.gemh : "",
                ISSUER_NAME: sale.company.name,
                INVOICEMARK: mark,
                ISSUER_SUBJECTFIELD: sale.issuerSubjectField,
                ISSUER_EMAIL: sale.issuerEmail ? sale.issuerEmail : null,
                ISSUER_PHONE: sale.issuerPhone ? sale.issuerPhone : null,
                ISSUER_ADDRESS: sale.branchAddress,
                CANCELATIONMARK: cancelmark,
                INVOICEUID: sale.invoiceUid,
                VEHICLE_NUMBER: sale.vehicleNumber,
                ISSUER_VAT: sale.company.taxpayerId,
                CP_NAME: sale.customerName,
                CP_CODE: sale.customerCode,
                INVOICE_TYPE: sale.invoiceType,
                INVOICE_NUMBER: sale.series + " "+ sale.invoiceNumber,
                MYDATA_INVOICE_TYPE: sale.myDataInvoiceType,
                CP_VAT: sale.customerTaxPayerId,
                INVOICE_DATE: moment(sale.invoiceDate).format('DD/MM/YYYY'),
                INVOICE_TIME: moment(sale.invoiceDate).format('LTS'),
                INVOICE_ENTRIES: printentries,
                TOTALTAXES: Commons.formatNumber(parseFloat(sale.totalPayment - sale.totalWithVat).toFixed(2)),
                TOTALVAT: Commons.formatNumber(parseFloat(sale.totalWithVat - sale.totalWithoutVat).toFixed(2)),
                TOTALNODISCOUNT: Commons.formatNumber(sale.totalWithoutDiscount),
                TOTALWITHOUTVAT: Commons.formatNumber(sale.totalWithoutVat),
                TOTALWITHVAT: Commons.formatNumber(sale.totalWithVat),
                TOTALPAYMENT: Commons.formatNumberEuro(sale.totalPayment),
                TOTALDISCOUNT: Commons.formatNumber(sale.totalDiscount),
                CP_ADDRESS: _customerAddress + " " + _customerAddressNumber,
                CP_CITY: _city + " " + _postalcode,
                TOTALCOUNTITEMS: countitems,
                CP_PAYTYPE: sale.paymentType,
                CP_TAXOFFICE: sale.taxOfficeName,
                CP_SUBJECT_FIELD: sale.subjectField,
                NOTES: sale.notes,
                SEND_PLACE: sale.sendPlace ? sale.sendPlace : t("ourPlace"),
                TOTALOTHERTAXES: Commons.formatNumber(sale.totalOtherTaxes),
                TOTALWHTAXES: sale.totalDeductionsTaxes?Commons.formatNumber(sale.totalWithHoldTaxes-sale.totalDeductionsTaxes):Commons.formatNumber(sale.totalWithHoldTaxes),
                TOTALFEESTAXES: Commons.formatNumber(sale.totalFeesTaxes),
                TOTALSTUMPOFDUTYTAXES: Commons.formatNumber(sale.totalStumpOfDutyTaxes),
                TOTALDEDUCTIONTAXES: Commons.formatNumber(parseFloat(sale.totalDeductionsTaxes?sale.totalDeductionsTaxes:0)),
                CURRENCY: currency ? currency : "",
                TOTALPAYMENT_ON_CURRENCY: currency ? parseFloat(sale.totalPayment * sale.exchangeRate).toFixed(2) : "",
                SENDER_NAME: sale.senderName ? sale.senderName : "",
                SENDER_TAXPAYER_ID: sale.senderTaxPayerId ? sale.senderTaxPayerId : "",
                RECIPIENT_NAME: sale.recipientName ? sale.recipientName : "",
                RECIPIENT_TAXPAYER_ID: sale.recipientTaxPayerId ? sale.recipientTaxPayerId : "",
                VAT_EXCEPTION_CATEGORIES: vatExceptionsUnique,
                CORRELATIVE_INVOICE: sale.correlativeSaleInstance ? sale.correlativeSaleInstance.mark : "",
                IBAN: sale.iban,
                MOREINFO: sale.moreInfo,
                QRURL: sale.qrUrl,
                WITHHOLD_TAXES:withHoldTax?"TAX_"+withHoldTax.uid:null,
                OTHER_TAXES:otherTax?"TAX_"+otherTax.uid:null,
                STUMP_DUTY:stumpOfDutyTax?"TAX_"+stumpOfDutyTax.uid:null,
                FEES:feesTax?"TAX_"+feesTax.uid:null,
                DEDUCTION:deduction?deduction.name:null,
                MOVE_PURPOSE:sale.movePurpose?t(sale.movePurpose):null,
                DISPATCH_DATE:sale.dispatchDate?moment(sale.dispatchDate).format('DD/MM/YYYY HH:mm'):null,
                COUNTRY: sale.customerCountry?sale.customerCountry.alpha2:"",
                saleInstanceMoreTaxes:sale.saleInstanceMoreTaxes


            }
            entries.pop();
            sale.entries = entries;
            return printSale;


        },
        getPrintDesign() {



            var printentries = [];
            var counter = 1;
            var countitems = 0;
            var printentry = {
                id: counter++,
                CODE: "123",
                NAME_LIST: "Test Product",
                QUANTITY_LIST: 1,
                MU_LIST: "L",
                UP_LIST: "1.00",
                DISCOUNT: "0.00",
                WHTAXES: "0.00",
                VAT_LIST: 24,
                PRICE_NOVAT_LIST: "1.00",
                PRICE_LIST: "1.24",
            }
            printentries.push(printentry);

            var _comp = JSON.parse(localStorage.getItem("company"));

            var printSale = {
                LOGO: "",
                ISSUER_DOY: _comp.taxpayerId,
                ISSUER_GEMH: _comp.gemh,
                ISSUER_NAME: _comp.name,
                INVOICEMARK: 400001885100498,
                ISSUER_SUBJECTFIELD: "subjectField",
                ISSUER_EMAIL: "email",
                ISSUER_PHONE: "phone",
                ISSUER_ADDRESS: "address",
                CANCELATIONMARK: null,
                INVOICEUID: "50a88a68-50ca-45a6-b82a-37621ebdb77b",
                ISSUER_VAT: _comp.taxpayerId,
                CP_NAME: t("TEST CUSTOMER"),
                CP_CODE: "TEST",
                INVOICE_TYPE: "TEST",
                INVOICE_NUMBER: "Α 1",
                CP_VAT: "000000000",
                INVOICE_DATE: "01/02/2021",
                INVOICE_TIME: moment("01/02/2021").format('LTS'),
                INVOICE_ENTRIES: printentries,
                TOTALTAXES: "0.00",
                TOTALVAT: "0.24",
                TOTALNODISCOUNT: "1.00",
                TOTALWITHOUTVAT: "1.00",
                TOTALWITHVAT: "1.24",
                TOTALPAYMENT: "1.24",
                TOTALDISCOUNT: "0.00",
                CP_ADDRESS: "TEST ADDRESS",
                CP_CITY: "TEST CITY",
                TOTALCOUNTITEMS: 1,
                CP_PAYTYPE: "TEST",
                CP_TAXOFFICE: "TEST",
                CP_SUBJECT_FIELD: "TEST SUBJECT",
                NOTES: "TEST",
                SEND_PLACE: t("ourPlace"),
                TOTALOTHERTAXES: "0.00",
                TOTALWHTAXES: "0.00",
                TOTALFEESTAXES: "0.00",
                TOTALSTUMPOFDUTYTAXES: "0.00",
                VAT_EXCEPTION_CATEGORIES: [t("VatExceptionCategory")],
                IBAN: "GR1234567891234566798",
                MOREINFO: "GR1234567891234566798",
                TEMPLATE_LANG:  i18n.language,
                COUNTRY:t("GR")
            }

            return printSale;


        },
        setPrintSale(psale) {
            setPrintSale(psale)
        },
        print(psale) {
            if (psale && psale != "") {
                setPrintSale(psale)
                setTimeout(function () {
                    _print();
                }, 1000);
            }
        },
        printFrame() {
            setTimeout(function () {
                _print();
            }, 1000);
        },
        saveAsPDF(psale) {
            if (psale && psale != "") {
                setPrintSale(psale)
                setTimeout(function () {

                    var t=document.getElementById('template');
                    var docbody = document.getElementById('template').contentWindow.document.body;
                    var dochead = document.getElementById('template').contentWindow.document.head;
                    dochead.innerHTML = '<link  type="text/css" rel="stylesheet" href="template.css"/>' + dochead.innerHTML;
                    html2canvas(docbody).then(canvas => {
                        const imgData = canvas.toDataURL('image/jpeg');
                        const pdf = new jsPDF('p', 'mm', [297, 210]);
                        pdf.addImage(imgData, 'JPEG', 0, 0, 210, 297);
                        var blob = pdf.output('blob');
                        var file = new File([blob], "name");
                        pdf.save("download.pdf");

                    });
                }, 1000);
            }
        },
        _sendEmailWithAttachement(contacts, callback) {
            if (contacts.length > 0) {
                var docbody = document.getElementById('template').contentWindow.document.body;
                var dochead = document.getElementById('template').contentWindow.document.head;
                dochead.innerHTML = '<link  type="text/css" rel="stylesheet" href="template.css"/>' + dochead.innerHTML;

                html2canvas(docbody).then(canvas => {
                    const imgData = canvas.toDataURL('image/jpeg');
                    const pdf = new jsPDF('p', 'mm', [297, 210]);
                    pdf.addImage(imgData, 'JPEG', 0, 0, 210, 297);
                    var blob = pdf.output('blob');
                    var file = new File([blob], "name");
                    sendInvoiceEmailWithAttachment(file, contacts, callback)
                    // pdf.save("download.pdf");

                });
            }
        },
        _sendEmail(contacts, callback) {
            ApiClient.sendInvoiceEmail(contacts).then(
                response => {
                    callback(response);
                },
                error => {
                    console.error(
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString())
                }
            );
        }

    }));

    const getStyle = () => {
        return props.style ? props.style : { height: 0, width: 0 }
    }


    const getMainStyle = () => {
        var str =
            `:root {
        --appcolor: `+ props.template.mainColor + `;
        --secondaryColor:`+ props.template.secondaryColor + `;
        --appfont:`+ props.template.appFont + `;
        }`;
        return str;
    }

    const getTemplateComponent = () => {

        if (props.template) {

            if (props.template.template == "2xa5_simple_template") {

                return <Template2xA5Component extracss={props.extracss} mainStyle={getMainStyle()} t={t} sale={printSale} />
            }
            else if (props.template.template == "thermal_printer_template") {

                return <ThermalPrinterComponent extracss={props.extracss} mainStyle={getMainStyle()} t={t} sale={printSale} />
            }
            else if (props.template.template == "glamour_template") {

                return <GlamourTemplateComponent extracss={props.extracss} mainStyle={getMainStyle()} t={t} sale={printSale} />
            }
            else {
                return <TemplateComponent extracss={props.extracss} mainStyle={getMainStyle()} t={t} sale={printSale} />
            }
        }
        else {
            // dochead.innerHTML = '<link  type="text/css" rel="stylesheet" href="../template.css"/><link href="https://fonts.googleapis.com/css?family=Open+Sans&family=Ubuntu&family=GFS+Neohellenic" rel="stylesheet" type="text/css"></link>' + dochead.innerHTML;
            return <TemplateComponent extracss={props.extracss} t={t} sale={printSale} />
        }
    }


    return (

        <IFrame id="template" ref={templateRef} style={getStyle()} >
            {getTemplateComponent()}
        </IFrame>
    );
});

export default IframePrintTemplate


