import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from "react";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import "react-datepicker/dist/react-datepicker.css";
import Select, { components } from 'react-select'
import { CustomConfirmAlert as confirmAlert } from '../custom-confirm-alert';
import 'font-awesome/css/font-awesome.min.css';
import Modal from 'react-bootstrap/Modal';
import SaleEntriesExtra from "./sale-entries-extras.component";
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import loadProductOptions from "../../services/loadProducts";
import { AsyncPaginate } from "react-select-async-paginate";
import Commons from '../../util/commons'


const ActionsRenderer = forwardRef((props, ref) => {

    const [disabled, setDisabled] = useState(false);

    const { t } = useTranslation();

    const handleRemoveRow = (e) => {
        e.preventDefault();
        confirmAlert({
            title: t("submitConfirm"),
            message: t("areYouSure"),
            buttons: [
                {
                    label: t('Yes'),
                    onClick: () => {
                        props.agGridReact.gridOptions.rowData.splice(props.rowIndex, 1)
                        props.agGridReact.api.setRowData(props.agGridReact.gridOptions.rowData)
                        props.colDef.cellRendererParams.onRemoveRow();
                    }
                },
                {
                    label: t('No'),
                    onClick: () => {
                    }
                }
            ]
        });
    }

    const handleShowExtra = (e) => {
        e.preventDefault();
        props.colDef.cellRendererParams.onShowExtra(props.rowIndex, props.node.data);
    }

    useImperativeHandle(ref, () => {
        var countDisplayedRows = props.agGridReact.api.getDisplayedRowCount() - 1;
        if (props.rowIndex === countDisplayedRows) {
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }
        return {
            refresh(params) {

            }
        };
    });
    return (
        <span>
            <div className="btn-group" role="group">
                <button onClick={handleShowExtra} disabled={disabled} className="btn btn-sm btn-skin action action-edit"><i className="fa fa-pencil"></i></button>
                <button onClick={handleRemoveRow} disabled={disabled} className="btn btn-sm btn-skin action action-remove"><i className="fa fa-times-circle"></i></button>
            </div>
        </span>
    );
});

let initRowData;

const intValRenderer = forwardRef((props, ref) => {


    const [val, setVal] = useState(props.value);

    useImperativeHandle(ref, () => {
        return {
            refresh(params) {
                var val = props.getValue();
                if (val !== undefined) {
                    if(val!="-"){
                        var _val = parseFloat(val.toString().replace(",", ".")).toFixed(2);
                        setVal(_val);
                        props.node.setDataValue(props.column.colId, _val);
                    }
                    else{
                        setVal(val);
                        props.node.setDataValue(props.column.colId, val);
                    }
                }

            }
        };
    });
    if (props.colDef.field === "quantity") {
        return <span className="editableCell">{val && val !== "" ? val : <div><span className="addtext"></span><div className="addtextafter"><i className="fa fa-edit"></i></div></div>}</span>

    }
    else {
        return <span className="editableCell">{val && val !== "" ? val : <div><span className="addtext"></span><div className="addtextafter"><i className="fa fa-edit"></i></div></div>}</span>

    }
});

const doubleValRenderer = forwardRef((props, ref) => {


    const [val, setVal] = useState(props.value);

    useImperativeHandle(ref, () => {
        return {
            refresh(params) {
                var val = props.getValue();
                if (val !== undefined) {
                    var _val;
                    if (props.colDef.cellRendererParams && props.colDef.cellRendererParams.precision) {
                        _val = parseFloat(val.replace(",", ".")).toFixed(props.colDef.cellRendererParams.precision);
                    }
                    else {
                        _val = parseFloat(val.replace(",", ".")).toFixed(2);
                    }

                    setVal(_val);
                    props.node.setDataValue(props.column.colId, _val);

                }

            }
        };
    });
    if (props.colDef.field === "unitPrice") {
        return <span className="editableCell">{val && val !== "" ? val : <div><span className="addtext"></span><div className="addtextafter"><i className="fa fa-edit"></i></div></div>}</span>

    }
    else if (props.colDef.field === "discount") {
        return <span className="editableCell">{val && val !== "" ? val : <div><span className="addtext"></span><div className="addtextafter"><i className="fa fa-edit"></i></div></div>}</span>

    }
    else {
        return <span className="editableCell">{val && val !== "" ? val : <div><span className="addtext"></span><div className="addtextafter"><i className="fa fa-edit"></i></div></div>}</span>

    }
});
const MURenderer = forwardRef((props, ref) => {
    const [mu, setMU] = useState(props.value);
    useImperativeHandle(ref, () => {
        return {
            refresh(params) {
                var val = props.getValue();
                if (val !== undefined) {

                    setMU(val);
                }

            }
        };
    });
    return (
        <span className="editableCell">{mu ? mu.label : <div><span className="addtext"></span><div className="addtextafter"><i className="fa fa-edit"></i></div></div>}</span>

    );
});
const MUEditor = forwardRef((props, ref) => {
    const [selectedVal, setSelectedVal] = useState(props.value);
    const [editing, setEditing] = useState(true);
    const refContainer = useRef(null);



    useEffect(() => {
        focus();
    }, []);

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return selectedVal ? selectedVal : props.value;
            },

            isPopup() {
                return true;
            }
        };
    });

    useEffect(() => {
        if (!editing) {
            props.api.stopEditing();
        }
    }, [editing]);

    const focus = () => {
        window.setTimeout(() => {
            let container = ReactDOM.findDOMNode(refContainer.current);
            if (container) {
                container.focus();
            }
        });
    };


    const onSelectChange = val => {
        setSelectedVal(val);
        setTimeout(function () {
            props.api.stopEditing();
            props.node.setDataValue("measurementUnit", val);

        })

    }
    const onMouseOver = e => {
        refContainer.current.setState({ isOpen: true })
        let container = ReactDOM.findDOMNode(e.target).parentNode.getElementsByTagName("input")[0];
        if (container !== undefined) {
            container.focus();
        }
    }


    return (<div style={{ width: props.column.actualWidth + 'px' }}>

        <span onMouseOver={onMouseOver} className="selectCell">
            <Select
                autosize={true}
                menuIsOpen={true}
                defaultValue={selectedVal}
                onChange={onSelectChange}
                onChangeValue={onSelectChange}
                options={props.colDef.options} ref={refContainer}>
            </Select>
        </span>
    </div>
    );
});

const VatRenderer = forwardRef((props, ref) => {
    const [vat, setVat] = useState(props.value);


    useImperativeHandle(ref, () => {
        return {
            refresh(params) {
                var val = props.getValue();
                if (val !== undefined) {
                    var rowNode = props.agGridReact.api.getRowNode(props.rowIndex);
                    setVat(val);
                }
            }
        };
    });
    return (
        <span className="editableCell">{vat ? vat.label : <div><span className="addtext"></span><div className="addtextafter"><i className="fa fa-edit"></i></div></div>}</span>
    );
});

const VatEditor = forwardRef((props, ref) => {
    const [selectedVal, setSelectedVal] = useState(props.value);
    const [editing, setEditing] = useState(true);
    const refContainer = useRef(null);
    const [editNew, setEditNew] = useState(false);


    useEffect(() => {
        focus();
    }, []);


    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return selectedVal != null ? selectedVal : props.value;
            },

            isPopup() {
                return true;
            }
        };
    });

    useEffect(() => {
        if (!editing) {
            props.api.stopEditing();
        }
    }, [editing]);

    const focus = () => {
        window.setTimeout(() => {
            let container = ReactDOM.findDOMNode(refContainer.current);
            if (container) {
                container.focus();
            }
        });
    };


    const onSelectChange = val => {
        setSelectedVal(val);
        setTimeout(function () {
            props.api.stopEditing();
            props.node.setDataValue("vat", val);

        })

    }
    const onMouseOver = e => {
        refContainer.current.setState({ isOpen: true })
        let container = ReactDOM.findDOMNode(e.target).parentNode.getElementsByTagName("input")[0];
        if (container !== undefined) {
            container.focus();
        }
    }


    return (<div style={{ width: props.column.actualWidth + 'px' }}>

        <span onMouseOver={onMouseOver} className="selectCell">
            <Select
                autosize={true}
                menuIsOpen={true}
                defaultValue={selectedVal}
                onChange={onSelectChange}
                onChangeValue={onSelectChange}
                options={props.colDef.options} ref={refContainer}>
            </Select>
        </span>
    </div>
    );
});

const ProductRenderer = forwardRef((props, ref) => {
    const { t } = useTranslation();

    function setRowNodeDataValue(rowNode, name, value) {
        if (rowNode.data[name] != value) {
            rowNode.setDataValue(name, value);
        }
    }

    const [product, setProduct] = useState(props.value);

    useImperativeHandle(ref, () => {
        return {
            refresh(params) {
                var val = props.getValue();

                if (val !== undefined) {
                    var rowNode = props.agGridReact.api.getRowNode(props.rowIndex);
                    setProduct(val);
                    setRowNodeDataValue(rowNode, "product", val)
                    if (val.model !== undefined) {

                        setRowNodeDataValue(rowNode, "unitPrice", parseFloat(val.model.price).toFixed(2))

                        if (Commons.isOnGroup(params.invoiceType.myDataInvoiceType, "noQuantity")) {
                            rowNode.setDataValue("quantity", "-");
                        }
                        else {
                            if (rowNode.data.quantity === undefined) {
                                rowNode.setDataValue("quantity", 1);
                            }
                        }

                        if (rowNode.data.discount === undefined) {
                            rowNode.setDataValue("discount", '0.00');
                        }

                        if (rowNode.data.vat === undefined) {
                            if (Commons.isOnGroup(params.invoiceType.myDataInvoiceType, "noVat")) {
                                rowNode.setDataValue("vat", { value: "VAT_EMPTY", label: "-" });
                            }
                            else if (Commons.isOnGroup(params.invoiceType.myDataInvoiceType, "zeroVat")) {
                                rowNode.setDataValue("vat", { value: "VAT_0", label: "0" });
                            }
                            else {
                                rowNode.setDataValue("vat", { value: val.model.vat.id, label: val.model.vat.value });
                            }
                        }
                        if (rowNode.data.measurementUnit === undefined) {
                            if (Commons.isOnGroup(params.invoiceType.myDataInvoiceType, "noMeasurementUnit")) {
                                rowNode.setDataValue("measurementUnit", { value: "-", label: "-" });
                            }
                            else {
                                rowNode.setDataValue("measurementUnit", { value: val.model.measurementUnit, label: t(val.model.measurementUnit) });
                            }
                        }
                        var _taxes = rowNode.data.taxes;
                        if (_taxes === undefined) {
                            var pmt = [];
                            if (val.model.productMoreTaxes) {
                                val.model.productMoreTaxes.forEach(element => {
                                    var amount = 0;

                                    if (element.deduction) {
                                        element.myDataMoreTax=element.deduction;
                                        if (element.myDataMoreTax.isPercent) {
                                            var rate = element.myDataMoreTax.amountValue / 100;
                                            var q = rowNode.data.quantity == "-" ? 1 : rowNode.data.quantity;
                                            amount = parseFloat(rowNode.data.unitPrice * q * rate).toFixed(2);
                                        }
                                    }

                                    if (element.amount && !isNaN(parseInt(element.amount))) {
                                        amount = element.amount
                                    }
                                    else if (element.myDataMoreTax.amountValue.includes("%")) {
                                        var rate = element.myDataMoreTax.amountValue.replace("%", "") / 100;
                                        var q = rowNode.data.quantity == "-" ? 1 : rowNode.data.quantity;
                                        amount = parseFloat(rowNode.data.unitPrice * q * rate).toFixed(2);
                                    }
                                    
                                    pmt.push({
                                        amount: amount,
                                        myDataMoreTax: {
                                            value: element.myDataMoreTax.type=="DEDUCTION"?element.myDataMoreTax.id:element.myDataMoreTax.uid,
                                            uid: element.myDataMoreTax.uid,
                                            label:element.myDataMoreTax.type=="DEDUCTION"? element.myDataMoreTax.name:t("TAX_" + element.myDataMoreTax.uid),
                                            type: element.myDataMoreTax.type,
                                            key: element.myDataMoreTax.key,
                                            amountValue: element.myDataMoreTax.amountValue,
                                            isSubtracted: element.myDataMoreTax.type=="DEDUCTION"?element.deduction.isSubtracted :null                                    },
                                        id: element.id,
                                        myDataMoreTaxes: props.colDef.cellRendererParams.myDataMoreTaxes,
                                        myDataMoreTaxesTypes: props.colDef.cellRendererParams.myDataMoreTaxesTypes,
                                        type: { label: t(element.myDataMoreTax.type), value: element.myDataMoreTax.type },
                                    });
                                })
                                rowNode.setDataValue("taxes", pmt);
                            }

                        }
                        // if (rowNode.data.withHoldTax === undefined) {
                        //     if (val.model.withHoldTax) {
                        //         var name = "WITHHOLD_TAX_" + val.model.withHoldTax.key;
                        //         _taxes.withHoldTax = { value: val.model.withHoldTax.key, label: t(name), amountValue: val.model.withHoldTax.amountValue.replace("%", "") }
                        //     }
                        // }
                        // if (rowNode.data.otherTax === undefined) {
                        //     if (val.model.otherTax) {
                        //         var name = "TAX_" + val.model.otherTax.key;
                        //         _taxes.otherTax = { value: val.model.otherTax.key, label: t(name), amountValue: val.model.otherTax.amountValue.replace("%", "") }
                        //     }
                        // }
                        if (rowNode.data.vatCategoryException === undefined) {
                            if (val.model.vatCategoryException) {
                                var name = val.model.vatCategoryException;
                                var _vatCategoryException = { value: val.model.vatCategoryException, label: t(name) }
                                rowNode.setDataValue("vatCategoryException", _vatCategoryException)
                            }
                        }
                        var vat = val.model.vat.value === "-" ? 0 :val.model.vat.value;
                        var unitPriceWithVat = parseFloat(1 + vat / 100).toFixed(2) * parseFloat(val.model.price).toFixed(2);
                        setRowNodeDataValue(rowNode, "unitPriceWithVat", unitPriceWithVat.toString());

                    }
                }
            }
        };
    });
    // var rowNode=props.agGridReact.api.getRowNode(props.rowIndex);
    // var oldval=rowNode.data.product!=undefined?rowNode.data.product.value:null;
    return (
        <span className="editableCell">{product != null && product.label != null ? product.label : <div><span className="addtext"></span><div className="addtextafter"><i className="fa fa-edit"></i></div></div>}</span>
    );
});

const ProductEditor = forwardRef((props, ref) => {


    const [selectedVal, setSelectedVal] = useState(props.value);
    const [editing, setEditing] = useState(true);
    const refContainer = useRef(null);
    const [editNew, setEditNew] = useState(false);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        focus();
    }, []);

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return selectedVal != null ? selectedVal : props.value;
            },

            isPopup() {
                return true;
            }
        };
    });

    useEffect(() => {
        if (!editing) {
            props.api.stopEditing();
        }
    }, [editing]);

    const focus = () => {
        window.setTimeout(() => {
            let container = ReactDOM.findDOMNode(refContainer.current);
            if (container) {
                container.focus();
            }
        });
    };


    const onSelectChange = (val,action) => {
        setSelectedVal(val);
        setTimeout(function () {
            props.api.stopEditing();
            props.node.setDataValue("product", val);
            if (!editNew) {
                props.node.setDataValue("product", val);
            }
        })
    }
    const onMouseOver = e => {
        let container = ReactDOM.findDOMNode(e.target).parentNode.getElementsByTagName("input")[0];
        if (container != undefined) {
            container.focus();
        }
    }
    const onInputChange = (val, action) => {
        if (val.length > 220) {
            toast.dismiss();
            if (!toast.isActive()) {
                toast.error(t("Error on length. Max length is 220 characters"))
            }

            return
        }
        if (val != "") {
            setEditNew(true);
        }

        if(action.action == "input-change"){
            var _pro=props.node.data.product;
            var _vat=props.node.data.vat;
            var vat= { id: "VAT_24", value: 24};
            var quantity=props.node.data.quantity;
            if(_vat){
                vat={
                    id:_vat.value,
                    value:_vat.label
                };
            }
            else if(_pro.model && _pro.model.vat){
                vat=_pro.model.vat;
            }

            var price=0;
            if(props.node.data.unitPrice){
                price=props.node.data.unitPrice;
            }
            else if(_pro.model && _pro.model.price){
                price=_pro.model.price;
            }


            var model={
                value: val,
                label: val,
                measurementUnit:_pro.model? (_pro.model.measurementUnit?_pro.model.measurementUnit: "PIECES"):  "PIECES",
                vat: vat,
                price: price,
            }



            setSelectedVal({
                value: val,
                label: val,
                model: model
            })
            if(props.node.data.quantity){
             setTimeout(function(){
                props.node.setDataValue("quantity", quantity);
             },300)

            }
        }
    }


    const loadProductsPageOptions = async (q, prevOptions, { page }) => {
        const { options, hasMore } = await loadProductOptions(q, page);
        return {
            options,
            hasMore,

            additional: {
                page: page + 1
            }
        };
    };
    const defaultAdditional = {
        page: 1
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.stopPropagation();
            props.api.stopEditing();
        }
    }
  

    return (<div style={{ width: props.column.actualWidth + 'px' }}>

        <span onMouseOver={onMouseOver}  ref={refContainer} className="selectCell">
            <AsyncPaginate
                inputId="product_select"
                autosize={true}
                onKeyDown={onKeyDown}
                defaultValue={selectedVal?(selectedVal.label ? selectedVal : null):null}
                menuIsOpen={true}
                onChange={onSelectChange}
                onChangeValue={onSelectChange}
                maxMenuHeight={200}
                onInputChange={onInputChange}
                placeholder={t('placeholderSelectCT')}
                inputValue={selectedVal?selectedVal.label:null}
                loadOptionsOnMenuOpen={true}
                additional={defaultAdditional}
                // isClearable={true}
                loadOptions={loadProductsPageOptions}
               
            >
            </AsyncPaginate>
        </span>
    </div>
    );
});

const SaleEntries = forwardRef((props, ref) => {


    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [showExtra, setShowExtra] = useState(false);
    const [extraRowIndex, setExtraRowIndex] = useState(false);
    const [extraRowData, setExtraRowData] = useState([]);
    const { getValues, formState: { errors } } = useForm();
    const [extraTaxes, setExtraTaxes] = useState([]);
    const [vatCategoryException, setVatCategoryException] = useState([]);


    const [hasError, setHasError] = useState(false);
    const refSalesExtraEntries = useRef();
    const [rowTotals, setRowTotals] = useState(0.00)
    const [rowTotalsConstraintMess, setRowTotalsConstraintMess] = useState("")
    const [rowTotalsMess, setRowTotalsMess] = useState("")
    const [rowTotalsConstraintDisplay, setRowTotalsConstraintDisplay] = useState("none")



    const { t } = useTranslation();


    const rowStyle = { background: 'white' };
    var TOTALS;


    const onGridReady = params => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        params.api.sizeColumnsToFit();
        window.addEventListener('resize', function () {
            setTimeout(function () {
                // params.api.sizeColumnsToFit();

            });
        });

        var data = params.api.gridOptionsWrapper.gridOptions.rowData;
        if (data != undefined) {
            data.push({ product: { value: undefined, label: undefined }, description: undefined, quantity: undefined, price: undefined })
            params.api.setRowData(data)
        }
        else {
            data = [];
            data.push({ product: { value: undefined, label: undefined }, description: undefined, quantity: undefined, price: undefined })
            params.api.setRowData(data)
        }



    };

    useImperativeHandle(ref, () => ({


        getData() {
         
            var rowData = [];
            gridApi.forEachNode(node => rowData.push(node.data));
            rowData.pop();

            var retdata = {
                entries: rowData,
            }
            return retdata;

        },
        setData(data) {
            var newdata = [];
            data.forEach(e => {
                newdata.push(e)
            })
            newdata.push({ product: { value: undefined, label: undefined }, quantity: undefined, unitPrice: undefined })
            gridApi.setRowData(newdata);
            gridApi.gridOptionsWrapper.gridOptions.rowData = newdata;
        },
        getNotes() {
            return getValues().notes;
        },
        clearData() {
            var data = [{ product: { value: undefined, label: undefined }, description: undefined, quantity: undefined, price: undefined, measurementUnit: undefined }];
            gridApi.setRowData(data)
            gridApi.gridOptionsWrapper.gridOptions.rowData = data;
            // gridApi.forEachNode(node => rowData.push(node.data));
            props.reCalculateTotals();
        },
        hasError() {
            return hasError;
        }

    }));



    const addRow = () => {

        var data = { product: { value: undefined, label: undefined }, description: undefined, quantity: undefined, price: undefined, measurementUnit: undefined };
        gridApi.gridOptionsWrapper.gridOptions.rowData.push(data)
        var countDisplayedRows = gridApi.getDisplayedRowCount() - 1;
        gridApi.setRowData(gridApi.gridOptionsWrapper.gridOptions.rowData)

        // gridApi.applyTransaction({add:[data]})        
        // var actions= document.getElementsByClassName("action");
        // Array.from(actions).forEach((el) => {
        //     el.disabled=false;
        // });

        gridApi.ensureIndexVisible(countDisplayedRows, 'top');
    }


    function setRowNodeDataValue(rowNode, name, value) {
        if (rowNode.data[name] != value) {
            rowNode.setDataValue(name, value);
        }
    }

    const onCellValueChanged = (params) => {

        setTimeout(function () {
            // params.node.rowIndex
            var nodeIndex = params.node.rowIndex;
            var rowNode = gridApi.getRowNode(nodeIndex);
            var countDisplayedRows = gridApi.getDisplayedRowCount() - 1;
            var data = params.node.data;

            if (nodeIndex == countDisplayedRows && params.newValue != undefined) {
                var colid = params.column.colId;
                switch (colid) {
                    case "product":
                    case "vat":
                    case "measurementUnit": {
                        if (params.newValue.value != undefined && params.newValue.value != "") {
                            addRow(nodeIndex);
                            // gridApi.redrawRows();
                        }
                        break;
                    }
                    default: {
                        addRow(nodeIndex);
                        // gridApi.redrawRows();
                        break;
                    }
                }
            }
            if (params.column != undefined) {
                var colid = params.column.colId;
                switch (colid) {
                    case "vat":
                    case "quantity":
                    case "discount":
                    case "unitPrice": {
                        var quantity = data.quantity;
                        if (data.quantity == "-") {
                            quantity = 1;
                        }


                        var vat = 0;
                        if (data.vat != undefined) {
                            vat = data.vat.label === "-" ? 0 : data.vat.label;
                        }
                        var discount = 0.0;
                        if (data.discount != undefined) {
                            discount = parseFloat(data.discount).toFixed(2);
                        }
                        var unitPriceWithVat = parseFloat(data.unitPrice) * (1 + vat / 100);


                        var priceWithoutVat = data.unitPrice * quantity;
                        priceWithoutVat -= discount * priceWithoutVat / 100

                        var priceWithVat = unitPriceWithVat * quantity;

                        unitPriceWithVat = parseFloat(unitPriceWithVat);


                        var vatamount = priceWithVat - priceWithoutVat;
                        vatamount = parseFloat(vatamount).toFixed(2)
                        priceWithVat = priceWithVat.toFixed(2);
                        priceWithoutVat = priceWithoutVat.toFixed(2);

                        priceWithVat -= discount * priceWithVat / 100
                        priceWithVat = parseFloat(priceWithVat).toFixed(2);


                        setRowNodeDataValue(rowNode, "priceWithoutVat", priceWithoutVat)
                        setRowNodeDataValue(rowNode, "priceWithVat", priceWithVat)
                        
                        if (colid == "unitPrice" || colid == "vat") {
                            var upwv1 = parseFloat(unitPriceWithVat).toFixed(4)
                            var upwv2 = parseFloat(data.unitPriceWithVat).toFixed(4)
                            if (upwv1 != upwv2) {
                                setRowNodeDataValue(rowNode, "unitPriceWithVat", unitPriceWithVat.toString())
                            }
                        }

                        if (data.extraLineFields) {
                            var new_extralineFields = getNewExtraLineFields(data)
                            rowNode.setDataValue("extraLineFields", new_extralineFields);
                            var extraRowTotals = getExtraRowTotals(data);
                            var q = data.quantity == "-" ? 1 : data.quantity;
                            var lineprice = data.unitPrice * q


                            var discount = 0.0;
                            if (data.discount != undefined) {
                                discount = parseFloat(data.discount).toFixed(2);
                            }

                            lineprice -= discount * lineprice / 100


                          
                            if (parseFloat(extraRowTotals).toFixed(2) != parseFloat(lineprice).toFixed(2)) {
                                if (!toast.isActive()) {
                                    toast.warn(t("classificationSumError"), {
                                        toastId: "customId"
                                    });
                                    setRowTotals(lineprice)
                                    toast.clearWaitingQueue();
                                }
                            }
                        }

                        break;
                    }
                    case "unitPriceWithVat": {
                        var vat = 0;
                        if (data.vat != undefined) {
                            vat = data.vat.label === "-" ? 0 : data.vat.label;
                        }
                        var quantity = data.quantity;
                        if (data.quantity == "-") {
                            quantity = 1;
                        }

                        var unitPrice = parseFloat(data.unitPriceWithVat) / (1 + 0.01 * vat);
                        unitPrice = parseFloat(unitPrice).toFixed(2);
                        var upwv2 = parseFloat(data.unitPrice).toFixed(2)
                        if (unitPrice != upwv2) {
                            setRowNodeDataValue(rowNode, "unitPrice", unitPrice.toString())

                        }
                        var priceWithVat = parseFloat(data.unitPriceWithVat * quantity).toFixed(2);

                        var discount = 0.0;
                        if (data.discount != undefined) {
                            discount = parseFloat(data.discount).toFixed(2);
                        }
                        priceWithVat -= discount * priceWithVat / 100
                        priceWithVat = parseFloat(priceWithVat).toFixed(2);
                        setRowNodeDataValue(rowNode, "priceWithVat", priceWithVat)
                        break;
                    }
                    case "product": {
                        if (data.product && data.product.model) {
                            if (data.product.model.productClassifications) {
                                var _classData = [];
                                data.product.model.productClassifications.forEach(e => {
                                    _classData.push(e.productClassification);
                                })
                                setExtraRowData({ extraLineFields: _classData });
                            }
                        }

                        break;
                    }
                }


            }
            setTimeout(function () {
                props.reCalculateTotals();
            });
        })
    }

    const getNewExtraLineFields = (data) => {
        var nf = {};
        var new_extralineFields = []
        data.extraLineFields.forEach(e => {
            var nfjson = JSON.stringify(e);
            nf = JSON.parse(nfjson);
            if (nf.isPercent) {
                if (data.unitPrice && data.quantity) {
                    var q = data.quantity == "-" ? 1 : data.quantity;
                    nf.amount = parseFloat(data.unitPrice * q * nf.percentAmount / 100).toFixed(2)
                    nf.amount -= nf.amount * data.discount / 100;
                    nf.amount = parseFloat(nf.amount).toFixed(2);
                }
            }
            else {
                if (e.percentAmount) {
                    nf.amount = parseFloat(nf.percentAmount).toFixed(2)
                    nf.amount -= nf.amount * data.discount / 100;
                    nf.amount = parseFloat(nf.amount).toFixed(2);
                }

            }

            new_extralineFields.push(nf);
        })
        return new_extralineFields;
    }

    const getExtraRowTotals = (data) => {
        var totalRowAmount = 0;
        data.extraLineFields.forEach(e => {
            var rowamount = 0;
            if (e.isPercent) {
                if (data.unitPrice && data.quantity) {
                    var q = data.quantity == "-" ? 1 : data.quantity;

                    var lineprice = parseFloat(data.unitPrice * q * e.percentAmount / 100).toFixed(2);
                    lineprice -= data.discount * lineprice / 100

                    rowamount = parseFloat(lineprice).toFixed(2)
                }
            }
            else {
                if (e.percentAmount) {
                    rowamount = parseFloat(e.percentAmount).toFixed(2)
                }
                else {
                    rowamount = parseFloat(e.amount).toFixed(2)
                }
            }
            totalRowAmount += parseFloat(rowamount);
        })
        return totalRowAmount;
    }
    const hangleShowExtra = (rowIndex, rowdata) => {
        setExtraRowIndex(rowIndex)


        var node = gridApi.getRowNode(rowIndex);
        var _quantity = node.data.quantity == "-" ? 1 : node.data.quantity;
        var amount = node.data.unitPrice * _quantity;


        var discount = 0.0;
        if (node.data.discount != undefined) {
            discount = parseFloat(node.data.discount).toFixed(2);
        }

        amount -= discount * amount / 100
        var _amount = parseFloat(amount).toFixed(2);
        var _totalsum = 0;
        if (rowdata.extraLineFields == undefined || rowdata.extraLineFields.length == 0) {

            if (rowdata.product.model.productClassifications && rowdata.product.model.productClassifications.length > 0) {
                var elf = [];
                rowdata.product.model.productClassifications.forEach(e => {
                    if (e.invoiceType.id == props.selectedInvoiceType.value) {
                        var types = e.classificationValues;
                        var opts = [];
                        types.forEach(element => {
                            opts.push({
                                value: element,
                                label: t(element) + " (" + element + ")",
                            });
                        });
                        var amount = node.data.unitPrice * _quantity;

                        if (e.isPercent) {
                            amount = parseFloat(amount * e.amount / 100).toFixed(2)
                        }
                        else {
                            amount = e.amount;
                        }
                        amount -= amount * node.data.discount / 100;
                        amount = parseFloat(amount).toFixed(2);
                        _totalsum += parseFloat(amount);
                        var prefix = e.classificationCategory.replace("CATEGORY_", "") + ") ";
                        var cl = {
                            classificationCategory: { value: e.classificationCategory, label: prefix + t(e.classificationCategory) },
                            classificationValue: { value: e.classificationValue, label: t(e.classificationValue) + " (" + e.classificationValue + ")" },
                            classificationValues: opts,
                            amount: amount,
                            isPercent: e.isPercent,
                            percentAmount: e.amount
                        }

                        elf.push(cl);
                    }

                })

                rowdata.extraLineFields = elf;

                if (elf.length == 0) {
                    rowdata = addDefaultClassifications(props, rowdata, rowIndex)
                }

            }
            else if (props.incomeClassCategories[0]) {
                rowdata = addDefaultClassifications(props, rowdata, rowIndex)
            }
        }
        else {
            if (rowdata.product.model.productClassifications && rowdata.product.model.productClassifications.length > 0) {
                rowdata.product.model.productClassifications.forEach(e => {
                    var amount = node.data.unitPrice * _quantity;
                    if (e.isPercent) {
                        amount = parseFloat(amount * e.amount / 100).toFixed(2)
                    }
                    else {
                        amount = e.amount;
                    }
                    _totalsum += parseFloat(amount);
                });
            }
        }


        setRowTotals(_amount.toString());

        if (_totalsum != _amount) {
            setRowTotalsConstraintDisplay("unset");
            setRowTotalsMess(<span>{t("linetotals") + ": " + _amount.toString()}</span>);
        }
        else {
            setRowTotalsConstraintDisplay("none");
            setRowTotalsMess(<span>{t("linetotals") + ": " + _amount.toString()}</span>);
        }

        if (rowdata) {
            setExtraRowData(rowdata.extraLineFields)
            let groupTaxes = props.moreTaxesOptions.reduce((r, a) => {
                r[a.type] = [...r[a.type] || [], a];
                return r;
            }, {});
            if (rowdata.taxes) {
                rowdata.taxes.forEach(e => {
                    if (e.myDataMoreTaxes == undefined || e.myDataMoreTaxes == null || Array.isArray(e.myDataMoreTaxes) && e.myDataMoreTaxes.length == 0) {
                        e.myDataMoreTaxes = groupTaxes[e.myDataMoreTax.type]
                    }
                })
            }


            setExtraTaxes(rowdata.taxes)
            setVatCategoryException(rowdata.vatCategoryException)
        }



        setShowExtra(true);
        var obj = {
            extraLineFields: rowdata ? rowdata.extraLineFields : null,
            taxes: rowdata ? rowdata.taxes : null,
            vatCategoryException: rowdata ? rowdata.vatCategoryException : null
        }

        initRowData = JSON.stringify(obj);

    }
    function addDefaultClassifications(props, rowdata, rowIndex) {

        if (props.invoiceClassCategories && props.invoiceClassCategories.length > 0) {

            var _extraLineFields = [];
            props.invoiceClassCategories.forEach(category => {
                var types = category.classificationValues;
                var opts = [];
                types.forEach(element => {
                    opts.push({
                        value: element,
                        label: t(element) + " (" + element + ")",
                    });
                });

                var label1 = "";
                var label2 = ""


                var node = gridApi.getRowNode(rowIndex);
                var q = node.data.quantity == "-" ? 1 : node.data.quantity;
                var _amount = 0;
                if (category.isPercent) {
                    _amount = node.data.unitPrice * q * category.amount / 100
                }
                else {
                    _amount = category.amount;
                }

                _amount -= _amount * node.data.discount / 100;
                _amount = parseFloat(_amount).toFixed(2)

                if (props.selfPricing == category.selfPricing) {
                    if (category.selfPricing) {
                        label1 = category.classificationCategory.replace("CATEGORY_", "") + ") " + t(category.classificationCategory);
                        label2 = t(category.classificationValue) + " (" + category.classificationValue + ")";
                        _extraLineFields.push({
                            classificationCategory: { value: category.classificationCategory, label: label1 },
                            classificationValue: { value: category.classificationValue, label: label2 },
                            amount: _amount,
                            isPercent: category.isPercent,
                            percentAmount: category.amount,
                            classificationValues: opts
                        })
                    }
                    else {
                        label1 = category.classificationCategory.replace("CATEGORY_", "") + ") " + t(category.classificationCategory);
                        label2 = t(category.classificationValue) + " (" + category.classificationValue + ")";
                        _extraLineFields.push({
                            classificationCategory: { value: category.classificationCategory, label: label1 },
                            classificationValue: { value: category.classificationValue, label: label2 },
                            amount: _amount,
                            isPercent: category.isPercent,
                            percentAmount: category.amount,
                            classificationValues: opts
                        })
                    }
                }



            })

            rowdata.extraLineFields = _extraLineFields;
            return rowdata;
        }
        else {
            if (props.incomeClassCategories[0]) {
                var types = props.incomeClassCategories[0].classificationValues;
                var opts = [];
                types.forEach(element => {
                    opts.push({
                        value: element,
                        label: t(element) + " (" + element + ")",
                    });
                });


                var node = gridApi.getRowNode(rowIndex);

                var label1 = props.incomeClassCategories[0].value.replace("CATEGORY_", "") + ") " + t(props.incomeClassCategories[0].value);
                var label2 = t(props.incomeClassCategories[0].classificationValues[0]) + " (" + props.incomeClassCategories[0].classificationValues[0] + ")";
                var q = node.data.quantity == "-" ? 1 : node.data.quantity;
                var _amount = q * node.data.unitPrice;
                _amount -= _amount * node.data.discount / 100;
                _amount = parseFloat(_amount).toFixed(2)
                rowdata.extraLineFields = [{
                    classificationCategory: { value: props.incomeClassCategories[0].value, label: label1 },
                    classificationValue: { value: props.incomeClassCategories[0].classificationValues[0], label: label2 },
                    amount: _amount,
                    classificationValues: opts,
                    isPercent: true,
                    percentAmount: 100,
                }];
                return rowdata;
            }
        }


    }
    const handleCloseExtra = (e, update) => {
        setShowExtra(false);
        var obj = JSON.parse(initRowData)
        if (update) {
            var _items = refSalesExtraEntries.current.getData()
            console.log(_items)
            var rowNode = gridApi.getRowNode(extraRowIndex);
            rowNode.setDataValue("extraLineFields", _items.saleEntryClassifications);
            var nodes = [];
            gridApi.forEachNode(node => {
                nodes.push(node.data);
            });
            rowNode.setDataValue("taxes", _items.taxes);
            rowNode.setDataValue("vatCategoryException", _items.vatCategoryException);
            props.reCalculateTotals();
        }
        else {
            var rowNode = gridApi.getRowNode(extraRowIndex);
            rowNode.setDataValue("extraLineFields", obj.extraLineFields);
            rowNode.setDataValue("taxes", obj.taxes);
            rowNode.setDataValue("vatCategoryException", obj.vatCategoryException);

        }

    }
    const getRowStyle = params => {


        if (params.node.rowIndex == params.api.rowModel.rowsToDisplay.length - 1) {
            return { background: '#d2f6fac7' };
        }
        else {
            if (!isValid(params.node.data)) {
                setHasError(true)
                return { background: '#ffb09c' };
            }
            else {
                setHasError(false)
                return { background: 'white' };
            }

        }
    };
    const onRemoveRow = () => {
        var rowData = [];
        if (gridApi != undefined) {
            gridApi.forEachNode(node => rowData.push(node.data));
            rowData.pop();
            var retdata = {
                entries: rowData,
                totals: TOTALS
            }

            props.reCalculateTotals();
            // props.onUpdateData(retdata)
        }
    }
    const suppressEnter = (params) => {
        var KEY_ENTER = 'Enter';
        var event = params.event;

        var key = event.key;
        var suppress = key === KEY_ENTER;
        return suppress;
    };

    const onCellEditingStarted = (e) => {
        if (e.colDef.field == "vat") {
            if (Commons.isOnGroup(props.selectedInvoiceType.myDataInvoiceType, "noVat") || Commons.isOnGroup(props.selectedInvoiceType.myDataInvoiceType, "zeroVat")) {
                e.colDef.editable = false;
                gridApi.stopEditing();
            }

        }
        else if (e.colDef.field == "quantity") {
            if (Commons.isOnGroup(props.selectedInvoiceType.myDataInvoiceType, "noQuantity")) {
                e.colDef.editable = false;
                gridApi.stopEditing();
            }
        }
        else if (e.colDef.field == "measurementUnit") {
            if (Commons.isOnGroup(props.selectedInvoiceType.myDataInvoiceType, "noMeasurementUnit")) {
                e.colDef.editable = false;
                gridApi.stopEditing();
            }
        }
    }
    return (
        <div className="col-md-12 product_entries_table_container">

            <div>
                <div className="ag-theme-alpine editableGrid sales-entries-table-container" style={{

                    width: '100%',
                }}>
                    <AgGridReact
                        onGridReady={onGridReady}
                        rowStyle={rowStyle}
                        getRowStyle={getRowStyle}
                        style={{ width: '100%', height: '300px' }}
                        onCellValueChanged={onCellValueChanged}
                        onCellEditingStarted={onCellEditingStarted}
                        stopEditingWhenCellsLoseFocus={true}
                        borders={true}
                        suppressRowTransform={true}
                        singleClickEdit={true}
                        skipHeaderOnAutoSize={true}
                        rowData={[]}
                        defaultColDef={{
                            flex: 1,
                            resizable: true,
                            wrapText: true,
                            autoHeight: true,
                            suppressMovable: true
                        }}
                        frameworkComponents={{
                            productRenderer: ProductRenderer,
                            productEditor: ProductEditor,
                            vatRenderer: VatRenderer,
                            vatEditor: VatEditor,
                            muRenderer: MURenderer,
                            muEditor: MUEditor,
                            intValRenderer: intValRenderer,
                            doubleValRenderer: doubleValRenderer,
                            actionsRenderer: ActionsRenderer,
                        }}
                    >

                        {/* suppressSizeToFit={true}  */}
                        <AgGridColumn hide={true} field="extraLineFields"></AgGridColumn>
                        <AgGridColumn hide={true} field="taxes"></AgGridColumn>
                        <AgGridColumn hide={true} field="vatCategoryException"></AgGridColumn>
                        <AgGridColumn flex={3} minWidth={200} headerClass="required" headerName={t("ProductService")} options={props.products}
                            cellRendererParams={{
                                invoiceType: props.selectedInvoiceType,
                                myDataMoreTaxesTypes: props.moreTaxesTypesOptions,
                                myDataMoreTaxes: props.moreTaxesOptions
                            }}
                            cellRenderer="productRenderer" cellEditor="productEditor" editable={true} field="product"
                            suppressKeyboardEvent={function (params) {
                                // params.event.preventDefault();
                                // params.event.stopImmediatePropagation();
                                return suppressEnter(params);
                            }}
                        ></AgGridColumn>
                        <AgGridColumn flex={0.5} minWidth={80} headerClass="required" headerName={t("Quantity")} editable={true} cellRenderer="intValRenderer" field="quantity"></AgGridColumn>
                        <AgGridColumn headerComponentParams={{ template: "<span><i class='fa fa-info-circle percentInfoIcon'></i>" + t("MUN") + "</span>" }} flex={0.5} minWidth={60} headerClass="required" headerTooltip={t("muInfo")} headerName={t("MUN")} options={props.measurementUnits} editable={true} cellEditor="muEditor" cellRenderer="muRenderer" field="measurementUnit"></AgGridColumn>
                        <AgGridColumn flex={0.5} minWidth={100} headerClass="required" headerName={t("unitPrice")} editable={true} cellRenderer="doubleValRenderer" field="unitPrice"></AgGridColumn>
                        <AgGridColumn headerComponentParams={{ template: "<span><i class='fa fa-info-circle percentInfoIcon'></i>" + t("Vat") + "</span>" }} flex={0.5} minWidth={60} headerClass="required" headerTooltip={t("vatInfo")} headerName={t("Vat")} options={props.vats} cellRendererParams={{ invoiceType: props.selectedInvoiceType }} cellEditor="vatEditor" cellRenderer="vatRenderer" editable={true} field="vat"></AgGridColumn>
                        <AgGridColumn flex={0.5} minWidth={100} headerClass="required" headerName={t("unitPriceWithVat")} editable={true} cellRendererParams={{ precision: 4 }} cellRenderer="doubleValRenderer" field="unitPriceWithVat"></AgGridColumn>
                        <AgGridColumn flex={0.5} minWidth={76} headerName={t("DiscountRate")} editable={true} cellRenderer="doubleValRenderer" field="discount"></AgGridColumn>
                        <AgGridColumn flex={0.5} minWidth={100} headerName={t("priceWithoutVat")} field="priceWithoutVat"></AgGridColumn>
                        <AgGridColumn flex={0.5} minWidth={90} headerName={t("priceWithVat")} cellStyle={{ backgroundColor: "LightBlue" }} field="priceWithVat"></AgGridColumn>
                        <AgGridColumn headerComponentParams={{ template: "<span><i class='fa fa-info-circle percentInfoIcon'></i>" + t("Actions") + "</span>" }} flex={0.5} minWidth={80} headerTooltip={t("actionInfo")} headerName={t("Actions")} cellRenderer="actionsRenderer" cellRendererParams={{ onRemoveRow: onRemoveRow, onShowExtra: hangleShowExtra }} field="name"></AgGridColumn>

                    </AgGridReact>
                </div>
            </div>
            <Modal
                aria-labelledby="contained-modal-title-vcenter"

                centered
                show={showExtra}
                dialogClassName="extras-modal"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header >
                    <Modal.Title>{t("extraLineInfo")}

                    </Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <span className="totalConstraintInfo">{rowTotalsMess}</span>
                    {props.isInvoice00_1 ? <></> :
                        <div className="row">
                            <div style={{ display: rowTotalsConstraintDisplay }} id="classificationSumError" className="alert alert-danger" role="alert">
                                {t("classificationSumError")}
                            </div>
                        </div>
                    }

                    <SaleEntriesExtra ref={refSalesExtraEntries}
                        optionVatExceptionCategories={props.vatCategoryExceptions}
                        otherTaxesOptions={props.otherTaxes}
                        withHoldTaxesOptions={props.withHoldTaxes}
                        extraRowData={extraRowData}
                        extraTaxes={extraTaxes}
                        vatCategoryException={vatCategoryException}
                        setRowTotalsMess={setRowTotalsMess}
                        setRowTotalsConstraintDisplay={setRowTotalsConstraintDisplay}
                        rowTotals={rowTotals}
                        incomeClassCategories={props.incomeClassCategories}
                        moreTaxesTypesOptions={props.moreTaxesTypesOptions}
                        moreTaxesOptions={props.moreTaxesOptions}
                        myDataInvoiceType={props.selectedInvoiceType ? props.selectedInvoiceType.myDataInvoiceType : null}
                        isInvoice00_1={props.isInvoice00_1}
                    >
                    </SaleEntriesExtra>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-skin" onClick={e => handleCloseExtra(e, false)}>{t("cancel")}</button>
                    <button id="onExtras" className="btn btn-skin" onClick={e => handleCloseExtra(e, true)}>{t("accept")}</button>
                </Modal.Footer>
            </Modal>


        </div>
    );
});

const isValid = (nodeData) => {
    return nodeData.unitPrice != undefined
        && nodeData.quantity != undefined
        && nodeData.product.value != undefined;
}

export default SaleEntries;