import React, { useState, useEffect, useRef } from "react";
import ApiClient from "../services/api-client"
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import IFramePrintTemplate from '../components/iframe-print-template.component';
import { ChromePicker } from "react-color-hook";
import BlockUi from 'react-block-ui';
import FormLayout from '../components/form-layout'
import { useForm } from "react-hook-form";
import { CustomConfirmAlert as confirmAlert } from '../components/custom-confirm-alert';
import { useHistory } from "react-router-dom"
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone-uploader'
import default_logo from '../img/default.png';
import ReactDOM from 'react-dom';
import AceEditor from "react-ace";

import Slider from 'rc-slider';


import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/ext-language_tools";
import reactCSS from 'reactcss'
import Tippy from '@tippyjs/react';


export default function InvoiceView(props) {

    const { t } = useTranslation();
    const iframePrintTemplate = useRef();
    const [extracss, setExtracss] = useState();
    const default_main_color = "#013289"
    const default_secondary_color = "#FFFFFF"
    const [template, setTemplate] = useState({});
    const { id } = useParams();
    const history = useHistory();

    const [blockuiLoading, setBlockuiLoading] = useState(false);
    const refDropZone = useRef();
    const [logoPath, setLogoPath] = useState();
    const [pathPreview, setPathPreview] = useState(default_logo);
    const [logoFile, setLogoFile] = useState();
    const [zoomValue, setZoomValue] = useState(100);
    const [displayMainColorPicker, setDisplayMainColorPicker] = useState(false);
    const [displaySecondaryColorPicker, setSecondaryMainColorPicker] = useState(false);



    const { register, handleSubmit, setValue, reset, methods, formState: { errors }, clearErrors } = useForm();



    useEffect(() => {
        
        var dummyPrintSale = iframePrintTemplate.current.getPrintDesign();


        setBlockuiLoading(true)

        var company = JSON.parse(localStorage.getItem("company"));


        var companyId = company.id;
        ApiClient.getTemplateSettings(companyId, id).then(
            response => {

                var _template = response.data;

                _template.template = _template.template ? _template.template : "simple_template";
                _template.mainColor = _template.mainColor ? _template.mainColor : default_main_color;
                _template.secondaryColor = _template.secondaryColor ? _template.secondaryColor : default_secondary_color;
                _template.appFont = _template.appFont ? _template.appFont : "Arial";

                setValue("template", _template.template)
                setValue("css", _template.css);
                setValue("appFont", _template.appFont);
                setValue("iban", _template.iban);
                setValue("moreInfo", _template.moreInfo);


                setTemplate(_template)
                if (_template.logo) {
                    setLogoPath(_template.logo + "?" + Math.random())
                    dummyPrintSale.LOGO = _template.logo + "?" + Math.random();
                }
                loadCss(_template.css)
                dummyPrintSale.IBAN= _template.iban;
                dummyPrintSale.MOREINFO= _template.moreInfo;


                iframePrintTemplate.current.setPrintSale(dummyPrintSale);
                setBlockuiLoading(false)

            },
            error => {
                setBlockuiLoading(false)
                console.error(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString())
            }
        );
    }, []);

    var timeout;

    const onCssInputChange = (val) => {
        setExtracss(val);
        setTemplate(template => ({
            ...template,
            ...{ css: val }
        }));
    }

    function loadCss(css) {

        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(function () {
            setExtracss(css)
        }, 1000)
    }

    const onChangeIbanHandler = (e) => {
        var val = e.target.value;
        setTemplate(template => ({
            ...template,
            ...{ iban: val }
        }));

    }

    const onChangeMoreInfoHandler = (e) => {
        var val = e.target.value;
        setTemplate(template => ({
            ...template,
            ...{ moreInfo: val }
        }));

    }
    
    const handleChangeMainComplete = (color) => {
        setTemplate(template => ({
            ...template,
            ...{ mainColor: color.hex }
        }));

    }
    const handleChangeSecondaryComplete = (color) => {
        setTemplate(template => ({
            ...template,
            ...{ secondaryColor: color.hex }
        }));

    }
    const onChangeTemplateHandler = (e) => {
        var val = e.target.value;
        setTemplate(temp => ({
            ...temp,
            ...{ template: val }
        }));
    }

    const onSubmit = (templateObj, e) => {
        e.preventDefault();

        document.getElementById("clearLogo").style.display = "unset"

        var company = JSON.parse(localStorage.getItem("company"));
        templateObj.branch = {
            id: id,

        }

        templateObj.logo =document.getElementById("preview").src;

        if (templateObj.logo) {
            templateObj.logo = templateObj.logo.split("?")[0]
        }
        if (templateObj.logo.includes("static/media/default") || templateObj.logo.includes("data:image/png;base64")) {
            templateObj.logo = null;
        }

        let formData = new FormData();
        var file = new File(["empty"], logoPath, {
            type: "text/plain",
        });
        var files = [];
        if (logoFile) {
            file = logoFile;
            files.push(file);
            formData.append("file", file, "name");
        }


        templateObj.mainColor = template.mainColor;
        templateObj.secondaryColor = template.secondaryColor;
        templateObj.appFont = template.appFont;
        templateObj.css = template.css;


        formData.append("template", JSON.stringify(templateObj));
        console.log(templateObj)

        confirmAlert({
            title: t("submitConfirm"),
            message: t("areYouSure"),
            buttons: [
                {
                    label: t('Yes'),
                    className: "accept",
                    onClick: () => ApiClient.saveCompanyTemplateAndUploadFiles(company.id, formData).then(
                        response => {

                            if (id === undefined) {
                                // e.target.reset();
                                // reset();

                                history.push("/")
                                history.push("/designer");
                            }
                            toast.success(t("saveSucceded"))

                        },
                        error => {
                            console.error(error.response);
                            toast.error("Error: " + error.response.data.message);
                        }
                    )
                },
                {
                    label: t('No')
                }
            ]
        });
    }
    const onDeleteClick = e => {
        var companyId = JSON.parse(localStorage.getItem("company")).id;
        e.preventDefault();
        confirmAlert({
            title: t("submitConfirm"),
            message: t("areYouSureToDelete"),
            buttons: [
                {
                    label: t('Yes'),
                    onClick: () => ApiClient.deleteCompanyTemplate(companyId, id).then(
                        response => {
                            history.push("/customers");
                            toast.success(t("deleteSucceded"))

                        },
                        error => {
                            toast.error("Error: " + error.data)
                            console.error(error.data)
                        }
                    )
                },
                {
                    label: t('No'),
                    onClick: () => { return }
                }
            ]
        });

    }
    var deleteBtn = "";
    if (id != undefined) {
        deleteBtn = <button onClick={onDeleteClick} className="btn btn-danger deletebtn">
            {t("Delete")}
        </button>
    }
    const onChangeFont = (e) => {
        var val = e.target.value;
        setTemplate(template => ({
            ...template,
            ...{ appFont: val }
        }));
    }
    const showDropZone = () => {
        // refDropZone.current.open()
        var _input = document.getElementsByClassName("dzu-input");
        if (_input.length > 0) {
            _input[0].click();
        }
    }
    const onMouseOver = e => {
        let container = ReactDOM.findDOMNode(e.target).parentNode.getElementsByTagName("input")[0];
        if (container != undefined) {
            container.focus();

        }
    }
    const handleChangeStatus = ({ meta, file }, status) => {

        if (status == "done") {
            setLogoFile(file);
            setLogoPath(meta.previewUrl);
            setPathPreview(meta.previewUrl);

            var dummyPrintSale = iframePrintTemplate.current.getPrintDesign();
            dummyPrintSale.LOGO = meta.previewUrl;
            iframePrintTemplate.current.setPrintSale(dummyPrintSale);

            document.getElementById("clearLogo").style.display = "unset"
        }
        else {
            document.getElementById("clearLogo").style.display = "none"
        }
    }
    const clearLogo = () => {
        setLogoFile(null);
        setLogoPath(null);
        setPathPreview(default_logo)
        document.getElementsByClassName("dzu-previewButton")[0].click();
    }



    const copyClipBoard = (e, elementId) => {
        var copyText = document.getElementById(elementId);
        navigator.clipboard.writeText(copyText.value);
    }

    // const st={
    //     "-ms-zoom": 0.75,
    //     "-moz-transform": scale(0.75),
    //     "-moz-transform-origin": "0 0",
    //     "-o-transform": scale(0.75),
    //     "-o-transform-origin": "0 0",
    //     "-webkit-transform": scale(0.75),
    //     "-webkit-transform-origin": "0 0"
    // }

    const hangleZoomChange = (val) => {
        setZoomValue(val);
        var el = document.getElementById('designer-frame-container');
        var h = (el.scrollHeight - el.clientHeight) / 2;
        var w = (el.scrollWidth - el.clientWidth) / 2;

        document.getElementById('designer-frame-container').scrollTop = h;
        document.getElementById('designer-frame-container').scrollLeft = w;
    }
    const styles = reactCSS({
        'default': {
            colorMain: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: template.mainColor,
            },
            colorSecondary: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: template.secondaryColor,
            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                position: 'absolute',
                zIndex: '2',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });

    const handleOpenMainColorPicker = () => {
        setDisplayMainColorPicker(true)
    }
    const handleCloseMainColorPicker = () => {
        setDisplayMainColorPicker(false)
    }
    const handleOpenSecondaryColorPicker = () => {
        setSecondaryMainColorPicker(true)
    }
    const handleCloseSecondaryColorPicker = () => {
        setSecondaryMainColorPicker(false)
    }

    return (
        <BlockUi tag="div" blocking={blockuiLoading}>
            <form autoComplete="off" id="customer_form" onSubmit={handleSubmit(onSubmit)}>
                <div className="container-fluid" style={{ paddingLeft: 0, paddingRight: 0 }}>

                    <div className="alert alert-primary small-info" style={{ padding: 4 }} role="alert">
                        {t("designerCssHelpInfo")}
                    </div>

                    <FormLayout title={t('templateAdd')}

                        headerRightContent={
                            <div className="offset-md-9 col-md-3 addmodelBtnContainer">
                                <a className="btn btn-skin header-btn" onClick={function () { history.push("/templates") }}>
                                    {t("templateSearch")}  <i className="fa fa-search"></i>
                                </a>
                            </div>
                        }
                        footer={
                            <div className="form-group text-center">
                                <button type="submit" className="btn btn-skin">
                                    {t("Save")}
                                </button>
                            </div>
                        }
                    >

                        <div className="row">
                            <div className="col-md-3">
                                <div className="card left-panel-designer-card" style={{ marginTop: 0, paddingLeft: 0, paddingRight: 0 }}>
                                    <div className="row">

                                        <label className="control-label inputlabel">{t('template')}</label>
                                        <div className="form-group">
                                            <div className="form-group required">
                                                <select
                                                    name="template"
                                                    type="text"
                                                    {...register('template')}
                                                    onChange={onChangeTemplateHandler}
                                                    className={`form-select`}
                                                >
                                                    <option value="simple_template">Simple Template</option>
                                                    <option value="2xa5_simple_template">2xA5 Simple Template</option>
                                                    <option value="thermal_printer_template">thermal_printer_template</option>
                                                            {/* <option value="glamour_template">glamour_template</option> */}

                                                </select>
                                            </div>

                                        </div>
                                        <div>
                                            <div className="col-md-12 form-group">
                                                <label className="control-label inputlabel">{t("companyLogo")}<span style={{ fontSize: 12 }}> ({t("suggestedDims")}: 340x60 px)</span></label>
                                                <div className="d-flex">
                                                    <img id="preview" className="img-thumbnail" style={{ height: 120 }}
                                                        src={pathPreview} onClick={showDropZone}
                                                        alt="new"></img>
                                                    <span id="clearLogo" style={{ display: "none" }} onClick={clearLogo}><i className="fa fa-times" ></i></span>
                                                </div>
                                            </div>
                                            <span onMouseOver={onMouseOver} style={{ display: "none" }} className="selectCell">
                                                <Dropzone
                                                    onChangeStatus={handleChangeStatus}
                                                    maxFiles={1}
                                                    multiple={false}
                                                    // canCancel={true}
                                                    // PreviewComponent={null}
                                                    // InputComponent={function(){
                                                    //     return <input class="dzu-input" type="file" accept="image/*"></input>
                                                    // }}
                                                    ref={refDropZone}
                                                    accept="image/*"
                                                    inputContent={(files, extra) => ('Click to upload logo')}
                                                    styles={{
                                                        dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
                                                        inputLabel: (files, extra) => ({ fontSize: 14 }),
                                                    }}
                                                />
                                            </span>
                                        </div>

                                        <div><br></br></div>
                                        <div className="row">

                                            <div className="d-flex bd-highlight">
                                                <div className="p-2 flex-fill bd-highlight">
                                                    <label className="control-label inputlabel">{t("mainColor")}</label>
                                                    <div style={styles.swatch} onClick={handleOpenMainColorPicker}>
                                                        <div style={styles.colorMain} />
                                                    </div>
                                                    {displayMainColorPicker ? <div style={styles.popover}>
                                                        <div style={styles.cover} onClick={handleCloseMainColorPicker} />
                                                        <ChromePicker color={template.mainColor} width="auto" onChangeComplete={handleChangeMainComplete} />
                                                    </div> : null}
                                                </div>
                                                <div className="p-2 flex-fill bd-highlight">
                                                    <label className="control-label inputlabel">{t("secondaryColor")}</label>
                                                    <div style={styles.swatch} onClick={handleOpenSecondaryColorPicker}>
                                                        <div style={styles.colorSecondary} />
                                                    </div>
                                                    {displaySecondaryColorPicker ? <div style={styles.popover}>
                                                        <div style={styles.cover} onClick={handleCloseSecondaryColorPicker} />
                                                        <ChromePicker color={template.secondaryColor} width="auto" onChangeComplete={handleChangeSecondaryComplete} />
                                                    </div> : null}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12" >
                                            <label className="control-label">{t('iban')}
                                                <Tippy trigger="click" content={t("iban")}>
                                                    <i className="fa fa-x2 fa-info-circle infoicon"></i>
                                                </Tippy>
                                            </label>

                                            <textarea
                                                name="iban"
                                                {...register('iban')}
                                                type="text"
                                                className="form-control"
                                                onChange={onChangeIbanHandler}
                                            />
                                        </div>

                                        <div className="col-md-12" >
                                            <label className="control-label">{t('moreInfo')}
                                                <Tippy trigger="click" content={t("moreInfo")}>
                                                    <i className="fa fa-x2 fa-info-circle infoicon"></i>
                                                </Tippy>
                                            </label>

                                            <textarea
                                                name="moreInfo"
                                                {...register('moreInfo')}
                                                type="text"
                                                rows={5}
                                                className="form-control"
                                                onChange={onChangeMoreInfoHandler}
                                            />
                                        </div>


                                        <div className="col-md-12" style={{display:"none"}}>
                                            <label className="control-label inputlabel">{t("fontStyle")}</label>
                                            <select className="form-select"
                                                name="appFont"
                                                {...register('appFont', { required: true })}
                                                onChange={onChangeFont}>
                                                <option value="Arial">Arial </option>
                                                <option value="'Open Sans'">Open Sans </option>
                                            </select>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 text-center">
                                <div className="text-center">
                                    <span><small>zoom</small></span>
                                    <div className="row" style={{ paddingLeft: 40, paddingRight: 40, paddingBottom: 10 }}>
                                        <div className="offset-md-3 col-md-6">
                                            <Slider
                                                min={40}
                                                max={100}
                                                value={zoomValue}
                                                onChange={hangleZoomChange}
                                                onAfterChange={hangleZoomChange}
                                            />
                                        </div>
                                    </div>


                                    {/* <ReactBootstrapSlider
                                        value={zoomValue}
                                        change={hangleZoomChange}
                                        slideStop={hangleZoomChange}
                                        step={5}
                                        max={100}
                                        min={40}
                                        orientation="horizontal" /> */}
                                </div>
                                <div id="designer-frame-container" className="text-center designer-frame-container" style={{ height: (zoomValue / 100) * 320 + 'mm' }}>
                                    <BlockUi tag="div" blocking={blockuiLoading}>
                                        <IFramePrintTemplate logoPath={logoPath} template={template} extracss={extracss} style={{ height: '297mm', width: '800px', transform: 'scale(' + (zoomValue / 100) + ')' }} ref={iframePrintTemplate} ></IFramePrintTemplate>
                                    </BlockUi>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card right-panel-designer-card" style={{ marginTop: 0, padding: 0 }}>


                                    <div className="col-md-12">
                                        <label className="control-label inputlabel">{t("cssInput")}</label>

                                        <AceEditor
                                            mode="css"
                                            name="css"
                                            onChange={onCssInputChange}
                                            fontSize={14}
                                            width="100%"
                                            showPrintMargin={true}
                                            showGutter={false}
                                            highlightActiveLine={true}
                                            value={template ? template.css : ""}
                                            setOptions={{
                                                enableBasicAutocompletion: false,
                                                enableLiveAutocompletion: false,
                                                enableSnippets: false,
                                                showLineNumbers: true,
                                                tabSize: 2,
                                            }} />

                                    </div>
                                            <hr></hr>
                                    <div className="col-md-12">
                                        <div className="alert alert-primary small-info" role="alert">
                                            {t("designerCssHelpInfo2")}
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <label className="control-label inputlabel">{t("snipnet3")}
                                            <a style={{ float: "right" }} href="#" onClick={(e) => copyClipBoard(e, "snipnet3")}><i className="fa fa-copy"></i></a>
                                        </label>

                                        <textarea id="snipnet3" rows="3" disabled className="form-control cssInput" defaultValue={
                                            `.cont_signs{
    display:none;
}`
                                        }></textarea>
                                    </div>

                                    <div className="col-md-12">
                                        <label className="control-label inputlabel">{t("snipnet1")}
                                            <a style={{ float: "right" }} href="#" onClick={(e) => copyClipBoard(e, "snipnet1")}><i className="fa fa-copy"></i></a>
                                        </label>
                                        <textarea id="snipnet1" rows="6" disabled className="form-control cssInput" defaultValue={
                                            `.background_img_container {
    background: url("image_url")
    no-repeat center center;
    background-size: contain;
    opacity: 0.1;
}`
                                        }></textarea>
                                    </div>
                                    <div className="col-md-12">
                                        <label className="control-label inputlabel">{t("snipnet2")}
                                            <a style={{ float: "right" }} href="#" onClick={(e) => copyClipBoard(e, "snipnet2")}><i className="fa fa-copy"></i></a>
                                        </label>
                                        <textarea id="snipnet2" rows="6" disabled className="form-control cssInput" defaultValue={
                                            `.description_head_td {
    text-indent: -9999px;
    line-height: 0;
}
.description_head_td::after {
    content: "ΥΠΗΡΕΣΙΑ";
    text-indent: 0;
    display: block;
    line-height: initial;
    height: 100%;
}`
                                        }></textarea>
                                    </div>


                                    <div className="col-md-12">
                                        <label className="control-label inputlabel">{t("snipnet4")}
                                            <a style={{ float: "right" }} href="#" onClick={(e) => copyClipBoard(e, "snipnet4")}><i className="fa fa-copy"></i></a>
                                        </label>
                                        <textarea id="snipnet4" rows="3" disabled className="form-control cssInput" defaultValue={
                                            `.product_table td:nth-child(3){
    display:none;
}
                                            `
                                        }></textarea>
                                    </div>
                                    <div className="col-md-12">
                                        <label className="control-label inputlabel">{t("snipnet5")}
                                            <a style={{ float: "right" }} href="#" onClick={(e) => copyClipBoard(e, "snipnet5")}><i className="fa fa-copy"></i></a>
                                        </label>
                                        <textarea id="snipnet5" rows="6" disabled className="form-control cssInput" defaultValue={
                                            `.invoice-box.gb .issuer_name {
    text-indent: -9999px;
    line-height: 0;
    visibility:hidden;
}
.invoice-box.gb .issuer_name::after {
    content: "PrintestoreEnglish";
    text-indent: 0;
    display: block;
    line-height: initial;
    height: 100%;
    visibility:visible;

}
`
                                        }></textarea>
                                    </div>

                                        <br></br>
                                    <div className="col-md-12">
                                        <iframe width="100%" height="300" src="https://www.youtube.com/embed/bJchYY08_RE" title="YouTube video player" frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; allowfullscreen"
                                            allowFullScreen="allowfullscreen"
                                            webkitallowfullscreen="webkitallowfullscreen">
                                        </iframe>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </FormLayout>

                </div>


            </form>
        </BlockUi>
    );
}
