import axios from 'axios';
import { authHeader } from '../util/AuthUtils';
import { API_BASE_URL } from '../util/constants';
import localforage from 'localforage'
import memoryDriver from 'localforage-memoryStorageDriver'
import { setup } from 'axios-cache-adapter'

const API_URL = API_BASE_URL + "/";

// Create `localforage` instance
const forageStore = localforage.createInstance({
  // List of drivers used
  driver: [
    localforage.INDEXEDDB,
    localforage.LOCALSTORAGE,
    memoryDriver._driver
  ],
  // Prefix all storage keys to prevent conflicts
  name: 'timologic-cache'
})

async function configure() {
  // Register the custom `memoryDriver` to `localforage`
  await localforage.defineDriver(memoryDriver)



  // Create `axios` instance with pre-configured `axios-cache-adapter` using a `localforage` store
  return setup({
    // `axios` options
    baseURL: API_URL,
    timeout: 0,
    // `axios-cache-adapter` options
    cache: {
      maxAge: 15 * 60 * 1000,
      store: forageStore, // Pass `localforage` store to `axios-cache-adapter`
      invalidate: async (config, request) => {
        if (request.clearCacheEntry) {
          console.error(request)
          var fullUrl = request.baseURL + request.url;
          await forageStore.removeItem(fullUrl)
        }
      }
    }
  })
}

const instance = axios.create({
  baseURL: API_URL,
  timeout: 0 //infinite
});


// Create `axios-cache-adapter` instance
// const cache = setupCache({
//   maxAge: 15 * 60 * 1000
// })

// const instance = axios.create({
//   baseURL: API_URL,
//   timeout: 0, //infinite
//   // adapter: cache.adapter
// });


/**
 * Catch the unauthorized Request
 */
// instance.interceptors.response.use((response) => response, (error) => {
//   if (error.response.status === 401) {
//     window.location = '/login';
//   }
//   // if (error.response.status === 403) {
//   //   window.location = '/error?code=403';
//   // }
// });
class ApiClient {


  //COMPANY
  //=========================================================
  getCompany(id) {
    return instance.get('company/' + id, { headers: authHeader() });
  }

  getCompanyAccountant(id) {
    return instance.get('company/accountant/' + id, { headers: authHeader() });
  }


  deleteCompany(id) {
    return instance.delete('company/' + id, { headers: authHeader() });
  }

  saveCompany(object) {
    return instance.post('company', object, { headers: authHeader() });
  }

  getCompanyPage(pageObject) {
    return instance.get('company/page?params=' + encodeURIComponent(JSON.stringify(pageObject)), { headers: authHeader() });
  }


  //COMPANY PRODUCT
  //=========================================================
  getCompanyProducts(companyId) {
    return instance.get('company/' + companyId + '/product', { headers: authHeader() });
  }

  getCompanyProductsPage(companyId, pageObject) {
    return instance.get('company/' + companyId + '/product/page?params=' + encodeURIComponent(JSON.stringify(pageObject)), { headers: authHeader() });
  }

  getCompanyProduct(companyId, productId) {
    return instance.get('company/' + companyId + '/product/' + productId, { headers: authHeader() });
  }

  deleteCompanyProduct(companyId, productId) {
    return instance.delete('company/' + companyId + "/product/" + productId, { headers: authHeader() });
  }

  saveCompanyProduct(companyId, object) {
    return instance.post('company/' + companyId + "/product/", object, { headers: authHeader() });
  }


  //COMPANY PRODUCT CATEGORY
  //=========================================================
  getCompanyProductCategory(companyId, categoryId) {
    return instance.get('company/' + companyId + '/product/category/' + categoryId, { headers: authHeader() });
  }

  getCompanyProductCategories(companyId) {
    return instance.get('company/' + companyId + '/product/category', { headers: authHeader() });
  }

  //COMPANY SALE
  //=========================================================
  getCompanySaleInstance(companyId, id) {
    return instance.get('company/' + companyId + '/sale/' + id, { headers: authHeader() });
  }

  getCompanySaleInstanceByMark(companyId, mark) {
    return instance.get('company/' + companyId + '/sale/byMark/' + mark, { headers: authHeader() });
  }

  getCompanySaleInstances(companyId) {
    return instance.get('company/' + companyId + '/saleinstance', { headers: authHeader() });
  }


  getCompanySaleInstancesPage(companyId, pageObject) {
    return instance.get('company/' + companyId + '/sale/page?params=' + encodeURIComponent(JSON.stringify(pageObject)), { headers: authHeader() });
  }

  getCompanyPreSaleInstancesPage(companyId, pageObject) {
    return instance.get('company/' + companyId + '/sale/pre/page?params=' + encodeURIComponent(JSON.stringify(pageObject)), { headers: authHeader() });
  }

  async saleinstanceByinvoiceTypePage(companyId, pageObject, mydataInvoiceType) {
    return await instance.get('company/' + companyId + '/sale/page?myDataInvoiceType=' + mydataInvoiceType + '&params=' + encodeURIComponent(JSON.stringify(pageObject)), { headers: authHeader() });
  }

  getCompanyAadeSaleInstancesPage(companyId, pageObject, asCounterpart = null) {
    if (asCounterpart) {
      return instance.get('company/' + companyId + '/sale/aade/page?asCounterpart=' + asCounterpart + '&params=' + encodeURIComponent(JSON.stringify(pageObject)), { headers: authHeader() });
    }
    return instance.get('company/' + companyId + '/sale/aade/page?params=' + encodeURIComponent(JSON.stringify(pageObject)), { headers: authHeader() });
  }


  getGroupedSaleInstancePage(companyId, pageObject) {
    return instance.get('company/' + companyId + '/sale/grouped?params=' + encodeURIComponent(JSON.stringify(pageObject)), { headers: authHeader() });
  }

  getCompanyIncomesPage(companyId, pageObject) {
    return instance.get('company/' + companyId + '/incomes/page?params=' + encodeURIComponent(JSON.stringify(pageObject)), { headers: authHeader() });
  }
  
  getTransmittedDoc(companyId,mark) {
      return instance.get('aade/transmittedDoc?companyId=' + companyId + '&mark='+mark, { headers: authHeader() });
  }
  
  synchronizeCompanyIncomes(companyId){
    return instance.post('company/' + companyId + '/incomes/synchronize',{}, { headers: authHeader() });
  }

  //COMPANY CUSTOMER
  //=========================================================

  getCompanyCustomers(companyId) {
    return instance.get('company/' + companyId + '/customer', { headers: authHeader() });
  }

  getCompanyCustomer(companyId, customerId) {
    return instance.get('company/' + companyId + '/customer/' + customerId, { headers: authHeader() });
  }

  getCompanyCustomersPage(companyId, pageObject,mydataInvoiceType) {
    return instance.get('company/' + companyId + '/customer/page?mydataInvoiceType='+mydataInvoiceType+'&params=' + encodeURIComponent(JSON.stringify(pageObject)), { headers: authHeader() });
  }


  getCompanyCustomersForInvoiceType(companyId, mydataInvoiceType) {
    return instance.get('company/' + companyId + '/customer?myDataInvoiceType=' + mydataInvoiceType, { headers: authHeader() });
  }

  getCompanyCustomerByTaxpayerId(companyId, taxpayerId, customerName) {
    return instance.get('company/' + companyId + '/customer?taxpayerId=' + taxpayerId + "&customerName=" + customerName, { headers: authHeader() });
  }

  deleteCompanyCustomer(companyId, customerId) {
    return instance.delete('company/' + companyId + "/customer/" + customerId, { headers: authHeader() });
  }

  saveCompanyCustomer(companyId, object) {
    return instance.post('company/' + companyId + "/customer", object, { headers: authHeader() });
  }


  //COMPANY CUSTOMER CONTACT
  //=========================================================
  findCompanyCustomerContacts(companyId, taxpayerId, customerName) {
    return instance.get('company/' + companyId + '/customer/contact?taxpayerId=' + taxpayerId + "&customerName=" + customerName, { headers: authHeader() });
  }

  //COMPANY BRANCHES
  //=========================================================
  getCompanyBranches(companyId) {
    return instance.get('company/' + companyId + '/branch', { headers: authHeader() });
  }
  getCompanyBranchesFull(companyId) {
    return instance.get('company/' + companyId + '/branch/templates', { headers: authHeader() });
  }

  deleteCompanyBranch(companyId, id) {
    return instance.delete('company/' + companyId + '/branch/' + id, { headers: authHeader() });
  }


  //COMPANY SERIES
  //=========================================================

  getCompanySeries(companyId) {
    return instance.get('company/' + companyId + '/series', { headers: authHeader() });
  }

  deleteCompanySeries(companyId, id) {
    return instance.delete('company/' + companyId + '/series/' + id, { headers: authHeader() });
  }


  getCompanyCustomerCategories(companyId) {
    return instance.get('company/' + companyId + '/customercategories', { headers: authHeader() });
  }
  getCompanyView(companyId) {
    return instance.get('company/' + companyId + '/view/company', { headers: authHeader() });
  }

  getCompanyDashboard(companyId) {
    return instance.get('company/' + companyId + '/view/dashboard', { headers: authHeader() });
  }





  //PRODUCTS
  //=========================================================

  getProductView(companyId) {
    return instance.get('company/' + companyId + '/view/product', { headers: authHeader() });
  }

  saveProduct(object) {
    return instance.post('product', object, { headers: authHeader() });
  }

  getProduct(id) {
    return instance.get('product/' + id, { headers: authHeader() });
  }

  getMeasurementUnits() {
    return instance.get('product/measurementunits', { headers: authHeader() });
  }

  deleteProduct(id) {
    return instance.delete('product/' + id, { headers: authHeader() });
  }
  getMaxProductCode(companyId, prefix) {
    return instance.get('product/maxcode/' + companyId + "/" + prefix, { headers: authHeader() });
  }



  //USERS
  //=========================================================

  getUsers() {
    return instance.get('user', { headers: authHeader() });
  }

  getUserTransactions() {
    return instance.get('user/transaction', { headers: authHeader() });
  }

  getUserCompanies() {
    return instance.get('user/company', { headers: authHeader() });
  }
  cancelSubscription() {
    return instance.post('user/cancelsubscription', {}, { headers: authHeader() });
  }
  cancelAccount() {
    return instance.post('user/cancelAccount', {}, { headers: authHeader() });
  }
  

  //CUSTOMERS
  //=========================================================


  getCustomerView(companyId) {
    return instance.get('company/' + companyId + '/view/customer', { headers: authHeader() });
  }

  deleteCustomerContact(companyId, id) {
    return instance.delete('company/' + companyId + '/customer/contact/' + id, { headers: authHeader() });
  }


  //COMPANY INVOICE TYPES
  //=========================================================

  getCompanyInvoiceType(companyId, id) {
    return instance.get('company/' + companyId + '/invoicetype/' + id, { headers: authHeader() });
  }


  getCompanyInvoiceTypes(companyId) {
    return instance.get('company/' + companyId + '/invoicetype', { headers: authHeader() });
  }

  //INVOICE TYPES
  //=========================================================

  saveInvoiceType(object) {
    return instance.post('invoicetype', object, { headers: authHeader() });
  }

  getInvoiceType(id) {
    return instance.get('invoicetype/' + id, { headers: authHeader() });
  }

  getMyDataInvoiceTypes() {
    return instance.get('invoicetype/mydataInvoiceTypes', { headers: authHeader() });
  }

  getInvoiceTypeSeries(invoiceTypeId,branchId=null) {
    if(branchId){
      return instance.get('invoicetype/' + invoiceTypeId + '/series?branchId='+branchId, { headers: authHeader() });
    }
    else{
      return instance.get('invoicetype/' + invoiceTypeId + '/series', { headers: authHeader() });
    }
  }


  getCompanyInvoiceTypeSeries(companyId,invoiceTypeId,branchId=null,isCredit=false) {
    if(branchId){
      return instance.get('company/' + companyId + '/invoicetype/' + invoiceTypeId + '/series?branchId='+branchId+'&isCredit='+isCredit, { headers: authHeader() }); 
    }
    else{
      return instance.get('company/' + companyId + '/invoicetype/' + invoiceTypeId + '/series?isCredit='+isCredit, { headers: authHeader() });
    }
  }

  getInvoiceTypeView(companyId) {
    return instance.get('company/' + companyId + '/view/invoicetype', { headers: authHeader() });
  }


  saveCompanyInvoiceType(companyId, object) {
    return instance.post('company/' + companyId + '/invoicetype', object, { headers: authHeader() });
  }

  deleteInvoiceType(id) {
    return instance.delete('invoicetype/' + id, { headers: authHeader() });
  }

  SaveBulkInvoiceTypeWithSeries(object) {
    return instance.post('invoicetype/bulkseries', object, { headers: authHeader() });
  }

  getClassificationCategoriesOfMyDataInvoice(invoiceTypeId, expenses) {
    return instance.get('invoicetype/' + invoiceTypeId + '/classificationCategories?expenses=' + expenses, { headers: authHeader() });
  }

  getIncomeClassificationCategoriesOfInvoiceType(invoiceTypeId, expenses) {
    return instance.get('invoicetype/' + invoiceTypeId + '/invoiceClassificationCategories?expenses=' + expenses, { headers: authHeader() });
  }


  deleteProductClassification(id) {
    return instance.delete('product/classification/' + id, { headers: authHeader() });
  }

  deleteProductCategoryClassification(id) {
    return instance.delete('productcategory/classification/' + id, { headers: authHeader() });
  }


  deleteInvoiceTypeClassification(id) {
    return instance.delete('invoicetype/classification/' + id, { headers: authHeader() });
  }

  deleteProductMoreTaxes(id) {
    return instance.delete('product/moretaxes/' + id, { headers: authHeader() });
  }

  deleteCompanySaleMoreTaxes(companyId,id) {
    return instance.delete('company/'+companyId+'/sale/moretaxes/' + id, { headers: authHeader() });
  }



  //SALES
  saveSale(object) {
    return instance.post('sale', object, { headers: authHeader() });
  }

  getSale(id) {
    return instance.get('sale/' + id, { headers: authHeader() });
  }

  getSalesView(id) {
    return instance.get('company/' + id + '/view/sale', { headers: authHeader() });
  }

  getMaxCompanySaleNumber(branchId, series, invType) {
    return instance.get('sale/maxNumber/' + branchId + "/" + series + "/" + invType, { headers: authHeader() });
  }
  getMaxCompanySaleInstanceNumber(object, companyId) {
    return instance.post('company/' + companyId + '/sale/maxInvoiceNumber/', object, { headers: authHeader() });
  }
  getSaleByInvoiceUid(invoiceUid) {
    return instance.get('aade/invoices/' + invoiceUid, { headers: authHeader() });
  }
  getInvoiceViewByInvoiceUid(invoiceUid) {
    return instance.get('/o/invoiceview/' + invoiceUid, { headers: authHeader() });
  }

  getCompanyCard(taxpayerId,branchId) {
    return instance.get('/o/card/' + taxpayerId+"/"+branchId, { headers: authHeader() });
  }

  getOpenPageTemplateSettings(companyId,branchId) {
    return instance.get('/o/company/'+companyId+'/branch/'+branchId+'/template');
  }

  saveSaleWithEntries(companyId, object) {
    return instance.post('company/' + companyId + '/mydata/send', object, { headers: authHeader() });
  }


  exportCompanySalesToExcel(companyId,pageObject) {
    
    return instance.get('company/' + companyId + '/sale/export?params=' + encodeURIComponent(JSON.stringify(pageObject)), { headers: authHeader(), responseType: 'blob' });
  }

  getCompanyDeductions(companyId) {
    return instance.get('/company/' + companyId + '/deduction', { headers: authHeader() });
  }

  saveDeductions(companyId, deductions) {
    return instance.post('company/' + companyId + '/deduction', deductions, { headers: authHeader() });
  }


  deleteCompanyDeduction(companyId,id) {
    return instance.delete('company/' + companyId + '/deduction/' + id, { headers: authHeader() });
  }



  //PRODUCT CATEGORIES

  // SaveProductCategories(object) {
  //   return instance.post('productcategory', object, { headers: authHeader() });
  // }


  SaveProductCategory(object) {
    return instance.post('productcategory', object, { headers: authHeader() });
  }


  deleteProductCategory(id) {
    return instance.delete('productcategory/' + id, { headers: authHeader() });
  }



  //CUSTOMER CATEGORIES

  SaveCustomerCategories(object) {
    return instance.post('customercategory', object, { headers: authHeader() });
  }

  deleteCustomerCategory(id) {
    return instance.delete('customercategory/' + id, { headers: authHeader() });
  }

  //MYDATA

  sendInvoiceToMyData(object) {
    return instance.post('mydata/send', object, { headers: authHeader() });
  }

  getMyDataInvoices() {
    return instance.get('mydata/getInvoices', { headers: authHeader() });
  }

  cancelMyDataInvoice(companyId, id) {
    return instance.post('company/' + companyId + '/mydata/' + id + "/cancel", {}, { headers: authHeader() });
  }

  checkAfm(AFMcalledfor, calledByAfm) {
    return instance.get('mydata/checkAfm/' + AFMcalledfor + "/" + calledByAfm, { headers: authHeader() });
  }

  //----------------

  UpdateUserWizard() {
    return instance.get('user/wizard', { headers: authHeader() });
  }


  UpdateUserTransaction(transaction) {
    return instance.post('user/transaction', transaction, { headers: authHeader() });
  }

  updateUserIsOnNotifyState() {
    return instance.post('user/updateIsOnNotifyState', {}, { headers: authHeader() });
  }


  //Country

  getCountriesInEU(inEU) {
    return configure().then(async (instance) => {
      return await instance.get('country/countriesInEU?inEU=' + inEU, { headers: authHeader() });
    })
  }
  //VIVA

  createPaymentOrder(packId) {
    return instance.post('viva/createOrder/' + packId, {}, { headers: authHeader() });
  }

  createReccuringPaymentOrder(packId) {
    return instance.post('viva/createRecurringOrder/' + packId, {}, { headers: authHeader() });
  }

  createVivaOrder() {
    return instance.post('viva/createOrder/', {}, { headers: authHeader() });
  }

  createPaymentTransaction(transactionId) {
    return instance.post('viva/createTransaction/' + transactionId, {}, { headers: authHeader() });
  }

  createUserTransaction() {
    return instance.post('viva/createTransaction', {}, { headers: authHeader() });
  }

  //AWS

  uploadFile(formData) {
    return instance.post('s3/uploadFile/', formData, { headers: authHeader("multipart/form-data") });
  }
  saveCompanyAndUploadFiles2(formData) {
    return instance.post('company/upload', formData, { headers: authHeader("multipart/form-data") });
  }
  saveCompanyAndUploadFiles(formData) {
    return instance.post('company/upload', formData, { headers: authHeader("multipart/form-data") });
  }
  sendInvoiceEmailWithAttachment(file) {
    return instance.post('s3/sendInvoiceEmailWithAttachement/', file, { headers: authHeader("multipart/form-data") });
  }

  sendInvoiceEmail(file) {
    return instance.post('s3/sendInvoiceEmail/', file, { headers: authHeader() });
  }

  //NOTIFICATIONS

  getNotifications() {
    return instance.get('user/notification', { headers: authHeader() });
  }


  

  //---------------------------------------


  testMyDataKeys(companyId, userId, token,vatNumber) {
    return instance.get('company/' + companyId + '/mydata/test?entityVatNumber='+vatNumber+'&userId=' + userId + "&token=" + token, { headers: authHeader() });
  }
  searchAccountant(email) {
    return instance.get('user/accountant/' + email, { headers: authHeader() });
  }
  accountantAssignment(companyId, userName) {
    return instance.get('user/accountant/assignment?companyId=' + companyId + "&userName=" + userName, { headers: authHeader() });
  }
  accountantUnassignment(companyId, userName) {
    return instance.get('user/accountant/unassignment?companyId=' + companyId + "&userName=" + userName, { headers: authHeader() });
  }


  saveCompanyTemplate(companyId,template) {
    return instance.post('company/'+companyId+'/branch/template', {template}, { headers: authHeader() });
  }

  getTemplateSettings(companyId,branchId) {
    return instance.get('company/'+companyId+'/branch/'+branchId+'/template', { headers: authHeader() });
  }



  getCompanyTemplateSettings(companyId) {
    return instance.get('company/'+companyId+'/branch/template', { headers: authHeader() });
  }

  saveCompanyTemplateAndUploadFiles(companyId,formData) {
    return instance.post('company/'+companyId+'/branch/template/upload', formData, { headers: authHeader("multipart/form-data") });
  }


  resetPassword(email) {
    return instance.post('auth/resetPassword?email='+email, {},{ headers: authHeader() });
  }

  changePassword(object) {
    return instance.post('auth/changePassword', object,{ headers: authHeader() });
  }

  synchronizeCompany(companyId) {
    return instance.post('company/'+companyId+'/mydata/synchronize', {},{ headers: authHeader() });
  }

  synchronizeCompanyTransmitted(companyId) {
    return instance.post('company/'+companyId+'/mydata/transmitted/synchronize', {},{ headers: authHeader() });
  }

  activateUserByLicenceKey(key) {
    return instance.post('user/activateLicence/'+key, {},{ headers: authHeader() });
  }

  getAllTaxOffices() {
    return instance.get('taxoffice', { headers: authHeader() });
  }

  getUserInvoicesFromTimologic(){
    return instance.get('user/invoices', { headers: authHeader() });
  }

  getMyDATASubscriptionKey(requestObject) {
    return instance.post('user/subscriptionKey', requestObject,{ headers: authHeader() });
  }

  createStripeAccount(companyId) {
    return instance.get('stripe/'+companyId, { headers: authHeader() });
  }

  checkoutStripe(companyId) {
    return instance.post('stripe/checkout/'+companyId,{}, { headers: authHeader() });
  }
}
export default new ApiClient();
