import React, { useState, useRef } from 'react';
import '../styles/register.css';
import { Link, Redirect } from 'react-router-dom'
import { ACCESS_TOKEN, RECAPTCHA_KEY } from '../util/constants';
import { signup, login } from '../util/AuthUtils';
import { getCurrentUser } from '../util/AuthUtils';
import ReCAPTCHA from "react-google-recaptcha";
import LoginRegisterLayout from '../components/login-register-layout';
import myDATALogo from '../img/mydata.png';
import { useTranslation } from 'react-i18next';



function Register(props) {
    const { t } = useTranslation();

    if (props.authenticated) {
        return <Redirect
            to={{
                pathname: "/",
                state: { from: props.location }
            }} />;
    }
    const getLoginUrl = () => {
        var url = "/login";

        let params = (new URL(window.location)).searchParams;
        let aff = params.get('aff');
        if (aff) {
            url += "?aff=" + aff;
        }
        return url;
    }
    return (

        <LoginRegisterLayout footer={
            <>
                <div><br></br></div>
                <p>
                    {t("tryFreeForSevenDaysMess")}
                </p>
                <small>{t("myDataCompatibleMessage")} <img style={{ width: 80, marginTop: 10 }} src={myDATALogo}></img>.  </small>
            </>
        }>

            <div className="divider">{t("or")}</div>

            <SignupForm {...props} />
            <br></br>
            <span className="login-link">{t("haveYouAlreadyAccountMess")} <br></br> {t("Connect")} <Link to={getLoginUrl()}>{t("here")}!</Link></span>
        </LoginRegisterLayout>
    );
}



function SignupForm(props) {
    const recaptchaRef = useRef();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [acceptTerms, setAcceptTerms] = useState(false);

    const { t } = useTranslation();

    const params = new URLSearchParams(props.location.search)
    let aff = params.get('aff')

    const handleInputChange = (event) => {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        switch (inputName) {
            case "email": {
                setEmail(inputValue);
                break;
            }
            case "name": {
                setName(inputValue);
                break;
            }
            case "password": {
                setPassword(inputValue);
                break;
            }
        }




    }
    const handleAcceptTermsChange = (event) => {
        setAcceptTerms(event.target.checked);
        const recaptchaValue = recaptchaRef.current.getValue();
        if (recaptchaValue && event.target.checked) {
            document.getElementById("signupBtn").removeAttribute("disabled");
        }
        else {
            document.getElementById("signupBtn").setAttribute("disabled", "disabled");
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const recaptchaValue = recaptchaRef.current.getValue();

        const signUpRequest = {
            name: email,
            email: email,
            password: password,
            captcha: recaptchaValue,
            acceptTerms: acceptTerms,
            affiliate: aff,
            roles: ['ROLE_MANAGER'],
        };


        signup(signUpRequest)
            .then(response => {
                // this.props.history.push("/login");
                const loginRequest = {
                    password: signUpRequest.password,
                    email: signUpRequest.email
                }
                login(loginRequest)
                    .then(response => {
                        localStorage.setItem(ACCESS_TOKEN, response.accessToken);


                        getCurrentUser()
                            .then(response => {
                                localStorage.setItem("user", JSON.stringify(response.user))
                                if (response.companies && response.companies.length == 1) {
                                    localStorage.setItem("company", JSON.stringify(response.companies[0]))
                                }
                                props.history.push("/home?9f14269849c04c3fc80e4e4616761e7b");
                            }).catch(error => {
                                console.error(error);
                            });

                    }).catch(error => {
                        console.error(error)
                        alert((error && error.message) || 'Oops! Something went wrong. Please try again!');
                    });

            }).catch(error => {
                console.error(error)
                alert((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
    }
    const onChangeCaptcha = (value) => {
        var termsAccepted = document.getElementById("acceptTerms").checked;
        if (value && termsAccepted) {
            document.getElementById("signupBtn").removeAttribute("disabled");
        }
        else {
            document.getElementById("signupBtn").setAttribute("disabled", "disabled");
        }

    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-item" style={{ display: "none" }}>
                <input type="text" name="name"
                    className="form-control" placeholder={t("Name")}
                    value={name} onChange={handleInputChange} />
            </div>
            <div className="login-form-item">
                <input type="email" name="email"
                    className="form-control" placeholder="Email"
                    value={email} onChange={handleInputChange} required />
            </div>
            <div className="login-form-item">
                <input type="password" name="password"
                    className="form-control" placeholder={t("Password")}
                    value={password} onChange={handleInputChange} minLength={6} required />
            </div>
            <div className="form-item text-center recapctha-container">
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={RECAPTCHA_KEY}
                    onChange={onChangeCaptcha}
                    size="compact"
                    hl="el"
                />
            </div>
            <hr></hr>
            <div className="form-item">
                <div className="form-item">
                    <input type="checkbox" id="acceptTerms" name="acceptTerms"
                        className="form-check-input"
                        checked={acceptTerms} onChange={handleAcceptTermsChange} required />{t("AcceptThe")} <a href="https://timologic.gr/privacy-policy.html" target="_blank">{t("termAndConditions")}</a>
                </div>
                <div><br></br></div>

                <button type="submit" disabled={true} id="signupBtn" className="btn btn-block btn-skin" >{t("Register")}</button>
            </div>

        </form>
    );
}

export default Register