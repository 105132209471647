import React, { Component } from 'react';
import { ACCESS_TOKEN } from '../util/constants';
import { Redirect } from 'react-router-dom'
import { getCurrentUser } from '../util/AuthUtils';

class OAuth2RedirectHandler extends Component {
    getUrlParameter(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');

        var results = regex.exec(this.props.location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    componentDidMount() {

        const token = this.getUrlParameter('token');
        const error = this.getUrlParameter('error');
        localStorage.setItem(ACCESS_TOKEN, token);
        getCurrentUser()
            .then(usr => {
                if(usr.companies && usr.companies.length==1){
                    localStorage.setItem("company", JSON.stringify(usr.companies[0]))
                }
                localStorage.setItem("user", JSON.stringify(usr.user))
                window.location.reload();
            }).catch(error => {
                this.setState({
                    loading: false
                });
            });
    }

    render() {
        const token = this.getUrlParameter('token');
        const error = this.getUrlParameter('error');
        if (token) {
            return <Redirect to={{
                pathname: "/home",
                state: { from: this.props.location,authenticated:true }
            }} />;
        } else {
            return <Redirect to={{
                pathname: "/login",
                state: {
                    from: this.props.location,
                    error: error
                }
            }} />;
        }
    }
}

export default OAuth2RedirectHandler;