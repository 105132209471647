import React from "react";

var QRCode = require('qrcode.react');


class GlamourTemplateComponent extends React.Component {


    createEntries = (entries) => {
        var rows = [];


        var rowlimit = 16;


        if (entries != undefined) {
            var diff = rowlimit - entries.length;
            var emptyEnries = [];
            if (diff > 0) {
                for (var i = 0; i < diff - 1; i++) {
                    var emptyEntry = {
                        id: "empty_" + i,
                        NAME_LIST: "",
                        QUANTITY_LIST: "",
                        MU_LIST: "",
                        UP_LIST: "",
                        DISCOUNT: "",
                        VAT_LIST: "",
                        PRICE_NOVAT_LIST: "",
                        PRICE_LIST: ""
                    }
                    emptyEnries.push(emptyEntry);
                }
            }

            entries = entries.concat(emptyEnries);
            entries.forEach(entry => {
                var row = <tr key={"entry_" + entry.id} className="products">
                    <td>
                        <span className="item_name">
                            <p><small>{entry.NAME_LIST}</small></p>
                        </span>
                    </td>
                    <td>
                        <span className="item_quantity">
                            <p>{entry.QUANTITY_LIST}</p>
                        </span>
                    </td>
                    <td>
                        <span className="item_mu">
                            <p>{entry.MU_LIST}</p>
                        </span>
                    </td>
                    <td>
                        <span className="item_unit_price">
                            <p>{entry.UP_LIST}</p>
                        </span>
                    </td>
                    <td>
                        <span className="item_discount">
                            <p>{entry.DISCOUNT}</p>
                        </span>
                    </td>
                    <td>
                        <span className="item_vat">
                            <p>{entry.VAT_LIST}</p>
                        </span>
                    </td>
                    <td>
                        <span className="item_price_novat">
                            <p>{entry.PRICE_NOVAT_LIST}</p>
                        </span>
                    </td>
                    <td>
                        <span className="item_price_withvat">
                            <p>{entry.PRICE_LIST}</p>
                        </span>
                    </td>
                </tr>
                rows.push(row)
            });
        }


        return (rows);
    }



    qrcodeValue = (invoiceUid) => {
        var _host = window.location.host

        return _host + "/mydata/" + invoiceUid
    }

    getLogo = (logo) => {
        if (logo) {
            if (!logo.includes("blob:")) {
                logo += "?" + Date.now();
            }
            return <img alt="" src={logo} className="logo_img"></img>
        }
    }

    createPage = (sale) => {
        return (
            <div className="invoice-box" key={"box_" + Math.floor(Math.random() * 10000)} >
                <div className="invoice-container-wrap">
                    <div className="invoice-container">
                        <main>
                            <div className="as-invoice invoice_style1">
                                <div className="download-inner" id="download_section">
                                    <header className="as-header header-layout1">
                                        <div className="row align-items-center justify-content-between">
                                            <div className="col-auto">
                                                <div className="header-logo"><a href="index.html">
                                                    {this.getLogo(sale.LOGO)}
                                                </a></div>
                                            </div>
                                            <div className="col-auto">
                                                <h1 className="big-title">Invoice</h1>
                                            </div>
                                        </div>
                                        <div className="header-bottom">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <div className="border-line"><img src="assets/img/bg/line_pattern.svg" alt="line" /></div>
                                                </div>
                                                <div className="col-auto">
                                                    <p className="invoice-number me-4"><b>Invoice No: </b>#935648</p>
                                                </div>
                                                <div className="col-auto">
                                                    <p className="invoice-date"><b>Date: </b>24/07/2022</p>
                                                </div>
                                            </div>
                                        </div>
                                    </header>
                                    <div className="row justify-content-between mb-4">
                                        <div className="col-auto">
                                            <div className="invoice-left"><b>Invoiced To:</b>
                                                <p className="address">Alex Farnandes<br></br>450 E 96th St, Indianapolis,<br></br>WRHX+8Q IN 46240,<br></br>United States</p>
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <div className="invoice-right"><b>Pay To:</b>
                                                <p className="address">Invce Inc<br></br>4510 E 96th St, Indianapolis,<br></br>IN 46240, United States<br></br>info@invce.com</p>
                                            </div>
                                        </div>
                                    </div>
                                    <table className="invoice-table">
                                        <thead>
                                            <tr>
                                                <th>Service</th>
                                                <th>Item Description</th>
                                                <th>Qty</th>
                                                <th>Price</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>UI/ UX Design</td>
                                                <td>Mobile Application Design</td>
                                                <td>8</td>
                                                <td>$100.00</td>
                                                <td>$800.00</td>
                                            </tr>
                                            <tr>
                                                <td>Development</td>
                                                <td>Website & App Development</td>
                                                <td>8</td>
                                                <td>$120.00</td>
                                                <td>$960.00</td>
                                            </tr>
                                            <tr>
                                                <td>Web Design</td>
                                                <td>Webpage Interface Design</td>
                                                <td>4</td>
                                                <td>$60.00</td>
                                                <td>$240.00</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="5">&nbsp;</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="row justify-content-between">
                                        <div className="col-auto">
                                            <div className="invoice-left"><b>Terms & Conditions</b>
                                                <p className="mb-0">Authoritatively envisioneer business<br></br>action items through parallel sources.</p>
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <table className="total-table">
                                                <tbody>
                                                    <tr>
                                                        <th>Sub Total:</th>
                                                        <td>$2000.00</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Tax:</th>
                                                        <td>$250.00</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Total:</th>
                                                        <td>$2250.00</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>

        )
    }

    createPages = (sale) => {
        var pages = [];

        var i, j, groupEntries, chunk = 13;
        if (sale) {
            if (sale.INVOICE_ENTRIES != undefined) {

                for (i = 0, j = sale.INVOICE_ENTRIES.length; i < j; i += chunk) {
                    groupEntries = sale.INVOICE_ENTRIES.slice(i, i + chunk);
                    var groupSale = sale;
                    groupSale.entries = groupEntries;
                    var page = this.createPage(groupSale);
                    pages.push(page);
                    // do whatever
                }
            }
        }


        return pages;

    }
    getMainStyle=()=>{
        if(this.props.mainStyle){
            return <style>{this.props.mainStyle}</style>
        }
    }

    getExtraCss=()=>{
        if(this.props.extracss){
            return <style>{this.props.extracss}</style>
        }
    }
    render() {
        return <div id="template_page" className="page template_page">
              <link  type="text/css" rel="stylesheet" href="../template4.css"/>
             <link href="https://fonts.googleapis.com/css?family=Open+Sans&family=Ubuntu&family=GFS+Neohellenic" rel="stylesheet" type="text/css"></link>
             {this.getMainStyle()}
             {this.getExtraCss()}
            {this.createPages(this.props.sale)}
        </div>;
    }

}
export default GlamourTemplateComponent;