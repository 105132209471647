import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from "react";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select'
import { useForm, Controller } from "react-hook-form";
import MoreTaxesGrid from '../editable-aggrids/line-more-taxes-grid';
import { CustomConfirmAlert as confirmAlert } from '../custom-confirm-alert';


const ActionsRenderer = forwardRef((props, ref) => {

    const [disabled, setDisabled] = useState(false);

    const { t } = useTranslation();

    const hangleDeleteRow = (e) => {
        e.preventDefault();
        let rowData = [];
        props.agGridReact.api.forEachNode(node => rowData.push(node.data));
        confirmAlert({
            title: t("submitConfirm"),
            message: t("areYouSure"),
            buttons: [
                {
                    label: t('Yes'),
                    onClick: () => {
                        rowData.splice(props.rowIndex, 1)
                        props.agGridReact.api.setRowData(rowData)
                        props.colDef.cellRendererParams.onRemoveRow();

                    }
                },
                {
                    label: t('No'),
                    onClick: () => {
                    }
                }
            ]
        });


    }

    useImperativeHandle(ref, () => {
        var countDisplayedRows = props.agGridReact.api.getDisplayedRowCount() - 1;
        if (props.rowIndex == countDisplayedRows) {
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }
        return {
            refresh(params) {

            }
        };
    });
    return (
        <span>
            <button onClick={hangleDeleteRow} disabled={disabled} className="btn btn-sm btn-skin action action-remove"><i className="fa fa-times-circle"></i></button>
        </span>
    );
});
const doubleValRenderer = forwardRef((props, ref) => {


    const [val, setVal] = useState(props.value);
    const { t, i18n } = useTranslation();

    useImperativeHandle(ref, () => {
        return {
            refresh(params) {
                var val = props.getValue();
                if (val != undefined) {
                    setVal(val);
                }

            }
        };
    });
    if (props.colDef.field == "price") {
        return <span className="editableCell">{val != null && val != "" ? parseFloat(val).toFixed(2) : <div><span className="addtext"></span><div className="addtextafter">...</div></div>}</span>

    }
    else if (props.colDef.field == "amount") {
        return <span className="editableCell">{val != null && val != "" ? parseFloat(val).toFixed(2) : <div><span className="addtext"></span><div className="addtextafter">...</div></div>}</span>

    }
    else {
        return <span className="editableCell">{val && val != "" ? parseFloat(val).toFixed(2) : <div><span className="addtext"></span><div className="addtextafter">...</div></div>}</span>

    }
});
const IncomingClassCategoryTypeRenderer = forwardRef((props, ref) => {
    const [mu, setMU] = useState(props.value);
    useImperativeHandle(ref, () => {
        return {
            refresh(params) {
                var val = props.getValue();
                if (val != undefined) {
                    setMU(val);
                }

            }
        };
    });
    return (
        <span className="editableCell">{mu ? mu.label : <div><span className="addtext"></span><div className="addtextafter">...</div></div>}</span>

    );
});
const IncomingClassCategoryTypeEditor = forwardRef((props, ref) => {
    const [selectedVal, setSelectedVal] = useState(props.value);
    const [editing, setEditing] = useState(true);
    const refContainer = useRef(null);


    useEffect(() => {
        focus();
    }, []);

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return selectedVal != null ? selectedVal : props.value;
            },

            isPopup() {
                return true;
            }
        };
    });

    useEffect(() => {


        if (!editing) {

            props.api.stopEditing();
        }
    }, [editing]);

    const focus = () => {
        window.setTimeout(() => {
            let container = ReactDOM.findDOMNode(refContainer.current);
            if (container) {
                container.focus();
            }
        });
    };

    const { t, i18n } = useTranslation();

    const onSelectChange = val => {
        setSelectedVal(val);
        var old_val = props.node.data.classificationCategory.value;

        setTimeout(function () {
            props.api.stopEditing();
            props.node.setDataValue("classificationCategory", val);

            var opts = [];


            val.classificationValues.forEach(element => {
                var lbl= t(element) + " (" + element + ")";
                // if(element=="-"){
                //     lbl= t(element) ;
                // }
                opts.push({
                    value: element,
                    label: lbl,
                });
            });
            props.node.setDataValue("classificationValues", opts);

            if (old_val != val.value) {

                props.node.setDataValue("classificationValue", { value: undefined, label: undefined });
            }
        })

    }
    const onMouseOver = e => {
        refContainer.current.setState({ isOpen: true })
        let container = ReactDOM.findDOMNode(e.target).parentNode.getElementsByTagName("input")[0];
        if (container != undefined) {
            container.focus();
        }
    }


    return (<div style={{ width: props.column.actualWidth + 'px' }}>

        <span onMouseOver={onMouseOver} className="selectCell">
            <Select
                autosize={true}
                menuIsOpen={true}
                defaultValue={selectedVal}
                onChange={onSelectChange}
                onChangeValue={onSelectChange}
                options={props.colDef.options} ref={refContainer}>
            </Select>
        </span>
    </div>
    );
});
const IncomingClassValueTypeRenderer = forwardRef((props, ref) => {
    const [val, setVal] = useState(props.value);
    const { t, i18n } = useTranslation();

    useImperativeHandle(ref, () => {
        return {
            refresh(params) {
                var val = props.getValue();
                if (val != undefined) {
                    var rowNode = props.agGridReact.api.getRowNode(props.rowIndex);
                    setVal(val);
                }

            }
        };
    });
    return (
        <span className="editableCell">{val != null ? val.label : <div><span className="addtext"></span><div className="addtextafter">...</div></div>}</span>
    );
});
const IncomingClassValueTypeEditor = forwardRef((props, ref) => {
    const [selectedVal, setSelectedVal] = useState(props.value);
    const [editing, setEditing] = useState(true);
    const refContainer = useRef(null);


    useEffect(() => {

        focus();
    }, []);

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return selectedVal != null ? selectedVal : props.value;
            },

            isPopup() {
                return true;
            }
        };
    });

    useEffect(() => {
        if (!editing) {
            props.api.stopEditing();
        }
    }, [editing]);

    const focus = () => {
        window.setTimeout(() => {
            let container = ReactDOM.findDOMNode(refContainer.current);
            if (container) {
                container.focus();
            }
        });
    };


    const onSelectChange = val => {
        setSelectedVal(val);
        setTimeout(function () {
            props.api.stopEditing();
            props.node.setDataValue("classificationValue", val);

        })

    }
    const onMouseOver = e => {
        refContainer.current.setState({ isOpen: true })
        let container = ReactDOM.findDOMNode(e.target).parentNode.getElementsByTagName("input")[0];
        if (container != undefined) {
            container.focus();
        }
    }


    return (<div style={{ width: props.column.actualWidth + 'px' }}>

        <span onMouseOver={onMouseOver} className="selectCell">
            <Select
                autosize={true}
                menuIsOpen={true}
                defaultValue={selectedVal}
                onChange={onSelectChange}
                onChangeValue={onSelectChange}
                options={props.node.data.classificationValues} ref={refContainer}>
            </Select>
        </span>
    </div>
    );
});
const SaleEntriesExtra = forwardRef((props, ref) => {
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const { t, i18n } = useTranslation();
    const { register, getValues, control, handleSubmit, setValue, reset, formState: { errors }, trigger, clearErrors } = useForm();
    const [hasError, setHasError] = useState(false);
    const [initRowTotals, setInitRowTotals] = useState(0);
    const [classTotals, setClassTotals] = useState(0);
    const prodMoreTaxesGridRef = useRef();
    const rowStyle = { background: 'white' };


    useImperativeHandle(ref, () => ({

        getRowsTotals() {
            return classTotals;
        },
        getData() {
            var items = [];
            gridApi.forEachNode(node => items.push(node.data));
            items.pop();
            var moretaxesData=prodMoreTaxesGridRef.current.getData();
            var taxesItems = prodMoreTaxesGridRef.current.transformData(moretaxesData,props.moreTaxesTypesOptions, props.moreTaxesOptions)
            var _items = {
                saleEntryClassifications: items,
                taxes: taxesItems,
                vatCategoryException: getValues().vatCategoryException
            }
            return _items;
        },
        setData(data) {
            var emptyRow = {
                classificationValue: { value: undefined, label: undefined },
                classificationCategory: { value: undefined, label: undefined },
                amount: undefined,
                isPercent: false
            }
            if (data != undefined) {
                data.push(emptyRow)
                gridApi.setRowData(data)
            }
            else {
                data = [];
                data.push(emptyRow)
                gridApi.setRowData(data)
            }
        }

    }));
    const isValid = (nodeData) => {
        return nodeData.classificationCategory.value != ""
            && nodeData.classificationCategory.value != undefined
            && nodeData.classificationValue.value != undefined
            && nodeData.classificationValue.value != ""
            && nodeData.amount != undefined
            && nodeData.amount != ""
    }

    const onGridReady = params => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        setInitRowTotals(props.rowTotals)
        // params.api.sizeColumnsToFit();
        window.addEventListener('resize', function () {
            setTimeout(function () {
                // params.api.sizeColumnsToFit();

            });
        });
        var data = props.extraRowData;

        var taxes = props.extraTaxes;
        var _taxes = [];
        debugger
        if (taxes) {
            taxes.forEach((e) => {
                var isNanAmount = isNaN(parseInt(e.amount));
                var rate = parseFloat(e.myDataMoreTax.amountValue.replace("%", "")) / 100;
                rate = rate.toFixed(2);
                e.amount = isNanAmount ? rate : e.amount;
                _taxes.push(e);
            })
        }

        if (_taxes && Object.keys(_taxes).length !== 0) {

            setTimeout(function () {
                prodMoreTaxesGridRef.current.setData(_taxes);
            })
        }


        if (data != undefined && data.length>0) {
            data.push({ classificationValue: { value: undefined, label: undefined }, classificationCategory: { value: undefined, label: undefined }, amount: undefined })
            params.api.setRowData(data)
        }
        else {
            data = [];
            data.push({ classificationValue: { value: undefined, label: undefined }, classificationCategory: { value: undefined, label: undefined }, amount: undefined })
            params.api.setRowData(data)
        }
        if (props.vatCategoryException != undefined) {
            setValue("vatCategoryException", props.vatCategoryException);
        }
        var items = [];
        if (params.api) {
            params.api.forEachNode(node => items.push(node.data));
            items.pop();
            var total = 0;
            items.forEach(i => {
                total += parseFloat(i.amount);
            })
            if (props.rowTotals != total && !props.isInvoice00_1) {
                props.setRowTotalsConstraintDisplay("unset")
                document.getElementById("onExtras").setAttribute("disabled", "disabled");
            }
            else {
                props.setRowTotalsConstraintDisplay("none")
                document.getElementById("onExtras").removeAttribute("disabled");
            }
        }
        //FOR THE NEXT MYDATA UPDATE
        // if (props.myDataInvoiceType == "invoice8_2") {
        //     document.getElementsByClassName("classification_table")[0].style.display = "none";
        // }
        // else {
        //     if (props.myDataInvoiceType == "invoice8_2") {
        //         document.getElementsByClassName("classification_table")[0].style.display = "unset";
        //     }
        // }
    };


    const addRow = () => {
        var data = { classificationValue: { value: undefined, label: undefined }, classificationCategory: { value: undefined, label: undefined }, amount: undefined };
        let rowData = [];
        gridApi.forEachNode(node => rowData.push(node.data));
        rowData.push(data)
        gridApi.setRowData(rowData)
        var countDisplayedRows = gridApi.getDisplayedRowCount() - 1;
        gridApi.ensureIndexVisible(countDisplayedRows, 'top');
    }

    const onCellValueChanged = (params) => {
        var nodeIndex = params.node.rowIndex;
        var rowNode = gridApi.getRowNode(nodeIndex);
        var countDisplayedRows = gridApi.getDisplayedRowCount() - 1;
        if (nodeIndex == countDisplayedRows && params.newValue != undefined) {
            addRow(nodeIndex);
        }

        var items = [];
        gridApi.forEachNode(node => items.push(node.data));
        items.pop();
        var total = 0;
        items.forEach(i => {
            if (i.classificationValue.value) {
                if (!i.classificationValue.value.includes("VAT_")) {
                    total += parseFloat(i.amount);
                }
            }

        })
        setClassTotals(total);

        switch (params.column.colId) {
            case "amount": {
                if (initRowTotals != total) {
                    props.setRowTotalsConstraintDisplay("unset")
                    document.getElementById("onExtras").setAttribute("disabled", "disabled");
                }
                else {
                    props.setRowTotalsConstraintDisplay("none")
                    document.getElementById("onExtras").removeAttribute("disabled");
                }
                break;
            }
        }
    }

    const getRowStyle = params => {


        if (params.node.rowIndex == params.api.rowModel.rowsToDisplay.length - 1) {
            return {
                background: '#d2f6fac7'

            };

        }
        else {
            if (!isValid(params.node.data)) {
                setHasError(true)
                return { background: '#ffb09c' };
            }
            else {
                setHasError(false)
                if (params.node.data.global) {
                    return { background: '#eee' };
                }
                else {
                    return { background: 'white' };
                }
            }
        }
    };

    const onRemoveRow = () => {
        var rowData = [];
        if (gridApi != undefined) {
            var items = [];
            gridApi.forEachNode(node => items.push(node.data));
            items.pop();
            var total = 0;
            items.forEach(i => {
                total += parseFloat(i.amount);
            })
            if (props.rowTotals != total) {
                props.setRowTotalsConstraintDisplay("unset")
                document.getElementById("onExtras").setAttribute("disabled", "disabled");
            }
            else {
                props.setRowTotalsConstraintDisplay("none")
                document.getElementById("onExtras").removeAttribute("disabled");
            }
        }
    }

    return (
        <div className="row">

            <div className="col-md-12">
                <div className="ag-theme-alpine editableSmGrid salesClassicicationsGrid classification_table" style={{

                    width: '100%',
                }}>
                    <AgGridReact
                        onGridReady={onGridReady}
                        rowStyle={rowStyle}
                        getRowStyle={getRowStyle}
                        style={{ width: '100%', height: '200px' }}
                        onCellValueChanged={onCellValueChanged}
                        stopEditingWhenGridLosesFocus={true}
                        borders={true}
                        defaultColDef={{
                            flex: 1,
                            resizable: true,
                            wrapText: true,
                            autoHeight: true,
                            suppressMovable: true
                        }}
                        suppressRowTransform={true}
                        singleClickEdit={true}
                        skipHeaderOnAutoSize={true}
                        frameworkComponents={{
                            incomingClassValueTypeRenderer: IncomingClassValueTypeRenderer,
                            incomingClassValueTypeEditor: IncomingClassValueTypeEditor,
                            incomingClassCategoryTypeRenderer: IncomingClassCategoryTypeRenderer,
                            incomingClassCategoryTypeEditor: IncomingClassCategoryTypeEditor,
                            doubleValRenderer: doubleValRenderer,
                            actionsRenderer: ActionsRenderer,
                        }}
                    >

                        {/* suppressSizeToFit={true}  */}
                        <AgGridColumn hide={true} field="classificationValues"></AgGridColumn>
                        <AgGridColumn flex={2} minWidth={200} headerName={t("incomingClassificationCategoryType")} options={props.incomeClassCategories} editable={true} cellEditor="incomingClassCategoryTypeEditor" cellRenderer="incomingClassCategoryTypeRenderer" field="classificationCategory"></AgGridColumn>
                        <AgGridColumn flex={3} minWidth={200} headerName={t("incomingClassificationValueType")} cellEditor="incomingClassValueTypeEditor" cellRenderer="incomingClassValueTypeRenderer" editable={true} field="classificationValue"></AgGridColumn>
                        <AgGridColumn flex={1} minWidth={100} headerName={t("Amount")} editable={true} cellRenderer="doubleValRenderer" field="amount"></AgGridColumn>
                        <AgGridColumn flex={1} minWidth={100} headerName={t("Actions")} cellRenderer="actionsRenderer" cellRendererParams={{ onRemoveRow: onRemoveRow }} field="name"></AgGridColumn>

                    </AgGridReact>




                </div>
                <hr></hr>
                <h5>{t("moreTaxes")}</h5>
                <MoreTaxesGrid
                    ref={prodMoreTaxesGridRef}
                    moreTaxesTypesOptions={props.moreTaxesTypesOptions}
                    moreTaxesOptions={props.moreTaxesOptions}
                    rowTotals={initRowTotals}
                >

                </MoreTaxesGrid>
                <form>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4 selectInputContainer form-group vatCategoryException">
                                <label className="control-label inputlabel">
                                    {t('vatCategoryException')}
                                </label>
                                <Controller
                                    render={({ field }) => (
                                        <Select {...field}
                                            isClearable={true} options={props.optionVatExceptionCategories}
                                            className={`form-control ${errors.vatCategoryException ? 'is-invalid' : ''}`}
                                            placeholder={t('Select')}
                                        >

                                        </Select>
                                    )}
                                    control={control}
                                    name="vatCategoryException"
                                    defaultValue=""
                                />


                                <div className="invalid-feedback">{errors.withHoldTax?.message}</div>
                            </div>

                        </div>
                    </div>

                </form>

            </div>
            <div><br /></div>

        </div>
    );
});
export default SaleEntriesExtra 