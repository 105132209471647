import React from "react";
import Commons from '../../util/commons'

var QRCode = require('qrcode.react');
class TemplateComponent extends React.Component {


    createEntries = (entries) => {
        var rows = [];
        var rowlimit = 15;


        if (entries != undefined) {
            entries = entries.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
            var diff = rowlimit - entries.length;
            var emptyEnries = [];
            if (diff > 0) {
                for (var i = 0; i < diff - 1; i++) {
                    var emptyEntry = {
                        id: "empty_" + i,
                        NAME_LIST: "",
                        QUANTITY_LIST: "",
                        MU_LIST: "",
                        UP_LIST: "",
                        DISCOUNT: "",
                        VAT_LIST: "",
                        PRICE_NOVAT_LIST: "",
                        PRICE_LIST: ""
                    }
                    emptyEnries.push(emptyEntry);
                }
            }

            entries = entries.concat(emptyEnries);
            entries.forEach(entry => {
                var row = <tr key={"entry_" + entry.id} className="products">
                    <td>
                        <span className="item_name">
                            <p><small>{entry.NAME_LIST}</small></p>
                        </span>
                    </td>
                    <td>
                        <span className="item_quantity">
                            <p>{entry.QUANTITY_LIST}</p>
                        </span>
                    </td>

                    {
                        this.props.sale ? !Commons.isOnGroup(this.props.sale.MYDATA_INVOICE_TYPE, "noMeasurementUnit") ?
                            <td>
                                <span className="item_mu">
                                    <p> {this.props.t(entry.MU_LIST, { lng: this.props.sale.TEMPLATE_LANG })}</p>
                                </span>
                            </td>

                            : <></>
                            : <></>
                    }
                    <td>
                        <span className="item_unit_price">
                            <p>{entry.UP_LIST}</p>
                        </span>
                    </td>
                    <td>
                        <span className="item_discount">
                            <p>{entry.DISCOUNT}</p>
                        </span>
                    </td>
                    <td>
                        <span className="item_vat">
                            <p>{entry.VAT_LIST}</p>
                        </span>
                    </td>
                    <td>
                        <span className="item_price_novat">
                            <p>{entry.PRICE_NOVAT_LIST}</p>
                        </span>
                    </td>
                    <td>
                        <span className="item_price_withvat">
                            <p>{entry.PRICE_LIST}</p>
                        </span>
                    </td>
                </tr>
                rows.push(row)
            });
        }


        return (rows);
    }



    qrcodeValue = (invoiceUid) => {
        var _host = window.location.host;

        return _host + "/mydata/" + invoiceUid
    }

    getLogo = (logo) => {
        if (logo) {
            if (!logo.includes("blob:")) {
                logo += "?" + Date.now();
            }
            return <img alt="" src={logo} className="logo_img"></img>
        }
    }

    createMoreTaxesTable(saleInstanceMoreTaxes, lang) {
        var rows = [];
        var types = [];

        function compare(a, b) {

            return a.myDataMoreTax.type.localeCompare(b.myDataMoreTax.type);
        }

        saleInstanceMoreTaxes.sort(compare);
        saleInstanceMoreTaxes.forEach(e => {
            debugger
            var type = e.myDataMoreTax.type;
            if (type == "DEDUCTION" && e.myDataMoreTax.label) {
                e.deduction = e.myDataMoreTax;
                e.deduction.name = e.myDataMoreTax.label;
            }

            if (e.deduction) {
                var type = "DEDUCTION";
            }

            if (!types.includes(e.myDataMoreTax.type)) {
                types.push(e.myDataMoreTax.type);
            }
            else {
                type = "";
            }
            debugger

            var TAX = e.myDataMoreTax ? "TAX_" + e.myDataMoreTax.uid : null;
            if (e.deduction) {
                rows.push(<tr className="othertax_row"><td><b>{this.props.t(type, { lng: lang })}</b></td><td>{e.deduction.name}</td><td>{e.amount}</td></tr>)
            }
            else {
                rows.push(<tr className="othertax_row"><td><b>{this.props.t(type, { lng: lang })}</b></td><td>{this.props.t(TAX, { lng: lang })}</td><td>{e.amount}</td></tr>)

            }
        })
        return rows;
    }

    createPage = (sale) => {
        return (
            <div className={"invoice-box " + sale.TEMPLATE_LANG} key={"box_" + Math.floor(Math.random() * 10000)} >
                <div className="top_table">
                    <table>
                        <tbody>
                            <tr className="top">
                                <td>
                                    <table className="header_table">
                                        <tbody>
                                            <tr>
                                                <td className="logo_container">
                                                    {this.getLogo(sale.LOGO)}
                                                </td>

                                                <td className="issuer_container">
                                                    <span className="issuer_name template_skin">{sale.ISSUER_NAME}</span>
                                                    <p> <span className="issuer_subjectField template_skin ">{sale.ISSUER_SUBJECTFIELD}</span></p>
                                                    <p><span className="issuer_address template_skin ">{sale.ISSUER_ADDRESS}</span></p>

                                                    <p>
                                                        <span className=" template_skin ">{this.props.t("TAXPAYER_ID", { lng: sale.TEMPLATE_LANG })}: </span><span className="issuer_vat template_skin ">{sale.ISSUER_VAT}</span>
                                                        &nbsp;
                                                        <span className=" template_skin ">{this.props.t("DOY", { lng: sale.TEMPLATE_LANG })}: </span> <span className="issuer_doy template_skin ">{sale.ISSUER_DOY}</span>
                                                        &nbsp;
                                                        <span className=" template_skin ">{this.props.t("GEMH", { lng: sale.TEMPLATE_LANG })}: </span> <span className="issuer_gemh template_skin ">{sale.ISSUER_GEMH}</span>
                                                    </p>
                                                    <p>
                                                        <span className=" template_skin ">{this.props.t("PHONE", { lng: sale.TEMPLATE_LANG })}: </span> <span className="issuer_phone template_skin ">{sale.ISSUER_PHONE}</span>
                                                        &nbsp;
                                                        <span className=" template_skin ">{this.props.t("EMAIL", { lng: sale.TEMPLATE_LANG })}: </span> <span className="issuer_email template_skin ">{sale.ISSUER_EMAIL}</span>
                                                    </p>
                                                    <span className="futureUse"></span>
                                                    {/* <p className="gemh_issuer_p template_skin "> <span className=" template_skin ">ΑΡ.ΓΕΜΗ: </span> <span className="issuer_gemh">{sale.ISSUER_GEMH}</span></p> */}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="main_info">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <table className="main_info_table">
                                            <tbody>
                                                <tr className="heading">
                                                    <td>
                                                        <span className="invoice_type">{sale.INVOICE_TYPE}</span>
                                                    </td>

                                                    <td>
                                                        <span className="invoice_number">{sale.INVOICE_NUMBER}</span>
                                                    </td>
                                                    <td>
                                                        <span className="invoice_date"> {sale.INVOICE_DATE}</span>
                                                    </td>
                                                    {/* <td>
                                                        <span className="invoice_time"> {sale.INVOICE_TIME}</span>
                                                    </td> */}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="trafficDataDivisioner">
                        <span>
                            {this.props.t("customerSection", { lng: sale.TEMPLATE_LANG })}
                        </span>
                    </div>

                    <table className="information_table">
                        <tbody>
                            <tr>
                                <td className="information_td_left">
                                    <div className="information left">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="template_skin bold">
                                                        <span> {this.props.t("CP_NAME", { lng: sale.TEMPLATE_LANG })}</span>
                                                    </td>
                                                    <td className="info_value">
                                                        <span>: </span>
                                                        <span className="counterparty_name">{sale.CP_NAME}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="template_skin bold">
                                                        <span> {this.props.t("TAXPAYER_ID", { lng: sale.TEMPLATE_LANG })}</span>
                                                    </td>
                                                    <td className="info_value">
                                                        <span>: </span>
                                                        <span className="counterparty_vat">{sale.CP_VAT}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="template_skin bold">
                                                        <span> {this.props.t("CP_SUBJECT_FIELD", { lng: sale.TEMPLATE_LANG })}</span>
                                                    </td>
                                                    <td className="info_value">
                                                        <span>: </span>
                                                        <span className="counterparty_activity">{sale.CP_SUBJECT_FIELD}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="template_skin bold">
                                                        <span> {this.props.t("DOY", { lng: sale.TEMPLATE_LANG })}</span>
                                                    </td>
                                                    <td className="info_value">
                                                        <span>: </span>
                                                        <span className="counterparty_doy">{sale.CP_TAXOFFICE}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                                <td> </td>
                                <td className="information_td_right">
                                    <div className="information right">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="template_skin bold">
                                                        <span> {this.props.t("CP_PAYTYPE", { lng: sale.TEMPLATE_LANG })}</span>
                                                    </td>
                                                    <td className="info_value">
                                                        <span>: </span>
                                                        <span className="counterparty_paytype">{this.props.t(sale.CP_PAYTYPE, { lng: sale.TEMPLATE_LANG })}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="template_skin bold">
                                                        <span> {this.props.t("COUNTRY", { lng: sale.TEMPLATE_LANG })}</span>
                                                    </td>
                                                    <td className="info_value">
                                                        <span>: </span>
                                                        <span className="counterparty_country">{this.props.t(sale.COUNTRY, { lng: sale.TEMPLATE_LANG })}</span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="template_skin bold">
                                                        <span> {this.props.t("CP_CITY", { lng: sale.TEMPLATE_LANG })}</span>
                                                    </td>
                                                    <td className="info_value">
                                                        <span>: </span>
                                                        <span className="counterparty_city">{sale.CP_CITY}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="template_skin bold">
                                                        <span> {this.props.t("CP_ADDRESS", { lng: sale.TEMPLATE_LANG })}</span>
                                                    </td>
                                                    <td className="info_value">
                                                        <span>: </span>
                                                        <span className="counterparty_address">{sale.CP_ADDRESS}</span>
                                                    </td>
                                                </tr>
                                                {/* <tr className="blank_row">
                                                    <td>&nbsp;</td>
                                                </tr> */}

                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    {
                        this.props.sale ? !Commons.isOnGroup(this.props.sale.MYDATA_INVOICE_TYPE, "noCountItems") ?
                            <>
                                <div className="trafficDataDivisioner">
                                    <span>
                                        {this.props.t("moveSection", { lng: sale.TEMPLATE_LANG })}
                                    </span>
                                </div>
                            </> : <></>
                            : <></>
                    }



                    <table className="information_table">
                        <tbody>
                            <tr>
                                <td className="information_td_left">
                                    <div className="information left">
                                        <table>
                                            <tbody>
                                                {
                                                    this.props.sale ? !Commons.isOnGroup(this.props.sale.MYDATA_INVOICE_TYPE, "noCountItems") ?

                                                        <tr>
                                                            <td className="template_skin bold">
                                                                <span> {this.props.t("SEND_PLACE", { lng: sale.TEMPLATE_LANG })}</span>
                                                            </td>
                                                            <td className="info_value">
                                                                <span>: </span>
                                                                <span className="send_place">{sale.SEND_PLACE}</span>
                                                            </td>
                                                        </tr>
                                                        : <></>
                                                        : <></>
                                                }

                                                {
                                                    sale.MOVE_PURPOSE ? <tr>
                                                        <td className="template_skin bold">
                                                            <span> {this.props.t("MOVE_PURPOSE", { lng: sale.TEMPLATE_LANG })}</span>
                                                        </td>
                                                        <td className="info_value">
                                                            <span>: </span>
                                                            <span className="move_purpose">{sale.MOVE_PURPOSE}</span>
                                                        </td>
                                                    </tr> : <></>
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                                <td> </td>
                                <td className="information_td_right">
                                    <div className="information right">
                                        <table>
                                            <tbody>
                                                {
                                                    sale.VEHICLE_NUMBER ?
                                                        <tr>
                                                            <td className="template_skin bold">
                                                                <span>{this.props.t("VEHICLE_NUMBER", { lng: sale.TEMPLATE_LANG })}</span>
                                                            </td>
                                                            <td className="info_value">
                                                                <span>: </span>
                                                                <span className="vahicle_number">{sale.VEHICLE_NUMBER}</span>
                                                            </td>
                                                        </tr> : <></>
                                                }
                                                {
                                                    sale.DISPATCH_DATE ?
                                                        <tr>
                                                            <td className="template_skin bold">
                                                                <span> {this.props.t("DISPATCH_DATE", { lng: sale.TEMPLATE_LANG })}</span>
                                                            </td>
                                                            <td className="info_value">
                                                                <span>: </span>
                                                                <span className="dispatch_date">{sale.DISPATCH_DATE}</span>
                                                            </td>
                                                        </tr> : <></>
                                                }

                                                {/* <tr className="blank_row">
                                                    <td>&nbsp;</td>
                                                </tr> */}
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>



                    {
                        sale.MYDATA_INVOICE_TYPE == "invoice2_01" || sale.MYDATA_INVOICE_TYPE == "invoice11_02" ? <>


                            <div className="main_info">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table className="main_info_table">
                                                    <tbody>
                                                        <tr className="heading">
                                                            <td>
                                                                <p> {this.props.t("senderName", { lng: sale.TEMPLATE_LANG })}</p>
                                                            </td>

                                                            <td>
                                                                <p> {this.props.t("recipientName", { lng: sale.TEMPLATE_LANG })}</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                            <table className="information_table">

                                <tbody>
                                    <tr>
                                        <td className="information_td_left">

                                            <div className="information left">

                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td className="template_skin bold">
                                                                <span> {this.props.t("CP_NAME", { lng: sale.TEMPLATE_LANG })}</span>
                                                            </td>
                                                            <td className="info_value">
                                                                <span>: </span>
                                                                <span className="counterparty_name">{sale.SENDER_NAME}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="template_skin bold">
                                                                <span> {this.props.t("TAXPAYER_ID", { lng: sale.TEMPLATE_LANG })}</span>
                                                            </td>
                                                            <td className="info_value">
                                                                <span>: </span>
                                                                <span className="counterparty_name">{sale.SENDER_TAXPAYER_ID}</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                        <td></td>
                                        <td className="information_td_right">
                                            <div className="information right">

                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td className="template_skin bold">
                                                                <span> {this.props.t("CP_NAME", { lng: sale.TEMPLATE_LANG })}</span>
                                                            </td>
                                                            <td className="info_value">
                                                                <span>: </span>
                                                                <span className="counterparty_name">{sale.RECIPIENT_NAME}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="template_skin bold">
                                                                <span> {this.props.t("TAXPAYER_ID", { lng: sale.TEMPLATE_LANG })}</span>
                                                            </td>
                                                            <td className="info_value">
                                                                <span>: </span>
                                                                <span className="counterparty_name">{sale.RECIPIENT_TAXPAYER_ID}</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table></>
                            : ""
                    }


                </div>
                <div className="product_container">
                    <div className="background_img_container"></div>
                    <table className="product_table">
                        <thead>
                            <tr className="heading">
                                <td className="description_head_td">
                                    <p>{this.props.t("PROD_SERV", { lng: sale.TEMPLATE_LANG })}</p>
                                </td>
                                <td className="quantity_head_td">
                                    <p>{this.props.t("QUANTITY", { lng: sale.TEMPLATE_LANG })}</p>
                                </td>

                                {
                                    this.props.sale ? !Commons.isOnGroup(this.props.sale.MYDATA_INVOICE_TYPE, "noMeasurementUnit") ?
                                        <td className="mu_head_td">
                                            <p> {this.props.t("MUN")}</p>
                                        </td>

                                        : <></>
                                        : <></>
                                }


                                <td className="up_head_td">
                                    <p> {this.props.t("UNIT_PRICE", { lng: sale.TEMPLATE_LANG })}</p>
                                </td>
                                <td className="disc_head_td">
                                    <p> {this.props.t("DISCOUNT", { lng: sale.TEMPLATE_LANG })}</p>
                                </td>
                                <td className="vat_head_td">
                                    <p>  {this.props.t("VAT", { lng: sale.TEMPLATE_LANG })} %</p>
                                </td>
                                <td className="pricenovat_head_td">
                                    <p>  {this.props.t("PRICE_WITHOUT_VAT", { lng: sale.TEMPLATE_LANG })}</p>
                                </td>
                                <td className="price_head_td">
                                    <p> {this.props.t("PRICE_WITH_VAT", { lng: sale.TEMPLATE_LANG })}</p>
                                </td>
                            </tr>
                        </thead>
                        <tbody>

                            {this.createEntries(sale.entries)}
                        </tbody>
                    </table>

                </div>
                <div className="footer_container">
                    <table className="footer_table">
                        <tbody>
                            <tr>
                                <td>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className="cont_notation">
                                                        <span className="template_skin bold">{this.props.t("NOTES", { lng: sale.TEMPLATE_LANG })}</span>
                                                        <div className="cont_notation_inner">
                                                            <span className="notes">{sale.NOTES}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>

                                                    <table className="taxes_details_table">
                                                        <tbody>
                                                            <tr>
                                                                <td className="qrcodeimg_container">
                                                                    <span className="qrcode_img">
                                                                        <QRCode
                                                                            size={100}
                                                                            bgColor={"#ffffff"}
                                                                            fgColor={"#000000"}
                                                                            level={"L"}
                                                                            includeMargin={false}
                                                                            renderAs={"svg"}
                                                                            value={sale.QRURL ? sale.QRURL : this.qrcodeValue(sale.INVOICEUID)}
                                                                        />
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <div className="taxes_details">
                                                                        <table>
                                                                            <tbody>
                                                                                {sale.saleInstanceMoreTaxes ? this.createMoreTaxesTable(sale.saleInstanceMoreTaxes, sale.TEMPLATE_LANG) : <></>}

                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="taxes_details" style={{ display: 'none' }}>
                                                                        <table>
                                                                            <tbody>
                                                                                {/* <tr >
                                                                                    <th>{this.props.t("TAXTYPE", { lng: sale.TEMPLATE_LANG })}</th><th>{this.props.t("Amount", { lng: sale.TEMPLATE_LANG })}</th>
                                                                                </tr> */}
                                                                                {
                                                                                    sale.WITHHOLD_TAXES ? <tr>
                                                                                        <td><b>{this.props.t("TOTALWHTAXES", { lng: sale.TEMPLATE_LANG })}: </b>{this.props.t(sale.WITHHOLD_TAXES, { lng: sale.TEMPLATE_LANG })}</td><td>{sale.TOTALWHTAXES}</td>
                                                                                    </tr> : <></>
                                                                                }

                                                                                {
                                                                                    sale.OTHER_TAXES ? <tr>
                                                                                        <td><b>{this.props.t("TOTALOTHERTAXES", { lng: sale.TEMPLATE_LANG })}: </b>{this.props.t(sale.OTHER_TAXES, { lng: sale.TEMPLATE_LANG })}</td><td>{sale.TOTALOTHERTAXES}</td>
                                                                                    </tr> : <></>
                                                                                }

                                                                                {
                                                                                    sale.FEES ? <tr>
                                                                                        <td><b>{this.props.t("TOTALFEESTAXES", { lng: sale.TEMPLATE_LANG })}: </b>{this.props.t(sale.FEES, { lng: sale.TEMPLATE_LANG })}</td><td>{sale.TOTALFEESTAXES}</td>
                                                                                    </tr> : <></>
                                                                                }

                                                                                {
                                                                                    sale.STUMP_DUTY ? <tr>
                                                                                        <td><b>{this.props.t("TOTALSTUMPOFDUTYTAXES", { lng: sale.TEMPLATE_LANG })}: </b>{this.props.t(sale.STUMP_DUTY, { lng: sale.TEMPLATE_LANG })}</td><td>{sale.TOTALSTUMPOFDUTYTAXES}</td>
                                                                                    </tr> : <></>
                                                                                }

                                                                                {
                                                                                    sale.DEDUCTION ? <tr>
                                                                                        <td><b>{this.props.t("TOTALDEDUCTIONTAXES", { lng: sale.TEMPLATE_LANG })}: </b>{sale.DEDUCTION}</td><td>{sale.TOTALDEDUCTIONTAXES}</td>
                                                                                    </tr> : <></>
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                {(sale.VAT_EXCEPTION_CATEGORIES && sale.VAT_EXCEPTION_CATEGORIES[0]) ? <td><small><b>{this.props.t("vatCategoryExceptionShort", { lng: sale.TEMPLATE_LANG })} </b><span className="vat_exception_span">{this.props.t(sale.VAT_EXCEPTION_CATEGORIES[0], { lng: sale.TEMPLATE_LANG })}</span></small></td> : <></>}

                                            </tr>
                                            <tr>
                                                {(sale.MOREINFO && sale.MOREINFO) ? <td className="morenfo_sign">{this.props.t(sale.MOREINFO)}</td> : <></>}

                                            </tr>
                                        </tbody>
                                    </table>
                                </td>

                                <td>
                                    {
                                        this.props.sale ? !Commons.isOnGroup(this.props.sale.MYDATA_INVOICE_TYPE, "noCountItems") ?
                                            <div className="count_totals_container">
                                                <span>{this.props.t("TOTALITEMS", { lng: sale.TEMPLATE_LANG })}: </span>
                                                <span className="count_total_prods">
                                                    {sale.TOTALCOUNTITEMS}
                                                </span>
                                            </div> : <></>
                                            : <></>
                                    }

                                    <div className="total_container">
                                        <div className="totals">
                                            <table className="totals_table">
                                                <tbody>
                                                    <tr>
                                                        <td className="text-left" >
                                                            <p>{this.props.t("TOTALVALUE", { lng: sale.TEMPLATE_LANG })}</p>
                                                        </td>
                                                        <td className="text-right">
                                                            <p><span className="total_nodiscount">{sale.TOTALNODISCOUNT}</span> </p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-left">
                                                            <p>{this.props.t("DISCOUNT", { lng: sale.TEMPLATE_LANG })}</p>

                                                        </td>
                                                        <td className="text-right">
                                                            <p><span className="total_discount">{sale.TOTALDISCOUNT}</span></p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-left">
                                                            <p>{this.props.t("VATVALUE", { lng: sale.TEMPLATE_LANG })}</p>
                                                        </td>
                                                        <td className="text-right">
                                                            <p><span className="total_withoutvat">{sale.TOTALVAT}</span> </p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-left">
                                                            <p>{this.props.t("MORETAXES", { lng: sale.TEMPLATE_LANG })}</p>
                                                        </td>
                                                        <td className="text-right">
                                                            <p><span className="total_whtaxes">{sale.TOTALTAXES}</span> </p>
                                                        </td>
                                                    </tr>
                                                    <tr >
                                                        <td className="text-left finalprice" >
                                                            <p>{this.props.t("PAYAMOUNT", { lng: sale.TEMPLATE_LANG })}</p>
                                                        </td>
                                                        <td className="text-right finalpricevalue">
                                                            <p><span className="totalpayment">{sale.TOTALPAYMENT}</span></p>
                                                        </td>
                                                    </tr>
                                                    {sale.CURRENCY && sale.CURRENCY != "EUR" ?
                                                        <tr >
                                                            <td className="text-left finalprice" >
                                                                <p>{this.props.t("TOTALPAYMENT_ON_CURRENCY", { currency: sale.CURRENCY, lng: sale.TEMPLATE_LANG })}</p>
                                                            </td>
                                                            <td className="text-right finalpricevalue">
                                                                <p><span className="totalpayment_on_currency">{sale.TOTALPAYMENT_ON_CURRENCY}</span> <span className="currency">{sale.CURRENCY ? sale.CURRENCY : "EUR"}</span></p>
                                                            </td>
                                                        </tr> : <></>}
                                                </tbody>
                                            </table>
                                            <div className="total_funny_box"></div>
                                        </div>
                                    </div>

                                    <table>
                                        <tbody>
                                            <tr>
                                                <td style={{ textAlign: "center" }}>{sale.IBAN != null ? <span className="iban_sign">{sale.IBAN}</span> : ""}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span className="cont_signs">
                                                        <div className="cont_sign_left">
                                                            <span className="sign_left">{this.props.t("ISSUANCE", { lng: sale.TEMPLATE_LANG })}</span>
                                                            <br></br>
                                                            <br></br>
                                                            <br></br>
                                                            <br></br>
                                                            <hr className="sign_hr"></hr>
                                                            <span className="fullname_sign">{this.props.t("Signature", { lng: sale.TEMPLATE_LANG })}</span>
                                                        </div>
                                                        <div className="cont_sign_right">
                                                            <span className="sign_right">{this.props.t("RECEIPT", { lng: sale.TEMPLATE_LANG })}</span>
                                                            <br></br>
                                                            <br></br>
                                                            <br></br>
                                                            <br></br>
                                                            <hr className="sign_hr"></hr>
                                                            <span className="fullname_sign">{this.props.t("Signature", { lng: sale.TEMPLATE_LANG })}</span>
                                                        </div>
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            {(sale.CORRELATIVE_INVOICE && sale.CORRELATIVE_INVOICE) ? <tr><td><small>{this.props.t("correlativeSale")} <span className="corr_invoice_span">{this.props.t(sale.CORRELATIVE_INVOICE)}</span></small></td></tr> : <></>}





                        </tbody>
                    </table>
                    <div className="mydata_footer">
                        <table>
                            <tbody>
                                <tr>
                                    <td>

                                        <span className="mydata_sign">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {sale.INVOICEUID ? <> <span>uid: </span> <span className="uid_sign">{sale.INVOICEUID}</span></> : <></>}
                                                        </td>
                                                        <td>
                                                            {sale.INVOICEMARK ? <><span>mark:</span>   <span className="mark_sign">{sale.INVOICEMARK}</span> </> : <span className="notPublished"><b>{this.props.t("notPublished")}</b></span>}
                                                        </td>
                                                        <td>
                                                            {sale.CANCELATIONMARK != null ? <span><span>cancelationMark:</span><span className="mark_sign">{sale.CANCELATIONMARK}</span></span> : ""}
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="sender_sign">
                                            {this.props.t("CREATEDBY", { lng: sale.TEMPLATE_LANG })}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }

    createPages = (sale) => {
        var pages = [];

        // var top_table=document.getElementsByClassName("top_table")[0]
        // if(top_table){
        //     var top_table_height=top_table.height;
        //     console.log(top_table_height);
        // }


        var i, j, groupEntries, chunk = 12;
        if (sale) {
            if (sale.INVOICE_ENTRIES != undefined) {

                for (i = 0, j = sale.INVOICE_ENTRIES.length; i < j; i += chunk) {
                    groupEntries = sale.INVOICE_ENTRIES.slice(i, i + chunk);
                    var groupSale = sale;
                    groupSale.entries = groupEntries;
                    var page = this.createPage(groupSale);
                    pages.push(page);
                    // do whatever
                }
            }
        }


        return pages;

    }

    getMainStyle = () => {
        if (this.props.mainStyle) {
            return <style>{this.props.mainStyle}</style>
        }
    }

    getExtraCss = () => {
        if (this.props.extracss) {
            return <style>{this.props.extracss}</style>
        }
    }

    render() {
        return <div id="template_page" className="page template_page">
            <link type="text/css" rel="stylesheet" href="../template.css" />
            <link href="https://fonts.googleapis.com/css?family=Open+Sans&family=Ubuntu&family=GFS+Neohellenic" rel="stylesheet" type="text/css"></link>
            {this.getMainStyle()}
            {this.getExtraCss()}
            {this.createPages(this.props.sale)}
        </div>;
    }

}
export default TemplateComponent;