import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import ApiClient from "../services/api-client"
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom"
import useMobileDetect from 'use-mobile-detect-hook';
import Tippy from '@tippyjs/react';


const AllFieldsRenderer = forwardRef((props, ref) => {
    const { t } = useTranslation();


    useImperativeHandle(ref, () => {
        return {
            refresh(params) {

            }
        };

    });





    // const rowData = <div className="container sales-aggrid-tooltip-inner">


    //     <div className="d-flex bd-highlight">
    //         <div className="p-1 flex-grow-1 bd-highlight"><b >{t("InvoiceType")}:</b></div>
    //         <div className="p-1 bd-highlight"><span >{props.data ? props.data.invoiceType : ""}</span></div>
    //     </div>
    //     <div className="d-flex bd-highlight">
    //         <div className="p-1 flex-grow-1 bd-highlight"><b>{t("Customer")}:</b></div>
    //         <div className="p-1 bd-highlight"><span>{props.data ? props.data.customerName : ""} ({props.data ? props.data.customerTaxPayerId : ""})</span></div>
    //     </div>
    //     <div className="d-flex bd-highlight">
    //         <div className="p-1 flex-grow-1 bd-highlight"><b >{t("issuerDate")}:</b></div>
    //         <div className="p-1 bd-highlight"><span>{props.data ? moment(props.data.invoiceDate).format('DD/MM/YYYY hh:mm') : ""}</span></div>
    //     </div>
    //     <div className="d-flex bd-highlight">
    //         <div className="p-1 flex-grow-1 bd-highlight"><b >{t("TotalWithoutVat")}:</b></div>
    //         <div className="p-1 bd-highlight"><span>{props.data ? props.data.totalWithoutVat : ""}</span></div>
    //     </div>
    //     <div className="d-flex bd-highlight">
    //         <div className="p-1 flex-grow-1 bd-highlight"><b >{t("TotalWithVat")}:</b></div>
    //         <div className="p-1 bd-highlight"><span>{props.data ? props.data.totalWithVat : ""}</span></div>
    //     </div>
    //     <div className="d-flex bd-highlight">
    //         <div className="p-1 flex-grow-1 bd-highlight"><b >{t("TotalPayment")}:</b></div>
    //         <div className="p-1 bd-highlight"><span>{props.data ? props.data.totalPayment : ""}</span></div>
    //     </div>
    //     <div className="d-flex bd-highlight">
    //         <div className="p-1 flex-grow-1 bd-highlight"><b >{t("Status")}:</b></div>
    //         <div className="p-1 bd-highlight"><span> {props.data ? props.data.mark ? props.data.cancelationMark ? <span className="badge bg-danger">{t("canceled")}</span> : <span className="badge bg-success">{t("sended")}</span> : <span className="badge bg-secondary">{t("notInMyData")}</span> : ""}</span></div>
    //     </div>
    // </div>;


    return (

        <div className="customer-grid-row">

            <div className="d-flex bd-highlight allFieldsColumnRow">
                <div className="p-0 bd-highlight">
                    <span >
                        {/* <Tippy trigger="click" content={"rowData"}>
                        <i className="fa fa-info-circle" style={{ marginRight: 6 }}></i>
                    </Tippy> */}
                        {props.data ? props.data.name + " " : ""}
                    </span>

                </div>
            </div>



            <div className="d-flex bd-highlight allFieldsColumnRow">

                <div className="p-0 bd-highlight">
                <i className="fa fa-envelope"></i>
                {
                       
                       props.data && props.data.contacts && props.data.contacts.length > 0 && props.data.contacts[0].email? <span style={{ marginRight: 6 }}>
                            
                           
                            <a href={"mailto:"+props.data.contacts[0].email}> <span style={{ marginLeft: 4 }}></span>
                             <span>
                                {props.data.contacts[0].email}
                            </span>
                            </a>
                        </span> : <></> 
                    }


                    {
                        props.data && props.data.taxpayerId? <> <span >{t("taxpayerId")}: </span><span style={{ marginRight: 6 }}>{props.data.taxpayerId}</span></> : <></>
                    }
                    
                    

                </div>

            </div>


            <div className="d-flex bd-highlight allFieldsColumnRow">
                <div className="p-0 flex-grow-1 bd-highlight">
                <i className="fa fa-phone"></i>
                    {
                        props.data && props.data.contacts && props.data.contacts.length && props.data.contacts[0].phone ? <span>
                            <a href={"tel:+ "+props.data.contacts[0].phone}>  <span style={{ marginRight: 6 }}></span>
                                { props.data.contacts[0].phone }
                            </a>
                        </span> : <></>
                    }
                </div>
            </div>


        </div >


    );
});


const ActionsRenderer = forwardRef((props, ref) => {


    const history = useHistory();
    const { t } = useTranslation();
    const detectMobile = useMobileDetect();


    const goToAddCustomer = (e) => {
        e.preventDefault();
        var _id = props.data.id;
        history.push("/customer/" + _id);
    }
    const goToSendInvoice = (e) => {
        e.preventDefault();
        var _id = props.data.id;
        var _url = "/sale?customer=" + _id
        window.open(_url, '_blank').focus();
    }
    useImperativeHandle(ref, () => {
        return {
            refresh(params) {

            }
        };
    });
    const getclass = () => {
        return detectMobile.isDesktop() ? "btn-group" : "btn-group-vertical"
    }
    return (
        <span>
            <div className={getclass()} role="group">
                <button onClick={goToSendInvoice} className="btn btn-sm btn-skin" title={t("sendInvoiceToCustomer")}><i className="fa fa-paper-plane"></i></button>
                <button onClick={goToAddCustomer} className="btn btn-sm btn-skin" title={t("editCustomer")}><i className="fa fa-edit"></i></button>
            </div>
        </span>
        // <span className="editableCell">{branch != null && branch.label != undefined ? branch.label : <span className="addtext"></span>}</span>
    );
});

export default function CustomerSearch() {

    const [content, setContent] = useState([]);
    const history = useHistory();
    const [gridApi, setGridApi] = useState(null);
    const { t } = useTranslation();
    const pagination = true;
    const paginationPageSize = 20;
    const [sheight, setSheight] = useState(window.innerHeight - 180);
    const detectMobile = useMobileDetect();


    const onGridReady = params => {
        setGridApi(params.api);
        // params.api.sizeColumnsToFit();

        window.addEventListener('resize', function () {
            setTimeout(function () {
                // params.api.sizeColumnsToFit();
                setSheight(window.innerHeight - 180);
            }, 300);
        });

        // params.api.sizeColumnsToFit();
    };
    const onQuickFilterChanged = e => {
        gridApi.setQuickFilter(e.target.value);
    };
    const goTo = id => {
        history.push("/customer/" + id);
    }

    useEffect(() => {

        var companyId = JSON.parse(localStorage.getItem("company")).id;
        ApiClient.getCompanyCustomers(companyId).then(
            response => {
                setContent(response.data);

            },
            error => {
                setContent(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString())
            }
        );

    }, []);



    return (
        <div className="container" style={{ padding: 0 }}>

            <header className="jumbotron search_header">
                <div className="d-flex bd-highlight">
                    <div className="me-auto p-0 bd-highlight">
                        <h5 className="subtitle skin">{t("searchCustomers")}</h5>
                    </div>
                    <div className="p-0 bd-highlight">
                        <button className="btn btn-skin" href="" onClick={function () { return history.push("/customer"); }}>
                            <span className="desktop-only">{t("customerAdd")} </span> <i className="fa fa-plus"></i>
                        </button>
                    </div>
                </div>
            </header>


            <div style={{ width: '100%', height: '100%' }}>
                <div className="row">
                    <div className="d-flex justify-content-between">
                        <div className="offset-md-4 col-md-4 text-center">
                            <input className="form-control" id="searchCustomersInput" placeholder={t("Search")} onChange={onQuickFilterChanged} />
                        </div>
                    </div>
                </div>
                {
                    detectMobile.isDesktop() ?
                        <div
                            id="myGrid"
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                            className="ag-theme-alpine-dark customer-search-grid"
                        >
                            <div className="ag-theme-alpine" style={{ height: sheight, width: '100%' }}>
                                <AgGridReact
                                    pagination={pagination}
                                    paginationPageSize={paginationPageSize}
                                    onGridReady={onGridReady}
                                    style={{ width: '100%', height: '100%;' }}
                                    onRowDoubleClicked={(e) => goTo(e.data.id)}
                                    rowData={content}
                                    // domLayout={'autoHeight'}
                                    frameworkComponents={{
                                        actionsRenderer: ActionsRenderer,
                                    }}
                                    overlayNoRowsTemplate={
                                        '<span style="padding: 10px;  color:#013289">' + t('noEntries') + '</span>'
                                    }
                                    gridOptions={{ suppressMenuHide: true }}
                                    defaultColDef={{
                                        flex: 1,
                                        resizable: true,
                                        sortable: true,
                                        wrapText: true,
                                        suppressMovable: true,
                                        autoHeight: true, icons: {
                                            menu: '<i style="color:white" class="fa fa-search"/>',
                                        },
                                    }}
                                >
                                    <AgGridColumn flex={2} minWidth={300} headerName={t("Name")} sortable={true} filter="agTextColumnFilter" field="name"></AgGridColumn>
                                    <AgGridColumn flex={1} minWidth={150} headerName={t("taxpayerId")} sortable={true} filter="agNumberColumnFilter" field="taxpayerId"></AgGridColumn>
                                    <AgGridColumn flex={2} minWidth={250} headerName={t("taxOffice")} sortable={true} filter="agTextColumnFilter" field="taxOffice.name"></AgGridColumn>
                                    <AgGridColumn flex={1} minWidth={100} valueFormatter={params => params.data != null ? (params.data.balance ? params.data.balance.toFixed(2) : 0.00) : 0.00} headerName={t("Balance")} sortable={true} filter="agTextColumnFilter" field="balance"></AgGridColumn>
                                    <AgGridColumn flex={1} minWidth={100} headerName={t("Actions")} cellRenderer="actionsRenderer" field="action"></AgGridColumn>

                                </AgGridReact>
                            </div>

                        </div> :

                        <div
                            id="myGrid"
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                            className="ag-theme-alpine-dark customer-mobile-table-container"
                        >
                            <div className="ag-theme-alpine" style={{ height: sheight, width: '100%' }}>
                                <AgGridReact
                                    pagination={pagination}
                                    paginationPageSize={paginationPageSize}
                                    onGridReady={onGridReady}
                                    style={{ width: '100%', height: '100%;' }}
                                    onRowDoubleClicked={(e) => goTo(e.data.id)}
                                    rowData={content}
                                    // domLayout={'autoHeight'}
                                    frameworkComponents={{
                                        actionsRenderer: ActionsRenderer,
                                        allFieldsRenderer: AllFieldsRenderer
                                    }}
                                    overlayNoRowsTemplate={
                                        '<span style="padding: 10px;  color:#013289">' + t('noEntries') + '</span>'
                                    }
                                    rowHeight={66}
                                    gridOptions={{ suppressMenuHide: true }}
                                    defaultColDef={{
                                        wrapText: false,
                                        suppressMovable: true,
                                        suppressHorizontalScroll: true,
                                        icons: {
                                            menu: '<i style="color:white" class="fa fa-search"/>',
                                        },
                                    }}
                                >
                                    <AgGridColumn flex={5} cellRenderer="allFieldsRenderer" headerName={t("")} field="name"></AgGridColumn>
                                    <AgGridColumn flex={1} headerName={t("")} cellRenderer="actionsRenderer" field="action"></AgGridColumn>

                                </AgGridReact>
                            </div>

                        </div>

                }

            </div>
        </div>
    );
}


