import React, { useState, useRef } from 'react';
import '../styles/register.css';
import { Redirect } from 'react-router-dom'
import { RECAPTCHA_KEY } from '../util/constants';
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ApiClient from "../services/api-client"
import { toast } from 'react-toastify';
import logo from '../img/timologic_login.png';



function ResePassword(props) {
    const { t } = useTranslation();


    if (props.authenticated) {
        return <Redirect
            to={{
                pathname: "/",
                state: { from: props.location }
            }} />;
    }

    return (

        <ResetPasswordForm {...props} />
    );
}



function ResetPasswordForm(props) {
    const recaptchaRef = useRef();
    const { token } = useParams();
    const [password, setPassword] = useState("");
    const [repassword, setRepassword] = useState("");


    const { t } = useTranslation();

    const handleInputChange = (event) => {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        switch (inputName) {
            case "password": {
                setPassword(inputValue);
                break;
            }
            case "repassword": {
                setRepassword(inputValue);
                break;
            }
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const recaptchaValue = recaptchaRef.current.getValue();

        if (repassword != password) {
            toast.error(t("repassNotEqualPass"));
            return;
        }

        const resetPassRequest = {

            password: password,
            captcha: recaptchaValue,
            token: token
        };

        ApiClient.changePassword(resetPassRequest).then(
            (response) => {
                var response = response.data;
                if (response.status == "SUCCESS") {
                    toast.success(t("passwordResetSuccess"));
                    props.history.push("/login");
                }
                else {
                    if (response.errors.length > 0) {
                        var error = response.errors[0];
                        toast.error(error.message);
                    }
                }


            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
    }
    const onChangeCaptcha = (value) => {
        if (value) {
            document.getElementById("signupBtn").removeAttribute("disabled");
        }
        else {
            document.getElementById("signupBtn").setAttribute("disabled", "disabled");
        }

    }

    return (<div className="login-register-container">
        <div className="signup-container container">
            <div className="signup-content row">
                <div className="link_logo" >
                    <img
                        src={logo}
                        alt="timologic myDATA app"
                        className="profile-img-card"
                    />
                    <img />
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="form-item">
                        <input type="password" name="password"
                            className="form-control" placeholder={t("Password")}
                            value={password} onChange={handleInputChange} minLength={6} required />
                    </div>
                    <div><br></br></div>
                    <div className="form-item">
                        <input type="password" name="repassword"
                            className="form-control" placeholder={t("rePassword")}
                            value={repassword} onChange={handleInputChange} minLength={6} required />
                    </div>
                    <div className="form-item text-center recapctha-container">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={RECAPTCHA_KEY}
                            onChange={onChangeCaptcha}
                        />
                    </div>
                    <hr></hr>
                    <div className="form-item">
                        <button type="submit" disabled={true} id="signupBtn" className="btn btn-block btn-skin" >{t("Reset")}</button>
                    </div>

                </form>
            </div>
        </div>
    </div>

    );
}

export default ResePassword