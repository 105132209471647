import React from "react";
import Commons from '../../util/commons'


var QRCode = require('qrcode.react');


class TemplateComponent extends React.Component {


    createEntries = (entries) => {
        var rows = [];


        var rowlimit = 15;


        if (entries != undefined) {
            var diff = rowlimit - entries.length;
            var emptyEnries = [];
            if (diff > 0) {
                for (var i = 0; i < diff - 1; i++) {
                    var emptyEntry = {
                        id: "empty_" + i,
                        NAME_LIST: "",
                        QUANTITY_LIST: "",
                        MU_LIST: "",
                        UP_LIST: "",
                        DISCOUNT: "",
                        VAT_LIST: "",
                        PRICE_NOVAT_LIST: "",
                        PRICE_LIST: ""
                    }
                    emptyEnries.push(emptyEntry);
                }
            }

            entries = entries.concat(emptyEnries);
            entries.forEach(entry => {
                var row = <tr key={"entry_" + entry.id} className="products">
                    <td>
                        <span className="item_name">
                            <p><small>{entry.NAME_LIST}</small></p>
                        </span>
                    </td>
                    <td>
                        <span className="item_quantity">
                            <p>{entry.QUANTITY_LIST}</p>
                        </span>
                    </td>
                    <td>
                        <span className="item_unit_price">
                            <p>{entry.UP_LIST}</p>
                        </span>
                    </td>
                    <td>
                        <span className="item_price_novat">
                            <p>{entry.PRICE_NOVAT_LIST}</p>
                        </span>
                    </td>
                    <td>
                        <span className="item_price_withvat">
                            <p>{entry.PRICE_LIST}</p>
                        </span>
                    </td>
                </tr>
                rows.push(row)
            });
        }


        return (rows);
    }



    qrcodeValue = (invoiceUid) => {
        var _host = window.location.host

        return _host + "/mydata/" + invoiceUid
    }

    getLogo = (logo) => {
        if (logo) {
            if (!logo.includes("blob:")) {
                logo += "?" + Date.now();
            }
            return <img alt="" src={logo} className="logo_img"></img>
        }
    }

    createPage = (sale) => {
        return (
            <div className="invoice-box" key={"box_" + Math.floor(Math.random() * 10000)} >
                <div className="top_table">
                    <table>
                        <tbody>
                            <tr className="top">
                                <td>
                                    <table className="header_table">
                                        <tbody>
                                            <tr>
                                                <td className="logo_container">
                                                    {this.getLogo(sale.LOGO)}
                                                </td>
                                            </tr>
                                            <tr>
                                                {/* <td className="thermal_issuer">{this.props.t("Issuer")}</td> */}
                                                <td className="issuer_container">
                                                    <span className="issuer_name template_skin">{sale.ISSUER_NAME}</span>
                                                    <p> <span className="issuer_subjectField template_skin ">{sale.ISSUER_SUBJECTFIELD}</span></p>
                                                    <p><span className="issuer_address template_skin ">{sale.ISSUER_ADDRESS}</span></p>

                                                    <p>
                                                        <span className=" template_skin ">{this.props.t("TAXPAYER_ID", { lng: sale.TEMPLATE_LANG })}: </span><span className="issuer_vat template_skin ">{sale.ISSUER_VAT}</span>
                                                        &nbsp;
                                                        <span className=" template_skin ">{this.props.t("DOY", { lng: sale.TEMPLATE_LANG })}: </span> <span className="issuer_doy template_skin ">{sale.ISSUER_DOY}</span>
                                                        &nbsp;
                                                        <span className=" template_skin ">{this.props.t("GEMH", { lng: sale.TEMPLATE_LANG })}: </span> <span className="issuer_gemh template_skin ">{sale.ISSUER_GEMH}</span>
                                                    </p>
                                                    <p>
                                                        <span className=" template_skin ">{this.props.t("PHONE", { lng: sale.TEMPLATE_LANG })}: </span> <span className="issuer_phone template_skin ">{sale.ISSUER_PHONE}</span>
                                                        &nbsp;
                                                        <span className=" template_skin ">{this.props.t("EMAIL", { lng: sale.TEMPLATE_LANG })}: </span> <span className="issuer_email template_skin ">{sale.ISSUER_EMAIL}</span>
                                                    </p>
                                                    <span className="futureUse"></span>
                                                </td>
                                            </tr>
                                            <hr></hr>
                                            <tr>
                                                {/* <td className="thermal_counterpart">{this.props.t("Customer")}</td> */}
                                                <td className="issuer_container">
                                                    <span className="issuer_name template_skin">{sale.CP_NAME}</span>
                                                    <p> <span className="issuer_subjectField template_skin ">{sale.CP_SUBJECT_FIELD}</span></p>
                                                    <p><span className="issuer_address template_skin ">{sale.CP_ADDRESS}</span></p>

                                                    <p>
                                                        <span className=" template_skin ">{this.props.t("TAXPAYER_ID", { lng: sale.TEMPLATE_LANG })}: </span><span className="issuer_vat template_skin ">{sale.CP_VAT}</span>
                                                        &nbsp;
                                                        <span className=" template_skin ">{this.props.t("DOY", { lng: sale.TEMPLATE_LANG })}: </span> <span className="issuer_doy template_skin ">{sale.CP_TAXOFFICE}</span>                                                    
                                                    </p>
                                                    <p>
                                                        <span className=" template_skin ">{this.props.t("CP_CITY", { lng: sale.TEMPLATE_LANG })}: </span><span className="issuer_vat template_skin ">{sale.CP_CITY}</span>
                                                        &nbsp;
                                                        <span className=" template_skin ">{this.props.t("COUNTRY", { lng: sale.TEMPLATE_LANG })}: </span> <span className="issuer_doy template_skin ">{sale.COUNTRY}</span>                                                    
                                                        &nbsp;
                                                        <span className=" template_skin ">{this.props.t("CP_PAYTYPE", { lng: sale.TEMPLATE_LANG })}: </span><span className="issuer_vat template_skin ">{sale.CP_PAYTYPE}</span>
                                                    </p>


                                                    <span className="futureUse"></span>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <hr></hr>
                    <div className="main_info">
                        <span>{sale.INVOICE_TYPE} {sale.INVOICE_NUMBER} {sale.INVOICE_DATE}</span>

                    </div>

                </div>
                <br></br>
                <div className="product_container">
                    <div className="background_img_container"></div>
                    <table className="product_table">
                        <thead>
                            <tr className="heading">
                                <td className="description_head_td">
                                    <p>{this.props.t("PROD_SERV")}</p>
                                </td>
                                <td className="quantity_head_td">
                                    <p>{this.props.t("QUANTITY")}</p>
                                </td>
                                <td className="up_head_td">
                                    <p> {this.props.t("UNIT_PRICE", { lng: sale.TEMPLATE_LANG })}</p>
                                </td>
                                <td className="pricenovat_head_td">
                                    <p>  {this.props.t("PRICE_WITHOUT_VAT", { lng: sale.TEMPLATE_LANG})}</p>
                                </td>
                                <td className="price_head_td">
                                    <p> {this.props.t("PRICE_WITH_VAT", { lng: sale.TEMPLATE_LANG})}</p>
                                </td>
                            </tr>
                        </thead>
                        <tbody>

                            {this.createEntries(sale.entries)}
                        </tbody>
                    </table>

                </div>
                <div className="footer_container">
                    <table className="footer_table">
                        <tbody>
                            <tr>
                                <td>
                                    <table>
                                        <tbody>
                                            <tr>
                                              
                                                <td>

                                                    <span className="qrcode_img">
                                                        <QRCode
                                                            size={100}
                                                            bgColor={"#ffffff"}
                                                            fgColor={"#000000"}
                                                            level={"L"}
                                                            includeMargin={false}
                                                            renderAs={"svg"}
                                                            value={this.qrcodeValue(sale.INVOICEUID)}
                                                        />
                                                    </span>
                                                </td>
                                                <td>
                                                    <div className="cont_notation">
                                                        <span className="template_skin bold">{this.props.t("NOTES", { lng: sale.TEMPLATE_LANG })}</span>
                                                        <div className="cont_notation_inner">
                                                            <span className="notes">{sale.NOTES}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>

                                            </tr>
                                        </tbody>
                                    </table>
                                </td>



                            </tr>
                            <tr>

                                <div className="total_container">



                                    <div className="totals">
                                        <table className="totals_table">
                                            <tbody>
                                                <tr>
                                                    <td className="text-left" >
                                                        <p>{this.props.t("TOTALVALUE", { lng: sale.TEMPLATE_LANG })}</p>
                                                    </td>
                                                    <td className="text-right">
                                                        <p><span className="total_nodiscount">{sale.TOTALNODISCOUNT}</span> </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left">
                                                        <p>{this.props.t("DISCOUNT", { lng: sale.TEMPLATE_LANG })}</p>

                                                    </td>
                                                    <td className="text-right">
                                                        <p><span className="total_discount">{sale.TOTALDISCOUNT}</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left">
                                                        <p>{this.props.t("VATVALUE", { lng: sale.TEMPLATE_LANG })}</p>
                                                    </td>
                                                    <td className="text-right">
                                                        <p><span className="total_withoutvat">{sale.TOTALVAT}</span> </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left">
                                                        <p>{this.props.t("MORETAXES", { lng: sale.TEMPLATE_LANG })}</p>
                                                    </td>
                                                    <td className="text-right">
                                                        <p><span className="total_whtaxes">{sale.TOTALTAXES}</span> </p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td className="text-left finalprice" >
                                                        <p>{this.props.t("PAYAMOUNT", { lng: sale.TEMPLATE_LANG })}</p>
                                                    </td>
                                                    <td className="text-right finalpricevalue">
                                                        <p><span className="totalpayment">{sale.TOTALPAYMENT}</span></p>
                                                    </td>
                                                </tr>
                                                {sale.CURRENCY && sale.CURRENCY != "EUR" ?
                                                    <tr >
                                                        <td className="text-left finalprice" >
                                                            <p>{this.props.t("TOTALPAYMENT_ON_CURRENCY", { currency: sale.CURRENCY, lng: sale.TEMPLATE_LANG })}</p>
                                                        </td>
                                                        <td className="text-right finalpricevalue">
                                                            <p><span className="totalpayment_on_currency">{sale.TOTALPAYMENT_ON_CURRENCY}</span> <span className="currency">{sale.CURRENCY ? sale.CURRENCY : "EUR"}</span></p>
                                                        </td>
                                                    </tr> : <></>}
                                            </tbody>
                                        </table>
                                    </div>


                                </div>


                            </tr>

                        </tbody>
                    </table>
                    <div className="mydata_footer">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <span className="mydata_sign">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <span>uid: </span> <span className="uid_sign">{sale.INVOICEUID}</span>
                                                        </td>
                                                        <td>
                                                            <span>mark:</span>   <span className="mark_sign">{sale.INVOICEMARK}</span>
                                                        </td>
                                                        <td>
                                                            {sale.CANCELATIONMARK != null ? <span><span>cancelationMark:</span><span className="mark_sign">{sale.CANCELATIONMARK}</span></span> : ""}
                                                        </td>
                                                    </tr>
                                                </tbody>

                                            </table>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="sender_sign">
                                            {this.props.t("CREATEDBY", { lng: sale.TEMPLATE_LANG })}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }

    createPages = (sale) => {
        var pages = [];

        var i, j, groupEntries, chunk = 12;
        if (sale) {
            if (sale.INVOICE_ENTRIES != undefined) {

                for (i = 0, j = sale.INVOICE_ENTRIES.length; i < j; i += chunk) {
                    groupEntries = sale.INVOICE_ENTRIES.slice(i, i + chunk);
                    var groupSale = sale;
                    groupSale.entries = groupEntries;
                    var page = this.createPage(groupSale);
                    pages.push(page);
                    // do whatever
                }
            }
        }


        return pages;

    }

    getMainStyle = () => {
        if (this.props.mainStyle) {
            return <style>{this.props.mainStyle}</style>
        }
    }

    getExtraCss = () => {
        if (this.props.extracss) {
            return <style>{this.props.extracss}</style>
        }
    }

    render() {
        return <div id="template_page" className="page template_page">
            <link type="text/css" rel="stylesheet" href="../template_thermal.css" />
            <link href="https://fonts.googleapis.com/css?family=Open+Sans&family=Ubuntu&family=GFS+Neohellenic" rel="stylesheet" type="text/css"></link>
            {this.getMainStyle()}
            {this.getExtraCss()}
            {this.createPages(this.props.sale)}
        </div>;
    }

}
export default TemplateComponent;