import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import ApiClient from "../services/api-client"
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { useHistory } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';


export default function CompanyBusinessCard() {

    const [content, setContent] = React.useState(0);
    const history = useHistory();
    const pagination = true;
    const paginationPageSize = 10;
    const { t } = useTranslation();
    const { taxpayerId } = useParams();
    const { branchId } = useParams();


    useEffect(() => {

        ApiClient.getCompanyCard(taxpayerId,branchId).then(
            response => {
                console.log(response.data)

                setContent(response.data)
            },
            error => {
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString())
            }
        );

    }, []);

    return (
        <div className="container">
            <div className="card business-card">
                <div className="card-body">
                    <p>
                        {content.companyName}
                    </p>
                    <p>
                        {content ? content.companySubjectField: ""}
                    </p>
                    <p>
                        {content ? content.companyAddress : ""}
                    </p>

                </div>
            </div>
        </div>
    );
}


