import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from "react";
import { toast } from 'react-toastify';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import Select from 'react-select'
import { useForm  } from "react-hook-form";
import ApiClient from "../../services/api-client"
import { CustomConfirmAlert as confirmAlert} from '../custom-confirm-alert';
import Commons from '../../util/commons'

const ActionsRenderer = forwardRef((props, ref) => {

    const [disabled, setDisabled] = useState(false);

    const { t, i18n } = useTranslation();

    const hangleDeleteRow = (e) => {
        e.preventDefault();

        let rowData = [];
        props.agGridReact.api.forEachNode(node => rowData.push(node.data));
        debugger

        confirmAlert({
            title: t("submitConfirm"),
            message: t("areYouSure"),
            buttons: [
                {
                    label: t('Yes'),
                    onClick: () => {
                        var id = props.data.id;
                        if (id != undefined) {
                            ApiClient.deleteInvoiceTypeClassification(id).then(
                                response => {
                                    rowData.splice(props.rowIndex, 1)
                                    props.agGridReact.api.setRowData(rowData)
                                    props.colDef.cellRendererParams.onRemoveRow();
                                    toast.success(t("deleteSucceded"))

                                },
                                error => {
                                    toast.error("Error: " + error.data)
                                    console.error(error.data)
                                }
                            )
                        }
                        else {
                            rowData.splice(props.rowIndex, 1)
                            props.agGridReact.api.setRowData(rowData)
                            props.colDef.cellRendererParams.onRemoveRow();
                        }

                    }
                },
                {
                    label: t('No'),
                    onClick: () => {
                    }
                }
            ]
        });






    }

    useImperativeHandle(ref, () => {
        var countDisplayedRows = props.agGridReact.api.getDisplayedRowCount() - 1;
        if (props.rowIndex == countDisplayedRows) {
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }
        return {
            refresh(params) {

            }
        };
    });
    return (
        <span>
            <button onClick={hangleDeleteRow} disabled={disabled} className="btn btn-sm btn-danger circle-btn"><i className="fa fa-times"></i></button>
        </span>
    );
});
const doubleValRenderer = forwardRef((props, ref) => {


    const [val, setVal] = useState(props.value);
    const { t, i18n } = useTranslation();

    useImperativeHandle(ref, () => {
        return {
            refresh(params) {
                var val = props.getValue();
                if (val != undefined) {
                    var _val = parseFloat(val.toString().replace(",", ".")).toFixed(2);
                    setVal(_val);
                    props.node.setDataValue(props.column.colId, _val);
                }

            }
        };
    });
    if (props.colDef.field == "price") {
        return <span className="editableCell">{val != null && val != "" ? parseFloat(val).toFixed(2) : <div><span className="addtext"></span><div className="addtextafter"><i className="fa fa-edit"></i></div></div>}</span>

    }
    else {
        return <span className="editableCell">{val && val != "" ? parseFloat(val).toFixed(2) : <div><span className="addtext"></span><div className="addtextafter"><i className="fa fa-edit"></i></div></div>}</span>

    }
});
const SelfPricingrenderer = forwardRef((props, ref) => {


    const [checked, setChecked] = useState(props.value?props.value:false);
    const [disabled, setDisabled] = useState(false);
    const { t, i18n } = useTranslation();

    const onChangeStatus = (e) => {
        props.node.setDataValue("selfPricing", e.target.checked);
        setTimeout(function () {
            props.api.stopEditing();

            var opts = [];
            var expenses = Commons.isOnGroup(props.colDef.cellRendererParams.myDataInvoiceType, "isExpense") ||  props.node.data.selfPricing ;
            ApiClient.getClassificationCategoriesOfMyDataInvoice(props.colDef.cellRendererParams.myDataInvoiceType, expenses).then(response => {
                var data = response.data;
                var _optionIncClassCategories = [];
                
                data.forEach(element => {
                    var _classificationCategory;
                    var _classificationValues;
                    _classificationCategory = element.classificationCategory;
                    _classificationValues = element.classificationValues;

                    var prefix = _classificationCategory.replace("CATEGORY_", "") + ") ";
                    _optionIncClassCategories.push({
                        value: _classificationCategory,
                        label: prefix + t(_classificationCategory),
                        classificationValues: _classificationValues,
                    });
                });
                
                var row_dom = document.querySelector(`.classificationGrid .ag-center-cols-container >  div[row-index="${props.node.rowIndex}"][role="row"]`)
                var selfpricing_invoicearr = ["invoice1_1", "invoice1_4", "invoice1_5", "invoice1_6", "invoice2_1", "invoice2_4", "invoice5_1", "invoice5_2"]

                setTimeout(function () {
                    props.colDef.cellRendererParams.setIncomeClassCategories(_optionIncClassCategories)
                    props.node.setDataValue("classificationCategory", { value: undefined, label: undefined });
                    props.node.setDataValue("classificationValue", { value: undefined, label: undefined });

                    var el = row_dom.querySelector("input[type='checkbox']");

                    if ((props.data.invoiceType)) {
                        if (!selfpricing_invoicearr.includes(props.colDef.cellRendererParams.myDataInvoiceType)) {

                            el.disabled = true;
                        }
                        else {
                            el.disabled = false;
                        }
                    }
                    else {
                        el.disabled = false;
                    }


                }, 100);
            },
                error => {
                    console.error(
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString())
                }
            );
        })
    }



    useImperativeHandle(ref, () => {
        return {
            refresh(params) {
                var val = props.getValue();
                if (val != undefined) {
                    setChecked(val);
                }

            }
        };
    });
    return <span className="editableCell"><input onChange={onChangeStatus} disabled={disabled} type="checkbox" checked={checked}></input></span>
});
const CheckboxRenderer = forwardRef((props, ref) => {


    const [checked, setChecked] = useState(props.value?props.value:false);
    const [disabled, setDisabled] = useState(false);
    const { t, i18n } = useTranslation();

    const onChangeStatus = (e) => {
        props.node.setDataValue("isPercent", e.target.checked);
    }
    useImperativeHandle(ref, () => {
        return {
            refresh(params) {
                var val = props.getValue();
                if (val != undefined) {
                    setChecked(val);
                }

            }
        };
    });
    return <span className="editableCell"><input onChange={onChangeStatus} disabled={disabled} type="checkbox" checked={checked}></input></span>
});
const IncomingClassCategoryRenderer = forwardRef((props, ref) => {
    const [mu, setMU] = useState(props.value);
    useImperativeHandle(ref, () => {
        return {
            refresh(params) {
                var val = props.getValue();
                if (val != undefined) {
                    setMU(val);
                }

            }
        };
    });
    return (
        <span className="editableCell">{mu.label  ? mu.label : <div><span className="addtext"></span><div className="addtextafter"><i className="fa fa-edit"></i></div></div>}</span>


    );
});
const IncomingClassCategoryEditor = forwardRef((props, ref) => {
    const [selectedVal, setSelectedVal] = useState(props.value);
    const [editing, setEditing] = useState(true);
    const refContainer = useRef(null);


    useEffect(() => {
        focus();
    }, []);

    useEffect(() => {
        if (props.colDef.cellRendererParams.myDataInvoiceType == undefined) {
            props.api.stopEditing();
        }
    }, [props.colDef.cellRendererParams.myDataInvoiceType]);

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return selectedVal != null ? selectedVal : props.value;
            },

            isPopup() {
                return true;
            }
        };
    });




    useEffect(() => {
        if (!editing) {

            props.api.stopEditing();
        }
    }, [editing]);

    const focus = () => {
        window.setTimeout(() => {
            let container = ReactDOM.findDOMNode(refContainer.current);
            if (container) {
                container.focus();
            }
        });
    };

    const { t } = useTranslation();

    const onSelectChange = val => {

        var old_val = props.node.data.classificationCategory.value;
        setSelectedVal(val);
        console.error(val)
        setTimeout(function () {
            props.api.stopEditing();


            props.node.setDataValue("classificationCategory", val);
            props.node.setDataValue("isPercent", true);
            props.node.setDataValue("amount", 100);

            var opts = [];

            val.classificationValues.forEach(element => {
                opts.push({
                    value: element,
                    label: t(element) + " (" + element + ")",
                });
            });

            props.node.setDataValue("classificationValues", opts);
            //if classificationCategory changed
            if (old_val != val.value) {

                props.node.setDataValue("classificationValue", { value: undefined, label: undefined });
            }

        })

    }
    const onMouseOver = e => {
        refContainer.current.setState({ isOpen: true })
        let container = ReactDOM.findDOMNode(e.target).parentNode.getElementsByTagName("input")[0];
        if (container != undefined) {
            container.focus();
        }
    }


    return (<div style={{ width: props.column.actualWidth + 'px' }}>

        <span onMouseOver={onMouseOver} className="selectCell">
            <Select
                autosize={true}
                menuIsOpen={true}
                defaultValue={selectedVal}
                onChange={onSelectChange}
                onChangeValue={onSelectChange}
                options={props.colDef.cellRendererParams.options} ref={refContainer}>
            </Select>
        </span>
    </div>
    );
});
const IncomingClassValueRenderer = forwardRef((props, ref) => {
    const [val, setVal] = useState(props.value);
    const { t, i18n } = useTranslation();
    const [editIcon, setEditIcon] = useState(<></>);
    
    
    var rowNode = props.agGridReact.api.getRowNode(props.rowIndex);


    useImperativeHandle(ref, () => {
        return {
            refresh(params) {
                var val = props.getValue();
                if (val != undefined) {
                    // var rowNode = props.agGridReact.api.getRowNode(props.rowIndex);
                    setVal(val);
                    // debugger
                    // if(rowNode.data.classificationCategory.value!=null){
                    //     setEditIcon(<i className="fa fa-edit"></i>); 
                    // }
                    // else{
                    //     setEditIcon(<></>)
                    // }
                }

            }
        };
    });
    return (
        <span className="editableCell">{val.label  ? val.label :  rowNode.data.classificationCategory.value!=null ?   <div><span className="addtext"></span><div className="addtextafter"><i className="fa fa-edit"></i></div></div>:   <div><span className="addtext"></span><div className="addtextafter"></div></div>}</span>
    );
});
const IncomingClassValueEditor = forwardRef((props, ref) => {
    const [selectedVal, setSelectedVal] = useState(props.value);
    const [editing, setEditing] = useState(true);
    const refContainer = useRef(null);


    useEffect(() => {

        focus();
    }, []);


    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return selectedVal != null ? selectedVal : props.value;
            },

            isPopup() {
                return true;
            }
        };
    });

    useEffect(() => {
        if (props.data.classificationCategory.value == undefined) {
            props.api.stopEditing();
        }
    }, [props.data.classificationCategory.value]);

    useEffect(() => {
        if (!editing) {
            props.api.stopEditing();
        }
    }, [editing]);

    const focus = () => {
        window.setTimeout(() => {
            let container = ReactDOM.findDOMNode(refContainer.current);
            if (container) {
                container.focus();
            }
        });
    };


    const onSelectChange = val => {
        setSelectedVal(val);
        setTimeout(function () {
            props.api.stopEditing();
            props.node.setDataValue("classificationValue", val);

        })

    }
    const onMouseOver = e => {
        refContainer.current.setState({ isOpen: true })
        let container = ReactDOM.findDOMNode(e.target).parentNode.getElementsByTagName("input")[0];
        if (container != undefined) {
            container.focus();
        }
    }


    return (<div style={{ width: props.column.actualWidth + 'px' }}>

        <span onMouseOver={onMouseOver} className="selectCell">
            <Select
                autosize={true}
                menuIsOpen={true}
                defaultValue={selectedVal}
                onChange={onSelectChange}
                onChangeValue={onSelectChange}
                options={props.node.data.classificationValues} ref={refContainer}>
            </Select>
        </span>
    </div>
    );
});
const InvoiceTypeClassificationsGrid = forwardRef((props, ref) => {
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const { t, i18n } = useTranslation();
    const { formState: { errors }, trigger } = useForm();
    const [hasError, setHasError] = useState(false);

    const rowStyle = { background: 'white' };


    useImperativeHandle(ref, () => ({

        transformData(data) {
            var newData = [];
            debugger;
            data.forEach(el => {

                var values = [];
                el.classificationValues.forEach(e => {
                    values.push({
                        value: e,
                        label: t(e) + "(" + e + ")"
                    })
                })

                var prefix;
                prefix = el.classificationCategory.replace("CATEGORY_", "") + ") ";
                var fel = {
                    id: el.id,
                    classificationCategory: { value: el.classificationCategory, label: prefix + t(el.classificationCategory) },
                    classificationValue: { value: el.classificationValue, label: t(el.classificationValue) + "(" + el.classificationValue + ")" },
                    selfPricing: el.selfPricing,
                    isPercent: el.isPercent,
                    amount: el.amount,
                    classificationValues:values
                }
                newData.push(fel)
            });
            return newData;
        },
        toogleCheckbox() {
            // console.log( props.node)
            var row_dom = document.querySelector(`.classificationGrid .ag-center-cols-container >  div[role="row"]`)
            var selfpricing_invoicearr = ["invoice1_1", "invoice1_4", "invoice1_5", "invoice1_6", "invoice2_1", "invoice2_4", "invoice5_1", "invoice5_2"]

            setTimeout(function () {

                var el = row_dom.querySelector("input[type='checkbox']");
                if (el) {
                    if ((props.myDataInvoiceType)) {
                        if (!selfpricing_invoicearr.includes(props.myDataInvoiceType)) {

                            el.disabled = true;
                        }
                        else {
                            el.disabled = false;
                        }
                    }
                    else {
                        el.disabled = false;
                    }

                }

            }, 100);
        },
        getData() {
            var items = [];
            var _items = [];
            gridApi.forEachNode(node => {

                items.push(node.data);

            });
            items.pop();
            items.forEach(element => {
                var obj = {}
                obj = {
                    "id": element.id,
                    "classificationCategory": element.classificationCategory.value,
                    "classificationValue": element.classificationValue.value,
                    "selfPricing": element.selfPricing,
                    "isPercent": element.isPercent,
                    "amount": element.amount
                }
                _items.push(obj);

            });
            return _items;
        },
        hasError() {
            return hasError;
        },
        setData(data) {
            if (data != undefined) {
                var newdata = [];
                data.forEach(e => {
                    if (e.selfPricing) {
                        e.classificationCategory = e.classificationCategory;
                        e.classificationValue = e.classificationValue;
                    }
                    newdata.push(e)
                })
                newdata.push({ classificationValue: { value: undefined, label: undefined }, classificationCategory: { value: undefined, label: undefined } },)
                gridApi.setRowData(newdata)
            }
            else {
                data = [];
                data.push({ classificationValue: { value: undefined, label: undefined }, classificationCategory: { value: undefined, label: undefined } })
                gridApi.setRowData(data)
            }
        }

    }));


    const isValid = (nodeData) => {
        return nodeData.data.classificationCategory.value != ""
            && nodeData.data.classificationCategory.value != undefined
            && nodeData.data.classificationValue.value != undefined
            && nodeData.data.classificationValue.value != ""
    }


    const onGridReady = params => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        window.addEventListener('resize', function () {
            setTimeout(function () {
                // params.api.sizeColumnsToFit();

            });
        });
        var data = props.productClassifications;
        if (data != undefined) {
            data.push({ classificationValue: { value: undefined, label: undefined }, classificationCategory: { value: undefined, label: undefined } })
            params.api.setRowData(data)
        }
        else {
            data = [];
            data.push({ classificationValue: { value: undefined, label: undefined }, classificationCategory: { value: undefined, label: undefined } })
            params.api.setRowData(data)
        }

    };

    const addRow = () => {

        // var _data = gridApi.gridOptionsWrapper.gridOptions.rowData;
        var data = { classificationValue: { value: undefined, label: undefined }, classificationCategory: { value: undefined, label: undefined } };
        // gridApi.updateRowData({ add: data, addIndex: -1 });

        let rowData = [];
        gridApi.forEachNode(node => rowData.push(node.data));

        rowData.push(data)
        gridApi.setRowData(rowData)


        var countDisplayedRows = gridApi.getDisplayedRowCount() - 1;
        gridApi.ensureIndexVisible(countDisplayedRows, 'top');
    }
    const toastId = useRef(null);
    const onCellValueChanged = (params) => {
        // params.node.rowIndex
        var nodeIndex = params.node.rowIndex;
        var countDisplayedRows = gridApi.getDisplayedRowCount() - 1;
        if (nodeIndex == countDisplayedRows && params.newValue != undefined) {
            addRow(nodeIndex);
        }
        var items = [];
        gridApi.forEachNode(node => {
            items.push(node);
        });
        items.pop();
        items.forEach(node => {



            if (params.node.data.invoiceType &&
                params.node.data.classificationCategory &&
                params.node.data.classificationValue &&
                node.data.classificationCategory &&
                node.data.classificationValue &&
                node.data.invoiceType && nodeIndex != node.rowIndex) {

                var cond = params.node.data.classificationCategory.value == node.data.classificationCategory.value &&
                    params.node.data.classificationValue.value == node.data.classificationValue.value &&
                    params.node.data.invoiceType.value == node.data.invoiceType.value

                if (cond) {
                    var row_dom = document.querySelector(`.classificationGrid .ag-center-cols-container >  div[row-index="${params.node.rowIndex}"][role="row"]`)
                    row_dom.style.backgroundColor = '#ffb09c'
                    setTimeout(function () {
                        if (!toast.isActive(toastId.current)) {
                            toastId.current = toast.error(t("duplicateInvoiceTypeCategoryCategoryValue"));
                        }
                        setHasError(true)
                    }, 100)
                    return;
                }

            }
        });

    }

    const getRowStyle = params => {


        if (params.node.rowIndex == params.api.rowModel.rowsToDisplay.length - 1) {
            return {
                background: '#d2f6fac7'
            };

        }
        else {
            if (!isValid(params.node)) {
                setHasError(true)
                return { background: '#ffb09c' };
            }
            else {
                setHasError(false)
                if (params.node.data.global) {
                    return { background: '#eee' };
                }
                else {
                    return { background: 'white' };
                }
            }
        }
    };

    const onRemoveRow = () => {
        var rowData = [];
        if (gridApi != undefined) {
            gridApi.forEachNode(node => rowData.push(node.data));
            rowData.pop();
        }
    }

    return (
        <div className="row">

            <div className="col-md-12">
                <div className="ag-theme-alpine editableGrid classificationGrid" style={{

                    width: '100%', height: 240,
                }}>
                    <AgGridReact
                        onGridReady={onGridReady}
                        rowStyle={rowStyle}
                        getRowStyle={getRowStyle}
                        style={{ width: '100%', height: '240px' }}
                        onCellValueChanged={onCellValueChanged}
                        stopEditingWhenGridLosesFocus={true}
                        borders={true}
                        suppressRowTransform={true}
                        singleClickEdit={true}
                        skipHeaderOnAutoSize={true}
                        defaultColDef={{
                            flex: 1,
                            resizable: false,
                            sortable: false,
                            wrapText: true,
                            suppressMovable:true,
                        }}
                        frameworkComponents={{
                            incomingClassValueRenderer: IncomingClassValueRenderer,
                            incomingClassValueEditor: IncomingClassValueEditor,
                            incomingClassCategoryRenderer: IncomingClassCategoryRenderer,
                            incomingClassCategoryEditor: IncomingClassCategoryEditor,
                            doubleValRenderer: doubleValRenderer,
                            actionsRenderer: ActionsRenderer,
                            checkboxRenderer: CheckboxRenderer,
                            selfPricingrenderer: SelfPricingrenderer
                        }}
                    >

                        {/* suppressSizeToFit={true}  */}
                        <AgGridColumn hide={true} field="classificationValues"></AgGridColumn>
                        <AgGridColumn flex={1} minWidth={150} headerName={t("selfPricing")} cellRenderer="selfPricingrenderer" cellRendererParams={{ myDataInvoiceType: props.myDataInvoiceType,setIncomeClassCategories:props.setIncomeClassCategories }} field="selfPricing"></AgGridColumn>
                        <AgGridColumn flex={3} minWidth={200} headerName={t("incomingClassificationCategoryType")} cellRendererParams={{ options: props.incomeClassCategories, myDataInvoiceType: props.myDataInvoiceType }} editable={true} cellEditor="incomingClassCategoryEditor" cellRenderer="incomingClassCategoryRenderer" field="classificationCategory"></AgGridColumn>
                        <AgGridColumn flex={3} minWidth={200} headerName={t("incomingClassificationValueType")} cellEditor="incomingClassValueEditor" cellRenderer="incomingClassValueRenderer" editable={true} field="classificationValue"></AgGridColumn>
                        <AgGridColumn flex={1} minWidth={150} headerName={t("percent")} cellRenderer="checkboxRenderer" field="isPercent"></AgGridColumn>
                        <AgGridColumn flex={1} minWidth={100} headerName={t("Amount")} editable={true} cellRenderer="doubleValRenderer" field="amount"></AgGridColumn>
                        <AgGridColumn flex={1} minWidth={100} headerName={t("Actions")} cellRenderer="actionsRenderer" cellRendererParams={{ onRemoveRow: onRemoveRow }} field="name"></AgGridColumn>

                    </AgGridReact>




                </div>
            </div>
            <div><br /></div>

        </div>
    );
});
export default InvoiceTypeClassificationsGrid 