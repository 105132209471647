import React from "react";
import { useForm } from "react-hook-form";
import ApiClient from "../services/api-client"
import { useTranslation } from 'react-i18next';
import { VIVA_VIEW_TRANSACTION } from '../util/constants';


export default function ChoosePackage() {



    const { formState: { errors } } = useForm();
    const { t } = useTranslation();

    const createReccuringPaymentOrder = (e,packId) => {
        ApiClient.createReccuringPaymentOrder(packId).then(
            (response) => {
                console.log(response)
                if (response.data.Success) {
                    localStorage.setItem("packId",packId);
                    window.location = VIVA_VIEW_TRANSACTION + "/web/checkout?ref=" + response.data.OrderCode;
                }
            },
            error => {
                console.error(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString())
            }
        )
    }
   
    return (
        <div className="container text-center">
            <br></br><br></br><br></br><br></br><br></br><br></br>
            <h3>{t("choosePackage")}</h3>
            <br></br>
            <br></br><br></br>

            <hr></hr>

            <section id="pricing" className="pricing">

                <div className="container" data-aos="fade-up">

                    <header className="section-header">
                        <h5>Οι Τιμές μας</h5>
                    </header>

                    <div className="row gy-4" data-aos="fade-left">

                        <div className="offset-md-4 col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="100">
                            <div className="box">

                                <h3 style={{color: "#65c600"}}>Μικρές επιχειρήσεις</h3>
                                <div className="price">5<sup>€</sup><span> / μήνα</span></div>
                                <ul>
                                    <li>10 Τιμολόγια</li>
                                    <li>ΑΑΔΕ- Σύνδεση με myDATA</li>
                                    <li className="na">Απεριόριστα τιμολόγια</li>
                                </ul>
                                <button className="btn btn-skin payvivabtn" onClick={e=>createReccuringPaymentOrder(e,"PACKAGE_0")}>{t("startNow")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}