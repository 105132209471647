import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from "react";
import ApiClient from "../services/api-client"
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom"
import { CustomConfirmAlert as confirmAlert } from '../components/custom-confirm-alert';
import moment from 'moment'
import BlockUi from 'react-block-ui';
import { toast } from 'react-toastify';
import CustomDateComponent from '../components/sales/customDateComponent.js';
import Modal from 'react-bootstrap/Modal';
import ReactTooltip from "react-tooltip";
import SelectTable from '../components/select-table'
import { useForm, Controller } from "react-hook-form";
import IFramePrintTemplate from '../components/iframe-print-template.component';
import MyDataLogo from "../img/mydata.png";

const MarkRenderer = forwardRef((props, ref) => {
    const [mark, setMark] = useState(props.value);
    const [cancellationMark, setCancellationMark] = useState("");

    useImperativeHandle(ref, () => {
        return {
            refresh(params) {
                var val = props.getValue();
                if (val) {
                    setMark(val);
                }
            }
        };

    });
    // const cancellationMark = rowNode.data.cancellationMark != null ? rowNode.data.cancellationMark : ""

    useEffect(() => {
        if (props.node.data != null) {
            var cm = props.node.data.cancellationMark;
            setCancellationMark(props.node.data.cancellationMark)

        }
    }, [props.node])

    const showDetailsModal = (e) => {
      
     
        // props.colDef.cellRendererParams.setDetailsInfo(props.data)

        var mark=e.target.innerText.trim();
        var companyId = JSON.parse(localStorage.getItem("company")).id;


        ApiClient.getCompanySaleInstanceByMark(companyId, mark).then(
            response => {
                console.log(response.data);
                props.colDef.cellRendererParams.setShowDetailsModal(true);
                props.colDef.cellRendererParams.setDetailsInfo(response.data)
            },
            error => {
                console.error(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString())
            }
        );
    }

    const formatMark = (mark) => {
        if (mark) {
            var markArray = mark.split(",");
            var _fullmark = [];
            if (markArray) {
                markArray.forEach(m => {
                    var series = m.match(/\((.*?)\)/);
                    var mark = m.replace(series[0], "").trim();

                    // var _mark=<span>{m}</span>
                    _fullmark.push(<span key={mark} className="mark_cell">[<b>{"(" + series[1] + ")"}</b><a href="#" onClick={showDetailsModal}>{mark}</a> ]</span>)

                })
            }

            return _fullmark;
        }
        return <></>;
    }

    return (

        <span>{formatMark(mark)}</span>
    );
});
const SeriesNumberRendener = forwardRef((props, ref) => {
    const [seriesNumber, setSeriesNumber] = useState(props.value);
    const [canceledIcon, setCanceledIcon] = useState("");

    useImperativeHandle(ref, () => {
        return {
            refresh(params) {
                var val = props.getValue();


                if (val) {
                    setSeriesNumber(val);
                }
            }
        };

    });
    // const canceledIcon = props.node.data.canceled? <i className='fa fa-times-circle canceledInvoice'></i> : ""
    // const canceledIcon = rowNode.data.canceled ? <i className='fa fa-times-circle canceledInvoice'></i> : ""
    // console.error(rowNode)

    useEffect(() => {
        if (props.node.data != null) {
            setCanceledIcon(props.node.data.canceled ? <i className='fa fa-times-circle canceledInvoice'></i> : "")

        }
    }, [props.node])

    return (

        <span><span>{seriesNumber}</span>{canceledIcon}</span>
    );
});


const DateRendener = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const [date, setDate] = useState(moment(props.value).format('DD/MM/YYYY'));


    useImperativeHandle(ref, () => {
        return {
            refresh(params) {
                var val = props.getValue();

                if (val) {
                    setDate(val);
                }
            }
        };

    });

    return (

        <span>  {date}</span>
    );
});

const ActionsRenderer = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const [actionElement, setActionElement] = useState(<span></span>);
    const [isCanceled, setIsCanceled] = useState();
    const history = useHistory();


    const goToPublic = () => {
        var invoiceUid = props.data.invoiceUid;
        window.open("/mydata/" + invoiceUid, "_blank");
    }



    useImperativeHandle(ref, () => {
        return {
            refresh(params) {



            }
        };

    });

    

    const hangleEdit = () => {
        history.push("/sale/" + props.node.data.id);
    }

    useEffect(() => {
        // 
        if (props.node.data != null) {
            var mark = props.node.data.mark;
            var cancelMark = props.node.data.cancellationMark;
            var _htm2 = <span><span style={{ color: "red" }}>{cancelMark}</span><span>{mark}</span>  </span>;
            props.node.setDataValue("mark", mark);
            props.node.setDataValue("cancelationMark", cancelMark);

        }



    }, [props.node])

    return (

        <span>  {actionElement}</span>
    );
});


export default function IncomesSearch() {

    const history = useHistory();
    const [gridApi, setGridApi] = useState(null);
    const [gridApi2, setGridApi2] = useState(null);
    const { t } = useTranslation();
    const [printSale, setPrintSale] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [fastFilter, setFastFilter] = useState("");
    const [blockuiLoading, setBlockuiLoading] = useState(false);
    const { control, formState: { errors } } = useForm();
    const [customerContacts, setCustomerContacts] = useState([]);
    const [selectedCustomerContacts, setSelectedCustomerContacts] = useState([]);
    const [lineData, setLineData] = useState({});
    const iframePrintTemplate = useRef();
    var to = null;
    const pagination = true;
    const paginationPageSize = 20;
    const [sheight, setSheight] = useState(window.screen.height * 0.8 - 240);

    const [sendPaymentLink, setSendPaymentLink] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);


    const blockUi = (block) => {
        setBlockuiLoading(block)
    }

    const getRowData = (params) => {
        var companyId = JSON.parse(localStorage.getItem("company")).id;
        var pageNo = gridApi.paginationGetCurrentPage();
        var sortBy = null;
        var order = "asc";
        var filterModel = gridApi.getFilterModel();
        gridApi.columnController.columnApi.getColumnState().map((v) => {
            if (v.sort != null) {
                sortBy = v.colId;
                order = v.sort
            }
        });


        var simpleColumnFilters = [];
        Object.keys(filterModel).forEach(k => {
            var obj = {
                column: k,
                filter: filterModel[k].filter,
                dateFrom: filterModel[k].dateFrom,
                dateTo: filterModel[k].dateTo,
                filterType: filterModel[k].filterType,
                searchOperator: filterModel[k].type
            }
            if (obj.column == "seriesNumber") {
                obj.column = "series";
                obj.concatWith = "invoiceNumber";
            }
            simpleColumnFilters.push(obj);
        })


        var object = {
            "quickSearch": fastFilter,
            "pageNo": pageNo,
            "sortBy": sortBy,
            "order": order,
            "pageSize": paginationPageSize,
            "simpleColumnFilters": simpleColumnFilters
        }

        ApiClient.getCompanyIncomesPage(companyId, object).then(
            response => {

                var saleInstances = response.data.objectList;
                var newSaleInstances = [];
                saleInstances.forEach(element => {
                    var inst = element;
                    inst.seriesNumber = inst.series + inst.invoiceNumber;
                    newSaleInstances.push(inst);
                });

                params.successCallback(newSaleInstances, response.data.totalElements);
                if (newSaleInstances.length == 0) {
                    gridApi.showNoRowsOverlay();
                }
            },
            error => {
                console.error(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString())
            }
        );
    }


    useEffect(() => {
        if (gridApi) {
            const dataSource = {
                getRows: (params) => {
                    let mounted = true;
                    if (mounted) {
                        getRowData(params);
                    }
                    return () => mounted = false;
                }
            }
            let timer1 = setTimeout(function () {
                gridApi.setDatasource(dataSource);
            }, 1000);

            return () => {
                clearTimeout(timer1);
            };

        }
    }, [fastFilter]);


    useEffect(() => {
        if (gridApi) {
            const dataSource = {
                getRows: (params) => {
                    let mounted = true;
                    if (mounted) {
                        getRowData(params);
                    }
                    return () => mounted = false;
                }
            }

            gridApi.setDatasource(dataSource);
        }
        if (gridApi2) {
            const dataSource = {
                getRows: (params) => {
                    let mounted = true;
                    if (mounted) {
                        getRowData(params);
                    }
                    return () => mounted = false;
                }
            }

            gridApi2.setDatasource(dataSource);
        }
    }, [gridApi, gridApi2]);

    const onGridReady = params => {
        setGridApi(params.api);
        // params.api.sizeColumnsToFit();
        window.addEventListener('resize', function () {
            setTimeout(function () {
                // params.api.sizeColumnsToFit();
                setSheight(window.screen.height * 0.8 - 240);
            }, 300);
        });

    };


    const onGridReady2 = params => {
        setGridApi2(params.api);
        // params.api.sizeColumnsToFit();
        window.addEventListener('resize', function () {
            setTimeout(function () {
                // params.api.sizeColumnsToFit();
                setSheight(window.screen.height * 0.8 - 240);
            }, 300);
        });

    };



    const onQuickFilterChanged = e => {
        gridApi.fastFilter = e.target.value;
        setFastFilter(e.target.value);

    };
    const print = (psale) => {
        iframePrintTemplate.current.print(psale)
    }
    const onClickPrint = data => {
        console.log(data)
        setPrintSale(data)
        setTimeout(function () {
            print(data);
        })
    }
    const goTo = invoiceUid => {
        history.push("/mydata/" + invoiceUid);
    }
    const onPaginationChanged = (params) => {
        var currentPage = params.api.paginationGetCurrentPage();
        if (gridApi) {
            gridApi.paginationGoToPage(currentPage);
        }


    }
    const dateFormatter = (params) => {
        if (params.data != undefined) {
            var dateAsString = moment(params.data.invoiceDate).format('DD/MM/YYYY hh:mm')
            console.log(dateAsString)
            return dateAsString
        }
        return ""
    }
    const onChangeContact = data => {
        setSelectedCustomerContacts(data);
    }
    const onInputChangeContact = (contact) => {

    }
    function getContactEmails(sale, sendPaymentLink) {
        var customerName = sale.customeName;
        var company = JSON.parse(localStorage.getItem("company"));
        var subject = t("emailSubject", { company: company.name });

        var contactEmails = [];
        selectedCustomerContacts.forEach(contact => {
            if (contact.email == undefined || contact.email == null) {
                contact.email = contact.value;
                contact.name = contact.value
            }
            var body = "<p>" + t("emailBody1", { contactName: contact.name }) + "</p>";
            body += "<p>" + t("emailBody2", { company: company.name }) + "</p>";
            var link = "<a href='https://app.timologic.gr/mydata/" + sale.invoiceUid + "'>" + sale.invoiceType + " " + sale.series + sale.invoiceNumber + "</a>";
            body += "<p>" + t("emailBody3", { link: link }) + "</p>";
            body += "<p>" + t("emailBody4") + "</p>";
            var link2 = "<a href=" + company.vivaQuickPay + ">" + t("here") + "</a>";
            if (sendPaymentLink && company.vivaQuickPay) {
                body += "<p>" + t("emailBody6", { link: link2 }) + "</p>";
            }
            // body += "<small>" + t("emailBody5") + "</small>";
            contactEmails.push({
                subject: subject,
                body: body,
                email: contact.email
            });
        })
        return contactEmails;

    }

    const [detailsInfo, setDetailsInfo] = useState();

    const saleEntriesRender = (saleEntries) => {
        var rows = [];
        var _thead = <thead><tr><th>{t("priceWithoutVat")}</th><th>{t("vat")}</th><th>{t("priceWithVat")}</th><th>{t("saleEntryClassifications")}</th></tr></thead>;
        saleEntries.forEach(entry => {
            var _tr = <tr><td>{entry.priceWithoutVat}</td><td>{entry.vat.value}</td><td>{entry.priceWithVat}</td><td>{saleEntryClassificationRender(entry.saleEntryClassifications)}</td></tr>;
            rows.push(_tr);
        });

        return <table className="table">{_thead}<tbody>{rows}</tbody></table>;
    }
    const saleEntryClassificationRender = (classifications) => {
        var rows = [];
        var _thead = <thead><tr><th>{t("incomingClassificationCategoryType")}</th><th>{t("incomingClassificationValueType")}</th><th>{t("amount")}</th></tr></thead>;
        classifications.forEach(entry => {
            var classCat = entry.classificationCategory
            var classType = entry.classificationValue

            var _tr = <tr><td>{t(classCat)}</td><td>{t(classType)}</td><td>{entry.amount}</td></tr>;
            rows.push(_tr);
        });

        return <table className="table">{_thead}<tbody>{rows}</tbody></table>;
    }

    const synchronize=()=>{
        var companyId = JSON.parse(localStorage.getItem("company")).id;
        ApiClient.synchronizeCompanyIncomes(companyId).then(
            response => {
                console.log(response.data);
              
            },
            error => {
                console.error(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString())
            }
        );
    }

    return (
        <BlockUi tag="div" blocking={blockuiLoading}>

            <div className="container-fluid">
                <header className="jumbotron">
                    <div className="row">
                        <div className="col-md-6">
                            <h5 className="subtitle skin">{t("invoicesSearch")}</h5>
                        </div>
                        <div className="col-md-6">
                            <div className="addmodelBtnContainer">
                                <button className="btn btn-skin" href="" onClick={function () { return history.push("/sale"); }}>
                                    {t("PublicationNew")}  <i className="fa  fa-plus"></i>
                                </button>
                            </div>
                            {/* <div className="addmodelBtnContainer">
                                <button className="btn btn-skin" href="" onClick={function () { return history.push("/sales/aade"); }}>
                                    {t("InvoicesOnMydata")}  <i className="fa  fa-plus"></i>
                                </button>
                            </div> */}
                        </div>
                    </div>

                </header>
                <div style={{ width: '100%', height: '100%' }}>
                    <div className="row">
                        <div className="d-flex justify-content-between">
                            <div className="p-1">
                                <button className="btn btn-success" onClick={synchronize}> Synchronize</button>

                            </div>
                        </div>

                    </div>

                    <div className="row">
                        <div className="ag-theme-alpine-dark sales-table-container col-md-12" >
                            <div className="ag-theme-alpine" style={{ height: sheight, width: '100%' }}>
                                <AgGridReact
                                    pagination={pagination}
                                    paginationPageSize={paginationPageSize}
                                    onGridReady={onGridReady}
                                    style={{ width: '100%', height: '100%;' }}
                                    // onRowDoubleClicked={(e) => goTo(e.data.invoiceUid)}
                                    // rowData={content}
                                    // domLayout={'autoHeight'}
                                    suppressRowTransform={true}
                                    frameworkComponents={{
                                        actionsRenderer: ActionsRenderer,
                                        seriesNumberRendener: SeriesNumberRendener,
                                        dateRendener: DateRendener,
                                        agDateInput: CustomDateComponent
                                    }}
                                    overlayNoRowsTemplate={
                                        '<span style="padding: 10px;  color:#013289">' + t('noEntries') + '</span>'
                                    }
                                    onPaginationChanged={onPaginationChanged}
                                    rowModelType={'infinite'}
                                    cacheBlockSize={paginationPageSize}
                                    gridOptions={{ suppressMenuHide: true }}
                                    skipHeaderOnAutoSize={true}
                                    alwaysShowHorizontalScroll={true}
                                    defaultColDef={{
                                        flex: 1,
                                        resizable: true,
                                        sortable: true,
                                        wrapText: true,
                                        suppressMovable:true,
                                        autoHeight: true, icons: {
                                            menu: '<i style="color:white" class="fa fa-search"/>',
                                        },
                                    }}
                                >
                                    {/* <AgGridColumn flex={1} minWidth={120} headerName={t("Number")} cellRenderer="seriesNumberRendener" sortable={true} filter="agTextColumnFilter" field="seriesNumber"></AgGridColumn> */}
                                    <AgGridColumn flex={5} minWidth={220} headerName={t("InvoiceType")} valueFormatter={params=>params.data?t("invoice"+params.data.invType.replace(".","_"))+" ("+params.data.invType+")":""} sortable={true} filter="agTextColumnFilter" field="invType"></AgGridColumn>
                                    <AgGridColumn minWidth={150} valueFormatter={dateFormatter} flex={3} headerName={t('DateTime')} sortable={true} filter="agDateColumnFilter" field="invoiceDate" cellRenderer="dateRendener" ></AgGridColumn>
                                    <AgGridColumn flex={2} minWidth={150} headerName={t("taxpayerId")} sortable={true} filter="agTextColumnFilter" field="counterVatNumber"></AgGridColumn>
                                    <AgGridColumn minWidth={100}  flex={2}  valueFormatter={params => params.data ? params.data.netValue.toFixed(2) : 0.00} headerName={t("TotalWithoutVat")} sortable={true} filter="agNumberColumnFilter" field="netValue"></AgGridColumn>
                                    <AgGridColumn minWidth={100}  flex={2}  valueFormatter={params => params.data ? params.data.vatAmount.toFixed(2) : 0.00} headerName={t("VatAmount")} sortable={true} filter="agNumberColumnFilter" field="vatAmount"></AgGridColumn>
                                    <AgGridColumn minWidth={100}  flex={2}  valueFormatter={params => params.data ? params.data.grossValue.toFixed(2) : 0.00} headerName={t("TotalWithVat")} sortable={true} filter="agNumberColumnFilter" field="grossValue"></AgGridColumn>
                                    <AgGridColumn minWidth={220} flex={4}  valueFormatter={params => params.data ? "("+params.data.count+") "+ params.data.minMark +" - "+params.data.minMark : ""}  headerName={t("MarkRange")} sortable={true} filter="agTextColumnFilter" field="markRange"></AgGridColumn>


                                </AgGridReact>
                            </div>
                            <div><br></br></div>
                        </div>

                    </div>

                </div>
            </div>


            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                fullscreen
                centered
                show={showDetailsModal}
                dialogClassName="invoice-details-modal modal-fullscreen"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header >
                    <Modal.Title>{t("invoiceDetails")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <h4>MARK: {detailsInfo?detailsInfo.mark:""}</h4>
                        {detailsInfo ? saleEntriesRender(detailsInfo.saleEntries) : ""}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-skin" onClick={function () { setShowDetailsModal(false) }}>{t("cancel")}</button>
                </Modal.Footer>
            </Modal>



        </BlockUi>
    );
}


