import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import ApiClient from "../services/api-client"
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import VivaLogo from '../img/viva_logo.png';
import VivaFooter from '../img/viva_footer.png';
import { VIVA_VIEW_TRANSACTION } from '../util/constants';
import { useHistory } from "react-router-dom";
import MyDataLogo from "../img/mydata.png";


export default function StartSubscription() {


    const { id } = useParams();

    const [transactionId, setTransactionId] = useState("");
    const { formState: { errors } } = useForm();
    const { t, i18n } = useTranslation();
    const history = useHistory();


    useEffect(() => {
        let mounted = true;
        return () => mounted = false;
    }, []);

    const createOrder = (e, packId) => {
        var paymentMethod = document.getElementById("paymentMethod").value;

        if (paymentMethod == "0") {
            ApiClient.createReccuringPaymentOrder(packId).then(
                (response) => {
                    if (response.data.Success) {
                        localStorage.setItem("packId", packId);
                        window.location = VIVA_VIEW_TRANSACTION + "/web/checkout?ref=" + response.data.OrderCode;
                    }
                },
                error => {
                    console.error(
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString())
                }
            )
        }
        else {
            ApiClient.createPaymentOrder(packId).then(
                (response) => {
                    if (response.data.Success) {
                        localStorage.setItem("packId", packId);
                        window.location = VIVA_VIEW_TRANSACTION + "/web/checkout?ref=" + response.data.OrderCode + "&paymentMethod=" + paymentMethod;
                    }
                },
                error => {
                    console.error(
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString())
                }
            )
        }

    }


    const choosePackageContainer =
        <div className="container-fluid text-center">

            <br></br>

            <section id="pricing" className="pricing">
                <div className="container-fluid" data-aos="fade-up">



                    <ul className="nav justify-content-center nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="monthly-packs-tab" data-bs-toggle="tab" data-bs-target="#monthly-packs" type="button" role="tab" aria-controls="monthly-packs" aria-selected="true">{t("monthlyPacks")}</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link " id="yearly-packs-tab" data-bs-toggle="tab" data-bs-target="#yearly-packs" type="button" role="tab" aria-controls="yearly-packs" aria-selected="false">{t("yearlyPacks")}<sup><span className="badge bg-success yearlyPercentDiscount">-18%</span></sup></button>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <br></br>
                        <div className="tab-pane fade" id="yearly-packs" role="tabpanel" aria-labelledby="yearly-packs-tab">
                            <div className="row justify-content-center">
                                Ο παρακάτω τιμές αναφέρονται σε 12 μήνες συνδρομή
                            </div>
                            <div><br></br></div>
                            <div className="row">

                                <div className="col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="pricing-box">

                                        <div className="price"><sup></sup><span></span></div>
                                        <ul>
                                            <li>Σύνδεση με το <img className="gfd" alt="myDATA ΑΑΔΕ" src={MyDataLogo} width="17%"></img> </li>
                                            <li>Εως και <b>25</b> τιμολόγια/μήνα</li>

                                        </ul>

                                        <button className="btn btn-lg btn-skin payvivabtn" onClick={e => createOrder(e, "PACKAGE_5")}>Ξεκινήστε με 4.16 €</button>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="pricing-box">

                                        <div className="price"><sup></sup><span></span></div>
                                        <ul>
                                            <li>Σύνδεση με το <img className="gfd" alt="myDATA ΑΑΔΕ" src={MyDataLogo} width="17%"></img> </li>
                                            <li>Εως και <b>200</b> τιμολόγια/μήνα</li>

                                        </ul>

                                        <button className="btn btn-lg btn-skin payvivabtn" onClick={e => createOrder(e, "PACKAGE_6")}>Ξεκινήστε με 6.66 €</button>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="pricing-box">

                                        <div className="price"><sup></sup><span></span></div>
                                        <ul>
                                            <li>Σύνδεση με το <img className="gfd" alt="myDATA ΑΑΔΕ" src={MyDataLogo} width="17%"></img> </li>
                                            <li>Εως και <b>500</b> τιμολόγια/μήνα</li>

                                        </ul>

                                        <button className="btn btn-lg btn-skin payvivabtn" onClick={e => createOrder(e, "PACKAGE_7")}>Ξεκινήστε με 9.25 €</button>
                                    </div>
                                </div>

                                <div><br></br></div>

                                <div className="col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="pricing-box">

                                        <div className="price"><sup></sup><span></span></div>
                                        <ul>
                                            <li>Σύνδεση με το <img className="gfd" alt="myDATA ΑΑΔΕ" src={MyDataLogo} width="17%"></img> </li>
                                            <li>Εως και <b>24000</b> τιμολόγια/μήνα</li>

                                        </ul>

                                        <button className="btn btn-lg btn-skin payvivabtn" onClick={e => createOrder(e, "PACKAGE_8")}>Ξεκινήστε με 16.66 €</button>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="pricing-box">

                                        <div className="price"><sup></sup><span></span></div>
                                        <ul>
                                            <li>Σύνδεση με το <img className="gfd" alt="myDATA ΑΑΔΕ" src={MyDataLogo} width="17%"></img> </li>
                                            <li>Απεριόριστα τιμολόγια</li>

                                        </ul>

                                        <button className="btn btn-lg btn-skin payvivabtn" onClick={e => createOrder(e, "PACKAGE_9")}>Ξεκινήστε με 25.00 €</button>
                                    </div>
                                </div>

                            </div>



                        </div>

                        <div className="tab-pane fade show active" id="monthly-packs" role="tabpanel" aria-labelledby="monthly-packs-tab">
                            <div className="row">

                                <div className="col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="pricing-box">

                                        <div className="price"><sup></sup><span></span></div>
                                        <ul>
                                            <li>Σύνδεση με το <img className="gfd" alt="myDATA ΑΑΔΕ" src={MyDataLogo} width="17%"></img> </li>
                                            <li>Εως και <b>25</b> τιμολόγια/μήνα</li>

                                        </ul>

                                        <button className="btn btn-lg btn-skin payvivabtn" onClick={e => createOrder(e, "PACKAGE_0")}>Ξεκινήστε με 4.99 €</button>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="pricing-box">

                                        <div className="price"><sup></sup><span></span></div>
                                        <ul>
                                            <li>Σύνδεση με το <img className="gfd" alt="myDATA ΑΑΔΕ" src={MyDataLogo} width="17%"></img> </li>
                                            <li>Εως και <b>200</b> τιμολόγια/μήνα</li>

                                        </ul>

                                        <button className="btn btn-lg btn-skin payvivabtn" onClick={e => createOrder(e, "PACKAGE_1")}>Ξεκινήστε με 7.99 €</button>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="pricing-box">

                                        <div className="price"><sup></sup><span></span></div>
                                        <ul>
                                            <li>Σύνδεση με το <img className="gfd" alt="myDATA ΑΑΔΕ" src={MyDataLogo} width="17%"></img> </li>
                                            <li>Εως και <b>500</b> τιμολόγια/μήνα</li>

                                        </ul>

                                        <button className="btn btn-lg btn-skin payvivabtn" onClick={e => createOrder(e, "PACKAGE_2")}>Ξεκινήστε με 10.99 €</button>
                                    </div>
                                </div>

                                <div><br></br></div>

                                <div className="col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="pricing-box">

                                        <div className="price"><sup></sup><span></span></div>
                                        <ul>
                                            <li>Σύνδεση με το <img className="gfd" alt="myDATA ΑΑΔΕ" src={MyDataLogo} width="17%"></img> </li>
                                            <li>Εως και <b>2000</b> τιμολόγια/μήνα</li>

                                        </ul>

                                        <button className="btn btn-lg btn-skin payvivabtn" onClick={e => createOrder(e, "PACKAGE_3")}>Ξεκινήστε με 19.99 €</button>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="pricing-box">

                                        <div className="price"><sup></sup><span></span></div>
                                        <ul>
                                            <li>Σύνδεση με το <img className="gfd" alt="myDATA ΑΑΔΕ" src={MyDataLogo} width="17%"></img> </li>
                                            <li>Απεριόριστα τιμολόγια</li>

                                        </ul>

                                        <button className="btn btn-lg btn-skin payvivabtn" onClick={e => createOrder(e, "PACKAGE_4")}>Ξεκινήστε με 29.99 €</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <div><br></br></div>

            <div className="offset-md-5 col-md-2" >
                <label>Τρόποι πληρωμής</label>
                <select id="paymentMethod" defaultValue="0" className="form-select">
                    <option value="0">ΚΑΡΤΑ (προτεινόμενος)</option>
                    <option value="3">ΜΕΤΡΗΤΑ</option>
                    <option value="4">e-banking</option>
                </select>
            </div>
            <div><br></br></div>
            <div> Στις τιμές δεν περιλαμβάνεται Φ.Π.Α.</div>

        </div>

    return (
        <div className="startSubscription-container">
            <div className="container text-center">
                <br></br><br></br>
                <h3>Ξεκίνα σήμερα τη συνδρομή σου! Οι συνδρομές στο πρόγραμμα ανανεώνονται αυτόματα εκτός και αν ακυρώσετε τη συνδρομή σας</h3>
                <br></br>



                <div>{choosePackageContainer}</div>
                <hr></hr>
                <div className="row">
                    <div className="col-md-12">
                        <small>
                            Γίνονται δεκτές πιστωτικές ή χρεωστικές κάρτες Visa, Mastercard ή American express, μετρητά ή E-banking οποιασδήποτε τράπεζας. Για να διασφαλιστεί ή απόλυτη ασφάλεια των ηλεκτρονικών σας συναλλαγών αυτές γίνονται με ανακατεύθυνση, μέσα από το σύστημα ασφαλείας τoυ αδειοδοτημένου από την Τράπεζα της Ελλάδος ιδρύματος πληρωμών Viva Payments η οποία εγγυάται ένα ασφαλές περιβάλλον συναλλαγών στο κορυφαίο σύστημα ηλεκτρονικών πληρωμών. Τα δεδομένα της πιστωτικής σας κάρτας δεν αποθηκεύονται στον διακομιστή μας.
                        </small>
                    </div>
                </div>
                <div><br></br></div>

                <div className="row">
                    <a href="https://timologic.gr/blog/tropoi-pliromis/" target="_blank">Τρόποι πληρωμής</a>
                </div>
                <div className="text-center"> <img style={{ width: '40%' }} src={VivaFooter} alt="Viva" /></div>
                <div>
                    <button onClick={() => { history.goBack(); }} className="btn btn-skin">{t("goBackToApplication")}</button>
                </div>
                <div><br></br></div>
            </div>
        </div>

    );
}