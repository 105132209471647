import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import ApiClient from "../services/api-client"
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { useHistory } from "react-router-dom"
import { useTranslation } from 'react-i18next';


const ActionsRenderer = forwardRef((props, ref) => {
    const history = useHistory();

    const handleInputChange = (e) => {
        e.preventDefault();
        var rowNode = props.agGridReact.api.getRowNode(props.rowIndex);
        var _id = rowNode.data.id;
        history.push("/productCategory/" + _id);
    }
    useImperativeHandle(ref, () => {
        return {
            refresh(params) {

            }
        };
    });
    return (
        <span><button onClick={handleInputChange} className="btn btn-sm btn-warning"><i className="fa fa-edit"></i></button></span>
        // <span className="editableCell">{branch != null && branch.label != undefined ? branch.label : <span className="addtext"></span>}</span>
    );
});


export default function ProductCategorySearch() {

    const [content, setContent] = useState([]);
    const history = useHistory();
    const [gridApi, setGridApi] = useState(null);
    const { t, i18n } = useTranslation();

    useEffect(() => {

        var companyId = JSON.parse(localStorage.getItem("company")).id;
        ApiClient.getCompanyProductCategories(companyId).then(
            response => {
                console.log(response.data)
                setContent(response.data);

            },
            error => {
                setContent(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString())
            }
        );

    }, []);

    const pagination = true;
    const paginationPageSize = 10;


    const onGridReady = params => {
        setGridApi(params.api);
        // params.api.sizeColumnsToFit();
        window.addEventListener('resize', function () {
            setTimeout(function () {
                // params.api.sizeColumnsToFit();
            });
        });

        // params.api.sizeColumnsToFit();
    };
    const onQuickFilterChanged = e => {
        gridApi.setQuickFilter(e.target.value);
    };


    const goTo = id => {
        history.push("/productCategory/" + id);
    }
    return (

        <div className="container" style={{padding:4}}>
            <header className="jumbotron">
                <div className="row">
                    <div className="col-md-6">
                        <h5 className="subtitle skin">{t("classificationsOfProductsSearch")}</h5>
                    </div>
                    <div className="col-md-6">
                        <div className="addmodelBtnContainer">
                            <button className="btn btn-skin" href="" onClick={function () { return history.push("/productCategory"); }}>
                                {t("classificationsOfProductsAdd")}  <i className="fa  fa-plus"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </header>
            <br></br>
            <div className="row">
                <div className="col-md-12">
                    <div className="alert alert-primary small-info" role="alert">
                        <p >{t("productCategoriesInfo")}</p>
                    </div>
                </div>
            </div>
            <div style={{ width: '100%', height: '100%' }}>
                <div className="row">
                    <div className="offset-md-4 col-md-4">
                        <p className="text-center">
                            <input placeholder={t('Search')} className="form-control" onChange={onQuickFilterChanged} />
                        </p>
                    </div>

                </div>

                <div
                    id="myGrid"
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                    className="ag-theme-alpine-dark"
                >
                    <div className="ag-theme-alpine" style={{ height: 300, width: '100%'}}>
                        <AgGridReact
                            pagination={pagination}
                            paginationPageSize={paginationPageSize}
                            onGridReady={onGridReady}
                            style={{ width: '100%', height: '100%;' }}
                            onRowDoubleClicked={(e) => goTo(e.data.id)}
                            rowData={content}
                            frameworkComponents={{
                                actionsRenderer: ActionsRenderer,
                            }}
                            overlayNoRowsTemplate={
                                '<span style="padding: 10px;  color:#013289">' + t('noEntries') + '</span>'
                            }
                        >
                            <AgGridColumn flex={3} headerName={t("Name")} sortable={true} filter="agTextColumnFilter" field="name"></AgGridColumn>
                            <AgGridColumn flex={1} headerName={t("Actions")} cellRenderer="actionsRenderer" field="name"></AgGridColumn>

                        </AgGridReact>
                    </div>

                </div>
            </div>
        </div>
    );
}


