import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentStatus from '../components/stripe-payment-status';
import { useTranslation } from 'react-i18next';
import logo from '../img/timologic_login.png';
import { STRIPE_PUBLISHABLE_KEY } from '../util/constants';



function StripePaymentComplete() {
    const { t } = useTranslation();

    const params = new URLSearchParams(window.location.search);
    let secret = params.get('secret');
    let accountId = params.get('accountId');


    const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY, {
        stripeAccount: accountId,
        locale: "el"
    });


    const options = {
        clientSecret: secret
    };

    return (
        <div className="container">
            <br></br>
            <br></br>
         
            <Elements stripe={stripePromise} options={options}>
                <PaymentStatus />
            </Elements>
            <div className="footer">
            <img
                style={{width:200}}
                src={logo}
                alt="timologic myDATA app"
                className="profile-img-card"
            />
            <img />
            </div>
        </div>

    );
};

export default StripePaymentComplete;