import React from "react";
import { confirmAlert } from 'react-confirm-alert';
import logo_confirm from '../img/logo_timologic_confirm.png';


export function CustomConfirmAlert(onConfirm) {

    const customUI = (obj, onClose) => {
        const renderButtons = (buttons) => {
            var btns = []
            if (buttons) {
                var counter=0;
                buttons.forEach(btn => {
                    var addcls=btn.className?btn.className:"";
                    var clsname="btn btn-skin "+addcls;
                    var keyName="confirm-footer-btn-"+counter;
                    btns.push(<button key={keyName} onClick={() => { if (btn.onClick) { btn.onClick() }; onClose(); }} className={clsname}>{btn.label}</button>);
                    counter++;
                });
            }

            return btns;
        }

        return (
            <div className="card confirm-card" style={{ maxWidth: 500 }}>
                <div className="card-header card-header-skin">
                    <img className="confirm_logo" src={logo_confirm} style={{ width: 30, height: 30 }}></img>
                    {obj.title}
                </div>
                <div className="card-body">
                    <h5 className="card-title"> {obj.header}</h5>
                    {obj.message}
                    <p>  <small>{obj.submessage}</small></p>
                </div>
                <div className="card-footer">
                    <div className="react-confirm-alert-button-group">
                        {renderButtons(obj.buttons)}
                    </div>
                </div>
            </div>
        );
    }
    return confirmAlert({
        customUI: ({ onClose }) => customUI(onConfirm, onClose)
    });
}
