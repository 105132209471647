import React, { useState, useImperativeHandle ,forwardRef} from "react";

import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';


const ErrorDetailsModal = forwardRef((props, ref) => {

    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [code, setCode] = useState(false);
    const [mess, setMess] = useState(false);
    const [body, setBody] = useState(false);


    useImperativeHandle(ref, () => ({

        open(mess){
            console.log(mess)
            setCode(mess.code)
            setMess(mess.message)
            if(mess.code==201){
                setBody(cardBody573)
            }
            setShow(true)

        },
        close(){
            setShow(false)
        },

    }));
    const handleCloseHelpMyData = () => {
        setShow(false);
    }

    const cardBody573=
    <div>
        <div>{t("errorDetai_201")}</div>
        <ul>
            <li>{t("errorDetai_201_1")} <a href="https://www1.aade.gr/saadeapps2/bookkeeper-web"> https://www1.aade.gr/saadeapps2/bookkeeper-web</a></li>
            <li>{t("errorDetai_201_2")}</li>
            <li>{t("errorDetai_201_3")}</li>
        </ul>
        <div>{t("errorDetai_201_end")}</div>
    </div>

    return (
        <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                backdrop="static"
                keyboard={false}
                scrollable={true}

            >
                <Modal.Header >
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <h3 className="text-center"> {t("ErrorDetails",{"code":code})}</h3>
                        <div className="card">
                            <div className="card-header">
                               {mess} 
                            </div>
                            <div className="card-body">
                                {body}
                            </div>
                        </div>
                      
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-skin" onClick={handleCloseHelpMyData}>ok</button>
                </Modal.Footer>
            </Modal>


    );
}
);

export default ErrorDetailsModal;
