import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import ApiClient from "../services/api-client"
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { useHistory } from "react-router-dom"
import { useTranslation } from 'react-i18next';



const AllFieldsRenderer = forwardRef((props, ref) => {
    const { t } = useTranslation();


    useImperativeHandle(ref, () => {
        return {
            refresh(params) {

            }
        };

    });

    return (

        <div className="invoiceType-grid-row">

            <div className="d-flex bd-highlight allFieldsColumnRow">
                <div className="p-1 bd-highlight">
                    <span>{t("Name")}: <b>{props.data.name}</b></span>
                </div>
            </div>

            <div className="d-flex bd-highlight allFieldsColumnRow">

                <div className="p-1 flex-grow-1 bd-highlight">
                    <span>{t("Type")}: <b>{props.data.myDataInvoiceType.replace("invoice", "").replace("_", ".")}</b>({t(props.data.myDataInvoiceType)})</span>
                </div>
                
            </div>
            <div className="d-flex bd-highlight allFieldsColumnRow">
                <div className="p-1 bd-highlight">
                    <span>{t("mSeries")}: <b>{props.data.seriesNamesConcat}</b></span>
                </div>
            </div>
        </div>


    );
});



const ActionsRenderer = forwardRef((props, ref) => {


    const history = useHistory();

    const handleInputChange = (e) => {
        e.preventDefault();
        history.push("/invoicetype/" + props.data.id);
    }
    useImperativeHandle(ref, () => {
        return {
            refresh(params) {

            }
        };
    });
    return (
        <span>
            <div className="btn-group" role="group">
                <button onClick={handleInputChange} className="btn btn-sm btn-skin-secondary"><i className="fa fa-edit"></i></button>
            </div>
        </span>
        // <span className="editableCell">{branch != null && branch.label != undefined ? branch.label : <span className="addtext"></span>}</span>
    );
});


const MyDataInvoiceTypeRenderer = forwardRef((props, ref) => {
    const [val, setVal] = useState(props.data);
    const { t, i18n } = useTranslation();

    useImperativeHandle(ref, () => {
        return {
            refresh(params) {
                var val = props.getValue();
                if (val != undefined) {
                    setVal(props.data);
                }

            }
        };
    });
    const inv_type = () => t(val.myDataInvoiceType) + " (" + val.myDataInvoiceTypeKey + ")";
    return (
        <span>{inv_type()}</span>
    );
});

export default function InvoiceTypeSearch() {

    const [content, setContent] = React.useState(0);
    const history = useHistory();
    const [gridApi, setGridApi] = useState(null);
    const { t, i18n } = useTranslation();
    const [sheight, setSheight] = useState(window.innerHeight - 190);

    useEffect(() => {

        var companyId = JSON.parse(localStorage.getItem("company")).id;
        ApiClient.getCompanyInvoiceTypes(companyId).then(
            response => {
                if (response) {
                    setContent(response.data);
                }


            },
            error => {
                setContent(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString())
            }
        );

    }, []);

    const pagination = false;
    const paginationPageSize = 100;


    const onGridReady = params => {
        setGridApi(params.api);
        // params.api.sizeColumnsToFit();
        window.addEventListener('resize', function () {
            setTimeout(function () {
                // params.api.sizeColumnsToFit();
                setSheight(window.innerHeight - 190);
            }, 300);
        });

        // params.api.sizeColumnsToFit();
    };
    const onQuickFilterChanged = e => {
        gridApi.setQuickFilter(e.target.value);
    };


    const goTo = id => {
        history.push("/invoicetype/" + id);
    }
    return (

        <div className="container" style={{padding:0}}>


            <header className="jumbotron search_header">
                <div className="d-flex bd-highlight">
                    <div className="me-auto p-0 bd-highlight">
                        <h5 className="subtitle skin">{t("invtypeSearch")}</h5>
                    </div>
                    <div className="p-0 bd-highlight">
                        <button className="btn btn-skin" href="" onClick={function () { return history.push("/invoicetype"); }}>
                            <span className="desktop-only">{t("invtypeAdd")} </span> <i className="fa fa-plus"></i>
                        </button>
                    </div>
                </div>
            </header>



            <div style={{ width: '100%', height: '100%' }}>

                <div className="row">
                    <div className="d-flex justify-content-between">
                        <div className="offset-md-4 col-md-4 text-center">
                            <input className="form-control" id="searchInvoiceTypesInput" placeholder={t("Search")} onChange={onQuickFilterChanged} />
                        </div>
                    </div>
                </div>

                <div
                    id="myGrid"
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                    className="ag-theme-alpine-dark invoiceType-mobile-table-container"
                >
                    <div className="ag-theme-alpine" style={{ height: sheight, width: '100%' }}>
                        <AgGridReact
                            pagination={pagination}
                            paginationPageSize={paginationPageSize}
                            onGridReady={onGridReady}
                            style={{ width: '100%', height: '100%;' }}
                            onRowDoubleClicked={(e) => goTo(e.data.id)}
                            rowData={content}
                            rowHeight={74}
                            defaultColDef={{
                                wrapText: false,
                                suppressMovable: true,
                                suppressHorizontalScroll: true,
                                icons: {
                                    menu: '<i style="color:white" class="fa fa-search"/>',
                                },
                            }}
                            frameworkComponents={{
                                myDataInvoiceTypeRenderer: MyDataInvoiceTypeRenderer,
                                actionsRenderer: ActionsRenderer,
                                allFieldsRenderer: AllFieldsRenderer
                            }}
                        >
                            <AgGridColumn flex={5} cellRenderer="allFieldsRenderer" headerName={t("Name")} sortable={true} filter="agTextColumnFilter" field="name"></AgGridColumn>
                            {/* <AgGridColumn flex={3} minWidth={300} headerName={t("Type")} sortable={true} filter="agTextColumnFilter" cellRenderer="myDataInvoiceTypeRenderer" field="myDataInvoiceType"></AgGridColumn>
                            <AgGridColumn flex={2} minWidth={100} headerName={t("mSeries")} sortable={true} filter="agTextColumnFilter" field="seriesNamesConcat"></AgGridColumn> */}
                            <AgGridColumn flex={1} maxWidth={40} minWidth={40} headerName={t("Actions")} cellRenderer="actionsRenderer" field="action"></AgGridColumn>

                        </AgGridReact>
                    </div>

                </div>
            </div>
        </div>
    );
}


