
import ApiClient from "./api-client"

const sleep = ms =>
    new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, ms);
    });

const optionsPerPage = 10;
var _data = [];
var countData = 0;
const loadOptions = async (search, page, props) => {
    var companyId = JSON.parse(localStorage.getItem("company")).id

    var sortBy = null;
    var order = "asc";
    var simpleColumnFilters;
    if (search) {
        simpleColumnFilters = [
            {
                column: "series",
                filter: search,
                filterType: "text",
                concatWith:"invoiceNumber"
            }, {
                column: "invoiceType",
                filter: search,
                filterType: "text"
            },
            {
                column: "mark",
                filter: search,
                filterType: "text"
            }]


    }
    var object = {
        "quickSearch": "",
        "pageNo": page,
        "sortBy": sortBy,
        "order": order,
        "pageSize": 10,
        "simpleColumnFilters": simpleColumnFilters
    }

    let filteredOptions;
    let hasMore;
    let slicedOptions;
    const result = await ApiClient.saleinstanceByinvoiceTypePage(companyId, object, props.selectedInvoiceType.myDataInvoiceType);

    var data = result.data.objectList;


    if (page == 1) {
        countData = data.length;
    }
    else {
        countData += data.length;
    }


    _data = [];
    data.forEach(element => {

        var _d = {
            code: element.series + element.invoiceNumber,
            label: element.invoiceType,
            value: element.id,
            mark: element.mark,
            model: element
        }

        _data.push(_d)


    });


    // if (!search) {
    //     filteredOptions = _data;
    // } else {
    //     const searchLower = search.toLowerCase();

    //     filteredOptions = _data.filter(({ label }) =>
    //         label.toLowerCase().includes(searchLower)
    //     );
    // }

    hasMore = Math.ceil(countData / optionsPerPage) >= page;
    // slicedOptions = _data.slice(
    //     (page - 1) * optionsPerPage,
    //     page * optionsPerPage
    // );

    return {
        options: _data,
        hasMore
    };
};

export default loadOptions;
