// export const API_BASE_URL = 'http://timologic-env.eba-ptpm9jjb.eu-central-1.elasticbeanstalk.com/api';
// export const OAUTH2_REDIRECT_URI = 'https://master.d3tht8zq4ccbwn.amplifyapp.com/oauth2/redirect'

// export const API_BASE_URL = 'http://localhost:8080/api';
// export const OAUTH2_REDIRECT_URI = 'http://localhost:8081/oauth2/redirect';

var _SRV_BASE_URL = 'http://localhost:8080';
var _OAUTH2_REDIRECT_URI = 'http://localhost:8081/oauth2/redirect';   
var _VIVA_VIEW_TRANSACTION="https://demo.vivapayments.com";
var _RECAPTCHA_KEY="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";
var _STRIPE_PUBLISHABLE_KEY="pk_test_51Mr3OwEdMV4nIlbbwlPkdUawl8u6CLj2GYsblQBgxby393eoCykoHdiD01ohZcwVtPGI3qV5OOcuucJUIxFegJM200ti9sReFE";
var _CHECKOUT_REDIRECT_URI = 'http://localhost:8081/checkout/complete';   


if(process.env.REACT_APP_BUILD_ENV === 'production'){
      _SRV_BASE_URL = 'https://api.timologic.gr';
      _OAUTH2_REDIRECT_URI = 'https://app.timologic.gr/oauth2/redirect';   
      _VIVA_VIEW_TRANSACTION="https://www.vivapayments.com";
      _RECAPTCHA_KEY="6LeykPkcAAAAAHNWnvWHgrUVDFLqGeSw8zpio0_J";
      _STRIPE_PUBLISHABLE_KEY="pk_live_51Mr3OwEdMV4nIlbbPv4o1t7EUFKwZU6DZFcxw9btMdwqRb7GKsjpR3RDyK9HMDFjoT01Kt1qN5ra561RkUKjLUog00V5j2ND95";
      _CHECKOUT_REDIRECT_URI = 'https://app.timologic.gr/checkout/complete';   
}
if(process.env.REACT_APP_BUILD_ENV === 'development'){
      _SRV_BASE_URL = 'https://devapi.timologic.gr';
      _OAUTH2_REDIRECT_URI = 'https://dev.timologic.gr/oauth2/redirect';   
      _VIVA_VIEW_TRANSACTION="https://demo.vivapayments.com";
      _RECAPTCHA_KEY="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";
      _STRIPE_PUBLISHABLE_KEY="pk_live_51Mr3OwEdMV4nIlbbPv4o1t7EUFKwZU6DZFcxw9btMdwqRb7GKsjpR3RDyK9HMDFjoT01Kt1qN5ra561RkUKjLUog00V5j2ND95";
      _CHECKOUT_REDIRECT_URI = 'https://dev.timologic.gr/checkout/complete';   
}
var _API_BASE_URL = _SRV_BASE_URL+'/api';

export const SRV_BASE_URL=_SRV_BASE_URL;
export const API_BASE_URL=_API_BASE_URL;
export const OAUTH2_REDIRECT_URI=_OAUTH2_REDIRECT_URI;
export const ACCESS_TOKEN = 'accessToken';
export const GOOGLE_AUTH_URL = API_BASE_URL + '/oauth2/authorize/google?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const FACEBOOK_AUTH_URL = API_BASE_URL + '/oauth2/authorize/facebook?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const APPLE_AUTH_URL = API_BASE_URL + '/apple/oauth2/authorize?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const GITHUB_AUTH_URL = API_BASE_URL + '/oauth2/authorize/github?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const VIVA_VIEW_TRANSACTION=_VIVA_VIEW_TRANSACTION;
export const RECAPTCHA_KEY=_RECAPTCHA_KEY;
export const SPECIAL_ENGLISH_AFFILIATE=43;
export const STRIPE_PUBLISHABLE_KEY=_STRIPE_PUBLISHABLE_KEY;
export const CHECKOUT_REDIRECT_URI=_CHECKOUT_REDIRECT_URI;



