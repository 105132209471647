import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from "react";
import ApiClient from "../../services/api-client"
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom"
import moment from 'moment'
import BlockUi from 'react-block-ui';
import CustomDateComponent from './customDateComponent.js';
import Modal from 'react-bootstrap/Modal';
import { useForm } from "react-hook-form";
import IFramePrintTemplate from '../iframe-print-template.component';
import InvoiceSimpleView from './invoice-simple-view.component';
import commons from "../../util/commons";


const MarkRenderer = forwardRef((props, ref) => {
    const [mark, setMark] = useState(props.value);
    const [cancellationMark, setCancellationMark] = useState("");

    useImperativeHandle(ref, () => {
        return {
            refresh(params) {
                var val = props.getValue();
                if (val) {
                    setMark(val);
                }
            }
        };

    });

    useEffect(() => {
        if (props.node.data != null) {
            setCancellationMark(props.node.data.cancellationMark)

        }
    }, [props.node])
    return (

        <span style={{ position: "relative" }}><span style={{ left: 0, bottom: 0, color: "red", position: "absolute" }}>{cancellationMark}</span><span>{mark}</span>  </span>
    );
});
const SeriesNumberRendener = forwardRef((props, ref) => {
    const [seriesNumber, setSeriesNumber] = useState(props.value);
    const [canceledIcon, setCanceledIcon] = useState("");

    useImperativeHandle(ref, () => {
        return {
            refresh(params) {
                var val = props.getValue();


                if (val) {
                    setSeriesNumber(val);
                }
            }
        };

    });

    useEffect(() => {
        if (props.node.data != null) {
            setCanceledIcon(props.node.data.canceled ? <i className='fa fa-times-circle canceledInvoice'></i> : "")

        }
    }, [props.node])

    return (

        <span><span>{seriesNumber}</span>{canceledIcon}</span>
    );
});
const ActionsRenderer = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const [actionElement, setActionElement] = useState(<span></span>);
    const history = useHistory();

    useImperativeHandle(ref, () => {
        return {
            refresh(params) {



            }
        };

    });

    const showDetailsModal = () => {
        props.colDef.cellRendererParams.setShowDetailsModal(true);
        var _data = commons.convertData(props.data);
        console.log(props.data)
        console.log(_data)
        props.colDef.cellRendererParams.setDetailsInfo(_data)
    }



    const getTransmittedDoc = () => {
        var mark = props.data.mark;
        var companyId = JSON.parse(localStorage.getItem("company")).id;
        ApiClient.getTransmittedDoc(companyId, mark).then(
            response => {

                console.log(response)
            },
            error => {
                console.error(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString())
            }
        );
    }
    useEffect(() => {

        const actionel = <span>
            <div className="btn-group" role="group">
                <button onClick={showDetailsModal} title={t("sendEmailInvoiceTitle")} className="btn btn-sm btn-skin-secondary"><i className="fa fa-info"></i></button>
                <button onClick={getTransmittedDoc} title={t("publicInvoiceLinkTitle")} className="btn btn-sm btn-skin-secondary"><i className="fa fa-eye"></i></button>

            </div>
        </span>
        setActionElement(actionel)

    }, [props.node])

    return (

        <span>  {actionElement}</span>
    );
});


export default function AadeTransmittedDocsSearch() {

    const history = useHistory();
    const [gridApi, setGridApi] = useState(null);
    const { t } = useTranslation();
    const [printSale, setPrintSale] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [fastFilter, setFastFilter] = useState("");
    const [blockuiLoading, setBlockuiLoading] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const { control, formState: { errors } } = useForm();
    const [lineData, setLineData] = useState({});
    const iframePrintTemplate = useRef();
    var to = null;
    const pagination = true;
    const paginationPageSize = 20;
    const [sheight, setSheight] = useState(window.screen.height * 0.8 - 240);

    const [detailsInfo, setDetailsInfo] = useState();


    const blockUi = (block) => {
        setBlockuiLoading(block)
    }

    const getRowData = (params) => {
        var companyId = JSON.parse(localStorage.getItem("company")).id;
        var pageNo = gridApi.paginationGetCurrentPage();
        var sortBy = null;
        var order = "asc";
        var filterModel = gridApi.getFilterModel();
        gridApi.columnController.columnApi.getColumnState().map((v) => {
            if (v.sort != null) {
                sortBy = v.colId;
                order = v.sort
            }
        });


        var simpleColumnFilters = [];
        Object.keys(filterModel).forEach(k => {
            var obj = {
                column: k,
                filter: filterModel[k].filter,
                dateFrom: filterModel[k].dateFrom,
                dateTo: filterModel[k].dateTo,
                filterType: filterModel[k].filterType
            }
            simpleColumnFilters.push(obj);
        })


        var object = {
            "quickSearch": fastFilter,
            "pageNo": pageNo,
            "sortBy": sortBy,
            "order": order,
            "pageSize": paginationPageSize,
            "simpleColumnFilters": simpleColumnFilters
        }

        ApiClient.getCompanyAadeSaleInstancesPage(companyId, object, false).then(
            response => {

                var saleInstances = response.data.objectList;
                var newSaleInstances = [];
                saleInstances.forEach(element => {
                    var inst = element;
                    var postStr = inst.customerTaxPayerId;
                    if (inst.customerName) {
                        postStr = inst.customerTaxPayerId + " (" + inst.customerName + ")";
                    }

                    inst.seriesNumber = inst.series + inst.invoiceNumber;
                    inst.customerTaxPayerId = postStr;
                    newSaleInstances.push(inst);
                });

                params.successCallback(newSaleInstances, response.data.totalElements);
                if (newSaleInstances.length == 0) {
                    gridApi.showNoRowsOverlay();
                }
            },
            error => {
                console.error(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString())
            }
        );

    }


    useEffect(() => {
        if (gridApi) {
            const dataSource = {
                getRows: (params) => {
                    let mounted = true;
                    if (mounted) {
                        getRowData(params);
                    }
                    return () => mounted = false;
                }
            }
            let timer1 = setTimeout(function () {
                gridApi.setDatasource(dataSource);
            }, 1000);

            return () => {
                clearTimeout(timer1);
            };

        }
    }, [fastFilter]);


    useEffect(() => {
        if (gridApi) {
            const dataSource = {
                getRows: (params) => {
                    let mounted = true;
                    if (mounted) {
                        getRowData(params);
                    }
                    return () => mounted = false;
                }
            }

            gridApi.setDatasource(dataSource);
        }
    }, [gridApi]);

    const onGridReady = params => {
        setGridApi(params.api);
        // params.api.sizeColumnsToFit();
        window.addEventListener('resize', function () {
            setTimeout(function () {
                // params.api.sizeColumnsToFit();
                setSheight(window.screen.height * 0.8 - 240);
            }, 300);
        });

        // params.api.sizeColumnsToFit();
    };
    const onQuickFilterChanged = e => {
        console.log();
        gridApi.fastFilter = e.target.value;
        // gridApi.setQuickFilter(e.target.value);
        setFastFilter(e.target.value);

    };
    const print = (psale) => {
        iframePrintTemplate.current.print(psale)
    }
    const onClickPrint = data => {
        console.log(data)
        setPrintSale(data)
        setTimeout(function () {
            print(data);
        })
    }
    const goTo = invoiceUid => {
        history.push("/mydata/" + invoiceUid);
    }
    const onPaginationChanged = (val) => {
        var currentPage = val.api.paginationGetCurrentPage();
        setCurrentPage(currentPage)

    }
    const dateFormatter = (params) => {
        if (params.data != undefined) {
            var dateAsString = moment(params.data.invoiceDate).format('DD/MM/YYYY hh:mm')
            return dateAsString
        }
        return ""
    }

    const getPrintSale = (sale) => {
        var saleClone = JSON.parse(JSON.stringify(sale));
        var cancelmark = sale.mark.props.children[0].props.children;
        var mark = sale.mark.props.children[1].props.children;
        saleClone.cancellationMark = cancelmark;
        saleClone.mark = mark;
        return iframePrintTemplate.current.getPrintSale2(saleClone)
    }

    const saleEntriesRender = (saleEntries) => {
        var rows = [];
        var _thead = <thead><tr><th>{t("priceWithoutVat")}</th><th>{t("vat")}</th><th>{t("priceWithVat")}</th><th>{t("saleEntryClassifications")}</th></tr></thead>;
        saleEntries.forEach(entry => {
            var _tr = <tr><td>{entry.priceWithoutVat}</td><td>{entry.vat}</td><td>{entry.priceWithVat}</td><td>{saleEntryClassificationRender(entry.saleEntryClassifications)}</td></tr>;
            rows.push(_tr);
        });

        return <table className="table">{_thead}<tbody>{rows}</tbody></table>;
    }

    const saleEntryClassificationRender = (classifications) => {
        var rows = [];
        var _thead = <thead><tr><th>{t("incomeClassificationCategoryType")}</th><th>{t("incomeClassificationValueType")}</th><th>{t("amount")}</th></tr></thead>;
        classifications.forEach(entry => {
            var classCat = entry.incomeClassificationCategoryType ? entry.incomeClassificationCategoryType : entry.expensesClassificationCategoryType
            var classType = entry.incomeClassificationValueType ? entry.incomeClassificationValueType : entry.expensesClassificationValueType

            var _tr = <tr><td>{t(classCat)}</td><td>{t(classType)}</td><td>{entry.amount}</td></tr>;
            rows.push(_tr);
        });

        return <table className="table">{_thead}<tbody>{rows}</tbody></table>;
    }

    const synchronizeCompanyTransmitted = () => {
        setBlockuiLoading(true)
        var companyId = JSON.parse(localStorage.getItem("company")).id;
        ApiClient.synchronizeCompanyTransmitted(companyId).then(
            response => {

                setBlockuiLoading(false)
            },
            error => {
                console.error(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString());
                setBlockuiLoading(false)
            }
        );
    }

    return (
        <BlockUi tag="div" blocking={blockuiLoading}>
            <div className="container-fluid">
                <header className="jumbotron">
                    <div className="row">
                        <div className="col-md-6">
                            <h5 className="subtitle skin">{t("invoicesSearch")}</h5>
                        </div>
                        <div className="col-md-6">
                            <div className="addmodelBtnContainer">
                                <button className="btn btn-skin" href="" onClick={function () { return history.push("/sales"); }}>
                                    {t("InvoicesFromTimologic")}
                                </button>
                                <button style={{ width: 160 }} className="btn btn-skin" onClick={synchronizeCompanyTransmitted}>Synchronize</button>

                            </div>
                        </div>
                    </div>

                </header>
                <div style={{ width: '100%', height: '100%' }}>
                    <div className="row">
                        <div className="offset-md-4 col-md-4">
                            <p className="text-center">
                                <input placeholder={t('Search')} className="form-control" onChange={onQuickFilterChanged} />
                            </p>
                        </div>

                    </div>

                    <div
                        id="myGrid"
                        style={{
                            height: '100%',
                            width: '100%',
                        }}
                        className="ag-theme-alpine-dark sales-table-container"
                    >
                        <div className="ag-theme-alpine" style={{ height: sheight, width: '100%' }}>
                            <AgGridReact
                                pagination={pagination}
                                paginationPageSize={paginationPageSize}
                                onGridReady={onGridReady}
                                style={{ width: '100%', height: '100%;' }}
                                onRowDoubleClicked={(e) => goTo(e.data.invoiceUid)}
                                // rowData={content}
                                // domLayout={'autoHeight'}
                                suppressRowTransform={true}
                                frameworkComponents={{
                                    actionsRenderer: ActionsRenderer,
                                    seriesNumberRendener: SeriesNumberRendener,
                                    markRenderer: MarkRenderer,
                                    agDateInput: CustomDateComponent
                                }}
                                overlayNoRowsTemplate={
                                    '<span style="padding: 10px;  color:#013289">' + t('noEntries') + '</span>'
                                }
                                onPaginationChanged={onPaginationChanged}
                                rowModelType={'infinite'}
                                cacheBlockSize={paginationPageSize}
                                defaultColDef={{
                                    flex: 1,
                                    resizable: true,
                                    sortable: true,
                                    wrapText: true,
                                    autoHeight: true,
                                    suppressMovable:true,
                                }}
                            >
                                <AgGridColumn flex={1} minWidth={120} headerName={t("Number")} cellRenderer="seriesNumberRendener" sortable={true} filter="agTextColumnFilter" field="seriesNumber"></AgGridColumn>
                                <AgGridColumn flex={5} minWidth={220} headerName={t("InvoiceType")} sortable={true} filter="agTextColumnFilter" valueFormatter={params => { return params.data ? t(params.data.invoiceType) : "" }} field="invoiceType"></AgGridColumn>
                                <AgGridColumn minWidth={150} valueFormatter={dateFormatter} flex={3} headerName={t('DateTime')} sortable={true} filter="agDateColumnFilter" field="invoiceDate"></AgGridColumn>
                                <AgGridColumn flex={5} minWidth={250} headerName={t("Customer")} sortable={true} filter="agTextColumnFilter" field="customerTaxPayerId"></AgGridColumn>
                                <AgGridColumn minWidth={100} valueFormatter={params => params.data != null ? params.data.totalWithoutVat.toFixed(2) : 0.00} flex={2} headerName={t("TotalWithoutVat")} sortable={true} filter="agNumberColumnFilter" field="totalWithoutVat"></AgGridColumn>
                                <AgGridColumn minWidth={100} valueFormatter={params => params.data != null ? params.data.totalWithVat.toFixed(2) : 0.00} flex={2} headerName={t("TotalWithVat")} sortable={true} filter="agNumberColumnFilter" field="totalWithVat"></AgGridColumn>
                                <AgGridColumn flex={4} minWidth={250} headerName={t("invoiceUid")} sortable={true} filter="agTextColumnFilter" field="invoiceUid"></AgGridColumn>
                                <AgGridColumn flex={4} minWidth={150} headerName={t("mark")} cellRenderer="markRenderer" sortable={true} filter="agNumberColumnFilter" field="mark"></AgGridColumn>
                                <AgGridColumn flex={3} minWidth={200} headerName={t("Actions")} cellRendererParams={
                                    {
                                        printSale: printSale,
                                        onClickPrint: onClickPrint,
                                        blockUi: blockUi,
                                        setShowDetailsModal: setShowDetailsModal,
                                        setLineData: setLineData,
                                        getPrintSale: getPrintSale,
                                        setDetailsInfo: setDetailsInfo
                                    }
                                } cellRenderer="actionsRenderer" field="actions"></AgGridColumn>

                            </AgGridReact>
                        </div>
                        <div><br></br></div>
                        <IFramePrintTemplate ref={iframePrintTemplate} ></IFramePrintTemplate>
                    </div>
                </div>
            </div>
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                fullscreen
                centered
                show={showDetailsModal}
                dialogClassName="invoice-details-modal modal-fullscreen"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header >
                    <Modal.Title>{t("Invoice Details")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <InvoiceSimpleView invoice={detailsInfo}></InvoiceSimpleView>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-skin" onClick={function () { setShowDetailsModal(false) }}>{t("cancel")}</button>
                </Modal.Footer>
            </Modal>


        </BlockUi>
    );
}


