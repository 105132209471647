import React from "react";
import './i18n';

import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import { ToastContainer } from 'react-toastify';


import App from "./App";
import * as serviceWorker from "./serviceWorker";

const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}

ReactDOM.render(

  <BrowserRouter>
    <AlertProvider template={AlertTemplate} {...options}>
      <App />
      <ToastContainer style={{width:"auto"}} autoClose={10000}/>
    </AlertProvider>
  </BrowserRouter>,
  document.getElementById("root")

);

serviceWorker.unregister();
