import React, { useState, useEffect } from 'react';
import { useStripe, Elements } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';

const PaymentStatus = () => {
  const stripe = useStripe();
  const [message, setMessage] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the "payment_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    // Retrieve the PaymentIntent
    stripe
      .retrievePaymentIntent(clientSecret)
      .then(({ paymentIntent }) => {
        // Inspect the PaymentIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
        switch (paymentIntent.status) {
          case 'succeeded':
            setMessage(
              <div class="alert alert-success" role="alert">
                {t("stripePaymentSuccess")}
              </div>);
            break;

          case 'processing':
            setMessage(
              <div class="alert alert-secondary" role="alert">
                {t("stripePaymentProcessing")}
              </div>);
            break;


          case 'requires_payment_method':
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            setMessage(
              <div class="alert alert-danger" role="alert">
                {t("stripeRequiresPaymentMethod")}

              </div>);
            break;

          default:
            setMessage(
              <div class="alert alert-danger" role="alert">
                {t("stripeUknownPaymentError")}
              </div>);
            break;
        }
      });
  }, [stripe]);


  return message;
};

export default PaymentStatus;