import React from 'react';
import {
  Route,
  Redirect
} from "react-router-dom";

import { ACCESS_TOKEN } from '../util/constants';
import Paymemnt from "./payment.component.js";



const PrivateRoute = ({ component: Component, authenticated, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      localStorage.getItem(ACCESS_TOKEN) != null ? (
        JSON.parse(localStorage.getItem("user")).userStatus !== "NOT_PAID" ? <Component {...rest} {...props} /> :
          <Paymemnt  {...rest} {...props} />


      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
            search: props.location.search
          }}
        />
      )
    }
  />
);

export default PrivateRoute