import React, { forwardRef } from "react";
import { useHistory } from "react-router-dom"
import { useTranslation } from 'react-i18next';


const FormTemplate = (props) => {

    const { t } = useTranslation();
    const history = useHistory();

    const showIcon=(props)=>{
        if(props.icon){
            return <span>{props.icon}</span>
        }
    }

    return (
        <div className="card internal-card">
            <div className="card-header internal-header">
                <h5 className="subtitle skin">
                    <span className="title-span">
                        {showIcon(props)}
                        {props.title}
                    </span>
                    {props.headerRightContent}
                </h5>
               
               
            </div>
            <div className="card-body form-card-body">
                {props.children}
            </div>
            {
                props.footer ? 
                <div className="card-footer internal-footer">
                    <div className="form-group text-center">
                        {props.footer }
                    </div>
                </div> :
                    ""
            }

        </div>
    );
}
export default FormTemplate

