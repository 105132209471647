import React, { useState, useImperativeHandle, forwardRef } from "react";

import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ApiClient from "../../services/api-client"
import { toast } from 'react-toastify';


const MyDataSubscriptionModal = forwardRef((props, ref) => {

    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);


    const validationSchema = Yup.object().shape({
        taxisNetUserName: Yup.string().required(t("fieldRequired")),
        taxisNetPassword: Yup.string().required(t("fieldRequired")),
    });

    const { register, getValues, control, handleSubmit, setValue, reset, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });


    useImperativeHandle(ref, () => ({

        open() {
            setShowModal(true)
        },
        close() {
            setShowModal(false)
        },

    }));
    const handleCloseModal = (e) => {
        e.preventDefault();
        setShowModal(false);
    }

    const onSubmit = (request, e) => {
        e.preventDefault();
        e.stopPropagation()
        props.setBlockuiLoading(true)
        ApiClient.getMyDATASubscriptionKey(request).then(
            response => {
                props.myDATASubscriptionCallback(response.data)
                props.setBlockuiLoading(false)
                setShowModal(false);
            },
            error => {
                toast.error(error.message + ": " + error.response.data.message)
                console.error(error.response);
                props.setBlockuiLoading(false)
            }
        )
    }

    return (
        <form id="myDATA_subscriptionKey_form" autoComplete="off" >

        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showModal}
            backdrop="static"
            keyboard={false}
            scrollable={true}

        >
           

                <Modal.Header >
                    <Modal.Title>
                    <h3 className="text-center">{t("myDATAsubscriptionModalTitle")}</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                       
                        <div className="alert alert-warning small-info" role="alert">
                            {t("myDATAsubscriptionModalInfo")}
                        </div>

                        <div className="row">
                            <div className="col-md-6 form-group required">
                                <label className="control-label">{t("taxisNetUserName")}</label>
                                <input
                                    name="taxisNetUserName"
                                    type="text"
                                    {...register('taxisNetUserName', { required: true })}
                                    autoComplete="off"
                                    className={`form-control ${errors.taxisNetUserName ? 'is-invalid' : ''}`}
                                />
                                <div className="invalid-feedback">{errors.taxisNetUserName?.message}</div>
                            </div>
                            <div className="col-md-6 form-group required">
                                <label className="control-label">{t("taxisNetPassword")}</label>
                                <input
                                    name="taxisNetPassword"
                                    type="password"
                                    autoComplete="new-password"
                                    {...register('taxisNetPassword', { required: true })}
                                    className={`form-control ${errors.taxisNetPassword ? 'is-invalid' : ''}`}
                                />
                                <div className="invalid-feedback">{errors.taxisNetPassword?.message}</div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-skin" onClick={handleSubmit(onSubmit)}>{t("createMyDataKeys")}</button>
                    <button className="btn btn-skin" onClick={handleCloseModal}>{t("cancel")}</button>
                </Modal.Footer>
          
        </Modal>
        </form>

    );
}
);

export default MyDataSubscriptionModal;
