import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import SelectTable from '../select-table'
import ApiClient from "../../services/api-client"
import { useTranslation } from 'react-i18next';
import loadCompaniesOptions from "../../services/loadCompanies";

const loadPageOptions = async (q, prevOptions, { page }, props) => {
    const { options, hasMore } = await loadCompaniesOptions(q, page, props);
    return {
        options,
        hasMore,

        additional: {
            page: page + 1
        }
    };
};
export default function ChooseCompany(props) {

    const { t } = useTranslation();
    const [selectedCompany, setSelectedCompany] = useState();

    const handleChange = value => {
        setSelectedCompany(value)
        var id = value.value;
        localStorage.removeItem("defaults");
        ApiClient.getCompany(id).then(
            (response) => {
                var jsonComp = response.data;
                localStorage.setItem("company", JSON.stringify(jsonComp));

            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                this.setState({
                    loading: false,
                    message: resMessage
                });
            }
        );
    };





    useEffect(() => {
        let mounted = true;
        var jsonComp = JSON.parse(localStorage.getItem('company'));
        if (jsonComp) {
            setSelectedCompany({
                value: jsonComp.id,
                label: jsonComp.name,
                taxid: jsonComp.taxpayerId
            })
        }
        return () => mounted = false;

    }, []);

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={props.showCompanyModal}
            dialogClassName="company-modal-dialog"
            onHide={props.handleCloseCompanyModal}
            backdrop="static"
            className="company-modal"
            keyboard={false}
        >
            <Modal.Header style={{padding:6}}>
                <Modal.Title><small>{t("chooseCompany")}</small></Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: 0 }}>
                <div className="chooseCompanySelectContainer">
                    <SelectTable
                        inputId="choose-company-select"
                        width={500}
                        defaultValue={selectedCompany}
                        onChangeValue={handleChange}
                        columns={[
                            {
                                header: t("Name"),
                                field: "name",
                                flex: 8,
                                asLabel: true
                            },
                            {
                                header: t("taxpayerId"),
                                field: "taxpayerId",
                                flex: 4
                            }
                        ]}
                        value={selectedCompany}
                        asyncPaginate={true}
                        key={new Date().getTime()}
                        className={`form-control`}
                        placeholder={t('Select')}
                        loadOptions={loadPageOptions}
                    />
                </div>

            </Modal.Body>
            <Modal.Footer  style={{padding:6}}>
                <Button className="btn-sm btn-skin" onClick={props.handleCloseCompanyModal} variant="primary">{t("Choose")}</Button>
            </Modal.Footer>
        </Modal>

    );
}
