import React, { forwardRef, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from "react-country-flag"
import fbLogo from '../img/fb-logo.png';
import googleLogo from '../img/google-logo.png';
import appleLogo from '../img/apple-logo.png';

import { GOOGLE_AUTH_URL, FACEBOOK_AUTH_URL, APPLE_AUTH_URL, ACCESS_TOKEN } from '../util/constants';
import logo from '../img/timologic_login.png';
import Commons from '../util/commons'
import LogoEmpty from '../img/logo-empty.png';
import { Link } from 'react-router-dom'


function SocialLogin(props) {
    let agent = navigator.userAgent;
    const { t } = useTranslation();

    console.log(agent)
    let display = "block"


    //  if (agent.includes("ANDROID_KEY")) {
    //      display = "none"
    //  }

    if (window.location !== window.parent.location) {
        display = "none"
    }

    const singleSignInAction = (provider) => {


        switch (provider) {
            case "google": {
                window.location.href = GOOGLE_AUTH_URL;
                break;
            }
            case "fb": {
                window.location.href = FACEBOOK_AUTH_URL;
                break;
            }
            case "apple": {
                window.location.href = APPLE_AUTH_URL;
                break;
            }
        }

        // if (agent.includes("ANDROID_KEY")) {
        //     setTimeout(function () {
        //         window.Android.runSingleSignIn(provider);
        //     }, 2000)
        // }
        // else{
        //     switch(provider){
        //         case "google":{
        //             window.location.href=GOOGLE_AUTH_URL;
        //             break;
        //         }
        //         case "fb":{
        //             window.location.href=FACEBOOK_AUTH_URL;
        //             break;
        //         }
        //         case "apple":{
        //             window.location.href=APPLE_AUTH_URL;
        //             break;
        //         }
        //     }
        // }
    }

    return (
        <div className="social-login" style={{ display: display }}>
            {t("ConnectWith")}

            <div className="row">


                <div className="d-flex justify-content-between">
                    <a className="btn btn-block social-btn" id="google_ssi_btn" onClick={function () { singleSignInAction("google"); }}>
                        <img src={googleLogo} alt="Timologic myDATA Google" />

                    </a>
                    <a className="btn btn-block social-btn" id="fb_ssi_btn" onClick={function () { singleSignInAction("fb"); }}>
                        <img src={fbLogo} alt="Timologic myDATA Facebook" />
                    </a>
                    <a className="btn btn-block social-btn" id="apple_ssi_btn" onClick={function () { singleSignInAction("apple"); }}>
                        <img src={appleLogo} alt="Timologic myDATA Apple" />
                    </a>
                </div>
            </div>
            {/* <div className="or-separator">
                <span className="or-text">{t("or")}</span>
            </div> */}
        </div>
    );
}


const LoginRegisterLayout = (props) => {

    const { t, i18n } = useTranslation();

    const changeLang = (e, lang) => {
        i18n.changeLanguage(lang.toLowerCase())
    }

    if (i18n.language == "en-US") {
        i18n.changeLanguage("gr")
    }

    const [color, setColor] = useState("transparent")

    useEffect(() => {
        let mounted = true;

        if (window.location !== window.parent.location) {
            setColor("#333")
        }
        return () => mounted = false;

    }, []);
    const getLogo = () => {
        // var url = (window.location != window.parent.location)
        //     ? document.referrer
        //     : document.location.href;

        // let params = (new URL(url)).searchParams;

        // let aff = params.get('aff');
        // var whitelabel = Commons.getCookie("whitelabel");
        // var parentStr = Commons.getParentQueryString();

        // if (whitelabel == "true") {
        //     return "https://etimologio.gr/assets/img/logo-e-timologio-mydata-timologiera-timologisi.png";

        // }
        // else {
        //     return logo
        // }


        //if we are on iframe
        if (window.location !== window.parent.location) {
            //send message to iframe to ask aff
            window.parent.postMessage("messageFromLogin", "*");
            return LogoEmpty;
            // return "https://etimologio.gr/assets/img/logo-e-timologio-mydata-timologiera-timologisi.png";
        }


        return logo

    }
    return (
        <div className="login-register-container">
            <div className="signup-container container">
                <div className="row ">
                    <div className="col-sm-12" style={{ padding: 0 }}>

                        <div className="signup-content">
                            <div style={{ backgroundColor: color }} className="link_logo" >
                                {/* <a href="https://timologic.gr/" target="_blank"> </a> */}
                                <img
                                    src={getLogo()}
                                    alt="timologic myDATA app"
                                    className="profile-img-card"
                                />
                                <img />
                            </div>
                            <h4>{t("InvoiceApplication")}</h4>
                            <SocialLogin />

                            {props.children}
                            <div><hr></hr></div>
                            <small>{t("forgotPassMess")}<Link to="/forgot">{t("here")}!</Link></small>
                            <div className="row">
                                <div children="d-flex">
                                    <a href="#" onClick={(e) => changeLang(e, "GB")}>
                                        <ReactCountryFlag
                                            className="flagIcon"
                                            countryCode="GB"
                                            svg
                                            style={{
                                                fontSize: '2em',
                                                lineHeight: '2em',
                                            }}
                                            aria-label="United States"
                                        />
                                    </a>
                                    <a href="#" onClick={(e) => changeLang(e, "GR")}>
                                        <ReactCountryFlag
                                            className="flagIcon"
                                            countryCode="GR"
                                            svg
                                            style={{
                                                fontSize: '2em',
                                                lineHeight: '2em',
                                            }}
                                            aria-label="Ελλαδα"
                                        />
                                    </a>

                                </div>

                            </div>
                        </div>


                    </div>
                </div>
                <div className="row">
                    {props.footer}
                </div>

            </div>
        </div>
    );
}
export default LoginRegisterLayout

