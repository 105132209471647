import React, { useState, useEffect, useRef } from "react";
import { getDefaults, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ApiClient from "../../services/api-client"
import { CustomConfirmAlert as confirmAlert } from '../custom-confirm-alert';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';
import moment from 'moment'
import SaleEntries from "../sales/sale-entries.component";
import Commons from '../../util/commons'
import { useHistory } from "react-router-dom";
import MyDataLogo from "../../img/mydata.png";
// import PlacesAutocomplete, {
//     geocodeByAddress,
//     getLatLng,
// } from 'react-places-autocomplete';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import SelectTable from '../select-table'
import { toast } from 'react-toastify';
import MoreTaxesGrid from '../editable-aggrids/sale-more-taxes-grid';
import EntriesSummary from "../sales/entries.summary.component";

import ReactDOM from 'react-dom';
import loadSaleInstancesOptions from "../../services/loadSaleInstances";
import loadCustomersOptions from "../../services/loadCustomers";

import FormLayout from '../form-layout';
import IFramePrintTemplate from '../iframe-print-template.component';
import ReactFlagsSelect from "react-flags-select";
import Tippy from '@tippyjs/react';
import { API_BASE_URL } from '../../util/constants';
import useMobileDetect from 'use-mobile-detect-hook';

import ErrorDetailsModal from "../modals/error-details-modal";

const loadPageOptions = async (q, prevOptions, { page }, props) => {
    const { options, hasMore } = await loadSaleInstancesOptions(q, page, props);
    return {
        options,
        hasMore,

        additional: {
            page: page + 1
        }
    };
};
const loadCustomerPageOptions = async (q, prevOptions, { page }, props) => {
    const { options, hasMore } = await loadCustomersOptions(q, page, props);
    return {
        options,
        hasMore,

        additional: {
            page: page + 1
        }
    };
};
var saleEntries = {
    entries: [], totals: {
        totalWithoutDiscount: 0, totalWithoutVat: 0, totalWithDiscount: 0, totalWithVat: 0, totalPayment: 0, totalvat: 0
    }
};
const SalesAddEdit = (props) => {

    const { id } = useParams();
    const [content, setContent] = useState({ customers: { data: [], columns: [] } });
    const [customerContacts, setCustomerContacts] = useState({ data: [], columns: [] });
    const selectInputRef = useRef();
    const iframePrintTemplate = useRef();
    const refSalesEntries = useRef();
    const [salesLeft, setSalesLeft] = useState(0);
    const { t } = useTranslation();
    const [startDate, setStartDate] = useState(new Date());
    const [dispatchDate, setDispatchDate] = useState(null);
    const [mydata, setMyData] = useState(false);
    const [editMark, setEditMark] = useState("");
    const [customerRequired, setCustomerRequired] = useState(true);
    const [addressRequired, setAddressRequired] = useState(false);
    const [blockuiLoading, setBlockuiLoading] = useState(false);
    const history = useHistory();
    const [correlativeSales, setCorrelativeSales] = useState({ data: [], columns: [] });
    const [suggestedInClassCatsOfInvType, setSuggestedInClassCatsOfInvType] = useState([]);
    const [suggestedInvoiceClassCatsOfInvType, setSuggestedInvoiceClassCatsOfInvType] = useState([]);
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [selfPricingChecked, setSelfPricingChecked] = useState(false);
    const [inputCustomerValue, setCustomerInputValue] = useState("");
    const [customerValue, setCustomerValue] = useState("");
    const [moreTaxesTypesOptions, setMoreTaxesTypesOptions] = useState([]);
    const [DefaultCountry, setDefaultCountry] = useState({});
    const [countryDisabled, setCountryDisabled] = useState(false);
    const [mark, setMark] = useState(null);
    const [customerHidden, setCustomerHidden] = useState("flex");
    const [transportInfoDisplay, setTransportInfoDisplay] = useState("none");
    const [entriesHidden, setEntriesHidden] = useState("none");
    const [otherTaxHidden, setOtherTaxHidden] = useState("none");
    const [otherTaxRequired, setOtherRequired] = useState(false);
    const [template, setTemplate] = useState(false);
    const [isInvoice00_1, setIsInvoice00_1] = useState(false);
    const [sosMessage, setSosMessage] = useState(false);
    const [moreTaxesOptions, setMoreTaxesOptions] = useState([]);
    const [otherTaxesOptions, setOtherTaxesOptions] = useState([]);
    const saleMoreTaxesGridRef = useRef();
    const refEntriesSummary = useRef();
    const [initiallyCollapsed, setInitiallyCollapsed] = useState({ collapsed: true, show: false });
    const [initiallyCollapsedMore, setInitiallyCollapsedMore] = useState({ collapsed: true, show: false });



    let SELECTED_COMPANY = JSON.parse(localStorage.getItem("company"));

    const [onEditDisabled, setOnEditDisabled] = useState(false);
    const [dismissSalesEditAlert, setDismissSalesEditAlert] = useState(false);
    const [sendPaymentLink, setSendPaymentLink] = useState(false);
    const detectMobile = useMobileDetect();


    const [totals, setTotals] = useState({
        totalWithoutDiscount: "0.00",
        totalWithDiscount: "0.00",
        totalWithoutVat: "0.00",
        totalWithVat: "0.00",
        totalPayment: "0.00",
        totalvat: "0.00"
    });

    const params = new URLSearchParams(window.location.search);
    let customer = params.get('customer');
    let isCredit = params.get('credit');
    let isPreSale = params.get('presale');

    isCredit = isCredit == "true" ? true : false;
    isPreSale = isPreSale == "true" ? true : false;
    let defcountry = {};
    var TOTALS;
    const [selectedTemplateLanguage, setSelectedTemplateLanguage] = useState("GR");

    const refErrorDetailsModal = useRef();


    const validationSchema = Yup.object().shape({
        customer: customerRequired ? Yup.object().required(t("fieldRequired")).nullable() : Yup.object().nullable(),
        branch: Yup.object().required(t("fieldRequired")).nullable(),
        invoiceType: Yup.object().required(t("fieldRequired")).nullable(),
        series: Yup.object().required(t("fieldRequired")).nullable(),
        // subjectField: Yup.string().nullable().test('len', t("maxLengthValidation", { maxlength: 150 }), val => val ? val.length : "" <= 150),
        subjectField: Yup.string().nullable().max(150, t("maxLengthValidation", { maxlength: 150 })),
        // postalCode: Yup.number().typeError(t("mustbenumber")).nullable().transform((curr, orig) => orig === '' ? null : curr),
        invoiceDate: Yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr).required(t("fieldRequired")),
        taxpayerId: customerRequired ? Yup.string().required(t("fieldRequired")).nullable() : Yup.string().nullable(),
        city: addressRequired ? Yup.string().required(t("fieldRequired")) : Yup.string().nullable(),
        otherTaxCategory: otherTaxRequired ? Yup.object().required(t("fieldRequired")).nullable() : Yup.object().nullable(),
        otherTaxAmount: otherTaxRequired ? Yup.string().required(t("fieldRequired")).nullable() : Yup.string(),

    });

    const { register, getValues, control, handleSubmit, setValue, reset, formState: { errors }, trigger, clearErrors } = useForm({
        resolver: yupResolver(validationSchema)
    });

    useEffect(() => {

        var companyId = SELECTED_COMPANY.id;
        var defaults = Commons.getDefaults();
        let mounted = true;

        if (defaults.branch && defaults.branch.value && !isCredit) {
            ApiClient.getTemplateSettings(companyId, defaults.branch.value).then(response => {
                var _template = response.data;
                _template.branch = defaults.branch.value;
                _template.mainColor = _template.mainColor ? _template.mainColor : "#013289";
                _template.secondaryColor = _template.secondaryColor ? _template.secondaryColor : "#FFFFFF";
                _template.appFont = _template.appFont ? _template.appFont : "Arial";
                setTemplate(_template);

            },
                error => {
                    console.error(
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString())
                }
            );
        }


        if (defaults != undefined && defaults.mydata != undefined) {
            setMyData(defaults.mydata)
        }
        else {
            if (SELECTED_COMPANY.myDataToken) {
                setMyData(true)
            }
            else {
                setMyData(false)
            }
        }

        if (defaults) {
            setDismissSalesEditAlert(defaults.dismissSalesEditAlert)

        }

        var columns = [
            {
                header: t("invoiceNumber"),
                field: "code",
                flex: 2,
            },
            {
                header: t("InvoiceType"),
                field: "invoiceType",
                asLabel: true,
                flex: 6
            },
            {
                header: t("mark"),
                field: "mark",
                flex: 4
            }
        ]
        var options = { data: [], columns: columns }

        setCorrelativeSales(options)
        setBlockuiLoading(true)
        ApiClient.getSalesView(companyId).then(response => {
            var data_companyBranches = response.data.companyBranches;
            var data_companyInvoiceTypes = response.data.companyInvoiceTypes;
            var data_paymentTypes = response.data.paymentTypes;
            var data_vats = response.data.vats;
            var data_measurementUnits = response.data.measurementUnits;
            var data_vatCategoryExceptions = response.data.vatCategoryExceptions;
            var data_myDataMovePurposes = response.data.myDataMovePurposes;
            var data_taxOffices = response.data.taxOffices;
            var data_countries = response.data.countries;
            var data_currencies = response.data.currencyTypeList;


            var applicationSosMessages = response.data.applicationSosMessages;

            if (applicationSosMessages && applicationSosMessages.sosMessage) {
                setSosMessage({
                    message: applicationSosMessages.sosMessage,
                    alertClass: "alert alert-" + applicationSosMessages.sosMessageStatus + " alert-dismissible fade show"
                })
            }



            setSalesLeft(response.data.countSales)

            var optionBranches = [];
            var optionsinvoiceTypes = [];
            var optionspaymentTypes = [];
            var optionsVats = [];
            var optionsMU = [];
            var optionsWHT = [];
            var optionsOT = [];
            var optionsVCT = [];
            var optionsMP = [];
            var optionsTO = [];
            var optionsCountries = [];
            var optionsCurrencies = [];


            data_countries.forEach(element => {
                var to = {
                    value: element.alpha2,
                    label: t(element.alpha2),
                    alpha2: element.alpha2
                }
                optionsCountries.push(to);
                if (element.alpha2 == "GR") {
                    setDefaultCountry(to)
                    defcountry = to;
                }
            });

            data_currencies.forEach(element => {
                optionsCurrencies.push({
                    value: element.symbol,
                    label: element.name + " (" + element.symbol + ")",
                    rate: element.rate
                });
            });

            data_companyBranches.forEach(element => {
                optionBranches.push({
                    value: element.id,
                    label: element.name,
                    model: element
                });
            });

            data_companyInvoiceTypes.forEach(element => {
                optionsinvoiceTypes.push({
                    value: element.id,
                    label: element.name,
                    myDataInvoiceType: element.myDataInvoiceType,
                    invoiceTypeClassifications: element.invoiceTypeClassifications
                });
            });

            data_paymentTypes.forEach(element => {
                optionspaymentTypes.push({
                    value: element,
                    label: element,
                });
            });

            data_vats.forEach(element => {
                optionsVats.push({
                    value: element.id,
                    label: element.value,
                });
            });

            data_measurementUnits.forEach(element => {
                optionsMU.push({
                    value: element,
                    label: t(element),
                });
            });



            var myDataMoreTaxesOptions = [];
            var myDataMoreTaxesData = response.data.myDataMoreTaxes;
            myDataMoreTaxesData.forEach(element => {
                myDataMoreTaxesOptions.push({
                    value: element.type == "DEDUCTION" ? element.id : element.uid,
                    uid: element.type == "DEDUCTION" ? element.id : element.uid,
                    label: element.type == "DEDUCTION" ? element.name : t("TAX_" + element.uid),
                    type: element.type,
                    key: element.key,
                    amountValue: element.amountValue,
                    isSubtracted: element.isSubtracted
                });
            });

            var moreTaxesTypesOptions = [];
            var myDataMoreTaxesTypes = response.data.myDataMoreTaxesTypes;
            myDataMoreTaxesTypes.forEach(element => {
                moreTaxesTypesOptions.push({
                    value: element,
                    label: t(element)
                });
            });

            let groupTaxes = myDataMoreTaxesOptions.reduce((r, a) => {
                r[a.type] = [...r[a.type] || [], a];
                return r;
            }, {});

            var typeTaxes = groupTaxes["OTHER_TAXES"];

            setMoreTaxesOptions(myDataMoreTaxesOptions);
            setMoreTaxesTypesOptions(moreTaxesTypesOptions);
            setOtherTaxesOptions(typeTaxes)


            data_vatCategoryExceptions.forEach(element => {
                optionsVCT.push({
                    value: element,
                    label: t(element)
                });
            });

            data_myDataMovePurposes.forEach(element => {
                optionsMP.push({
                    value: element,
                    label: t(element)
                });
            });

            data_taxOffices.forEach(element => {
                optionsTO.push({
                    value: element.id,
                    label: t(element.name)
                });
            });


            reset();
            var _content = {
                sale: null,
                paymentTypes: optionspaymentTypes,
                currencies: optionsCurrencies,
                vats: optionsVats,
                measurementUnits: optionsMU,
                withHoldTaxes: optionsWHT,
                otherTaxes: optionsOT,
                vatCategoryExceptions: optionsVCT,
                movePurposes: optionsMP,
                taxOffices: optionsTO,
                optionsinvoiceTypes: optionsinvoiceTypes,
                optionBranches: optionBranches,
                optionsCountries: optionsCountries
            }
            if (mounted) {

                window.addEventListener("message", listenMessage, false);
                if (window.addEventListener) {
                    window.addEventListener("message", listenMessage, false);
                } else {
                    window.removeEventListener("message");
                    window.attachEvent("onmessage", listenMessage);
                }
                setContent(_content)


                if (id != undefined) {
                    if (!isPreSale) {
                        setOnEditDisabled(true)
                    }

                    ApiClient.getCompanySaleInstance(companyId, id).then(
                        response => {
                            var sale = response.data;

                            var _invoiceType = {
                                label: sale.invoiceType.name,
                                value: sale.invoiceType.id,
                                myDataInvoiceType: sale.invoiceType.myDataInvoiceType,
                                invoiceTypeClassifications: sale.invoiceType.invoiceTypeClassifications
                            }

                            sale.invoiceType = _invoiceType
                            if (!isCredit) {
                                if (sale.mark) {
                                    var markInfo =
                                        <div>
                                            <div>{t("currentMarkInfo") + ": " + sale.mark + ". "}</div>

                                            {dismissSalesEditAlert ?
                                                <>
                                                    <div className="alert alert-warning alert-dismissible fade show" id="dismissSalesEditAlert" role="alert" style={{ padding: 10 }}>
                                                        <div><small> {t("currentMarkInfo2")}</small></div>
                                                        <button type="button" style={{ padding: 8 }} className="btn-close" onClick={dismissSalesEditAlertHandle} data-bs-dismiss="alert" aria-label="Close"></button>
                                                    </div></> : <></>
                                            }

                                        </div>
                                    setEditMark(markInfo);
                                }
                            }



                            changeBranch(sale.branch);
                            sale.invoiceType.branchId = sale.branch.id;
                            changeInvType(sale.invoiceType);



                            if (!isCredit && !isPreSale) {
                                setValue("invoiceType", sale.invoiceType);
                                var _ser = { value: sale.series.name, label: sale.series.name };
                                sale.series = _ser;
                                setValue("series", sale.series);
                                setValue("invoiceNumber", sale.invoiceNumber);
                            }
                            else {



                                if (sale.invoiceType.myDataInvoiceType == "invoice1_1" || sale.invoiceType.myDataInvoiceType == "invoice2_1" || sale.invoiceType.myDataInvoiceType == "invoice1_2" || sale.invoiceType.myDataInvoiceType == "invoice1_3") {
                                    var _invtype = {
                                        label: t("invoice5_1"),
                                        value: 999999,
                                        myDataInvoiceType: "invoice5_1"
                                    }
                                    setValue("invoiceType", _invtype);
                                    document.getElementsByClassName("correlativeSale")[0].style.display = "unset";
                                    sale.correlativeSale = {
                                        label: sale.invoiceType.label,
                                        value: sale.id,
                                        mark: sale.mark,
                                    }
                                    setValue("correlativeSale", sale.correlativeSale);
                                }
                            }

                            var asExpenses = getValues().selfPricing || Commons.isOnGroup(sale.invoiceType.myDataInvoiceType, "isExpense");

                            ApiClient.getClassificationCategoriesOfMyDataInvoice(sale.invoiceType.myDataInvoiceType, asExpenses).then(response => {
                                var data = response.data;
                                var obj = {}
                                data.forEach(element => {
                                    if (element.classificationCategory) {
                                        obj[element.classificationCategory] = element.classificationValues;
                                    }
                                    else {
                                        obj[element.classificationCategory] = element.classificationValues;
                                    }
                                });
                                setDataWithClassifications(sale, obj, myDataMoreTaxesOptions)
                            },
                                error => {
                                    console.error(
                                        (error.response &&
                                            error.response.data &&
                                            error.response.data.message) ||
                                        error.message ||
                                        error.toString())
                                }
                            );


                            var customer = { label: sale.customer.name, value: sale.customer.name, model: sale.customer }
                            var city = sale.customer.city;
                            var address = sale.customer.address;
                            var postalCode = sale.postalCode;
                            var taxpayerId = sale.customer.taxpayerId;
                            var addressNumber = sale.customer.number;
                            var subjectField = sale.customer.subjectField;
                            var branch = { label: sale.branch.name, value: sale.branch.id, model: sale.branch }

                            var paymentType = { label: t(sale.paymentType), value: sale.paymentType }



                            if (sale.moreTaxes && sale.moreTaxes.length > 0) {
                                var _data_transformed = saleMoreTaxesGridRef.current.transformData(sale.moreTaxes, moreTaxesTypesOptions, myDataMoreTaxesOptions)
                                saleMoreTaxesGridRef.current.setData(_data_transformed);
                                setInitiallyCollapsed({ collapsed: false, show: true });
                            }


                            setTimeout(function () {
                                if (taxpayerId) {
                                    setValue("taxpayerId", taxpayerId);
                                }
                                if (customer) {
                                    setValue("customer", customer);
                                    setCustomerValue(customer);
                                }

                                setValue("branch", branch);
                                if (address) {
                                    setValue("fulladdress", address);
                                }
                                setValue("postalCode", postalCode);
                                setValue("addressNumber", addressNumber);
                                setValue("subjectField", subjectField)
                                setValue("city", city);
                                setValue("postalCode", sale.customer.postalCode);
                                if (sale.customer && sale.customer.taxOffice) {
                                    setValue("taxOffice", { label: sale.customer.taxOffice.name, value: sale.customer.taxOffice.id });
                                }

                                setValue("notes", sale.notes);

                                if (sale.vehicleNumber || sale.dispatchDate) {
                                    setInitiallyCollapsedMore({ collapsed: false, show: true });
                                }


                                if (sale.movePurpose) {
                                    setValue("movePurpose", {
                                        label: t(sale.movePurpose),
                                        value: sale.movePurpose
                                    });
                                }

                                if (sale.dispatchDate) {
                                    var _dispatchDate = moment(sale.dispatchDate).toDate();
                                    setValue("dispatchDate", _dispatchDate);
                                    setDispatchDate(_dispatchDate);
                                }


                                setValue("vehicleNumber", sale.vehicleNumber);
                                setValue("sendPlace", sale.sendPlace);
                                setValue("exchangeRate", sale.exchangeRate);

                                if (sale.currency) {
                                    setValue("currency", {
                                        label: sale.currency,
                                        value: sale.currency
                                    });
                                }

                                if (!isCredit) {
                                    var invdate = moment(sale.invoiceDate).toDate();
                                    setValue("invoiceDate", invdate);
                                    setStartDate(invdate);
                                    setMark(sale.mark)
                                }
                                else {
                                    setValue("invoiceDate", startDate);
                                }

                                if (sale.customer && sale.customer.country) {
                                    setValue("country", { label: t(sale.customer.country.alpha2), value: sale.customer.country.alpha2 });
                                }
                                setValue("paymentType", paymentType);


                            }, 100)

                        },
                        error => {
                            setBlockuiLoading(false)
                            toast.error("Error: " + error.response.data.message)
                            console.error(error.response.data)
                        }
                    )

                }
                else {
                    setValue("paymentType", convertPaymentType({ value: "CASH", label: t("CASH") }))
                    setValue("invoiceDate", startDate);



                    var defaults = Commons.getDefaults();

                    if (defaults != undefined && defaults.notes != undefined) {
                        setValue("notes", defaults.notes)
                    }

                    if (defaults.paymentType) {
                        setValue("paymentType", defaults.paymentType);
                        if (defaults.paymentType.value == "ON_CREDIT") {
                            document.getElementById("balance_container").style.display = "unset"
                        }
                        else {
                            document.getElementById("balance_container").style.display = "none"
                        }
                    }

                    if (defaults.templateLang) {
                        setValue("templateLang", defaults.templateLang);
                        setSelectedTemplateLanguage(defaults.templateLang.toUpperCase())
                    }


                    if (defaults.branch != undefined) {
                        setValue("branch", defaults.branch);
                    }
                    else {
                        if (optionBranches.length == 1) {
                            var _branch = optionBranches[0];
                            setValue("branch", _branch);
                            Commons.setDefault("branch", _branch)
                            if (_branch) {
                                ApiClient.getTemplateSettings(companyId, _branch.value).then(response => {
                                    var _template = response.data;
                                    _template.branch = _branch.value;
                                    _template.mainColor = _template.mainColor ? _template.mainColor : "#013289";
                                    _template.secondaryColor = _template.secondaryColor ? _template.secondaryColor : "#FFFFFF";
                                    _template.appFont = _template.appFont ? _template.appFont : "Arial";
                                    setTemplate(_template);

                                },
                                    error => {
                                        console.error(
                                            (error.response &&
                                                error.response.data &&
                                                error.response.data.message) ||
                                            error.message ||
                                            error.toString())
                                    }
                                );
                            }
                        }
                    }


                    if (defaults.invoiceType != undefined) {
                        // 
                        changeInvType(defaults.invoiceType);
                        setValue("invoiceType", defaults.invoiceType);

                    }
                    else {
                        if (optionsinvoiceTypes.length == 1) {
                            changeInvType(optionsinvoiceTypes[0]);
                            setValue("invoiceType", optionsinvoiceTypes[0]);
                        }
                        else {
                            setBlockuiLoading(false)
                        }
                    }
                }

            }
        });

        return () => mounted = false;

    }, []);

    function getContactEmails(sale, sendPaymentLink) {
        var customerName = sale.customeName;
        var company = SELECTED_COMPANY;
        var subject = t("emailSubject", { company: company.name });

        var contactEmails = [];
        selectedContacts.forEach(contact => {
            if (contact.email == undefined || contact.email == null) {
                contact.email = contact.value;
                contact.name = contact.value
            }
            var body = "<p>" + t("emailBody1", { contactName: contact.name }) + "</p>";
            body += "<p>" + t("emailBody2", { company: company.name }) + "</p>";
            var link = "<a href='https://app.timologic.gr/mydata/" + sale.invoiceUid + "'>" + sale.invoiceType.name + " " + sale.series.name + sale.invoiceNumber + "</a>";
            body += "<p>" + t("emailBody3", { link: link }) + "</p>";

            if (sendPaymentLink && company.vivaQuickPay) {
                body += "<p>" + t("emailVivaPayMess") + "</p>";
                body += "<p class='viva-pay-btn-cont'><a class='viva-pay-btn' href='" + company.vivaQuickPay + "/" + sale.totalPayment + "'>" + t("payWithViva") + "</a></p>";
            }
            if (sendPaymentLink && company.stripeAccountID) {
                var amount = sale.totalWithVat;
                body += "<p>" + t("emailStripePayMess") + "</p>";
                body += "<p class='stripe-pay-btn-cont'><a class='stripe-pay-btn' href='" + API_BASE_URL + "/stripe/checkout/" + SELECTED_COMPANY.id + "?amount=" + amount + "&saleInstanceId=" + sale.id + "'>" + t("payWithTimologicPay") + "</a></p>";
            }
            contactEmails.push({
                subject: subject,
                body: body,
                email: contact.email
            });
        })
        return contactEmails;

    }

    function afterPrintProcess(sale) {
        debugger
        setTimeout(function () {
            if (selectedContacts.length > 0) {
                document.getElementById("template").style.display = "unset";
                setBlockuiLoading(false)
            }
            else {
                history.push("/")
                history.push("/sale")
                setBlockuiLoading(false);
            }
        }, 2000)
    }

    function getProductClassificationOfInvoicetype(classifications, invtype) {
        if (classifications != null) {
            for (var i = 0; i < classifications.length; i++) {
                if (classifications[i].invoiceType.myDataInvoiceType == invtype) {
                    return classifications[i];
                }
            }

        }
        return null;
    }

    function prepareSale(_sale) {

        var sale = JSON.parse(JSON.stringify(_sale));


        if (mark && !isCredit) {
            sale.mark = mark;
        }
        if (id && !isCredit) {
            sale.id = id;
        }

        sale.saleEntries = [];
        refSalesEntries.current.getData().entries.forEach(e => {
            var saleEntry = {};

            if (!isCredit && sale.invoiceType.myDataInvoiceType != "invoice5_1") {
                saleEntry.id = e.id
            }
            if (!Number.isInteger(e.product.value)) {
                saleEntry.product = { id: undefined, name: e.product.label }
            }
            else {
                saleEntry.product = { id: e.product.value, name: e.product.label }
            }
            if (e.measurementUnit.value == "-") {
                saleEntry.measurementUnit = "PIECES";
            }
            else {
                saleEntry.measurementUnit = e.measurementUnit.value;
            }

            if (e.quantity == "-") {
                saleEntry.quantity = 1;
            }
            else {
                saleEntry.quantity = e.quantity;
            }

            saleEntry.vat = e.vat.value;

            saleEntry.discount = e.discount;
            saleEntry.unitPrice = e.unitPrice;
            saleEntry.priceWithVat = e.priceWithVat;
            saleEntry.priceWithoutVat = e.priceWithoutVat;

            if (e.vatCategoryException) {
                saleEntry.vatCategoryException = e.vatCategoryException.value
            }


            var prodClass = null;
            if (e.product != null && e.product.model != null) {
                prodClass = getProductClassificationOfInvoicetype(e.product.model.productClassifications, sale.invoiceType.myDataInvoiceType);
            }

            var _classifications = [];
            if (e.extraLineFields != undefined) {
                e.extraLineFields.forEach(e => {
                    var _cl;
                    var asExpenses = getValues().selfPricing || Commons.isOnGroup(sale.invoiceType.myDataInvoiceType, "isExpense");
                    if (asExpenses) {
                        _cl = {
                            classificationCategory: e.classificationCategory.value,
                            classificationValue: e.classificationValue.value,
                            amount: parseFloat(e.amount).toFixed(2)
                        }
                    }
                    else {
                        _cl = {
                            classificationCategory: e.classificationCategory.value,
                            classificationValue: e.classificationValue.value,
                            amount: parseFloat(e.amount).toFixed(2)
                        }
                    }

                    _classifications.push(_cl);
                })

            }
            else if (prodClass != null) {
                var amnt = 0;
                if (prodClass.isPercent) {
                    amnt = e.priceWithoutVat * prodClass.amount / 100;
                }

                _cl = {
                    classificationCategory: prodClass.classificationCategory,
                    classificationValue: prodClass.classificationValue,
                    amount: parseFloat(amnt).toFixed(2)
                }

                _classifications.push(_cl);
            }
            else if (sale.invoiceType.invoiceTypeClassifications && sale.invoiceType.invoiceTypeClassifications.length > 0) {
                sale.invoiceType.invoiceTypeClassifications.forEach(_class => {
                    var _cl;
                    var asExpenses = getValues().selfPricing || Commons.isOnGroup(sale.invoiceType.myDataInvoiceType, "isExpense");
                    if (asExpenses == _class.selfPricing) {
                        var q = e.quantity == "-" ? 1 : e.quantity;
                        var _amount = 0;
                        if (_class.isPercent) {
                            _amount = e.unitPrice * q * _class.amount / 100
                        }
                        else {
                            _amount = _class.amount;
                        }
                        _amount -= e.discount * _amount / 100;

                        var _cl = {
                            classificationCategory: _class.classificationCategory,
                            classificationValue: _class.classificationValue,
                            amount: parseFloat(_amount).toFixed(2)
                        }
                        _classifications.push(_cl);
                    }

                })
            }
            else {
                var _cl;
                var asExpenses = getValues().selfPricing || Commons.isOnGroup(sale.invoiceType.myDataInvoiceType, "isExpense");
                var q = e.quantity == "-" ? 1 : e.quantity;
                var _amount = e.unitPrice * q;
                _amount -= e.discount * _amount / 100;
                if (asExpenses) {
                    _cl = {
                        classificationCategory: suggestedInClassCatsOfInvType[0] ? suggestedInClassCatsOfInvType[0].value : null,
                        classificationValue: suggestedInClassCatsOfInvType[0] ? suggestedInClassCatsOfInvType[0].classificationValues[0] : null,
                        amount: _amount
                    }
                }
                else {
                    _cl = {
                        classificationCategory: suggestedInClassCatsOfInvType[0] ? suggestedInClassCatsOfInvType[0].value : null,
                        classificationValue: suggestedInClassCatsOfInvType[0] ? suggestedInClassCatsOfInvType[0].classificationValues[0] : null,
                        amount: _amount
                    }
                }

                _classifications.push(_cl);
            }

            var _taxes = [];
            if (e.taxes) {
                e.taxes.forEach(e => {
                    // _taxes.push({
                    //     myDataMoreTax: "TAX_" + tax.myDataMoreTax.uid,
                    //     amount: tax.amount,
                    // })

                    var _myDataMoreTax_value = e.myDataMoreTax.value;
                    if (!_myDataMoreTax_value.toString().includes("TAX_")) {
                        _myDataMoreTax_value = "TAX_" + _myDataMoreTax_value
                    }

                    _taxes.push({
                        id: !isCredit && sale.invoiceType.myDataInvoiceType != "invoice5_1" ? e.id : null,
                        amount: e.amount,
                        myDataMoreTax: e.myDataMoreTax.type == "DEDUCTION" ? null : _myDataMoreTax_value,
                        deduction: e.myDataMoreTax.type == "DEDUCTION" ? { name: e.myDataMoreTax.label, amountValue: e.amount, isSubtracted: e.isSubtracted } : null,
                        type: e.myDataMoreTax.type
                    })
                })

            }
            saleEntry.moreTaxes = _taxes;
            saleEntry.saleEntryClassifications = _classifications;


           

            //FOR THE NEXT MYDATA UPDATE
            // if (sale.invoiceType.myDataInvoiceType == "invoice8_2") {
            //     entry.saleEntryClassifications = [];
            // }
            sale.saleEntries.push(saleEntry);
        })
        sale.movePurpose = sale.movePurpose ? sale.movePurpose.value : null;
        sale.templateLang = sale.templateLang ? sale.templateLang.toUpperCase() : "GR";

        sale.issuerPhone = sale.branch.model ? sale.branch.model.phone : null;
        sale.issuerEmail = sale.branch.model ? sale.branch.model.email : null;
        // sale.notes = refSalesEntries.current.getNotes();
        sale.branch = {
            id: sale.branch.value,
            name: sale.branch.label,
            address: sale.branch.model.address,
            subjectField: sale.branch.model.subjectField,
            branchNumber: sale.branch.model.branchNumber,
            logo: sale.branch.model.logo
        };
        sale.company = { id: SELECTED_COMPANY.id, taxpayerId: SELECTED_COMPANY.taxpayerId, name: SELECTED_COMPANY.name };


        var custTaxOffice = sale.taxOffice ? { name: sale.taxOffice.label, id: sale.taxOffice.value } : null;

        if (sale.customer) {
            if (!Number.isInteger(sale.customer.value)) {
                sale.customer = {
                    id: undefined,
                    name: sale.customer.label,
                    taxpayerId: sale.taxpayerId,
                    number: sale.addressNumber,
                    postalCode: sale.postalCode,
                    subjectField: sale.subjectField,
                    city: sale.city,
                    address: sale.fulladdress,
                    taxOffice: custTaxOffice,
                    country: sale.country.value
                };
            }
            else {
                sale.customer = {
                    id: sale.customer.value,
                    name: sale.customer.label,
                    taxpayerId: sale.taxpayerId,
                    country: sale.customer.model.country,
                    number: sale.addressNumber,
                    address: sale.fulladdress,
                    postalCode: sale.postalCode,
                    city: sale.city,
                    taxOffice: sale.customer.model.taxOffice,
                    subjectField: sale.subjectField,
                    country: sale.country.value
                };
            }

            //correction of empty country
            if (sale.customer.country == undefined) {
                delete sale.customer;
            }
        }



        if (sale.correlativeSale != null) {
            sale.correlativeSale = sale.correlativeSale.value
        }

        sale.invoiceType = { id: sale.invoiceType.value, name: sale.invoiceType.label, myDataInvoiceType: sale.invoiceType.myDataInvoiceType };
        var _ser_lbl = sale.series == null ? "" : sale.series.label;
        var _ser_id = sale.series == null ? "" : sale.series.value;

        if (!Number.isInteger(_ser_id)) {
            _ser_id = "";
        }

        sale.series = { id: _ser_id, name: _ser_lbl };
        sale.paymentType = sale.paymentType.value;

        if (sale.currency) {
            sale.currency = sale.currency.value;
        }



        sale.totalWithVat = totals.totalWithVat;
        sale.totalWithoutVat = totals.totalWithoutVat;
        sale.totalWithDiscount = totals.totalWithDiscount;
        sale.totalWithoutDiscount = totals.totalWithoutDiscount;
        sale.totalPayment = totals.totalPayment;
        sale.totalDiscount = totals.totalDiscount;

        sale.totalOtherTaxes = totals.totalOtherTaxes;
        sale.totalFeesTaxes = totals.totalFeesTaxes;
        sale.totalStumpOfDutyTaxes = totals.totalStumpOfDutyTaxes;
        sale.totalWithHoldTaxes = totals.totalWithHoldTaxes;
        sale.totalDeductionsTaxes = totals.totalDeductionsTaxes;

        var store = false;
        if (sale.invoiceType.myDataInvoiceType == "invoice1_1" || sale.invoiceType.myDataInvoiceType == "invoice2_1") {
            store = true;
        }
        var user = JSON.parse(localStorage.getItem("user"));
        sale.contactEmails={
            contacts: getContactEmails(sale, sendPaymentLink),
            store: store,
            taxpayerId: SELECTED_COMPANY.taxpayerId,
            fromEmail: user.email
        } ;

 

        var moreTaxes = saleMoreTaxesGridRef.current.getData();
        var _moretaxes = [];
        moreTaxes.forEach(tax => {

            var _myDataMoreTax_value = tax.myDataMoreTax.value;
            if (!_myDataMoreTax_value.toString().includes("TAX_")) {
                _myDataMoreTax_value = "TAX_" + _myDataMoreTax_value
            }

            var t = {
                id: !isCredit && sale.invoiceType.myDataInvoiceType != "invoice5_1" ? tax.id : null,
                amount: tax.amount,
                myDataMoreTax: tax.myDataMoreTax.type == "DEDUCTION" ? null : _myDataMoreTax_value,
                deduction: tax.myDataMoreTax.type == "DEDUCTION" ? { name: tax.myDataMoreTax.label, amountValue: tax.amount, isSubtracted: tax.isSubtracted } : null,
                type: tax.myDataMoreTax.type
            }
            _moretaxes.push(t)
        })
        if (moreTaxes) {
            sale.moreTaxes = _moretaxes;
        }
        delete sale.taxpayerId;
        delete sale.city;
        delete sale.country;
        delete sale.postalCode;
        delete sale.fulladdress;
        delete sale.taxOffice;
        delete sale.subjectField;
        delete sale.addressNumber;
        delete sale.customerContacts;
        delete sale.otherTaxAmount;
        delete sale.otherTaxCategory;

        // if(sale.invoiceDate){
        //     sale.invoiceDate=moment(sale.invoiceDate).format('YYYY-MM-DD HH:mm:ss')
        // }
        // if(sale.dispatchDate){
        //     sale.dispatchDate=moment(sale.dispatchDate).format('YYYY-MM-DD HH:mm:ss')
        // }

        return sale;
    }

    function onSuccessSave(response, sale, _print) {
        var psale = getPrintSale();
        var _template = { template };
        if (_template) {
            psale.LOGO = _template.template.logo;
            psale.IBAN = _template.template.iban;
            psale.MOREINFO = _template.template.moreInfo;
        }
        if (response.data.response != undefined) {
            var resp = response.data.response[0];
            if (resp.errors != null) {
                var errors = resp.errors.error;

                console.log(errors);


                errors.forEach(error => {
                    console.error(error);
                    if (error.code == 201) {
                        toast.error(myDataErrorMessageWithLink(error))
                    }
                    else {
                        toast.error(error.message)
                    }

                });
                setBlockuiLoading(false)

                // parseError(resp.errors)
            }
            else {
                if (resp.statusCode == "Success") {
                    var mark = resp.invoiceMark;
                    psale.INVOICEMARK = mark;
                    psale.INVOICEUID = resp.invoiceUid;
                    psale.QRURL = resp.qrUrl;
                    if (mark) {
                        toast.success(<p><img style={{ width: 80 }} src={MyDataLogo}></img>{t("invoiceSended", { mark: mark })}</p>, { autoClose: 20000 })

                    }
                    else {
                        toast.success(t("saveSucceded"))
                    }
                    document.getElementsByClassName("innerContainerMain")[0].scrollTo(0, 0);
                }
                else {
                    toast.error(t("Error"))
                }

                if (_print) {
                    iframePrintTemplate.current.print(psale)
                }
                sale.invoiceUid = psale.INVOICEUID;
                afterPrintProcess(sale)
            }
        }
        else {
            if (_print) {
                iframePrintTemplate.current.print(psale)
            }
            sale.invoiceUid = psale.INVOICEUID;
            afterPrintProcess(sale)
            toast.success(t("saveSucceded"))
            document.getElementsByClassName("innerContainerMain")[0].scrollTo(0, 0);
        }
    }

    function saveSale(_sale, _print = false) {

        var entriesHasError = refSalesEntries.current.hasError();
        if (entriesHasError) {
            toast.error(t("entriesErrors"), 5000)
            return;
        }

        var entriesHasError = saleMoreTaxesGridRef.current.hasError();
        if (entriesHasError) {
            toast.error(t("entriesMoreTaxesErrors"), 5000)
            return;
        }


        var _entries = refSalesEntries.current.getData();
        if (_entries.entries.length == 0) {
            toast.error(t("entriesErrorsCount"), 5000)
            return;
        }


        var sale = prepareSale(_sale)
        console.debug(sale)

        Commons.setDefault("notes", _sale.notes);



        confirmAlert({
            title: t("submitConfirm"),
            message: sale.isPreSale ? t("areYouSurePreSale") : mydata ? t("areYouSureMyDataOn") : t("areYouSureMyDataOff"),
            buttons: [
                {
                    label: t('Yes'),
                    className: "accept",
                    onClick: () => {
                        setBlockuiLoading(true)
                        ApiClient.saveSaleWithEntries(SELECTED_COMPANY.id, sale).then(
                            response => {
                                onSuccessSave(response, sale, _print)
                            },
                            error => {
                                setBlockuiLoading(false)
                                toast.error("Error: " + error.response.data.message)
                                console.error(error.response)
                                setBlockuiLoading(false)
                            }
                        )
                    }
                },
                {
                    label: t('No'),
                    onClick: () => { return; }
                }
            ],

        });
    }

    function changeCust(val) {
        setValue("customer", val)
        setValue("invoiceDate", startDate);
        setCustomerValue(val)




        var _contacts = [];
        if (val.model) {
            var contacts = val.model.contacts;
            if (contacts) {
                contacts.forEach(e => {
                    _contacts.push({
                        value: e.email,
                        name: e.name,
                        email: e.email,
                        label: e.email
                    })
                });



                if (SELECTED_COMPANY.accountantEmail) {
                    _contacts.push({
                        value: SELECTED_COMPANY.accountantEmail,
                        name: t("companyAccountant"),
                        email: SELECTED_COMPANY.accountantEmail,
                        label: SELECTED_COMPANY.accountantEmail
                    })
                }

                var opts = {
                    data: _contacts, columns: [
                        {
                            header: t("Name"),
                            field: "name",
                            flex: 6,
                        },
                        {
                            header: t("Email"),
                            field: "email",
                            flex: 6,
                            asLabel: true
                        }
                    ]
                }
                setCustomerContacts(opts)
            }


            setValue("taxpayerId", val.model.taxpayerId);
            setValue("fulladdress", val.model.address);
            setValue("postalCode", val.model.postalCode);
            setValue("addressNumber", val.model.number);
            setValue("city", val.model.city);
            setValue("subjectField", val.model.subjectField);

            if (val.model.country != null) {
                setValue("country", { value: val.model.country.alpha2, label: t(val.model.country.alpha2) })
            }

            if (val.model.taxOffice) {
                setValue("taxOffice", { label: val.model.taxOffice.name, value: val.model.taxOffice.id });
            }

            var selectedMyDataInvType = getValues().invoiceType.myDataInvoiceType;
            if (selectedMyDataInvType == "invoice2_01" || selectedMyDataInvType == "invoice11_02") {
                setValue("recipientTaxPayerId", val.model.taxpayerId);
                setValue("recipientName", val.model.name);

                setValue("senderTaxPayerId", val.model.taxpayerId);
                setValue("senderName", val.model.name);
            }

            clearErrors("customer");
            clearErrors("taxpayerId");
        }
    }
    function setDataWithClassifications(saleObject, optionIncClassCategories, myDataMoreTaxesOptions, isPreSale = false) {
        var saleEntries = saleObject.saleEntries;
        var entries = [];
        saleEntries.forEach(el => {
            var e = el;
            var product;


            if (isPreSale) {
                product = {
                    label: el.product.label,
                    value: el.product.value,
                    model: el.product.model
                }
            }
            else {
                product = {
                    label: el.product.name,
                    value: el.product.name,
                    model: el.product
                }
            }
            var vat;

            if (isPreSale) {
                vat = {
                    label: el.vat.label,
                    value: el.vat.value,
                }
            }
            else {
                var _lab = el.vat.id.replace("VAT_", "");
                _lab = _lab == "EMPTY" ? "-" : _lab
                vat = {
                    label: _lab,
                    value: el.vat.id,
                }
            }

            var mu;
            if (isPreSale) {
                mu = {
                    value: el.measurementUnit.value,
                    label: t(el.measurementUnit.label),
                }
            }
            else {
                mu = {
                    value: el.measurementUnit,
                    label: t(el.measurementUnit),
                }
            }


            var _classifications = [];
            if (el.saleEntryClassifications) {
                el.saleEntryClassifications.forEach(e => {

                    var _cl;
                    var classcat = e.classificationCategory ? e.classificationCategory : e.classificationCategory;
                    if (classcat) {
                        var prefix = classcat.replace("CATEGORY_", "") + ") ";


                        var _cls = optionIncClassCategories[classcat];
                        var newcls = [];
                        if (_cls) {
                            _cls.forEach(e => {
                                newcls.push({
                                    label: t(e),
                                    value: e
                                });
                            });
                        }


                        if (e.classificationCategory) {
                            _cl = {
                                classificationCategory: {
                                    value: e.classificationCategory,
                                    label: prefix + t(e.classificationCategory),
                                },
                                classificationValue:
                                {
                                    value: e.classificationValue,
                                    label: t(e.classificationValue) + " (" + e.classificationValue + ")",
                                },
                                classificationValues: newcls,
                                amount: parseFloat(e.amount).toFixed(2)
                            }
                        }
                        else {
                            _cl = {
                                classificationCategory:
                                {
                                    value: e.classificationCategory,
                                    label: prefix + t(e.classificationCategory),
                                },
                                classificationValue:
                                {
                                    value: e.classificationValue,
                                    label: t(e.classificationValue) + " (" + e.classificationValue + ")",
                                },
                                classificationValues: newcls,
                                amount: parseFloat(e.amount).toFixed(2)
                            }

                        }
                        _classifications.push(_cl);
                    }

                })

            }
            else {
                if (isPreSale) {
                    var invtype = getValues().invoiceType;
                    var _cls
                    if (invtype.invoiceTypeClassifications && invtype.invoiceTypeClassifications.length > 0) {

                        invtype.invoiceTypeClassifications.forEach(_class => {
                            var _cl;
                            var asExpenses = getValues().selfPricing || Commons.isOnGroup(invtype.myDataInvoiceType, "isExpense");
                            var prefix = _class.classificationCategory.replace("CATEGORY_", "") + ") ";

                            if (asExpenses == _class.selfPricing) {
                                var q = e.quantity == "-" ? 1 : e.quantity;
                                var _amount = 0;
                                if (_class.isPercent) {
                                    _amount = e.unitPrice * q * _class.amount / 100
                                }
                                else {
                                    _amount = _class.amount;
                                }
                                _amount -= e.discount * _amount / 100;

                                var _cl = {
                                    classificationCategory: { label: prefix + ") " + t(_class.classificationCategory), value: _class.classificationCategory },
                                    classificationValue: { label: t(_class.classificationValue) + " (" + _class.classificationValue + ")", value: _class.classificationValue },
                                    classificationValues: _class.classificationValues,
                                    amount: parseFloat(_amount).toFixed(2)
                                }
                                _classifications.push(_cl);
                            }

                        })
                    }
                    else {
                        var _cls = optionIncClassCategories[0];
                        var types = _cls.classificationValues;
                        var opts = [];
                        if (types) {
                            types.forEach(element => {
                                opts.push({
                                    value: element,
                                    label: t(element) + " (" + element + ")",
                                });
                            });
                        }
                        var _cl = {
                            classificationCategory:
                            {
                                value: _cls.value,
                                label: _cls.label
                            },
                            classificationValue:
                            {
                                value: _cls.classificationValues[0],
                                label: t(_cls.classificationValues[0]) + " (" + _cls.classificationValues[0] + ")",
                            },
                            classificationValues: types,
                            amount: parseFloat(el.priceWithoutVat).toFixed(2)
                        }
                        _classifications.push(_cl);
                    }
                }
            }

            var _mtaxes = [];
            if (el.moreTaxes) {
                el.moreTaxes.forEach(m => {
                    var _value;
                    if (m.deductionName && m.deductionName != "" && m.deductionAmountValue) {
                        m.deduction = {
                            id: m.id,
                            amountValue: m.deductionAmountValue,
                            name: m.deductionName
                        };
                    }

                    if (m.deduction) {
                        _value = m.deduction.id;
                        m.myDataMoreTax = m.deduction;
                        m.myDataMoreTax.type = "DEDUCTION"
                    }
                    else {
                        _value = "TAX_" + m.myDataMoreTax.uid;
                    }

                    var type = m.myDataMoreTax.type
                    var allTaxes = myDataMoreTaxesOptions;

                    let groupTaxes = allTaxes.reduce((r, a) => {
                        r[a.type] = [...r[a.type] || [], a];
                        return r;
                    }, {});

                    var typeTaxes = groupTaxes[type];
                    var new_type_options = [];
                    typeTaxes.forEach(element => {
                        new_type_options.push({
                            value: "TAX_" + element.value,
                            label: t("TAX_" + element.value),
                            amountValue: element.amountValue,
                            uid: element.uid
                        });
                    });


                    _mtaxes.push({
                        amount: m.amount,
                        myDataMoreTax: {
                            label: m.deduction ? m.deduction.name : t(_value),
                            value: _value,
                            amountValue: m.myDataMoreTax.amountValue,
                            uid: m.myDataMoreTax.uid,
                            key: m.myDataMoreTax.key,
                            type: m.myDataMoreTax.type,
                            isSubtracted: m.deduction ? m.deduction.isSubtracted : null
                        },
                        type: {
                            label: t(m.myDataMoreTax.type),
                            value: m.myDataMoreTax.type,
                            amountValue: m.myDataMoreTax.amountValue
                        },
                        myDataMoreTaxes: typeTaxes
                    })
                    var selectedMyDataInvType = getValues().invoiceType.myDataInvoiceType;
                    if (selectedMyDataInvType == "invoice8_2") {
                        setValue("otherTaxCategory", _mtaxes[0].myDataMoreTax)
                        setValue("otherTaxAmount", _mtaxes[0].amount)
                    }

                });
            }
            var vat_val = vat.label == "-" ? 0 : vat.label;
            var unitPriceWithVat = el.unitPrice * (1 + vat_val / 100)
            var _vatCategoryException = el.vatCategoryException ? { label: t(el.vatCategoryException), value: el.vatCategoryException } : null;
            var entry = {
                product: product,
                unitPrice: parseFloat(el.unitPrice).toFixed(2),
                unitPriceWithVat: parseFloat(unitPriceWithVat).toFixed(4),
                quantity: el.quantity,
                id: el.id,
                measurementUnit: mu,
                vat: vat,
                discount: el.discount ? el.discount : "0.00",
                priceWithoutVat: parseFloat(el.priceWithoutVat.toString()).toFixed(2),
                priceWithVat: el.priceWithVat,
                extraLineFields: _classifications,
                vatCategoryException: _vatCategoryException,
                taxes: _mtaxes
            }
            entries.push(entry)
        })
        refSalesEntries.current.setData(entries)
        reCalculateTotals();

    }

    const onSubmit = (sale, e) => {
        e.preventDefault();

        if (sale.invoiceType.myDataInvoiceType == "invoice00_1" || sale.invoiceType.myDataInvoiceType == "invoice1_01") {
            sale.isPreSale = true;
            sale.mydata = false
        }
        else {
            sale.isPreSale = false;
            sale.mydata = mydata ? mydata : false;
        }

        saveSale(sale, false)


    }

    const onError = (error) => {
        console.error(error)
        var el_name = Object.keys(error)[0];

        if (el_name === "customer") {
            var element = document.getElementsByClassName("customerCard")[0];
            const yOffset = -10;
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            //toDo: reexamine above lines
            document.body.scrollTo({ top: y, behavior: 'smooth' });
            // document.getElementsByClassName("customerCard")[0].scrollIntoView(true);
        }
        else {
            if (document.getElementsByName(el_name)) {
                var element = document.getElementsByName(el_name)[0];
                const yOffset = -10;
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                // document.body.scrollTo({ top: y, behavior: 'smooth' });
                document.getElementsByClassName("innerContainerMain")[0].scrollTo({ top: y, behavior: 'smooth' });

            }

        }

    }

    const handlePrintAndSave = e => {
        // e.preventDefault();
        var sale = getValues();
        if (sale.invoiceType.myDataInvoiceType == "invoice00_1" || sale.invoiceType.myDataInvoiceType == "invoice1_01") {
            sale.isPreSale = true;
            sale.mydata = false
        }
        else {
            sale.isPreSale = false;
            sale.mydata = mydata ? mydata : false;
        }
        saveSale(sale, true)

    }

    function appendAccountantEmailOnContactsSelect() {
        var _contacts = [];
        if (SELECTED_COMPANY.accountantEmail) {
            _contacts.push({
                value: SELECTED_COMPANY.accountantEmail,
                name: t("companyAccountant"),
                email: SELECTED_COMPANY.accountantEmail,
                label: SELECTED_COMPANY.accountantEmail
            })
        }

        var opts = {
            data: _contacts, columns: [
                {
                    header: t("Name"),
                    field: "name",
                    flex: 6,
                },
                {
                    header: t("Email"),
                    field: "email",
                    flex: 6,
                    asLabel: true
                }
            ]
        }
        setCustomerContacts(opts)
    }


    const loadTaxOfficeOptions = (inputValue, callback) => {

        ApiClient.getAllTaxOffices().then(
            response => {

                var data_taxOffices = response.data;
                var optionsTO = []
                data_taxOffices.forEach(element => {
                    optionsTO.push({
                        value: element.id,
                        label: t(element.name)
                    });
                });
                callback(optionsTO)
            },
            error => {
                toast.error("Error: " + error.data)
                console.error(error.data)
            }
        );

    }


    const onInputChangeCustomer = (val, action) => {
        if (action.action != "input-blur" && action.action != "menu-close") {
            setValue("customer", { label: val, value: val });
            setCustomerInputValue(val)
            document.getElementById("saveCustomerForFuture").style.display = "unset";
            // setCustomerValue({ label: val, value: val })

        }
        else if (action.action == "menu-close") {
            var _country = getValues()["country"];
            if (_country == undefined || _country == "") {
                var selectedMyDataInvType = getValues().invoiceType.myDataInvoiceType;
                if (selectedMyDataInvType != "invoice1_3") {
                    setValue("country", DefaultCountry)
                }

            }
        }
    }

    const onChangeCustomer = val => {
        // var _val=val;
        if (val.__isNew__) {
            document.getElementById("saveCustomerForFuture").style.display = "unset";

        }
        else {
            document.getElementById("saveCustomerForFuture").style.display = "none";
        }
        changeCust(val)

    };

    const onChangePaymentType = val => {
        if (val.value == "ON_CREDIT") {
            document.getElementById("balance_container").style.display = "unset"
        }
        else {
            document.getElementById("balance_container").style.display = "none"
        }
        setValue("paymentType", val);
        Commons.setDefault("paymentType", val);
    };

    function changeSeries(val) {
        var companyId = SELECTED_COMPANY.id
        var branch = getValues().branch;
        var invType = getValues().invoiceType;
        var branchName, invTypeName;
        if (branch) {
            branchName = branch.label;
        }
        if (invType) {
            invTypeName = invType.label;
        }
        var series = val ? val.label : "";

        var invoiceNumberIdentifierDTO = {
            branch: branch.model.id,
            invoiceType: invTypeName,
            series: series
        }

        ApiClient.getMaxCompanySaleInstanceNumber(invoiceNumberIdentifierDTO, companyId).then(
            response => {
                if (response) {
                    setValue("invoiceNumber", response.data)
                }
                else {
                    setValue("invoiceNumber", 1)
                }
                clearErrors("series");
                clearErrors("invoiceNumber");

            },
            error => {
                toast.error("Error: " + error.data)
                console.error(error.data)
            }
        );
    }

    const onChangeSelfPricing = e => {
        var val = e.target.checked;
        setSelfPricingChecked(val);
        var invoiceType = getValues().invoiceType;
        var asExpenses = Commons.isOnGroup(invoiceType.myDataInvoiceType, "isExpense") || val;

        if (id == undefined) {
            ApiClient.getClassificationCategoriesOfMyDataInvoice(invoiceType.myDataInvoiceType, asExpenses).then(response => {
                var data = response.data;
                var _optionIncClassCategories = [];
                data.forEach(element => {
                    var _classificationCategoryType;
                    var _classificationValueTypes;
                    _classificationCategoryType = element.classificationCategory;
                    _classificationValueTypes = element.classificationValues;

                    var prefix = _classificationCategoryType.replace("CATEGORY_", "") + ") ";
                    _optionIncClassCategories.push({
                        value: _classificationCategoryType,
                        label: prefix + t(_classificationCategoryType),
                        classificationValues: _classificationValueTypes,
                    });
                });
                setSuggestedInClassCatsOfInvType(_optionIncClassCategories);
            },
                error => {
                    console.error(
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString())
                }
            );
            refSalesEntries.current.clearData();
        }
    }

    const onChangeSeries = val => {
        setValue("series", val);
        Commons.setDefault("series", val);
        changeSeries(val)
    }

    const onChangeBranch = val => {
        setValue("branch", val);
        changeBranch(val);
    }

    const onChangeMovePurpose = val => {
        setValue("movePurpose", val);

    }

    const taxOfficeOnChange = val => {
        setValue("taxOffice", val);
    }

    function changeBranch(val) {
        if (!isCredit && id == null) {
            Commons.setDefault("branch", val)
        }

        clearErrors("branch");
        setValue("series", "")
        setValue("invoiceNumber", "")
        var selectedInvType = getValues().invoiceType;
        var invoiceTypeId = selectedInvType.value;
        var branchId = val.id ? val.id : val.model.id;

        if (selectedInvType) {
            ApiClient.getCompanyInvoiceTypeSeries(SELECTED_COMPANY.id, invoiceTypeId, branchId, isCredit).then(response => {
                var data = response.data;
                var optionSeries = [];
                if (data) {
                    data.forEach(element => {
                        optionSeries.push({
                            value: element.id,
                            startNumber: element.startNumber,
                            label: element.name,
                        });
                    });
                }


                setContent(content => ({
                    ...content,
                    ...{ series: optionSeries }
                }));

            },
                error => {
                    setBlockuiLoading(false)
                    console.error(
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString())
                }
            );
        }

        var companyId = SELECTED_COMPANY.id;


        ApiClient.getTemplateSettings(companyId, branchId).then(response => {
            var _template = response.data;
            _template.branch = branchId;
            _template.mainColor = _template.mainColor ? _template.mainColor : "#013289";
            _template.secondaryColor = _template.secondaryColor ? _template.secondaryColor : "#FFFFFF";
            _template.appFont = _template.appFont ? _template.appFont : "Arial";
            setTemplate(_template);

        },
            error => {
                setBlockuiLoading(false)
                console.error(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString())
            }
        );
    }

    function changeInvType(val) {

        //========================================================
        // Default info message per invoice type
        var defaults = Commons.getDefaults();
        var infos = defaults.invoiceTypeMessages;
        document.getElementById("documentTypeInfo").style.display = "none";
        if (infos) {
            var index = infos.indexOf(val.myDataInvoiceType);
            if (infos[index]) {
                document.getElementById("documentTypeInfo").style.display = "block";
                document.querySelector("#documentTypeInfo .invoicetype_message").innerText = t(infos[index] + "_info");
                document.querySelector("#documentTypeInfo .invoicetype_message").setAttribute("data", "invoice2_1");
            }
        }
        //========================================================


        var companyId = SELECTED_COMPANY.id;
        var invoiceTypeId = val.value;
        clearErrors("taxpayerId");
        clearErrors("customer");
        document.getElementsByClassName("correlativeSale")[0].style.display = "none";
        document.getElementsByClassName("selfPricing")[0].style.display = "none";


        if (Commons.isOnGroup(val.myDataInvoiceType, "customerForbidden")) {
            document.getElementsByClassName("customerFromGroup")[0].classList.remove('required');
            var el = document.getElementsByClassName("customerFromGroup");
            document.getElementById("taxpayerContainer").classList.remove('required');
            setCustomerRequired(false);
            // setCustomerHidden("none");
        }
        else {
            if (!document.getElementsByClassName("customerFromGroup")[0].classList.contains("required")) {
                document.getElementsByClassName("customerFromGroup")[0].classList.add('required');
                document.getElementById("taxpayerContainer").classList.add('required');
                setCustomerRequired(true);
            }
            // setCustomerHidden("flex");
        }

        if (val.myDataInvoiceType == "invoice5_1" || val.myDataInvoiceType == "invoice1_6" || val.myDataInvoiceType == "invoice2_4" || val.myDataInvoiceType == "invoice8_2" || val.myDataInvoiceType == "invoice11_4") {
            document.getElementsByClassName("correlativeSale")[0].style.display = "unset";
            // setCustomerRequired(true);
            // setCustomerHidden("flex");
        }


        if (Commons.isOnGroup(val.myDataInvoiceType, "trafficDataForbidden")) {
            document.getElementsByClassName("transformDataCard")[0].style.display = "none";
        }
        else {
            document.getElementsByClassName("transformDataCard")[0].style.display = "flex";
        }


        if (val.myDataInvoiceType == "invoice8_2") {
            document.getElementById("taxesMoreAccordion").style.display = "none";
            document.getElementById("salesMoreAccordion").style.display = "none";

        }
        else {
            document.getElementById("taxesMoreAccordion").style.display = "unset";
            document.getElementById("salesMoreAccordion").style.display = "unset";
        }

        //=======================

        if (val.myDataInvoiceType == "invoice3_1") {

            document.getElementsByClassName("cityContainer")[0].classList.add("required")
            setAddressRequired(true)
        }
        else {
            document.getElementsByClassName("cityContainer")[0].classList.remove('required');
            setAddressRequired(false)
        }
        setTransportInfoDisplay("none");
        if (val.myDataInvoiceType == "invoice2_01" || val.myDataInvoiceType == "invoice11_02") {
            setTransportInfoDisplay("flex");
        }

        setIsInvoice00_1(false);
        if (val.myDataInvoiceType == "invoice00_1" || val.myDataInvoiceType == "invoice1_01") {
            setIsInvoice00_1(true);
        }


        // setCustomerHidden("flex");
        // if (Commons.isOnGroup(val.myDataInvoiceType, "countryDisable")) {
        //     setCountryDisabled(true)
        // }
        // else {
        //     setCountryDisabled(false)
        // }

        //------------------

        if (val.myDataInvoiceType == "invoice8_2") {


            var _saleEntries = [];
            var saleEntry = {};
            saleEntry.product = { id: undefined, label: t("AccomodationTax"), value: "AccomodationTax", model: { price: "0.00", priceWithVat: "0.00" } }
            saleEntry.measurementUnit = { label: "-", value: "-" };
            saleEntry.quantity = "-";
            saleEntry.vat = { label: "-", value: "VAT_EMPTY" };
            saleEntry.unitPrice = "0.00";
            saleEntry.priceWithVat = "0.00";
            saleEntry.priceWithoutVat = "0.00";
            saleEntry.unitPriceWithVat = "0.00";
            saleEntry.discount = "0.00";

            _saleEntries.push(saleEntry);

            refSalesEntries.current.setData(_saleEntries)

            setEntriesHidden("none")
            setOtherTaxHidden("flex")
            setOtherRequired(true)
        }
        else {
            setEntriesHidden("unset")
            setOtherTaxHidden("none")
            setOtherRequired(false)
        }
        //==========================



        if (Commons.isOnGroup(val.myDataInvoiceType, "allowSelfPrice")) {
            document.getElementsByClassName("selfPricing")[0].style.display = "unset";
        }

        var selfPricing = getValues().selfPricing;

        var asExpenses = Commons.isOnGroup(val.myDataInvoiceType, "isExpense") || selfPricing

        ApiClient.getClassificationCategoriesOfMyDataInvoice(val.myDataInvoiceType, asExpenses).then(response => {
            var data = response.data;
            var _optionIncClassCategories = [];
            data.forEach(element => {
                var _classificationCategoryType = element.classificationCategory;
                var _classificationValueTypes = element.classificationValues;

                var prefix = _classificationCategoryType.replace("CATEGORY_", "") + ") ";
                _optionIncClassCategories.push({
                    value: _classificationCategoryType,
                    label: prefix + t(_classificationCategoryType),
                    classificationValues: _classificationValueTypes,
                    classificationValue: element.classificationValue,
                });
            });

            setSuggestedInClassCatsOfInvType(_optionIncClassCategories);
            setSuggestedInvoiceClassCatsOfInvType(val.invoiceTypeClassifications);
            if (isPreSale) {
                var sale = getValues();
                saleEntries = refSalesEntries.current.getData();

                var saleObject = {
                    saleEntries: saleEntries.entries
                }
                if (saleEntries && saleEntries.entries.length > 0) {
                    setDataWithClassifications(saleObject, _optionIncClassCategories, sale.myDataMoreTaxes, true)
                }

            }

        },
            error => {
                console.error(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString())
            }
        );


        var branch = getValues().branch;
        var branchId = null;
        if (branch) {
            branchId = branch.model.id
        }
        if (branchId == null && val.branchId != null) {
            branchId = val.branchId;
        }
        setValue("series", "")
        setValue("invoiceNumber", "")
        ApiClient.getCompanyInvoiceTypeSeries(SELECTED_COMPANY.id, invoiceTypeId, branchId, isCredit).then(response => {
            var data = response.data;
            var optionSeries = [];
            if (data) {
                data.forEach(element => {
                    optionSeries.push({
                        value: element.id,
                        startNumber: element.startNumber,
                        label: element.name,
                    });
                });
            }

            setContent(content => ({
                ...content,
                ...{ series: optionSeries }
            }));

            setBlockuiLoading(false)

            clearErrors("invoiceType");

            if (document.querySelector(".afterinvoice")) {
                document.querySelector(".afterinvoice").style.display = "flex"
            }



            if (id == undefined && optionSeries.length > 0) {

                var defaultsSeriesLetters = optionSeries.map(c => c.label);

                if (defaults != undefined && defaults.series != undefined && defaults.series.__isNew__) {
                    setValue("series", defaults.series);
                    changeSeries(defaults.series);
                }
                else {
                    if (optionSeries.length == 1) {
                        setValue("series", optionSeries[0]);
                        changeSeries(optionSeries[0]);
                    }
                    else if (defaults.series && defaultsSeriesLetters.includes(defaults.series.label)) {
                        setValue("series", defaults.series);
                        changeSeries(defaults.series);
                    }
                }
            }




            //if query params customer not null
            let customer = params.get('customer')

            if (customer) {

                ApiClient.getCompanyCustomer(companyId, customer).then(
                    response => {

                        var cust = response.data;
                        var cust_obj = {
                            label: cust.name,
                            value: customer,
                            taxpayerId: cust.taxpayerId,
                            model: cust
                        };
                        setValue("customer", cust_obj);
                        setCustomerValue(cust_obj);
                        changeCust(cust_obj);


                    },
                    error => {
                        toast.error("Error: " + error.data)
                        console.error(error.data)
                    }
                );
            }


        },
            error => {
                setBlockuiLoading(false)
                console.error(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString())
            }
        );
        if (id == undefined) {
            Commons.setDefault("invoiceType", val)
        }

        appendAccountantEmailOnContactsSelect();

    }

    const onChangeInvoiceType = val => {
        setValue("invoiceType", val)
        var defaults = Commons.getDefaults();

        if (defaults != undefined && defaults.series != undefined && defaults.series.__isNew__) {
            Commons.setDefault("series", null)
        }



        if (!isPreSale) {
            refSalesEntries.current.clearData();
        }

        saleEntries = refSalesEntries.current.getData();
        if (content != undefined) {
            changeInvType(val)
        }


    }

    const onChangeCorrelativeInvoice = val => {
        setValue("correlativeSale", val);

        if (val.model) {
            var selfPricing = getValues().selfPricing;
            var asExpenses = Commons.isOnGroup(val.model.myDataInvoiceType, "isExpense") || selfPricing;
            var companyId = SELECTED_COMPANY.id;

            ApiClient.getCompanyCustomerByTaxpayerId(companyId, val.model.customerTaxPayerId, val.model.customerName).then(
                response => {
                    var cust = response.data;
                    var cust_obj = {
                        label: cust.name,
                        value: customer,
                        taxpayerId: cust.taxpayerId,
                        model: cust
                    };
                    setValue("customer", cust_obj);
                    setCustomerValue(cust_obj);
                    changeCust(cust_obj);
                },
                error => {
                    console.error("Error: " + error.data)
                }
            );

            ApiClient.getClassificationCategoriesOfMyDataInvoice(val.model.myDataInvoiceType, asExpenses).then(response => {
                var data = response.data;
                var obj = {}
                data.forEach(element => {
                    if (element.classificationCategory) {
                        obj[element.classificationCategory] = element.classificationValues;
                    }
                    else {
                        obj[element.classificationCategory] = element.classificationValues;
                    }
                });
                var _selectedInvoiceType = getValues().invoiceType;

                if (_selectedInvoiceType.myDataInvoiceType != "invoice8_2") {
                    setDataWithClassifications(val.model, obj, moreTaxesOptions)
                    if (val.model.saleInstanceMoreTaxes && val.model.saleInstanceMoreTaxes.length > 0) {
                        val.model.saleInstanceMoreTaxes.forEach(tax => {
                            if (tax.deduction) {
                                tax.myDataMoreTax = tax.deduction;
                            }

                            tax.value = "TAX_" + tax.uid;
                            tax.label = tax.type == "DEDUCTION" ? tax.name : t("TAX_" + tax.uid);
                            tax.type = {
                                value: tax.myDataMoreTax.type,
                                label: t(tax.myDataMoreTax.type)
                            }

                            tax.myDataMoreTax.value = "TAX_" + tax.myDataMoreTax.uid;
                            tax.myDataMoreTax.label = t("TAX_" + tax.myDataMoreTax.uid);


                        });
                        var _data_transformed = saleMoreTaxesGridRef.current.transformData(val.model.saleInstanceMoreTaxes, moreTaxesTypesOptions, moreTaxesOptions)
                        saleMoreTaxesGridRef.current.setData(_data_transformed);
                        reCalculateTotals();
                        setInitiallyCollapsed({ collapsed: false, show: true });
                    }
                }
            },
                error => {
                    console.error(
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString())
                }
            );
        }

    }

    const getPrintSale = () => {
        var sale = getValues();
        sale.entries = refSalesEntries.current.getData().entries;
        sale.saleInstanceMoreTaxes = saleMoreTaxesGridRef.current.getData();
        sale.totals = totals;
        var psale = iframePrintTemplate.current.getPrintSale(sale);
        var _template = { template };
        if (_template) {
            psale.LOGO = _template.template.logo;
            psale.IBAN = _template.template.iban;
            psale.MOREINFO = _template.template.moreInfo;
        }

        return psale;
    }

    const handlePrint = e => {
        e.preventDefault();
        var sale = getValues();
        sale.entries = refSalesEntries.current.getData().entries;
        sale.saleInstanceMoreTaxes = saleMoreTaxesGridRef.current.getData();
        sale.totals = totals;
        var psale = iframePrintTemplate.current.getPrintSale(sale);
        var _template = { template };
        if (_template) {
            psale.LOGO = _template.template.logo;
            psale.IBAN = _template.template.iban;
            psale.MOREINFO = _template.template.moreInfo;
        }
        iframePrintTemplate.current.print(psale)
    }

    const convertPaymentTypes = types => {
        var optionspaymentTypes = [];
        if (types != undefined) {
            types.forEach(element => {
                optionspaymentTypes.push({
                    value: element.value,
                    label: t(element.label),
                });
            });

        }


        return optionspaymentTypes
    }
    const convertPaymentType = type => {
        var optionspaymentType = {
            value: type.value,
            label: t(type.label),
        }
        return optionspaymentType
    }

    const listenMessage = (msg) => {

        var mess = msg.data;
        if (mess.source == "timologic") {
            var customerName = mess.sale.customerName;
            if (customerName == null) {
                customerName = mess.sale.customerFirstName + " " + mess.sale.customerLastName;
            }
            var customer = { label: customerName, value: customerName }
            var city = mess.sale.customerCity;
            var address = mess.sale.customerAddress;
            var postalCode = mess.sale.postalCode;
            var taxpayerId = mess.sale.customerTaxPayerId;
            var addressNumber = mess.sale.addressNumber;
            var subjectField = mess.sale.subjectField;
            var entries = [];
            if (mess.sale.products) {
                mess.sale.products.forEach(e => {
                    var productModel = {
                        name: e.name
                    }
                    var product = {
                        label: e.name,
                        value: e.name,
                        model: productModel
                    }
                    var vat_enum = "VAT_" + e.vat;
                    var vat_label = e.vat;

                    if (mess.sale.vat) {
                        vat_enum = "VAT_" + mess.sale.vat;
                        vat_label = mess.sale.vat;
                    }

                    var vat = {
                        label: vat_label,
                        value: vat_enum,
                    }

                    var mu = {
                        value: e.measurementUnit,
                        label: t(e.measurementUnit),
                    }
                    if (e.unitPriceWithVat) {
                        e.unitPrice = parseFloat(e.unitPriceWithVat / (1 + vat_label / 100))
                    }
                    var priceWithoutVat = parseFloat(e.quantity * e.unitPrice);

                    // var vat_amount = parseFloat(vat_label * priceWithoutVat / 100)

                    var unitPriceWithVat = e.unitPrice * (1 + vat_label / 100)

                    var priceWithVat = parseFloat(unitPriceWithVat * e.quantity).toFixed(2);


                    var entry = {
                        product: product,
                        unitPrice: parseFloat(e.unitPrice).toFixed(2),
                        unitPriceWithVat: parseFloat(unitPriceWithVat).toFixed(4),
                        quantity: e.quantity,
                        measurementUnit: mu,
                        vat: vat,
                        discount: "0.00",
                        priceWithoutVat: priceWithoutVat.toFixed(2),
                        priceWithVat: priceWithVat
                    }
                    if (entry.product.value) {
                        entries.push(entry);
                    }

                })
            }


            refSalesEntries.current.setData(entries)
            reCalculateTotals()

            if (taxpayerId) {
                setValue("taxpayerId", taxpayerId);
            }

            setValue("country", defcountry)

            if (customer) {
                setValue("customer", customer);
                setCustomerValue(customer);
            }
            if (city) {
                setValue("city", city);
            }
            if (address) {
                setValue("fulladdress", address);
            }
            if (postalCode) {
                setValue("postalCode", postalCode);
            }
            if (addressNumber) {
                setValue("addressNumber", addressNumber);
            }
            if (subjectField) {
                setValue("subjectField", subjectField)
            }
        }
    }

    const dismissSalesEditAlertHandle = () => {
        Commons.setDefault("dismissSalesEditAlert", true)
    }

    const myDataTokenEmptyToastMessage = () => {
        var _url = "/usercompany/" + SELECTED_COMPANY.id;
        return (
            <div>
                {t("myDataTokenEmpty1")} <a target="_blank" href={_url}>{t("myDataTokenEmpty2")}</a> {t("myDataTokenEmpty3")}
            </div>
        )
    };
    const myDataErrorMessageWithLink = (error) => {
        return (
            <div>
                {error.message}<span style={{ marginLeft: 10 }}><button className="btn btn-skin btn-sm" onClick={e => showErrorDetailsModal(error)}> {t("More")} </button> </span>
            </div>
        )
    }

    const showErrorDetailsModal = (error) => {
        refErrorDetailsModal.current.open(error);
    }

    const hangleChangeMyData = e => {
        if (SELECTED_COMPANY.myDataToken) {
            if (SELECTED_COMPANY.myDataToken.trim() != "") {
                setMyData(e.target.checked)
                Commons.setDefault("mydata", e.target.checked);
            }
            else {
                setMyData(false)
                Commons.setDefault("mydata", false);
                toast.error(myDataTokenEmptyToastMessage, { autoClose: 5000 })
            }
        }
        else {

            setMyData(false)
            Commons.setDefault("mydata", false);
            toast.error(myDataTokenEmptyToastMessage, { autoClose: 5000 })
        }
    }

    const onChangeContact = data => {
        setSelectedContacts(data);
    }
    const onChangeDispatchDate = (date) => {
        setDispatchDate(date)
        setValue("dispatchDate", date);
    }
    const onChangeDate = (date) => {
        setStartDate(date)
        setValue("invoiceDate", date);
    }

    const reCalculateTotals = () => {
        if (refSalesEntries.current) {
            var nodes = refSalesEntries.current.getData();
            var saletaxes = saleMoreTaxesGridRef.current.getData();
            refEntriesSummary.current.calculateAndUpdateUI(nodes, saletaxes);
        }
    }

    const onClickFetchTaxPayerId = () => {
        var AFMcalledfor = getValues().taxpayerId;
        var AFMcalledby = SELECTED_COMPANY.taxpayerId;
        setBlockuiLoading(true)
        ApiClient.checkAfm(AFMcalledfor, AFMcalledby).then(
            response => {
                try {
                    var res = response.data.Body.rgWsPublicAfmMethodResponse;

                    var error = res.pErrorRec_out;
                    if (error) {
                        if (error.errorCode != null) {
                            toast.error(error.errorDescr);
                            setBlockuiLoading(false)
                            return;
                        }
                    }


                    var basic_rec = res.RgWsPublicBasicRt_out;
                    var firm_act_tab = res.arrayOfRgWsPublicFirmActRt_out;
                    var _customerName = basic_rec.onomasia.substring(0, 127);
                    setValue("customer", { label: _customerName, value: _customerName });
                    setCustomerInputValue(_customerName)
                    setValue("fulladdress", basic_rec.postalAddress);
                    if (firm_act_tab) {
                        if (Array.isArray(firm_act_tab.RgWsPublicFirmActRtUser)) {
                            setValue("subjectField", firm_act_tab.RgWsPublicFirmActRtUser[0].firmActDescr);
                        }
                        else {
                            setValue("subjectField", firm_act_tab.RgWsPublicFirmActRtUser.firmActDescr);

                        }
                    }
                    setValue("country", DefaultCountry)
                    setValue("postalCode", basic_rec.postalZipCode);
                    setValue("city", basic_rec.postalAreaDescription);
                    setValue("addressNumber", basic_rec.postalAddressNo);
                    setValue("taxOffice", { "value": basic_rec.doy, "label": basic_rec.doyDescr });
                    var selectedMyDataInvType = getValues().invoiceType.myDataInvoiceType;
                    if (selectedMyDataInvType == "invoice2_01" || selectedMyDataInvType == "invoice11_02") {
                        setValue("recipientTaxPayerId", AFMcalledfor);
                        setValue("recipientName", _customerName);

                        setValue("senderTaxPayerId", AFMcalledfor);
                        setValue("senderName", _customerName);
                    }

                    document.getElementById("saveCustomerForFuture").style.display = "unset";
                    trigger();
                    setBlockuiLoading(false)
                }
                catch (e) {
                    setBlockuiLoading(false)
                }

            },
            error => {
                if (error.response) {
                    toast.error(error.message + ": " + error.response.data.message)
                    console.error(error.response.data.message);
                }
                setBlockuiLoading(false)
            }
        )
    }

    const onChangeCurrency = (currency) => {
        setValue("currency", currency)
        setValue("exchangeRate", parseFloat(currency.rate).toFixed(4))
    }

    const onBalanceChange = (e) => {
        var newbalance = e.target.value ? e.target.value : 0.0;
        setValue("balance", parseFloat(newbalance).toFixed(2))
    }
    const onInputChangeContact = (contact) => {

    }

    const onFooterAddCustomer = () => {
        window.open("/customer", '_blank');

    }
    const cleanNotes = () => {
        setValue("notes", "");
    }
    const onOtherTaxesSelectChange = (val) => {
        clearErrors("otherTaxCategory");

        var rowData = refSalesEntries.current.getData();

        var newEntries = rowData.entries;
        var amount = getValues().otherTaxAmount;
        if (amount && amount != "") {
            var _amount = amount.toString().replace(",", ".");
            amount = parseFloat(_amount).toFixed(2);
        }
        newEntries[0].taxes = [{
            myDataMoreTax: val,
            myDataMoreTaxes: otherTaxesOptions,
            type: {
                value: "OTHER_TAXES",
                label: t("OTHER_TAXES")
            },
            amount: amount
        }];

        setValue("otherTaxCategory", val)
        reCalculateTotals()
        refSalesEntries.current.setData(newEntries)
        saleEntries = refSalesEntries.current.getData();
    }

    const onOtherTaxAmountChange = (e) => {
        clearErrors("otherTaxAmount");

        var rowData = refSalesEntries.current.getData();
        var taxval = e.target.value.toString().replace(",", ".");

        var val = parseFloat(taxval).toFixed(2)

        var newEntries = rowData.entries;
        if (newEntries[0].taxes) {
            newEntries[0].taxes[0].amount = val
        }

        setValue("otherTaxAmount", val)
        reCalculateTotals()
        refSalesEntries.current.setData(newEntries)
    };

    const onCloseInvoiceTypeInfo = () => {
        var defaults = Commons.getDefaults();
        var invoice_type = getValues().invoiceType ? getValues().invoiceType.myDataInvoiceType : null;
        if (defaults && invoice_type) {
            var infos = defaults.invoiceTypeMessages;
            if (infos && infos.includes(invoice_type)) {
                var index = infos.indexOf(invoice_type);
                if (index !== -1) {
                    infos.splice(index, 1)
                    Commons.setDefault("invoiceTypeMessages", infos);
                }
            }
        }
    }

    const onSelectTemplateLanguage = (code) => {
        setSelectedTemplateLanguage(code);
        setValue("templateLang", code.toLowerCase(code));
        Commons.setDefault("templateLang", code.toLowerCase(code))
    }

    const selectStyles = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                fontSize: 12,
            }
        }
    }

    return (
        <BlockUi loader={<Loader active type='square-spin' />} tag="div" blocking={blockuiLoading}>
            <div id="salesContainerParent" className="salesContainerParent">
                <div className="alert alert-warning alert-dismissible fade show" role="alert" id="documentTypeInfo" style={{ display: "none" }}>
                    <div className="invoicetype_message"></div>
                    <button type="button" className="btn-close" data-bs-dismiss="alert" onClick={onCloseInvoiceTypeInfo} aria-label="Close"></button>
                </div>

                <form id="sales_form" onSubmit={handleSubmit(onSubmit, onError)}>
                    {/* <p className="skin" id="salesLeft">{t("salesLeftMess", { count: salesLeft })}</p> */}
                    <span id="salesLeft" className="badge rounded-pill bg-secondary font-size-11 me-1">{t("salesLeftMess", { count: salesLeft })}</span>

                    {sosMessage ? <div className={sosMessage.alertClass} role="alert" id="documentTypeInfo">
                        <div className="sos_message">{sosMessage.message}</div>
                    </div> : <></>}


                    <FormLayout
                        title={t('invoicePublice')}
                    // headerRightContent={
                    //     <p className="skin" id="salesLeft">{t("salesLeftMess", { count: salesLeft })}</p>

                    // }
                    >

                        <div>
                            <span>{editMark}</span>
                            <div className="card mainInvoiceInfoCard">
                                <div className="row">

                                    <div className="col-md-2 selectInputContainer form-group required">
                                        <label className="control-label inputlabel">{t('Branch')}
                                            <Tippy trigger="click" content={<span className="tooltip-skin">{t("saleBranchInfo")}</span>}>
                                                <i className="fa fa-x2 fa-info-circle infoicon"></i>
                                            </Tippy>
                                        </label>
                                        <Controller
                                            render={({ field }) => (
                                                <Select styles={{
                                                    menu: (provided, state) => ({
                                                        ...provided,
                                                        minWidth: 250,
                                                        border: '4px #333',
                                                        zIndex: 9999
                                                    }),
                                                    indicatorsContainer: (provided, state) => ({
                                                        ...provided,
                                                        padding: '0px',
                                                    }),
                                                    placeholder: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px',
                                                    }),
                                                    control: provided => ({
                                                        ...provided,
                                                        fontSize: '14px'
                                                    }),
                                                }}  {...field}
                                                    onChange={onChangeBranch}
                                                    isDisabled={onEditDisabled}
                                                    inputId="branch-react-select"
                                                    options={content.optionBranches}
                                                    className={`form-control ${errors.branch ? 'is-invalid' : ''}`}
                                                    placeholder={t('Select')}
                                                >

                                                </Select>
                                            )}
                                            control={control}
                                            rules={{ required: true }}
                                            name="branch"
                                            defaultValue=""
                                        />

                                        <div className="invalid-feedback">{errors.branch?.message}</div>
                                    </div>
                                    <div className="col-md-2 selectInputContainer form-group required">
                                        <label className="control-label inputlabel">{t('InvoiceType')}
                                            <Tippy trigger="click" content={<span className="tooltip-skin">{t("invoiceTypeInfo")}</span>}>
                                                <i className="fa fa-x2 fa-info-circle infoicon"></i>
                                            </Tippy>
                                        </label>
                                        <div className="input-group">

                                            <Controller
                                                render={({ field }) => (
                                                    <Select styles={{
                                                        menu: (provided, state) => ({
                                                            ...provided,
                                                            minWidth: 250,
                                                            border: '4px #333',
                                                            zIndex: 9999
                                                        }),
                                                        indicatorsContainer: (provided, state) => ({
                                                            ...provided,
                                                            padding: '0px',
                                                        }),
                                                        placeholder: (provided, state) => ({
                                                            ...provided,
                                                            fontSize: '12px',
                                                        }),
                                                        control: provided => ({
                                                            ...provided,
                                                            fontSize: '14px'
                                                        }),
                                                    }} {...field} onChange={onChangeInvoiceType} inputId="invoiceType-react-select"
                                                        options={content.optionsinvoiceTypes}
                                                        isDisabled={onEditDisabled}
                                                        className={`form-control ${errors.invoiceType ? 'is-invalid' : ''}`}
                                                        placeholder={t('Select')}
                                                        id="invoiceType"
                                                    >

                                                    </Select>
                                                )}
                                                control={control}
                                                rules={{ required: true }}
                                                name="invoiceType"
                                                defaultValue=""
                                            />


                                        </div>

                                        <div className="invalid-feedback">{errors.invoiceType?.message}</div>
                                    </div>

                                    <div className="col-md-2 selectInputContainer form-group correlativeSale" style={{ display: 'none' }}>
                                        <label className="control-label inputlabel">{t('correlativeSale')}</label>
                                        <Controller
                                            render={({ field }) => (
                                                <SelectTable {...field}
                                                    width={500}
                                                    onChangeValue={onChangeCorrelativeInvoice}
                                                    onChange={onChangeCorrelativeInvoice}
                                                    // options={correlativeSales}
                                                    loadOptions={loadPageOptions}
                                                    columns={correlativeSales.columns}
                                                    selectedInvoiceType={getValues().invoiceType}
                                                    asyncPaginate={true}
                                                    ref={selectInputRef}
                                                    isClearable={true}
                                                    key={new Date().getTime()}
                                                    className={`form-control ${errors.correlativeSale ? 'is-invalid' : ''}`}
                                                    placeholder={t('Select')}
                                                >

                                                </SelectTable>
                                            )}
                                            control={control}
                                            name="correlativeSale"
                                            defaultValue=""
                                        />


                                        <div className="invalid-feedback">{errors.correlativeSale?.message}</div>
                                    </div>

                                    <div className="col-md-2 form-group required">
                                        <label className="control-label inputlabel">{t('issuerDate')}
                                            <Tippy trigger="click" content={<span className="tooltip-skin">{t("invoiceDateInfo")}</span>}>
                                                <i className="fa fa-x2 fa-info-circle infoicon"></i>
                                            </Tippy>
                                        </label>

                                        <Controller
                                            render={({ field }) => (
                                                <DatePicker {...field}
                                                    selected={startDate}
                                                    minDate={new Date("01/01/2020")}
                                                    maxDate={moment().add(1, 'days').toDate()}
                                                    onChange={onChangeDate}
                                                    disabled={onEditDisabled}
                                                    dateFormat="dd/MM/yyyy"
                                                    autoComplete="off"
                                                    className={`form-control ${errors.invoiceDate ? 'is-invalid' : ''}`}
                                                >

                                                </DatePicker>

                                            )}
                                            control={control}
                                            rules={{ required: true }}
                                            name="invoiceDate"
                                            defaultValue=""
                                        />


                                        <div className="invalid-feedback">{errors.invoiceDate?.message}</div>
                                    </div>

                                    <div className="col-md-4 afterinvoice" style={{ display: "none" }}>

                                        <div className="row">
                                            <div className="col-md-6 selectInputContainer form-group required">
                                                <label className="control-label inputlabel">{t('Series')}
                                                    <Tippy trigger="click" content={<span className="tooltip-skin">{t("seriesInfo")}</span>}>
                                                        <i className="fa fa-x2 fa-info-circle infoicon"></i>
                                                    </Tippy>
                                                </label>
                                                <Controller
                                                    render={({ field }) => (
                                                        <CreatableSelect {...field}
                                                            styles={{
                                                                indicatorsContainer: (provided, state) => ({
                                                                    ...provided,
                                                                    padding: '0px',
                                                                }),
                                                                placeholder: (provided, state) => ({
                                                                    ...provided,
                                                                    fontSize: '12px',
                                                                }),
                                                                control: provided => ({
                                                                    ...provided,
                                                                    fontSize: '14px'
                                                                }),
                                                            }}
                                                            onChange={onChangeSeries} inputId="series-react-select"
                                                            options={content.series}
                                                            isDisabled={onEditDisabled && !isCredit}
                                                            className={`form-control ${errors.series ? 'is-invalid' : ''}`}
                                                            placeholder={t('Select')}
                                                        >

                                                        </CreatableSelect>
                                                    )}
                                                    disabled={onEditDisabled}
                                                    control={control}
                                                    rules={{ required: true }}
                                                    name="series"
                                                    defaultValue=""
                                                />


                                                <div className="invalid-feedback">{errors.series?.message}</div>
                                            </div>
                                            <div className="col-md-6 form-group required">
                                                <label className="control-label inputlabel">{t("invoiceNumber")}
                                                    <Tippy trigger="click" content={<span className="tooltip-skin">{t("saleSeriesInfo")}</span>}>
                                                        <i className="fa fa-x2 fa-info-circle infoicon"></i>
                                                    </Tippy>
                                                </label>

                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">#</span>
                                                    </div>
                                                    <input
                                                        name="invoiceNumber"
                                                        type="text"
                                                        maxLength={11}
                                                        readOnly={true}
                                                        {...register('invoiceNumber', { required: true, maxLength: 11 })}
                                                        className={`form-control ${errors.invoiceNumber ? 'is-invalid' : ''}`}
                                                    />
                                                    <div className="invalid-feedback">{errors.invoiceNumber?.message}</div>
                                                </div>

                                            </div>

                                        </div>

                                    </div>


                                </div>
                            </div>
                            <div><br></br></div>

                            <div className="card customerCard" style={{ display: customerHidden }}>
                                <div className="card-header">
                                    <i className="fa fa-user" style={{ marginRight: 6, color: "#013289" }}></i>
                                    <span className="prinnerdTab">{t("Customer")}</span>
                                </div>
                                <div className="card-body" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                    <div className="row customerRow">

                                        <div className="col-md-3 selectInputContainer form-group required customerFromGroup">
                                            <label className="control-label inputlabel">{t('Name')}
                                                <Tippy trigger="click" content={<span className="tooltip-skin">{t("saleCustomerInfo")}</span>}>
                                                    <i className="fa fa-x2 fa-info-circle infoicon"></i>
                                                </Tippy>
                                            </label>
                                            <Controller
                                                render={({ field, ref }) => (
                                                    <SelectTable {...field}
                                                        // width={300}
                                                        // footer={
                                                        //     {
                                                        //         buttons:[
                                                        //             {
                                                        //                 icon:"fa fa-plus",
                                                        //                 className:"btn btn-sm btn-skin",
                                                        //                 onClick:onFooterAddCustomer
                                                        //             }
                                                        //         ]
                                                        //     }
                                                        // }
                                                        ref={ref}
                                                        inputId="customer-react-select"
                                                        width={500}
                                                        onChange={onChangeCustomer}
                                                        inputValue={inputCustomerValue}
                                                        onChangeValue={onChangeCustomer}
                                                        onInputChange={onInputChangeCustomer}
                                                        // options={content.customers}
                                                        createTable={true}
                                                        selectedValue={customerValue}
                                                        selectedInvoiceType={getValues().invoiceType}
                                                        asyncPaginate={true}
                                                        columns={[
                                                            {
                                                                header: t("Name"),
                                                                field: "name",
                                                                flex: 8,
                                                                asLabel: true
                                                            },
                                                            {
                                                                header: t("taxpayerId"),
                                                                field: "taxpayerId",
                                                                flex: 4
                                                            }
                                                        ]}
                                                        isDisabled={onEditDisabled}
                                                        loadOptions={loadCustomerPageOptions}

                                                        className={`form-control ${errors.customer ? 'is-invalid' : ''}`}
                                                        placeholder={t('placeholderSelectCT')}
                                                    >

                                                    </SelectTable>

                                                )}
                                                control={control}
                                                rules={{ required: customerRequired }}
                                                name="customer"
                                                defaultValue=""
                                            />


                                            <div className="invalid-feedback">{errors.customer?.message}</div>
                                        </div>

                                        <div id="taxpayerContainer" className="col-md-2 form-group required">
                                            <label className="control-label inputlabel">{t("taxpayerId")}
                                                <Tippy trigger="click" content={<span className="tooltip-skin">{t("searchCustomerInfo")}</span>}>
                                                    <i className="fa fa-x2 fa-info-circle infoicon"></i>
                                                </Tippy>
                                            </label>

                                            <div className="input-group mb-2">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text fetchTaxPayerId" onClick={onClickFetchTaxPayerId}><i className="fa fa-search"></i></span>


                                                </div>
                                                <input
                                                    maxLength={22}
                                                    name="taxpayerId"
                                                    type="text"
                                                    disabled={onEditDisabled}
                                                    {...register('taxpayerId', { maxLength: 22, required: customerRequired })}
                                                    onKeyDown={(e) => { e.keyCode === 13 && e.preventDefault(); }}
                                                    className={`form-control ${errors.taxpayerId ? 'is-invalid' : ''}`}
                                                />
                                                <div className="invalid-feedback">{errors.taxpayerId?.message}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-2 selectInputContainer form-group required">
                                            <label className="control-label inputlabel">{t("Country")}</label>
                                            <Controller
                                                render={({ field }) => (
                                                    <Select {...field}
                                                        styles={{
                                                            indicatorsContainer: (provided, state) => ({
                                                                ...provided,
                                                                padding: '0px',
                                                            }),
                                                            placeholder: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '12px',
                                                            }),
                                                            control: provided => ({
                                                                ...provided,
                                                                fontSize: '14px'
                                                            }),
                                                        }}
                                                        isDisabled={countryDisabled}
                                                        options={content.optionsCountries}
                                                        className={`form-control  ${errors.country ? 'is-invalid' : ''}`}
                                                        placeholder={t('Select')}
                                                    >

                                                    </Select>
                                                )}

                                                control={control}
                                                rules={{ maxLength: 100 }}
                                                name="country"
                                                defaultValue=""
                                            />
                                            <div className="invalid-feedback">{errors.country?.message}</div>
                                        </div>

                                        <div className="col-md-2 form-group cityContainer">
                                            <label className="control-label inputlabel">{t("City")}</label>
                                            <input
                                                name="city"
                                                {...register('city', { maxLength: 32, required: addressRequired })}
                                                type="text"
                                                maxLength={32}
                                                onKeyDown={(e) => { e.keyCode === 13 && e.preventDefault(); }}
                                                className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                                            />
                                            <div className="invalid-feedback">{errors.city?.message}</div>
                                        </div>
                                        <div className="col-md-2 form-group addressContainer">
                                            <label className="control-label inputlabel">{t("Address")}</label>
                                            <input
                                                name="fulladdress"
                                                {...register('fulladdress', { maxLength: 64 })}
                                                onKeyDown={(e) => { e.keyCode === 13 && e.preventDefault(); }}
                                                maxLength={64}
                                                type="text"
                                                className={`form-control ${errors.fulladdress ? 'is-invalid' : ''}`}
                                            />
                                            <div className="invalid-feedback">{errors.fulladdress?.message}</div>
                                        </div>
                                        <div className="col-md-1 form-group">
                                            <label className="control-label inputlabel">{t("Number")}</label>
                                            <input
                                                name="addressNumber"
                                                {...register('addressNumber', { maxLength: 16 })}
                                                onKeyDown={(e) => { e.keyCode === 13 && e.preventDefault(); }}
                                                type="text"
                                                className={`form-control ${errors.addressNumber ? 'is-invalid' : ''}`}
                                            />
                                            <div className="invalid-feedback">{errors.addressNumber?.message}</div>
                                        </div>

                                        <div className="col-md-1 form-group">
                                            <label className="control-label inputlabel">{t("PostalCodeShort")}</label>
                                            <input
                                                name="postalCode"
                                                {...register('postalCode', { maxLength: 16 })}
                                                onKeyDown={(e) => { e.keyCode === 13 && e.preventDefault(); }}
                                                maxLength={16}
                                                type="text"
                                                className={`form-control ${errors.postalCode ? 'is-invalid' : ''}`}
                                            />
                                            <div className="invalid-feedback">{errors.postalCode?.message}</div>
                                        </div>


                                        <div className="col-md-3 selectInputContainer form-group">
                                            <label className="control-label inputlabel">{t("taxOffice")}</label>
                                            <Controller
                                                render={({ field }) => (
                                                    <Select {...field}
                                                        styles={{
                                                            indicatorsContainer: (provided, state) => ({
                                                                ...provided,
                                                                padding: '0px',
                                                            }),
                                                            placeholder: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '12px',
                                                            }),
                                                            control: provided => ({
                                                                ...provided,
                                                                fontSize: '14px'
                                                            }),
                                                        }}
                                                        options={content.taxOffices}
                                                        onChange={taxOfficeOnChange}
                                                        className={`form-control  ${errors.taxOffice ? 'is-invalid' : ''}`}
                                                        placeholder={t('Select')}
                                                    >
                                                    </Select>
                                                )}
                                                control={control}
                                                rules={{ maxLength: 100 }}
                                                name="taxOffice"
                                                defaultValue=""
                                            />
                                            <div className="invalid-feedback">{errors.taxOffice?.message}</div>
                                        </div>


                                        {/* <div className="col-md-3 selectInputContainer form-group">
                                            <label className="control-label inputlabel">{t("taxOffice")}</label>
                                            <Controller
                                                render={({ field }) => (
                                                    <AsyncSelect {...field}
                                                        cacheOptions
                                                        loadOptions={loadTaxOfficeOptions}
                                                        defaultOptions
                                                        styles={{
                                                            indicatorsContainer: (provided, state) => ({
                                                                ...provided,
                                                                padding: '0px',
                                                            })
                                                        }}
                                                        // options={content.taxOffices}
                                                        onChange={taxOfficeOnChange}
                                                        className={`form-control  ${errors.taxOffice ? 'is-invalid' : ''}`}
                                                        placeholder={t('Select')}
                                                    >
                                                    </AsyncSelect>


                                                )}
                                                control={control}
                                                rules={{ maxLength: 100 }}
                                                name="taxOffice"
                                                defaultValue=""
                                            />
                                            <div className="invalid-feedback">{errors.taxOffice?.message}</div>
                                        </div> */}



                                        <div className="col-md-4 form-group">
                                            <label className="control-label inputlabel">{t("subjectField")}</label>
                                            <input
                                                name="subjectField"
                                                maxLength={150}
                                                {...register('subjectField', { maxLength: 150 })}
                                                type="text"
                                                onKeyDown={(e) => { e.keyCode === 13 && e.preventDefault(); }}
                                                className={`form-control ${errors.subjectField ? 'is-invalid' : ''}`}
                                            />
                                            <div className="invalid-feedback">{errors.subjectField?.message}</div>
                                        </div>
                                        <div className="col-md-2" id="saveCustomerForFuture" style={{ display: "none" }}>
                                            <label className="control-label">{t("saveCustomerForFuture")}</label>
                                            <div className="form-switch">
                                                <input className="form-check-input" name="saveCustomerForFuture"  {...register('saveCustomerForFuture')} type="checkbox"></input>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="container-fluid">

                                <div className="row" style={{ display: transportInfoDisplay }}>

                                    <div className="transport_information_container card col-md-6" >
                                        <div className="card-header">
                                            <i className="fa fa-user" style={{ marginRight: 6, color: "#013289" }}></i>
                                            <span className="prinnerdTab">{t("senderName")}</span>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 form-group">
                                                <label className="control-label inputlabel">{t("senderName")}</label>
                                                <input
                                                    name="senderName"
                                                    maxLength={20}
                                                    {...register('senderName', { maxLength: 20 })}
                                                    className={`form-control ${errors.senderName ? 'is-invalid' : ''}`}
                                                />
                                                <div className="invalid-feedback">{errors.senderName?.message}</div>
                                            </div>


                                            <div className="col-md-6 form-group">
                                                <label className="control-label inputlabel">{t("senderTaxPayerId")}</label>
                                                <input
                                                    name="senderTaxPayerId"
                                                    maxLength={200}
                                                    {...register('senderTaxPayerId', { maxLength: 200 })}
                                                    className={`form-control ${errors.senderTaxPayerId ? 'is-invalid' : ''}`}
                                                />
                                                <div className="invalid-feedback">{errors.senderTaxPayerId?.message}</div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="transport_information_container card col-md-6">
                                        <div className="card-header">
                                            <i className="fa fa-user" style={{ marginRight: 6, color: "#013289" }}></i>
                                            <span className="prinnerdTab">{t("recipientName")}</span>
                                        </div>
                                        <div className="row">


                                            <div className="col-md-6 form-group">
                                                <label className="control-label inputlabel">{t("recipientName")}</label>
                                                <input
                                                    name="recipientName"
                                                    maxLength={200}
                                                    {...register('recipientName', { maxLength: 200 })}
                                                    className={`form-control ${errors.recipientName ? 'is-invalid' : ''}`}
                                                />
                                                <div className="invalid-feedback">{errors.recipientName?.message}</div>
                                            </div>


                                            <div className="col-md-6 form-group">
                                                <label className="control-label inputlabel">{t("recipientTaxPayerId")}</label>
                                                <input
                                                    name="recipientTaxPayerId"
                                                    maxLength={20}
                                                    {...register('recipientTaxPayerId', { maxLength: 20 })}
                                                    className={`form-control ${errors.recipientTaxPayerId ? 'is-invalid' : ''}`}
                                                />
                                                <div className="invalid-feedback">{errors.recipientTaxPayerId?.message}</div>
                                            </div>


                                        </div>


                                    </div>

                                </div>
                            </div>

                            <div className="accordion" id="salesMoreAccordion">


                                <div className="accordion-item " style={{ padding: 0 }}>
                                    <h2 className="accordion-header" style={{ width: 160 }} id="headingOne">
                                        <a className="accordion-button collapsed nonhilight" style={{ padding: 0 }} href="#" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                            {t("More")}
                                        </a>
                                    </h2>
                                    <hr></hr>
                                    <div id="collapseOne" className={initiallyCollapsedMore.show ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby="headingOne" data-bs-parent="#salesMoreAccordion">

                                        <div className="accordion-body" style={{ padding: 0 }}>

                                            <div className="row">

                                                <div className="col-md-9" >
                                                    <div style={{ marginTop: 0, padding: 8 }} className="card transformDataCard">

                                                        <div className="card-header">
                                                            <i className="fa fa-car" style={{ marginRight: 6, color: "#013289" }}></i>
                                                            <span className="prinnerdTab">  {t("TrafficData")}</span>

                                                        </div>

                                                        <div className="card-body">
                                                            <div className="row">

                                                                <div className="col-md-4 selectInputContainer form-group">
                                                                    <label className="control-label inputlabel">{t('MovePurpose')}
                                                                    </label>
                                                                    <Controller
                                                                        render={({ field }) => (
                                                                            <Select {...field}
                                                                                styles={{
                                                                                    indicatorsContainer: (provided, state) => ({
                                                                                        ...provided,
                                                                                        padding: '0px',
                                                                                    }),
                                                                                    placeholder: (provided, state) => ({
                                                                                        ...provided,
                                                                                        fontSize: '12px',
                                                                                    }),
                                                                                    control: provided => ({
                                                                                        ...provided,
                                                                                        fontSize: '14px'
                                                                                    }),
                                                                                }}
                                                                                isClearable
                                                                                onChange={onChangeMovePurpose}
                                                                                options={content.movePurposes}
                                                                                className={`form-control ${errors.movePurpose ? 'is-invalid' : ''}`}
                                                                                placeholder={t('Select')}
                                                                            >

                                                                            </Select>
                                                                        )}
                                                                        control={control}
                                                                        name="movePurpose"
                                                                        defaultValue=""
                                                                    />


                                                                    <div className="invalid-feedback">{errors.branch?.message}</div>
                                                                </div>

                                                                <div className="col-md-4 form-group">
                                                                    <label className="control-label inputlabel">{t('dispatchDateTime')}</label>

                                                                    <Controller
                                                                        render={({ field }) => (
                                                                            <DatePicker {...field}
                                                                                selected={dispatchDate}
                                                                                minDate={new Date("01/01/2020")}
                                                                                onChange={onChangeDispatchDate}
                                                                                showTimeSelect
                                                                                dateFormat="dd/MM/yyyy H:mm"
                                                                                timeIntervals="5"
                                                                                autoComplete="off"
                                                                                className={`form-control ${errors.dispatchDate ? 'is-invalid' : ''}`}
                                                                            >

                                                                            </DatePicker>

                                                                        )}
                                                                        control={control}
                                                                        name="dispatchDate"
                                                                        defaultValue=""
                                                                    />


                                                                    <div className="invalid-feedback">{errors.dispatchDate?.message}</div>
                                                                </div>

                                                                <div className="col-md-4 form-group">
                                                                    <label className="control-label inputlabel">{t("vehicleNumber")}</label>
                                                                    <input
                                                                        name="vehicleNumber"
                                                                        maxLength={20}
                                                                        {...register('vehicleNumber', { maxLength: 20 })}
                                                                        className={`form-control ${errors.vehicleNumber ? 'is-invalid' : ''}`}
                                                                    />
                                                                    <div className="invalid-feedback">{errors.vehicleNumber?.message}</div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                            <div className="row">

                                                <div className="col-md-9" >
                                                    <div style={{ marginTop: 0 }} className="card">
                                                        <div className="row">

                                                            <div className="col-md-4 selectInputContainer form-group">
                                                                <label className="control-label inputlabel">{t('Currency')}</label>
                                                                <Controller
                                                                    render={({ field }) => (
                                                                        <Select {...field}
                                                                            styles={{
                                                                                indicatorsContainer: (provided, state) => ({
                                                                                    ...provided,
                                                                                    padding: '0px',
                                                                                }),
                                                                                placeholder: (provided, state) => ({
                                                                                    ...provided,
                                                                                    fontSize: '12px',
                                                                                }),
                                                                                control: provided => ({
                                                                                    ...provided,
                                                                                    fontSize: '14px'
                                                                                }),
                                                                            }}
                                                                            onChange={onChangeCurrency}
                                                                            options={content.currencies}
                                                                            className={`form-control ${errors.currency ? 'is-invalid' : ''}`}

                                                                        >

                                                                        </Select>
                                                                    )}
                                                                    control={control}
                                                                    rules={{ required: true }}
                                                                    name="currency"
                                                                />

                                                                <div className="invalid-feedback">{errors.currency?.message}</div>
                                                            </div>
                                                            <div className="col-md-4 form-group">
                                                                <label className="control-label inputlabel">{t("ExchangeRate")}</label>
                                                                <input
                                                                    name="exchangeRate"
                                                                    maxLength={20}
                                                                    {...register('exchangeRate', { maxLength: 20 })}
                                                                    className={`form-control ${errors.exchangeRate ? 'is-invalid' : ''}`}
                                                                />
                                                                <div className="invalid-feedback">{errors.exchangeRate?.message}</div>
                                                            </div>


                                                            <div className="col-md-4 form-group">
                                                                <label className="control-label inputlabel">{t("sendPlace")}</label>
                                                                <input
                                                                    name="sendPlace"
                                                                    defaultValue={t("ourPlace")}
                                                                    maxLength={60}
                                                                    {...register('sendPlace')}
                                                                    className={`form-control ${errors.sendPlace ? 'is-invalid' : ''}`}
                                                                />
                                                                <div className="invalid-feedback">{errors.sendPlace?.message}</div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>


                                                <div className="col-md-3 form-group selfPricing" style={{ display: 'none' }}>
                                                    <div> <br></br></div>
                                                    <label className="control-label inputlabel">{t("selfPricing")}
                                                        <Tippy trigger="click" content={<span className="tooltip-skin">{t("selfPricingInfo")}</span>}>
                                                            <i className="fa fa-x2 fa-info-circle infoicon"></i>
                                                        </Tippy>
                                                    </label>

                                                    <div className="input-group mb-5">
                                                        <input
                                                            name="selfPricing"
                                                            type="checkbox"
                                                            {...register('selfPricing', { required: false })}
                                                            onChange={onChangeSelfPricing}
                                                            className={`form-check-input ${errors.selfPricing ? 'is-invalid' : ''}`}
                                                        />
                                                        <div className="invalid-feedback">{errors.selfPricing?.message}</div>
                                                    </div>


                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>


                            {isPreSale ? <div className="alert alert-warning alert-dismissible fade show" role="alert">
                                <div> {t("preSaleProductSuggestedClassificationsNotice")}</div>
                            </div> : <></>}

                            <div className="row" style={{ display: entriesHidden }}>

                                <SaleEntries ref={refSalesEntries}
                                    selectedInvoiceType={getValues().invoiceType}
                                    onChangeContact={onChangeContact}
                                    customerContacts={customerContacts}
                                    incomeClassCategories={suggestedInClassCatsOfInvType}
                                    invoiceClassCategories={suggestedInvoiceClassCatsOfInvType}
                                    vatCategoryExceptions={content.vatCategoryExceptions}
                                    otherTaxes={content.otherTaxes}
                                    withHoldTaxes={content.withHoldTaxes}
                                    measurementUnits={content.measurementUnits}
                                    selfPricing={selfPricingChecked}
                                    moreTaxesOptions={moreTaxesOptions}
                                    moreTaxesTypesOptions={moreTaxesTypesOptions}
                                    reCalculateTotals={reCalculateTotals}
                                    isInvoice00_1={isInvoice00_1}
                                    vats={content.vats}>

                                </SaleEntries>
                                <div className="text-center mobile-only" style={{ marginLeft: "42%", marginTop: 10 }}>
                                    <span className="scroll-sign">
                                        <i className="fa fa-2x fa-angle-left"></i>
                                        <i className="fa fa-2x fa-hand-o-up"> </i>
                                        <i className="fa fa-2x fa-angle-right"></i>
                                    </span>
                                </div>

                            </div>

                            <div className="card customerCard" style={{ display: otherTaxHidden }}>
                                <div className="card-header" style={{ backgroundColor: "#013289" }}>

                                    <label style={{ color: "white" }} className="control-label inputlabel">  {t("taxInfo")}
                                    </label>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-4 selectInputContainer form-group required">
                                            <label className="control-label inputlabel">{t("TaxType")} </label>
                                            {/* <Select
                                                autosize={true}
                                                // menuIsOpen={true}
                                                // defaultValue={selectedVal}
                                                onChange={onOtherTaxesSelectChange}
                                                onChangeValue={onOtherTaxesSelectChange}
                                                options={otherTaxesOptions}>
                                            </Select>

 */}


                                            <Controller
                                                render={({ field }) => (
                                                    <Select
                                                        styles={{
                                                            menu: (provided, state) => ({
                                                                ...provided,
                                                                minWidth: 250,
                                                                border: '4px #333',
                                                                zIndex: 9999
                                                            }),
                                                            indicatorsContainer: (provided, state) => ({
                                                                ...provided,
                                                                padding: '0px',
                                                            }),
                                                            placeholder: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '12px',
                                                            }),
                                                            control: provided => ({
                                                                ...provided,
                                                                fontSize: '14px'
                                                            })
                                                        }}  {...field}
                                                        onChange={onOtherTaxesSelectChange}
                                                        inputId="otherTax-react-select"
                                                        options={otherTaxesOptions}
                                                        className={`form-control ${errors.otherTaxCategory ? 'is-invalid' : ''}`}
                                                        placeholder={t('Select')}
                                                    >

                                                    </Select>
                                                )}
                                                control={control}
                                                name="otherTaxCategory"
                                            />




                                        </div>
                                        <div className="col-md-4 form-group required">
                                            <label className="control-label inputlabel">{t("amountAccomodationTax")} </label>
                                            <input
                                                name="otherTaxAmount"
                                                type="text"
                                                {...register('otherTaxAmount')}
                                                onBlur={onOtherTaxAmountChange}
                                                className={`form-control ${errors.otherTaxAmount ? 'is-invalid' : ''}`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div><hr /></div>
                            <div className="row">

                                <div className="col-md-3 form-group">
                                    <label className="control-label inputlabel">{t("Notes")}
                                        <Tippy trigger="click" content={<span className="tooltip-skin">{t("notesInfo")}</span>}>
                                            <i className="fa fa-x2 fa-info-circle infoicon"></i>
                                        </Tippy>
                                        <i className="fa fa-trash" style={{ cursor: "pointer", marginLeft: 4 }} onClick={cleanNotes}></i>
                                    </label>
                                    <textarea style={{ fontSize: 14 }}
                                        name="notes"
                                        maxLength="400"
                                        {...register('notes', { maxLength: 400 })}
                                        autoComplete="on"
                                        rows={4}
                                        className={`form-control ${errors.notes ? 'is-invalid' : ''}`}
                                        placeholder={t('Notes')}
                                    />
                                    <div className="invalid-feedback">{errors.notes?.message}</div>
                                </div>

                                <div className="col-md-3 selectInputContainer form-group">
                                    <label className="control-label inputlabel">{t('PaymentTypes')}</label>
                                    <Controller
                                        render={({ field }) => (
                                            <CreatableSelect {...field}
                                                styles={{
                                                    indicatorsContainer: (provided, state) => ({
                                                        ...provided,
                                                        padding: '0px',
                                                    }),
                                                    placeholder: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px',
                                                    }),
                                                    control: provided => ({
                                                        ...provided,
                                                        fontSize: '14px'
                                                    }),
                                                }}
                                                isClearable
                                                onChange={onChangePaymentType}
                                                options={convertPaymentTypes(content.paymentTypes)}
                                                className={`form-control ${errors.paymentType ? 'is-invalid' : ''}`}

                                            >

                                            </CreatableSelect>
                                        )}
                                        control={control}
                                        rules={{ required: true }}
                                        name="paymentType"
                                    />

                                    <div className="invalid-feedback">{errors.paymentType?.message}</div>
                                </div>



                                <div id="balance_container" className="col-md-2 form-group" style={{ display: "none" }}>
                                    <label className="control-label inputlabel">{t("PaidAmount")}</label>
                                    <input
                                        name="balance"
                                        type="text"
                                        {...register('balance')}
                                        onBlur={e => onBalanceChange(e)}
                                        className={`form-control ${errors.balance ? 'is-invalid' : ''}`}
                                    />
                                    <div className="invalid-feedback">{errors.balance?.message}</div>
                                </div>


                                <div className="col-md-3 selectInputContainer form-group">
                                    <label className="control-label inputlabel">{t("customerContacts")}
                                        <Tippy trigger="click" content={<span className="tooltip-skin">{t("ccInfo")}</span>}>
                                            <i className="fa fa-x2 fa-info-circle infoicon"></i>
                                        </Tippy>
                                    </label>

                                    <Controller
                                        render={({ field }) => (
                                            <SelectTable {...field}
                                                onChange={onChangeContact}
                                                onChangeValue={onChangeContact}
                                                onInputChange={onInputChangeContact}
                                                isMulti={true}
                                                options={customerContacts}
                                                createTable={true}
                                                className={`form-control ${errors.customerContact ? 'is-invalid' : ''}`}
                                                placeholder={t('Select')}
                                            >

                                            </SelectTable>
                                        )}
                                        control={control}
                                        name="customerContacts"
                                        // onKeyDown={(e) => { e.keyCode === 13 && e.preventDefault(); }}
                                        defaultValue=""
                                    />


                                    <div className="invalid-feedback">{errors.customerContact?.message}</div>
                                    <input type="checkbox" onChange={e => setSendPaymentLink(e.target.checked)} name="sendPaymentLink" checked={sendPaymentLink} className="form-check-input" />{t("sendPaymentLink")}

                                </div>



                            </div>
                            <br></br>

                            <div className="row">
                                <div className="col-md-6 form-group">
                                    <div className="accordion" id="taxesMoreAccordion">
                                        <div className="accordion-item " style={{ padding: 0 }}>

                                            <label className="accordion-header" style={{ width: 200 }} id="headingOne">

                                                <a style={{ paddingLeft: 0, paddingRight: 0 }} className={initiallyCollapsed.collapsed ? "accordion-button nonhilight collapsed" : "accordion-button nonhilight"} href="#" data-bs-toggle="collapse" data-bs-target="#collapseTaxes" aria-expanded="false" aria-controls="collapseOne">
                                                    {t("moreTaxes")}
                                                    <Tippy trigger="click" content={<span className="tooltip-skin">{t("moreTaxesInfo")}</span>}>
                                                        <i className="fa fa-x2 fa-info-circle infoicon"></i>
                                                    </Tippy>
                                                </a>

                                            </label>

                                            <div id="collapseTaxes" className={initiallyCollapsed.show ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby="headingOne" data-bs-parent="#taxesMoreAccordion">
                                                <div className="accordion-body" style={{ padding: 0 }}>
                                                    <MoreTaxesGrid
                                                        ref={saleMoreTaxesGridRef}
                                                        moreTaxesTypesOptions={moreTaxesTypesOptions}
                                                        moreTaxesOptions={moreTaxesOptions}
                                                        rowTotals={totals.totalWithoutVat}
                                                        reCalculateTotals={reCalculateTotals}
                                                    >
                                                    </MoreTaxesGrid>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="offset-md-1 col-md-2 selectInputContainer templateLangContainer form-group">

                                    <label className="control-label inputlabel"> {t("langTemplate")}</label>

                                    <Controller
                                        render={({ field }) => (
                                            <ReactFlagsSelect
                                                width={100}
                                                alignOptionsToRight
                                                name="templateLang"
                                                selected={selectedTemplateLanguage}
                                                onSelect={onSelectTemplateLanguage}
                                                customLabels={{ GR: "GR", GB: "GB" }}
                                                countries={["GR", "GB"]}
                                                fullWidth={false}
                                            />

                                        )}
                                        control={control}
                                        name="templateLang"
                                        defaultValue="GR"
                                    />

                                </div>




                                <div className="col-md-3 totalsparent" >
                                    <EntriesSummary ref={refEntriesSummary} onUpdateComplete={(data) => setTotals(data)}></EntriesSummary>
                                </div>

                            </div>
                            <IFramePrintTemplate style={{ width: 800, height: "297mm", left: -1000, top: 0, position: "fixed", zIndex: -100 }} extracss={template.css} template={template} ref={iframePrintTemplate} ></IFramePrintTemplate>
                        </div>

                    </FormLayout>

                    <div className="card footer salefooter">
                        <div className="container-fluid">
                            <div className="text-center">
                                <div className="container">

                                    <div className="row">

                                        {isInvoice00_1 ? <div className="col-md-1"></div> :
                                            <div className="sale_action offset-md-1 col-md-1">
                                                <div className="form-switch">
                                                    <input className="form-check-input" checked={mydata == undefined ? false : mydata} onChange={hangleChangeMyData} type="checkbox" id="flexSwitchCheckDefault"></input>
                                                </div>
                                                <label className="form-check-label " htmlFor="flexSwitchCheckDefault">
                                                    <Tippy trigger="click" content={<span className="tooltip-skin">{t("myDataInfo")}</span>}>
                                                        <i className="fa fa-x2 fa-info-circle infoicon"></i>
                                                    </Tippy>
                                                    <img style={{ width: 60 }} src={MyDataLogo}></img>

                                                </label>
                                            </div>
                                        }

                                        <div className="sale_action col-md-3">
                                            <button type="submit" className="btn btn-skin btn-lg">
                                                <i className="fa fa-save"></i>   {t("Publication")}
                                            </button>
                                        </div>


                                        <div className="sale_action col-md-3">
                                            <button onClick={handleSubmit(handlePrintAndSave, onError)} className="btn btn-skin btn-lg">
                                                <i className="fa fa-save"></i>  <i className="fa fa-print"></i> {t("PublicationAndPrint")}
                                            </button>
                                        </div>

                                        <div className="sale_action col-md-3">
                                            <button title={t("printbtnInfo")} onClick={handlePrint} className="btn btn-secondary">
                                                <i className="fa fa-eye"></i>    {t("printPreview")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </form>
                <div><br></br></div>
                <ErrorDetailsModal ref={refErrorDetailsModal}></ErrorDetailsModal>
            </div>
        </BlockUi >
    );
}

export default SalesAddEdit;