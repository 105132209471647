import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef } from "react";
import ApiClient from "../../services/api-client"
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { useHistory } from "react-router-dom"
import { CustomConfirmAlert as confirmAlert } from '../custom-confirm-alert';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ReactDOM from 'react-dom';


const IntValRenderer = forwardRef((props, ref) => {


    const [name, setName] = useState(props.value);

    useImperativeHandle(ref, () => {
        return {
            refresh(params) {
                var val = props.getValue();
                if (val != undefined) {
                    setName(val);
                }

            }
        };
    });
    return (
        <span className="editableCell">{name != undefined ? name : <div><span className="addtext"></span><div className="addtextafter"><i className="fa fa-edit"></i></div></div>}</span>

    );
});


const IntValEditor = forwardRef((props, ref) => {


    const [selectedVal, setSelectedVal] = useState(props.value);
    const [editing, setEditing] = useState(true);
    const refContainer = useRef(null);
    useEffect(() => {
        focus();
    }, []);

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return selectedVal != null ? selectedVal : props.value;
            },

            isPopup() {
                return true;
            }
        };
    });

    useEffect(() => {
        if (!editing) {
            props.api.stopEditing();
        }
    }, [editing]);

    const focus = () => {
        window.setTimeout(() => {
            let container = ReactDOM.findDOMNode(refContainer.current);
            if (container) {
                container.focus();
            }
        });
    };


    const onInputChange = val => {
        if (Number.isInteger(parseInt(val.target.value))) {
            setSelectedVal(parseInt(val.target.value).toString());
        }

    }



    return (

        <input type="text" onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
            }
        }} pattern="[0-9]*" className="ag-cell-inline-editing"
            defaultValue={selectedVal} ref={refContainer}
            onChange={onInputChange}>
        </input>
    );
});


const SimpleTextRenderer = forwardRef((props, ref) => {


    const [name, setName] = useState(props.value);

    useImperativeHandle(ref, () => {
        return {
            refresh(params) {
                var val = props.getValue();
                debugger
                if (val !== undefined) {
                    setName(val);
                }
            }
        };
    });
    return (
        <span className="editableCell">{name !== undefined ? name : <div><span className="addtext"></span><div className="addtextafter">...</div></div>}</span>
    );
});
// const ImageRenderer = forwardRef((props, ref) => {


//     const [logo, setLogo] = useState(props.value);

//     useImperativeHandle(ref, () => {
//         return {
//             refresh(params) {
//                 var val = props.getValue();
//                 if (val !== undefined) {
//                     setLogo(val);
//                 }
//             }
//         };
//     });
//     return (
//         <span className="editableCell">{logo ? <img style={{ objectFit: "scale-down", width: 60 }} src={logo}></img> : <img style={{ objectFit: "scale-down", width: 60 }} src={default_logo}></img>}</span>
//     );
// });
const ActionsRenderer = forwardRef((props, ref) => {


    const [disabled, setDisabled] = useState(false);
    const { t } = useTranslation();

    const handleInputChange = (e) => {
        e.preventDefault();
        let rowData = [];
        props.agGridReact.api.forEachNode(node => rowData.push(node.data));
        confirmAlert({
            title: t("submitConfirm"),
            message: t("areYouSure"),
            buttons: [
                {
                    label: t('Yes'),
                    onClick: () => {
                        var id = props.data.id;
                        if (id != undefined) {
                            var companyId=JSON.parse(localStorage.getItem("company")).id;
                            ApiClient.deleteCompanyBranch(companyId,id).then(
                                response => {
                                    rowData.splice(props.rowIndex, 1)
                                    props.agGridReact.api.setRowData(rowData)
                                    toast.success(t("deleteSucceded"))

                                },
                                error => {
                                    toast.error("Error: " + error.response.data.message)
                                    console.error(error.response)
                                }
                            )
                        }
                        else {
                            rowData.splice(props.rowIndex, 1)
                            props.agGridReact.api.setRowData(rowData)
                        }

                    }
                },
                {
                    label: t('No'),
                    onClick: () => {
                    }
                }
            ]
        });


    }
    useImperativeHandle(ref, () => {
        var rowNode = props.agGridReact.api.getRowNode(props.rowIndex);
        var countDisplayedRows = props.agGridReact.api.getDisplayedRowCount() - 1;
        if (props.rowIndex == countDisplayedRows) {
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }
        return {
            refresh(params) {

            }
        };
    });
    return (
        <span><button onClick={handleInputChange} disabled={disabled} className="btn btn-sm btn-danger circle-btn"><i className="fa fa-times"></i></button></span>
        // <span className="editableCell">{branch != null && branch.label != undefined ? branch.label : <span className="addtext"></span>}</span>
    );
});
// const ImageEditor = forwardRef((props, ref) => {


//     const [selectedVal, setSelectedVal] = useState(props.value);
//     const [editing, setEditing] = useState(true);
//     const refContainer = useRef(null);
//     useEffect(() => {
//         focus();

//     }, []);

//     useImperativeHandle(ref, () => {
//         return {
//             getValue() {
//                 return selectedVal != null ? selectedVal : props.value;
//             },

//             isPopup() {
//                 return true;
//             }
//         };
//     });

//     useEffect(() => {
//         if (!editing) {
//             props.api.stopEditing();
//         }
//     }, [editing]);

//     const focus = () => {
//         window.setTimeout(() => {
//             let container = ReactDOM.findDOMNode(refContainer.current);
//             if (container) {
//                 container.focus();
//             }
//         });
//     };
//     const handleChangeStatus = ({ meta, file }, status) => {
//         if (status == "done") {
//             setSelectedVal(meta.previewUrl)
//             props.node.setDataValue("logo", meta.previewUrl)
//             props.node.setDataValue("logofile", file)
//             props.api.stopEditing();
//             // setLogoFile(file);
//             // setLogoPath(meta.previewUrl);
//         }
//     }


//     const refDropZone = useRef();
//     const onMouseOver = e => {
//         // refContainer.current.setState({ isOpen: true })
//         let container = ReactDOM.findDOMNode(e.target).parentNode.getElementsByTagName("input")[0];
//         if (container != undefined) {
//             container.focus();

//         }
//     }

//     return (<div style={{ width: props.column.actualWidth + 'px' }}>

//         <span onMouseOver={onMouseOver} className="selectCell">
//             <Dropzone
//                 onChangeStatus={handleChangeStatus}
//                 maxFiles={1}
//                 multiple={false}
//                 canCancel={false}
//                 ref={refDropZone}
//                 accept="image/*"
//                 inputContent={(files, extra) => ('Click to upload logo')}
//                 styles={{
//                     dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
//                     inputLabel: (files, extra) => ({ fontSize: 14 }),
//                 }}
//             />
//         </span>
//     </div>
//     );
// });
const CompanyBranchesGrid = forwardRef((props, ref) => {

    const [content, setContent] = React.useState(0);
    const history = useHistory();
    const [gridApi, setGridApi] = useState(null);
    const rowStyle = { background: 'white' };
    const { t, i18n } = useTranslation();
    const [hasError, setHasError] = useState(false);



    const onGridReady = params => {
        setGridApi(params.api);

        // params.api.sizeColumnsToFit();
        window.addEventListener('resize', function () {
            setTimeout(function () {
                // params.api.sizeColumnsToFit();
            });
        });

        var data = params.api.gridOptionsWrapper.gridOptions;
        if (data != undefined && data.length > 0) {
            data.push({ id: undefined, name: undefined, address: undefined })
            params.api.setRowData(data)
        }
        else {
            data = [];
            data.push({ id: undefined, name: undefined, address: undefined })
            params.api.setRowData(data)
        }

    };


    const isValid = (nodeData) => {
        return nodeData.name != undefined
            && nodeData.name != ""
            && nodeData.address != undefined
            && nodeData.name != ""
    }

    const getRowStyle = params => {

        if (params.node.rowIndex == params.api.rowModel.rowsToDisplay.length - 1) {
            return { background: '#d2f6fac7' };
        }
        else {
            if (!isValid(params.node.data)) {
                setHasError(true)
                return { background: '#ffb09c' };
            }
            else {
                setHasError(false)
                return { background: 'white' };
            }

        }
    };



    const onCellValueChanged = (params) => {
        var nodeIndex = params.node.rowIndex;
        var countDisplayedRows = gridApi.getDisplayedRowCount() - 1;
        var rowNode = gridApi.getRowNode(nodeIndex);

        if (nodeIndex == countDisplayedRows && params.newValue != undefined) {
            if (params.column != undefined) {
                var colid = params.column.colId;

                switch (colid) {
                    case "name": {
                        debugger;
                        rowNode.setDataValue("branchNumber","0");
                        break;
                    }
                     case "address": {
                        debugger;
                        rowNode.setDataValue("branchNumber","0");
                        break;
                    }
                    
                }
    
            }
            addRow(nodeIndex);
        }

    }
    const addRow = () => {

        var data = { name: undefined, address: undefined, branchNumber:undefined,subjectField: undefined, email: undefined, phone: undefined };
        let rowData = [];
        gridApi.forEachNode(node => rowData.push(node.data));
        rowData.push(data)
        gridApi.setRowData(rowData)
        var countDisplayedRows = gridApi.getDisplayedRowCount() - 1;
        gridApi.ensureIndexVisible(countDisplayedRows, 'top');
    }

    useImperativeHandle(ref, () => ({

        getData() {
            var items = [];
            gridApi.forEachNode(node => {
                items.push(node.data);
            });
            items.pop();
            return items
        },
        hasError() {
            return hasError;
        },
        setData(data) {
            var newseries = [];
            data.forEach(element => {
                newseries.push(element);
            });
            debugger
            newseries.push({ name: undefined, id: undefined, branch: { value: undefined, label: undefined }, branchNumber:undefined,startNumber: undefined, phone: undefined, email: undefined });
            gridApi.setRowData(newseries)

        }

    }));


    return (

        <div style={{ width: '100%', height: '100%' }}>
            

            <div className="row">
                <div className="offset-md-4 col-md-4">
                    <div className="text-center">
                        <h5>{t("CompanyBranches")}</h5>
                    </div>
                </div>
                {/* <div className="offset-md-1 col-md-3">
                    <div className="alert alert-warning" style={{ padding: 4 }} role="alert">
                        <label> <i data-tip data-for="myDataInfo" className="fa fa-x2 fa-info-circle infoicon" data-bs-toggle="tooltip" data-bs-placement="top"></i>{t("companyLogo")}<span style={{ fontSize: 12 }}> ({t("suggestedDims")}: 340x60 px)</span></label>
                    </div>
                </div> */}
            </div>


            <div className="ag-theme-alpine editableSmGrid branchesGrid" style={{ height: 200, width: '100%' }}>
                <AgGridReact
                    onGridReady={onGridReady}
                    rowStyle={rowStyle}
                    getRowStyle={getRowStyle}
                    style={{ width: '100%', height: '300px' }}
                    onCellValueChanged={onCellValueChanged}
                    stopEditingWhenGridLosesFocus={true}
                    borders={true}
                    singleClickEdit={true}
                    rowData={props.branches}
                    defaultColDef={{
                        flex: 1,
                        resizable: false,
                        sortable: false,
                        wrapText: true,
                        suppressMovable:true,
                    }}
                    frameworkComponents={{
                        actionsRenderer: ActionsRenderer,
                        simpleTextRenderer: SimpleTextRenderer,
                        intValRenderer: IntValRenderer,
                        intValEditor: IntValEditor
                    }}
                >
                    <AgGridColumn hide={true} field="logofile"></AgGridColumn>
                    <AgGridColumn flex={3} minWidth={200} headerClass="required" editable={true} cellRenderer="simpleTextRenderer" headerName={t("branchName")} field="name"></AgGridColumn>
                    <AgGridColumn flex={3} minWidth={200} headerClass="required" options={content.optionBranches} cellRenderer="simpleTextRenderer" editable={true} headerName={t("Address")} field="address"></AgGridColumn>
                    <AgGridColumn flex={1} minWidth={100}  headerClass="required" options={content.optionBranches} cellEditor="intValEditor" cellRenderer="intValRenderer" headerTooltip={t("branchNameInfo")} headerComponentParams={{ template: "<span><i class='fa fa-info-circle percentInfoIcon'></i>" + t("BranchNumber") + "</span>" }}  editable={true} headerName={t("BranchNumber")}  field="branchNumber"></AgGridColumn>
                    <AgGridColumn flex={3} minWidth={200} options={content.optionBranches} cellRenderer="simpleTextRenderer" editable={true} headerName={t("subjectField")}  field="subjectField"></AgGridColumn>
                    <AgGridColumn flex={2} minWidth={200} options={content.optionBranches} cellRenderer="simpleTextRenderer" editable={true} headerName={t("Email")}  field="email"></AgGridColumn>
                    <AgGridColumn flex={2} minWidth={200} options={content.optionBranches} cellRenderer="simpleTextRenderer" editable={true} headerName={t("Phone")}  field="phone"></AgGridColumn>

                    {/* <AgGridColumn flex={1} headerComponentParams={{template:"<span><i class='fa fa-info-circle percentInfoIcon'></i>"+t("Logo")+"</span>"}}  minWidth={100}  headerTooltip={t("suggestedDims2")} editable={true} cellEditor="imageEditor" cellRenderer="imageRenderer" headerName={t("Logo")} field="logo"></AgGridColumn> */}
                    <AgGridColumn flex={1} minWidth={100} headerName={t("Actions")} cellRenderer="actionsRenderer" field="action"></AgGridColumn>


                </AgGridReact>
            </div>

        </div>
    );
})

export default CompanyBranchesGrid



