import React, { useState, useEffect, useRef } from "react";
import { useParams } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ApiClient from "../services/api-client"
import { CustomConfirmAlert as confirmAlert } from '../components/custom-confirm-alert';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import FormLayout from '../components/form-layout'
import BlockUi from 'react-block-ui';


export default function AccountantAssignment() {


  const { id } = useParams();
  const { t } = useTranslation();
  const [blockuiLoading, setBlockuiLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    username: Yup.string().required(t("fieldRequired"))
      .max(80, t("maxLengthValidation", { maxlength: 80 }))
  });


  const [content, setContent] = useState({});
  const { register, getValues, control, setError, handleSubmit, setValue, reset, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema)
  });


  const history = useHistory();

  const onSubmit = (accountant, e) => {

    e.preventDefault();
    setBlockuiLoading(true)
    var company = JSON.parse(localStorage.getItem("company"));

    ApiClient.searchAccountant(accountant.username).then(

      response => {
        var user = response.data;
        if (user) {
          confirmAlert({
            title: t("submitConfirm"),
            message: t("areYouSureForAssignment", { "name": user.name }),
            buttons: [
              {
                label: t('Yes'),
                className: "accept",
                onClick: () => ApiClient.accountantAssignment(company.id, accountant.username).then(

                  response => {
                    if (response.data) {
                      toast.success("accountant assigned")
                    }
                    else {
                      toast.success("Some error occured")
                    }
                    setBlockuiLoading(false)
                  },
                  error => {
                    toast.error("Error: " + error.response.data.message)
                    console.error(error.response.data)
                    setBlockuiLoading(false)
                  }
                )
              },
              {
                label: t('No')
              }
            ]
          });
        }
        else {
          toast.error("Error: User not exist")
        }
      },
      error => {
        toast.error("Error: " + error.response.data.message)
        console.error(error.response.data)
      }
    )
  }

  useEffect(() => {
    let mounted = true;
    var companyId = JSON.parse(localStorage.getItem("company")).id;
    if (mounted) {

      ApiClient.getCompanyAccountant(companyId).then(
        (response) => {
          var accountant = response.data;
          setValue("username", accountant.email)
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage
          });
        }
      );
    }
    return () => mounted = false;
  }, []);

  const unassign = (e) => {
    e.preventDefault();
    var companyId = JSON.parse(localStorage.getItem("company")).id;
    var email = getValues().username;
    ApiClient.accountantUnassignment(companyId, email).then(

      response => {
        if (response.data) {
          toast.success("accountant unassigned");
          setValue("username", null)
        }
        else {
          toast.success("Some error occured")
        }
      },
      error => {
        toast.error("Error: " + error.response.data.message)
        console.error(error.response.data)
      }
    );
  }


  return (
    <div className="container">
      <BlockUi tag="div" blocking={blockuiLoading}>

        <form autoComplete="off" id="product_form" onSubmit={handleSubmit(onSubmit)}>
          <FormLayout
            title={t('accountantAssignmnent')}
            footer={
              <div className="form-group text-center">
                <button type="submit" className="btn btn-skin">
                  {t("assign")}
                </button>
                <button onClick={unassign} className="btn btn-danger">
                  {t("unassign")}
                </button>
              </div>
            }
          >
            <div className="alert alert-primary" role="alert">
              <div> {t("accountantAssignInfo")} <a href="https://appacc.timologic.gr">https://appacc.timologic.gr</a></div>
              <div> {t("accountantAssignInfo2")}</div>
            </div>

            <div className="row">
              <div className="col-md-4  form-group required">
                <label className="control-label inputlabel">{t("Timologic Accountant Email")}</label>
                <input
                  name="username"
                  type="text"
                  {...register('username', { required: true, maxLength: 64 })}
                  onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                  className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                />
                <div className="invalid-feedback">{errors.name?.message}</div>
              </div>

            </div>



          </FormLayout>

        </form>
      </BlockUi>
    </div>
  );
}