import React, { forwardRef, useRef, useState, useImperativeHandle } from "react";
import { useTranslation } from 'react-i18next';
import ReactTooltip from "react-tooltip";
import ReactDOM from 'react-dom';
import Commons from '../../util/commons';

// export default function EntriesSummary(props) {
const EntriesSummary = forwardRef((props, ref) => {
    const refContainerTotals = useRef();
    const { t } = useTranslation();


    const [totals, setTotals] = useState({
        totalWithoutDiscount: "0.00",
        totalWithDiscount: "0.00",
        totalWithoutVat: "0.00",
        totalWithVat: "0.00",
        totalPayment: "0.00",
        totalvat: "0.00"
    });


    useImperativeHandle(ref, () => ({

        calculate(nodes, saletaxes = null) {
            var _totalWithoutDiscount = 0;
            var _totalWithDiscount = 0;
            var _totalWithoutVat = 0;
            var _totalWithVat = 0;
            var totalDiscount = 0;
            var totalWithHoldTaxes = 0;
            var totalVirtualWithHoldTaxes = 0;
            var totalOtherTaxes = 0;
            var totalStumpOfDutyTaxes = 0;
            var totalFeesTaxes = 0;
            var totalDeductionsTaxes = 0;
            var totalVirtualDeductionsTaxes = 0;
            var totalvat = 0;
            nodes.entries.forEach(node => {
                if (node.product.model != undefined) {
                    var _quant = node.quantity;
                    if (_quant == "-") {
                        _quant = 1;
                    }
                    var _vat = node.vat.label;
                    if (node.vat.label == "-") {
                        _vat = 0;
                    }
                    _totalWithoutDiscount += parseFloat(node.unitPrice) * parseFloat(_quant);
                    var rowPrice = parseFloat(node.unitPrice) * parseFloat(_quant);
                    totalDiscount += rowPrice * node.discount / 100;
                    var priceWithoutVat = node.unitPrice * _quant;
                    var discount = node.discount
                    priceWithoutVat -= discount * priceWithoutVat / 100
                    _totalWithoutVat += parseFloat(parseFloat(priceWithoutVat).toFixed(2));
                    var priceWithVat = parseFloat(parseFloat(priceWithoutVat + priceWithoutVat * _vat / 100).toFixed(2));
                    priceWithVat = parseFloat(priceWithVat)
                    _totalWithVat += priceWithVat;
                    if (node.taxes && node.taxes.length > 0) {
                        node.taxes.forEach(e => {
                            if (saletaxes == null || saletaxes == undefined || saletaxes.length == 0) {
                                var am_val = e.myDataMoreTax.amountValue.replace("%", "");
                                var _calcAmount = am_val * priceWithoutVat / 100;
                                if (e.myDataMoreTax.type == "WITHHOLD_TAXES") {
                                    if (e.myDataMoreTax.uid != 24) {
                                        if (e.amount && !Number.isNaN(e.amount)) {
                                            totalVirtualWithHoldTaxes += parseFloat(e.amount)
                                        }
                                        else {
                                            totalVirtualWithHoldTaxes += _calcAmount;
                                        }
                                    }
                                    if (e.amount && !Number.isNaN(e.amount)) {
                                        totalWithHoldTaxes += parseFloat(e.amount)
                                    }
                                    else {
                                        totalWithHoldTaxes += _calcAmount;
                                    }
                                }
                                else if (e.myDataMoreTax.type == "OTHER_TAXES") {
                                    if (e.amount && !Number.isNaN(e.amount)) {
                                        totalOtherTaxes += parseFloat(e.amount)
                                    }
                                    else {
                                        totalOtherTaxes += _calcAmount;
                                    }
                                }
                                else if (e.myDataMoreTax.type == "FEES") {
                                    if (e.amount && !Number.isNaN(e.amount)) {
                                        totalFeesTaxes += parseFloat(e.amount)
                                    }
                                    else {
                                        totalFeesTaxes += _calcAmount;
                                    }
                                }
                                else if (e.myDataMoreTax.type == "DEDUCTION") {
                                    if (e.myDataMoreTax.isSubtracted) {
                                        if (e.amount && !Number.isNaN(e.amount)) {
                                            totalVirtualDeductionsTaxes += parseFloat(e.amount)
                                        }
                                        else {
                                            totalVirtualDeductionsTaxes += _calcAmount;
                                        }
                                    }
                                    if (e.amount && !Number.isNaN(e.amount)) {
                                        totalDeductionsTaxes += parseFloat(e.amount)
                                    }
                                    else {
                                        totalDeductionsTaxes += _calcAmount;
                                    }
                                }
                                else if (e.myDataMoreTax.type == "STUMP_DUTY") {
                                    if (e.amount && !Number.isNaN(e.amount)) {
                                        totalStumpOfDutyTaxes += parseFloat(e.amount)
                                    }
                                    else {
                                        totalStumpOfDutyTaxes += _calcAmount;
                                    }
                                }
                            }
                        })
                    }
                    totalvat += parseFloat(parseFloat(priceWithoutVat * _vat / 100).toFixed(2));
                }
            });

            _totalWithDiscount = _totalWithoutDiscount - totalDiscount;


            if (saletaxes && saletaxes.length > 0) {
                saletaxes.forEach(e => {
                    if (e.myDataMoreTax.type == "WITHHOLD_TAXES") {
                        if (e.myDataMoreTax.uid != 24) {
                            totalVirtualWithHoldTaxes += parseFloat(e.amount)
                        }
                        totalWithHoldTaxes += parseFloat(e.amount)
                    }
                    else if (e.myDataMoreTax.type == "DEDUCTION") {
                        
                        if (e.myDataMoreTax.isSubtracted) {
                            totalVirtualDeductionsTaxes += parseFloat(e.amount)
                        }
                        totalDeductionsTaxes += parseFloat(e.amount)
                    }
                    else if (e.myDataMoreTax.type == "OTHER_TAXES") {
                        totalOtherTaxes += parseFloat(e.amount);
                    }
                    else if (e.myDataMoreTax.type == "FEES") {
                        totalFeesTaxes += parseFloat(e.amount);
                    }
                    else if (e.myDataMoreTax.type == "STUMP_DUTY") {
                        totalStumpOfDutyTaxes += parseFloat(e.amount);
                    }
                })
            }
            var totalTaxes = totalOtherTaxes + totalFeesTaxes + totalStumpOfDutyTaxes - totalWithHoldTaxes - totalDeductionsTaxes

            var totalVirtualTaxes = totalOtherTaxes + totalFeesTaxes + totalStumpOfDutyTaxes - totalVirtualWithHoldTaxes - totalVirtualDeductionsTaxes

            var _totals = {
                totalWithoutDiscount: parseFloat(_totalWithoutDiscount).toFixed(2),
                totalWithDiscount: parseFloat(_totalWithDiscount).toFixed(2),
                totalWithoutVat: parseFloat(_totalWithoutVat).toFixed(2),
                totalWithVat: parseFloat(_totalWithVat).toFixed(2),
                totalDiscount: parseFloat(totalDiscount).toFixed(2),
                moreTaxes: parseFloat(totalTaxes).toFixed(2),
                totalvat: parseFloat(totalvat).toFixed(2),
                totalPayment: parseFloat(parseFloat(_totalWithVat) + parseFloat(totalVirtualTaxes)).toFixed(2),
                totalOtherTaxes: parseFloat(totalOtherTaxes).toFixed(2),
                totalFeesTaxes: parseFloat(totalFeesTaxes).toFixed(2),
                totalStumpOfDutyTaxes: parseFloat(totalStumpOfDutyTaxes).toFixed(2),
                totalWithHoldTaxes: parseFloat(totalWithHoldTaxes + totalDeductionsTaxes).toFixed(2),
                totalDeductionsTaxes: parseFloat(totalDeductionsTaxes).toFixed(2)
            }
            return _totals;
        },

        updateUI(_totals) {

            let container = ReactDOM.findDOMNode(refContainerTotals.current).getElementsByClassName('discount');
            let container2 = ReactDOM.findDOMNode(refContainerTotals.current).getElementsByClassName('withoutdiscount');
            let container3 = ReactDOM.findDOMNode(refContainerTotals.current).getElementsByClassName('moretaxes');

            if (parseFloat(_totals.totalDiscount) == 0) {
                container[0].style.display = "none";
                container2[0].style.display = "none";
            }
            else {
                container[0].style.display = "flex";
                container2[0].style.display = "flex";
            }
            if (parseFloat(_totals.moreTaxes) == 0) {
                container3[0].style.display = "none";
            }
            else {
                container3[0].style.display = "flex";
            }

            if (props.onUpdateComplete) {
                props.onUpdateComplete(_totals);
            }

        },

        calculateAndUpdateUI(nodes, saletaxes = null) {
            var _totals = this.calculate(nodes, saletaxes);
            this.updateUI(_totals)
            setTotals(_totals);
        }

    }));

    return (
        <div ref={refContainerTotals} className="card totals" style={{ marginTop: 0, padding: 0 }}>
            <div className="card-body">
                <h5 className="card-title">{t("Totals")}</h5>

                <div className="row withoutdiscount" style={{ display: "none" }}>
                    <div className="d-flex bd-highlight" >
                        <div className="p-2 flex-grow-1 bd-highlight"><label className="total_label">{t("TotalWithoutDiscount")}</label></div>
                        <div className="p-2 bd-highlight"><label className="total_value" id="totalWithoutDiscount">{totals.totalWithoutDiscount?Commons.formatNumber(totals.totalWithoutDiscount):"0,00"}€</label></div>
                    </div>
                </div>
                <div className="row discount" style={{ display: "none" }}>
                    <div className="d-flex bd-highlight" >
                        <div className="p-2 flex-grow-1 bd-highlight"><label className="total_label">{t("TotalDiscount")}</label></div>
                        <div className="p-2 bd-highlight"><label className="total_value" id="totalWithDiscount">{totals.totalDiscount?Commons.formatNumber(totals.totalWithDiscount):"0,00"}€</label></div>
                    </div>
                </div>

                <div className="row">
                    <div className="d-flex bd-highlight">
                        <div className="p-2 flex-grow-1 bd-highlight"><label className="total_label">{t("TotalWithoutVat")}</label></div>
                        <div className="p-2 bd-highlight"><label className="total_value" id="totalWithoutVAT">{totals.totalWithoutVat?Commons.formatNumber(totals.totalWithoutVat):"0,00"}€</label></div>
                    </div>
                </div>

                <div className="row">
                    <div className="d-flex bd-highlight">
                        <div className="p-2 flex-grow-1 bd-highlight"><label className="total_label">{t("Vat")}</label></div>
                        <div className="p-2 bd-highlight"><label className="total_value" id="vat">{totals.totalvat?Commons.formatNumber(totals.totalvat):"0,00"}€</label></div>
                    </div>
                </div>

                <div className="row">
                    <div className="d-flex bd-highlight">
                        <div className="p-2 flex-grow-1 bd-highlight"><label className="total_label">{t("TotalWithVat")}</label></div>
                        <div className="p-2 bd-highlight"><label className="total_value" id="totalWithVAT">{totals.totalWithVat?Commons.formatNumber(totals.totalWithVat):"0,00"}€</label></div>
                    </div>
                </div>

                <div className="row moretaxes" style={{ display: "none" }}>
                    <div className="d-flex bd-highlight">
                        <div className="p-2 flex-grow-1 bd-highlight">
                            <label className="total_label">
                                {t("moreTaxes")}
                                <i data-tip data-for="moreTaxesTotalsInfo" style={{ color: "white" }} className="fa fa-x2 fa-info-circle infoicon" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                                <ReactTooltip className="skin-tooltip" html={true} id="moreTaxesTotalsInfo" place="top" effect="solid">
                                    {t("moreTaxesTotalsInfo")}
                                </ReactTooltip>
                            </label>
                        </div>
                        <div className="p-2 bd-highlight"> <label className="total_value" id="moreTaxes">{totals.moreTaxes?Commons.formatNumber(totals.moreTaxes):"0,00"}€</label></div>
                    </div>
                </div>
            </div>
            <div className="card-footer">
                <div className="row">
                    <div className="d-flex bd-highlight">
                        <div className="p-2 flex-grow-1 bd-highlight"><label className="noticed_label">{t("TotalPaymentAmount")}</label></div>
                        <div className="p-2 bd-highlight"><label className="noticed_value" id="totalPayment">{Commons.formatNumber(totals.totalPayment)}€</label></div>
                    </div>
                </div>
            </div>
        </div>
    );
}
);
export default EntriesSummary 