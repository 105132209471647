import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
export default forwardRef((props, ref) => {
  const [date, setDate] = useState(null);
  const [picker, setPicker] = useState(null);
  const refFlatPickr = useRef();
  const refInput = useRef();

  //*********************************************************************************
  //          LINKING THE UI, THE STATE AND AG-GRID
  //*********************************************************************************

  const onDateChanged = (date) => {
    setDate(date);
    props.onDateChanged(date);
  };

  useEffect(() => {
    setPicker(

    );
  }, []);

  useEffect(() => {
    if (picker) {
      console.log(picker)
      // picker.calendarContainer.classList.add('ag-custom-component-popup');
    }
  }, [picker]);

  useEffect(() => {
    //Callback after the state is set. This is where we tell ag-grid that the date has changed so
    //it will proceed with the filtering and we can then expect AG Grid to call us back to getDate
    if (picker) {
      // picker.setDate(date);
      console.log(picker)
    }
  }, [date, picker]);

  useImperativeHandle(ref, () => ({
    //*********************************************************************************
    //          METHODS REQUIRED BY AG-GRID
    //*********************************************************************************
    getDate() {
      //ag-grid will call us here when in need to check what the current date value is hold by this
      //component.
      return date;
    },

    setDate(date) {
      //ag-grid will call us here when it needs this component to update the date that it holds.
      setDate(date);
    },

    //*********************************************************************************
    //          AG-GRID OPTIONAL METHODS
    //*********************************************************************************

    setInputPlaceholder(placeholder) {
      if (refInput.current) {
        refInput.current.setAttribute('placeholder', placeholder);
      }
    },

    setInputAriaLabel(label) {
      if (refInput.current) {
        refInput.current.setAttribute('aria-label', label);
      }
    },
  }));

  // inlining styles to make simpler the component
  return (
    <div
      className="ag-input-wrapper custom-date-filter"
      role="presentation"
      ref={refFlatPickr}
    >
      <div >
      
        <DatePicker style={{position:"absolute",zIndex:99999}}
          // showTimeSelect
          dateFormat="dd/MM/yyyy"
          // timeIntervals="1"
          value={date}
          selected={date}
          onChange={(date) => onDateChanged(date)}
          className={"ag-input-field-input ag-text-field-input"}

        >

        </DatePicker>
      </div>

    </div>
  );
});