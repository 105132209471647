import React, { useState, useEffect, useRef } from "react";
import ApiClient from "../services/api-client"
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom"
import { toast } from 'react-toastify';


export default function TemplateSearch(props) {



    const { t } = useTranslation();
    const history = useHistory();
    const [templateSettingsSearchTable, setTemplateSettingsSearchTable] = useState();
    const getLogo = (logo) => {
        if (logo) {
            return <img className="img-thumbnail" src={logo + "?" + Math.floor(Math.random() * 1000 + 1)}></img>
        }
    }

    useEffect(() => {


        var company = JSON.parse(localStorage.getItem("company"));
        ApiClient.getCompanyBranchesFull(company.id).then(
            response => {

                var _data = response.data;
                var templateSettingsTableRows = [];
                console.log(_data)
                _data.forEach(element => {

                    var sets =
                        <div className="card col-md-4">
                            <div className="card-header">{element.branch.address}</div>
                            <div className="card-body">
                                {getLogo(element.templateSettings.logo)}
                                <h4>{element.branch.name}</h4>
                            </div>
                            <div className="card-footer text-center">
                                <button onClick={e => editTemplateSetting(e, element)} className="btn btn-skin"><i className="fa fa-edit"></i>Edit Template</button>
                            </div>
                        </div>

                    templateSettingsTableRows.push(sets);
                });
                var table = <div className="container"><div className="row">{templateSettingsTableRows}</div></div>
                setTemplateSettingsSearchTable(table);


            },
            error => {
                toast.error("Error: " + error.data)
                console.error(error.data)
            }
        );


    }, []);

    const editTemplateSetting = (e, element) => {
        e.preventDefault();
        history.push("/designer/" + element.branch.id);
    }
    return (
        <div className="container">
            <div className="row" >
                <div className="col-md-12 text-center">
                    <div className="alert " role="alert">
                        <h4>{t("templateSearchInfo")}</h4>
                    </div>
                    {templateSettingsSearchTable}
                </div>
            </div>
        </div>
    );
}
