import React, { useEffect, useState } from "react";
import ApiClient from "../services/api-client"
import { useTranslation } from 'react-i18next';

export default function VivaRedirectHandler() {

    const { t} = useTranslation();



    useEffect(() => {
        let mounted = true;
        const params = new URLSearchParams(window.location.search)
        let transactionId = params.get('t')
        let orderCode = params.get('s')
        var pack = {
            "packageId": localStorage.getItem("packId"),
            "transactionId": transactionId,
            "orderCode": orderCode
        }
        localStorage.removeItem("packId")
        if (transactionId) {
            ApiClient.UpdateUserTransaction(pack).then(
                response => {
                    localStorage.setItem("user", JSON.stringify(response.data));
                    window.location.href = "../sale";
                },
                error => {
                    console.error(
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString())
                }
            );
        }
        else {
            window.location.href = "../dashboard";
            localStorage.setItem("errorOnPayment", t("errorOnPayment"));
            console.error("Error on payment");
        }
        return () => mounted = false;

    }, []);


    return (<div></div>);
}
