import "bootstrap/dist/css/bootstrap.min.css";
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-toastify/dist/ReactToastify.css';
import "bootstrap/dist/js/bootstrap.bundle";
import "react-datepicker/dist/react-datepicker.css";
import 'font-awesome/css/font-awesome.min.css';
import 'react-dropzone-uploader/dist/styles.css'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'react-block-ui/style.css';
import 'loaders.css/loaders.min.css';
import "./styles/App.css";
import "./styles/skin.css";
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import 'react-dates/lib/css/_datepicker.css';
import 'tippy.js/dist/tippy.css'; 
import 'rc-slider/assets/index.css';
import 'pace-js'
import 'pace-js/themes/blue/pace-theme-minimal.css'


import React, { useEffect } from "react";
import { useIdleTimer } from 'react-idle-timer'
import { getCurrentUser, logout } from './util/AuthUtils';
import { useHistory } from "react-router-dom"
import MainLayout from './components/main-layout'


export default function App() {

  const history = useHistory();

  const handleOnIdle = event => {
    console.error("Iddle 15mins");
    logout();
    history.push("login")
  }

  const handleOnActive = event => {
  }

  const handleOnAction = event => {
  }


  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 1000,
    crossTab: true
  });

  useEffect(() => {
    let mounted = true;
    getCurrentUser()
      .then(response => {
        if (response == undefined) {
          localStorage.removeItem("accessToken")
        }
        else {
          localStorage.setItem("user", JSON.stringify(response.user))
          if (response.companies && response.companies.length == 1) {
            localStorage.setItem("company", JSON.stringify(response.companies[0]))
          }
        }
      }).catch(error => {
        console.error(error)
        if (error.status === 401) {
          localStorage.removeItem("accessToken")
          history.push("login")
        }

      });

    return () => mounted = false;

  }, []);

  return (
    <div  className="App Fade">
      <MainLayout />
    </div>
  );
}
