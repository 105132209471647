import React, { useEffect, useState } from 'react';
import { RedocStandalone } from 'redoc';
import { SRV_BASE_URL } from '../util/constants';



export default function Swagger(props) {

    const SWAGGER_URL = SRV_BASE_URL+"/v3/api-docs/public-api";


    return (
        <div className="innerContainer">
            <main className="flex-shrink-0">
                <RedocStandalone
                    specUrl={SWAGGER_URL}
                    options={{
                        theme: { colors: { primary: { main: '#013289' } } ,showExtensions:["x-logo"]},
                    }}
                />
            </main>
        </div>

    );

}
