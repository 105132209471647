import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import ApiClient from "../services/api-client"
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { useHistory } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import useMobileDetect from 'use-mobile-detect-hook';


const AllFieldsRenderer = forwardRef((props, ref) => {
    const { t } = useTranslation();


    useImperativeHandle(ref, () => {
        return {
            refresh(params) {

            }
        };

    });

    return (

        <div className="customer-grid-row">

            <div className="d-flex bd-highlight allFieldsColumnRow">
                <div className="p-0 bd-highlight">
                    <span >
                        {/* <Tippy trigger="click" content={"rowData"}>
                        <i className="fa fa-info-circle" style={{ marginRight: 6 }}></i>
                    </Tippy> */}
                        {props.data ? props.data.name + " " : ""}
                    </span>

                </div>
            </div>

            <div className="d-flex bd-highlight allFieldsColumnRow">
                <div className="p-0 bd-highlight">
                    <span>{t("price")}: <b>{props.data.price}</b>&euro; / {t(props.data.measurementUnit)}</span>
                </div>
            </div>

        </div >


    );
});


const ActionsRenderer = forwardRef((props, ref) => {


    const history = useHistory();
    const { t } = useTranslation();

    const handleInputChange = (e) => {
        e.preventDefault();
        var _id = props.data.id;
        history.push("/product/" + _id);
    }
    useImperativeHandle(ref, () => {
        return {
            refresh(params) {

            }
        };
    });
    return (
        <span>
            <div className="btn-group" role="group">
                <button onClick={handleInputChange} title={t("editProduct")} className="btn btn-sm btn-skin"><i className="fa fa-edit"></i></button>
            </div >
        </span>
    );
});


export default function ProductSearch() {

    const [content, setContent] = useState([]);
    const history = useHistory();
    const [gridApi, setGridApi] = useState(null);
    const { t } = useTranslation();
    const [sheight, setSheight] = useState(window.innerHeight - 180);
    const detectMobile = useMobileDetect();

    useEffect(() => {

        var companyId = JSON.parse(localStorage.getItem("company")).id;
        ApiClient.getCompanyProducts(companyId).then(
            response => {
                console.debug(response.data)
                setContent(response.data);

            },
            error => {
                setContent(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString())
            }
        );

    }, []);

    const pagination = true;
    const paginationPageSize = 20;


    const onGridReady = params => {
        setGridApi(params.api);
        window.addEventListener('resize', function () {
            setTimeout(function () {
                setSheight(window.innerHeight - 180);
            });
        });
    };
    const onQuickFilterChanged = e => {
        gridApi.setQuickFilter(e.target.value);
    };


    const goTo = id => {
        history.push("/product/" + id);
    }
    return (

        <div className="container" style={{padding:0}}>



            <header className="jumbotron search_header">
                <div className="d-flex bd-highlight">
                    <div className="me-auto p-0 bd-highlight">
                        <h5 className="subtitle skin">{t("productsSearch")}</h5>
                    </div>
                    <div className="p-0 bd-highlight">
                        <button className="btn btn-skin" href="" onClick={function () { return history.push("/product"); }}>
                            <span className="desktop-only">{t("productAdd")} </span> <i className="fa fa-plus"></i>
                        </button>
                    </div>
                </div>
            </header>


            <div style={{ width: '100%', height: '100%' }}>

                <div className="row">
                    <div className="d-flex justify-content-between">
                        <div className="offset-md-4 col-md-4 text-center">
                            <input className="form-control" id="searchProductsInput" placeholder={t("Search")} onChange={onQuickFilterChanged} />
                        </div>
                    </div>
                </div>
                {
                    detectMobile.isDesktop() ?
                        <div
                            id="myGrid"
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                            className="ag-theme-alpine-dark product-search-grid"
                        >
                            <div className="ag-theme-alpine" style={{ height: sheight, width: '100%' }}>
                                <AgGridReact
                                    pagination={pagination}
                                    paginationPageSize={paginationPageSize}
                                    suppressRowTransform={true}
                                    skipHeaderOnAutoSize={true}
                                    onGridReady={onGridReady}
                                    style={{ width: '100%', height: '100%;' }}
                                    onRowDoubleClicked={(e) => goTo(e.data.id)}
                                    rowData={content}
                                    frameworkComponents={{
                                        actionsRenderer: ActionsRenderer
                                    }}
                                    overlayNoRowsTemplate={
                                        '<span style="padding: 10px;  color:#013289">' + t('noEntries') + '</span>'
                                    }
                                    gridOptions={{ suppressMenuHide: true }}
                                    defaultColDef={{
                                        flex: 1,
                                        resizable: true,
                                        sortable: true,
                                        wrapText: true,
                                        suppressMovable: true,
                                        autoHeight: true, icons: {
                                            menu: '<i style="color:white" class="fa fa-search"/>',
                                        },
                                    }}
                                >
                                    <AgGridColumn flex={3} minWidth={200} headerName={t("Name")} sortable={true} filter="agTextColumnFilter" field="name"></AgGridColumn>
                                    <AgGridColumn flex={1} minWidth={120} headerName={t("measUnit")} sortable={true} filter="agTextColumnFilter" valueFormatter={(params) => { return t(params.value) }} field="measurementUnit"></AgGridColumn>
                                    <AgGridColumn flex={1} minWidth={120} headerName={t("Price")} sortable={true} valueFormatter={(params) => { return params.value.toFixed(2) }} filter="agNumberColumnFilter" field="price"></AgGridColumn>
                                    <AgGridColumn flex={1} minWidth={120} headerName={t("Vat")} sortable={true} filter="agNumberColumnFilter" field="vat.value"></AgGridColumn>
                                    <AgGridColumn flex={1} minWidth={120} headerName={t("priceWithVat")} sortable={true} filter="agNumberColumnFilter" valueFormatter={(params) => { return params.value.toFixed(2) }} field="priceWithVat"></AgGridColumn>
                                    <AgGridColumn flex={1} minWidth={120} headerName={t("Actions")} cellRenderer="actionsRenderer" field="action"></AgGridColumn>

                                </AgGridReact>
                            </div>

                        </div>
                        :
                        <div
                            id="myGrid"
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                            className="ag-theme-alpine-dark product-mobile-table-container"
                        >
                            <div className="ag-theme-alpine" style={{ height: sheight, width: '100%' }}>
                                <AgGridReact
                                    pagination={pagination}
                                    paginationPageSize={paginationPageSize}
                                    suppressRowTransform={true}
                                    skipHeaderOnAutoSize={true}
                                    onGridReady={onGridReady}
                                    style={{ width: '100%', height: '100%;' }}
                                    onRowDoubleClicked={(e) => goTo(e.data.id)}
                                    rowData={content}
                                    frameworkComponents={{
                                        actionsRenderer: ActionsRenderer,
                                        allFieldsRenderer: AllFieldsRenderer
                                    }}
                                    overlayNoRowsTemplate={
                                        '<span style="padding: 10px;  color:#013289">' + t('noEntries') + '</span>'
                                    }
                                    gridOptions={{ suppressMenuHide: true }}
                                    rowHeight={46}
                                    defaultColDef={{
                                        wrapText: false,
                                        suppressMovable: true,
                                        suppressHorizontalScroll: true,
                                        icons: {
                                            menu: '<i style="color:white" class="fa fa-search"/>',
                                        },
                                    }}
                                >
                                    <AgGridColumn flex={5} cellRenderer="allFieldsRenderer" headerName={t("Name")} sortable={true} filter="agTextColumnFilter" field="name"></AgGridColumn>
                                    <AgGridColumn flex={1} headerName={t("Actions")} cellRenderer="actionsRenderer" field="action"></AgGridColumn>

                                </AgGridReact>
                            </div>

                        </div>
                }

            </div>
        </div>
    );
}


