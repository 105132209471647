import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import ApiClient from "../../services/api-client"
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { useHistory } from "react-router-dom"
import { CustomConfirmAlert as confirmAlert } from '../custom-confirm-alert';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';


const ActionsRenderer = forwardRef((props, ref) => {


    const [disabled, setDisabled] = useState(false);
    const { t } = useTranslation();

    const handleInputChange = (e) => {
        e.preventDefault();
        let rowData = [];
        props.agGridReact.api.forEachNode(node => rowData.push(node.data));
        confirmAlert({
            title: t("submitConfirm"),
            message: t("areYouSure"),
            buttons: [
                {
                    label: t('Yes'),
                    onClick: () => {
                        var id = props.data.id;
                        debugger;
                        if (id != undefined) {
                            var company = JSON.parse(localStorage.getItem("company"));

                            ApiClient.deleteCustomerContact(company.id, id).then(
                                response => {
                                    rowData.splice(props.rowIndex, 1)
                                    props.agGridReact.api.setRowData(rowData)
                                    toast.success(t("deleteSucceded"))

                                },
                                error => {
                                    console.error(error.response)
                                    toast.error("Error: " + error.response.data.message)
                                }
                            )
                        }
                        else {
                            rowData.splice(props.rowIndex, 1)
                            props.agGridReact.api.setRowData(rowData)
                        }

                    }
                },
                {
                    label: t('No'),
                    onClick: () => {
                    }
                }
            ]
        });


    }
    useImperativeHandle(ref, () => {
        var rowNode = props.agGridReact.api.getRowNode(props.rowIndex);
        var countDisplayedRows = props.agGridReact.api.getDisplayedRowCount() - 1;
        if (props.rowIndex == countDisplayedRows) {
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }
        return {
            refresh(params) {

            }
        };
    });
    return (
        <span><button onClick={handleInputChange} disabled={disabled} className="btn btn-sm btn-danger"><i className="fa fa-times"></i></button></span>
        // <span className="editableCell">{branch != null && branch.label != undefined ? branch.label : <span className="addtext"></span>}</span>
    );
});





const SimpleTextRenderer = forwardRef((props, ref) => {


    const [name, setName] = useState(props.value);

    useImperativeHandle(ref, () => {
        return {
            refresh(params) {
                var val = props.getValue();
                if (val != undefined) {
                    setName(val);
                }

            }
        };
    });
    return (
        <span className="editableCell">{name  ? name : <div><span className="addtext"></span><div className="addtextafter"><i className="fa fa-edit "></i></div></div>}</span>

    );
});
const CustomerContactsGrid = forwardRef((props, ref) => {

    const [content, setContent] = React.useState(0);
    const history = useHistory();
    const [gridApi, setGridApi] = useState(null);
    const rowStyle = { background: 'white' };
    const { t, i18n } = useTranslation();
    const [hasError, setHasError] = useState(false);


    const onGridReady = params => {
        setGridApi(params.api);

        // params.api.sizeColumnsToFit();
        window.addEventListener('resize', function () {
            setTimeout(function () {
                // params.api.sizeColumnsToFit();
            });
        });

        var data = params.api.gridOptionsWrapper.gridOptions;
        if (data != undefined && data.length > 0) {
            data.push({ id: undefined, name: "", email: "" })
            params.api.setRowData(data)
        }
        else {
            data = [];
            data.push({ id: undefined, name: "", email: "" })
            params.api.setRowData(data)
        }

    };


    const isValid = (nodeData) => {
        return nodeData.name != ""
            && nodeData.name != undefined
    }

    // set background colour on even rows again, this looks bad, should be using CSS classes
    const getRowStyle = params => {


        if (params.node.rowIndex == params.api.rowModel.rowsToDisplay.length - 1) {
            return { background: '#d2f6fac7' };
        }
        else {
            if (!isValid(params.node.data)) {
                setHasError(true)
                return { background: '#ffb09c' };
            }
            else {
                setHasError(false)
                return { background: 'white' };
            }

        }
    };


    const onCellValueChanged = (params) => {

        // params.node.rowIndex
        var nodeIndex = params.node.rowIndex;
        var rowNode = gridApi.getRowNode(nodeIndex);
        var countDisplayedRows = gridApi.getDisplayedRowCount() - 1;
        if (nodeIndex == countDisplayedRows && params.newValue != undefined) {
            addRow(nodeIndex);
        }
        var rowData = [];
        gridApi.forEachNode(node => rowData.push(node.data));
        rowData.pop();

    }
    const addRow = () => {

        var data = { name: "", email: "" };
        let rowData = [];
        gridApi.forEachNode(node => rowData.push(node.data));
        rowData.push(data)
        gridApi.setRowData(rowData)
        var countDisplayedRows = gridApi.getDisplayedRowCount() - 1;
        gridApi.ensureIndexVisible(countDisplayedRows, 'top');
    }

    useImperativeHandle(ref, () => ({

        getData() {
            var items = [];
            gridApi.forEachNode(node => {
                items.push(node.data);
            });
            items.pop();
            return items
        },
        hasError() {
            return hasError;
        },
        setData(data) {
            var newseries = [];
            data.forEach(element => {
                newseries.push(element);
            });

            newseries.push({ name: undefined, id: undefined, email: undefined });
            gridApi.setRowData(newseries)

        }

    }));

    return (

        <div className="container" style={{padding:0}}>
            <div style={{ width: '100%', height: '100%' }}>
                <div className="row">
                    <div className="offset-md-4 col-md-4">

                        <div className="text-center">    {t("customerContacts")}
                            <Tippy trigger="click" content={<span className="tooltip-skin">{t("CustomerContactInfo")}</span>}>
                                <i className="fa fa-x2 fa-info-circle infoicon"></i>
                            </Tippy>
                        </div>
                    </div>
                </div>

                <div className="ag-theme-alpine editableSmGrid customerContactsGrid">
                    <AgGridReact
                        onGridReady={onGridReady}
                        rowStyle={rowStyle}
                        getRowStyle={getRowStyle}
                        style={{ width: '100%', height: '300px' }}
                        onCellValueChanged={onCellValueChanged}
                        stopEditingWhenGridLosesFocus={true}
                        borders={true}
                        singleClickEdit={true}
                        rowData={props.contacts}
                        defaultColDef={{
                            flex: 1,
                            resizable: false,
                            sortable: false,
                            wrapText: true,
                            suppressMovable:true,
                        }}
                        frameworkComponents={{
                            actionsRenderer: ActionsRenderer,
                            simpleTextRenderer: SimpleTextRenderer
                        }}
                    >
                        <AgGridColumn flex={3} minWidth={240} editable={true} headerName={t("Name")} cellRenderer="simpleTextRenderer" field="name"></AgGridColumn>
                        <AgGridColumn flex={2}  minWidth={160} editable={true} headerName={t("Phone")} cellRenderer="simpleTextRenderer" sortable={true} field="phone"></AgGridColumn>
                        <AgGridColumn flex={3}  minWidth={200} editable={true} headerName={t("Email")} cellRenderer="simpleTextRenderer" sortable={true} field="email"></AgGridColumn>
                        <AgGridColumn flex={1}  minWidth={100} headerName={t("Actions")} cellRenderer="actionsRenderer" field="action"></AgGridColumn>


                    </AgGridReact>
                </div>

            </div>
        </div>
    );
})


export default CustomerContactsGrid 
