import React, { Fragment, useState, useEffect, useRef } from "react";

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ReactCountryFlag from "react-country-flag";
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, useHistory } from 'react-router-dom';
import Logo from '../img/timologic.png';
import LogoEmpty from '../img/logo-empty.png';
import ApiClient from "../services/api-client"
import { useTranslation } from 'react-i18next';
import StartWizard from "../components/wizard/wizard.component";
import Wizard from "../components/wizard/wizard.component";
import ChooseCompanyModal from "./modals/choose-company";
import pflogo from '../img/profile.png';
import { logout } from '../util/AuthUtils';
import Commons from '../util/commons'
import { toast } from 'react-toastify';


export default function MainNavBar(props) {

    const hangleLogout = function () {
        logout();
    }
    const currentUser = JSON.parse(localStorage.getItem("user"));
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [showWizard, setShowWizard] = useState(false);
    const [showPrev, setShowPrev] = useState("hidden");
    const [showNext, setShowNext] = useState("visible");
    const [nextBtnText, setNextBtnText] = useState(t("nextStep"));
    const [showCompanyModal, setShowCompanyModal] = useState(false);
    const wizardRef = useRef();
    const [newNotifications, setNewNotifications] = useState();
    // const [playSound] = useSound(boopSfx);

    const [selectedCompany, setSelectedCompany] = useState({
        selectedCompany: { name: "" }
    });
    const [expanded, setExpanded] = useState(false);
    const params = new URLSearchParams(window.location.search)
    let urlShowWizard = params.get('wizard')

    var isAdmin = false;
    var isManager = false;
    var adminMenu = null;
    var managerMenu = null;
    var paymenu = null;

    const handleCloseWizard = () => setShowWizard(false);

    const handleCloseCompanyModal = () => {
        setSelectedCompany(JSON.parse(localStorage.getItem("company")))
        setShowCompanyModal(false)
        setTimeout(function () {
            window.location.reload();
        }, 1000)
    };
    const handleShowCompanyModal = () => {
        setShowCompanyModal(true)
    };

    const updateUserIsOnNotifyState = () => {
        ApiClient.updateUserIsOnNotifyState().then(
            response => {
                if (response != undefined) {
                    var userJson = localStorage.getItem("user")
                    var user = JSON.parse(userJson);
                    user.isOnNotifyState = false;
                    localStorage.setItem("user", JSON.stringify(user))
                    console.log(response);
                    setNewNotifications(<></>)
                }
            },
            error => {
                console.error(error.data)
            }
        )
    };


    useEffect(() => {
        let mounted = true;
        var usr = JSON.parse(localStorage.getItem("user"));
        var wizard = false;
        if (usr != null) {
            var wizard = usr.wizard;
            if (wizard) {
                setShowWizard(true)
            }
            else {
                var company = JSON.parse(localStorage.getItem("company"));
                if (company != undefined) {
                    setSelectedCompany(company);
                }
                else {
                    console.log(usr)
                    setShowCompanyModal(true);
                }
                if (usr.isOnNotifyState) {
                    // playSound();
                    setNewNotifications(
                        <Nav.Item>
                            <Nav.Link>
                                <span class="fa-stack fa-1x blob red" onClick={updateUserIsOnNotifyState} title={t("userHasNewMessages")}>
                                    <i className="fa fa-envelope  fa-stack-1x" ></i>
                                    <i className="fa fa-exclamation fa-stack-1x" style={{ color: "red" }}></i>
                                </span>
                            </Nav.Link>
                        </Nav.Item>
                    )
                }
            }

            if (urlShowWizard) {
                setShowWizard(true)
            }


        }
        else {

            var company = JSON.parse(localStorage.getItem("company"));
            if (company != undefined) {
                setSelectedCompany(company);
            }
            else {
                setShowCompanyModal(true);
            }
        }


        if (i18n.language == "en-US") {
            i18n.changeLanguage("gr")
        }

        return () => mounted = false;

    }, []);
    // if (currentUser != null) {
    //     isAdmin = currentUser.roles.includes("ROLE_ADMIN");
    // }
    // if (currentUser != null) {
    //     isManager = currentUser.roles.includes("ROLE_MANAGER");
    // }
    isManager = true;
    if (isAdmin) {
        adminMenu =
            <NavDropdown title={t("Management")}>
                <NavDropdown.Item as={Link} to="users">Users</NavDropdown.Item>
            </NavDropdown>
    }


    const getCompaniesUrl = () => {
        var _id = selectedCompany ? selectedCompany.id : "";
        return "/usercompany/" + _id;
    }
    function iOS() {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
    if (isAdmin || isManager) {
        managerMenu =
            <NavDropdown active={window.location.pathname == "/invoicetypes" || window.location.pathname == "/usercompanies"} title={t("Settings")}>
                <NavDropdown.Item active={window.location.pathname == "/usercompanies" || window.location.pathname.includes("/usercompany")} onClick={() => setExpanded(false)} as={Link} to={getCompaniesUrl()}>{t("userCompanies")}</NavDropdown.Item>
                <NavDropdown.Item active={window.location.pathname == "/invoicetypes"} onClick={() => setExpanded(false)} as={Link} to="/invoicetypes">{t("invtypes")}</NavDropdown.Item>
                <NavDropdown.Item active={window.location.pathname == "/productCategories"} onClick={() => setExpanded(false)} as={Link} to="/productCategories">{t("classificationsOfProducts")}</NavDropdown.Item>
                <NavDropdown.Item active={window.location.pathname == "/templates"} onClick={() => setExpanded(false)} as={Link} to="/templates">{t("invoiceDesigner")}</NavDropdown.Item>
                <NavDropdown.Item active={window.location.pathname == "/deductions"} onClick={() => setExpanded(false)} as={Link} to="/deductions">{t("Deductions")}</NavDropdown.Item>

                <NavDropdown.Item active={window.location.pathname == "/aade/sale"} onClick={() => setExpanded(false)} as={Link} to="/aade/sale">{t("searchByMarkFromAade")}</NavDropdown.Item>

                {/* <NavDropdown.Item active={window.location.pathname == "/accountantAssignment"} onClick={() => setExpanded(false)} as={Link} to="/accountantAssignment">{t("accountantAssignmnent")}</NavDropdown.Item> */}
                <NavDropdown.Item onClick={() => window.open("https://timologic.gr/blog/sindesi-eshop-mydata-timologisi/", '_blank')}>{t("eshopConnect")}</NavDropdown.Item>

            </NavDropdown>
    }

    if (localStorage.getItem("user")) {
        if (JSON.parse(localStorage.getItem("user")).lastTransactionDate == null && !iOS()) {
            paymenu = <Nav.Item >
                <Nav.Link id="pay_btn" as={Link} to="/startSubscription">{t("subscription")}</Nav.Link>
            </Nav.Item>
        }
    }  
   
    const User = JSON.parse(localStorage.getItem("user"));


    const getLogo = () => {
        if (window.location !== window.parent.location) {
            window.parent.postMessage("messageFromNavbar", "*");
            return LogoEmpty;
        }
        return Logo
    }

    function isEmpty(obj) {
        for (var prop in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                return false;
            }
        }

        return JSON.stringify(obj) === JSON.stringify({});
    }
    const handleSaveAndExit = () => {
        var data = wizardRef.current.getValues();
        debugger;
        var errors = wizardRef.current.getErrors();
        var vals = Object.values(errors);
        setTimeout(function () {
            if (isEmpty(vals[0])) {
                SaveChanges(data);
            }
        }, 100)
    }

    function SaveChanges(data) {
        localStorage.removeItem("wizard")
        var company = {
            name: data.name,
            taxpayerId: data.taxpayerId ? data.taxpayerId.trim() : "",
            taxOffice: { id: data.taxOffice.value }
        }
        if (company.taxOffice.id == undefined) {
            company.taxOffice = null;
        }
        var branch = {
            name: data.name,
            subjectField: data.subjectField,
            address: data.address,
            branchNumber:0
        }

        company.branches = [branch];

        console.log(JSON.stringify(company))
        ApiClient.saveCompany(company).then(
            response => {
                var savedCompany = response.data;
                var invoiceTypes = [];

                var stypes = data.myDataInvoiceType;
                stypes.forEach(element => {
                    invoiceTypes.push({
                        company: { id: savedCompany.id },
                        name: element.label,
                        myDataInvoiceType: element.value,
                        series: [{
                            name: "A",
                            branch: { id: savedCompany.branches[0].id },
                            startNumber: 0
                        }]
                    });
                });

                ApiClient.SaveBulkInvoiceTypeWithSeries(invoiceTypes).then(
                    response => {

                        ApiClient.UpdateUserWizard().then(
                            response => {
                                toast.success(t("saveSucceded"))
                                localStorage.setItem("company", JSON.stringify(savedCompany));
                                localStorage.setItem("user", JSON.stringify(response.data));
                                history.push("/sale");
                                window.location.reload();
                                Commons.setDefault("invoiceTypeMessages", ["invoice2_1", "invoice11_1"])

                            }
                        );
                    },
                    error => {
                        toast.error("Error: " + error.data)
                        console.error(error.data)
                    }
                )
                setShowWizard(false);
            },
            error => {
                toast.error(error.message + ": " + error.response.data.message, 20000)
                console.error(error.response.data.message)
            }
        );
    }

    const LanguageDropDown = () => {
        return <NavDropdown className="flagIconDropDown" title={
            <ReactCountryFlag
                className="flagIcon"
                svg
                countryCode={i18n.language ? i18n.language.toUpperCase() : "GR"}
                style={{
                    fontSize: '1em',
                    lineHeight: '1em',
                }}
            />
        }>
            <NavDropdown.Item active={i18n.language == "gb"} onClick={function () { i18n.changeLanguage("gb") }}>
                <ReactCountryFlag
                    className="flagIcon"
                    svg
                    countryCode="GB"
                    style={{
                        fontSize: '1em',
                        lineHeight: '1em',
                    }}
                />
                GB
            </NavDropdown.Item>
            <NavDropdown.Item active={i18n.language == "gr"} onClick={function () { i18n.changeLanguage("gr") }}>
                <ReactCountryFlag
                    className="flagIcon"
                    svg
                    countryCode="GR"
                    style={{
                        fontSize: '1em',
                        lineHeight: '1em',
                    }}
                />
                GR
            </NavDropdown.Item>
            {/* <NavDropdown.Item active={i18n.language == "al"} onClick={function () { i18n.changeLanguage("al") }}>
                <ReactCountryFlag
                    className="flagIcon"
                    svg
                    countryCode="AL"
                    style={{
                        fontSize: '1em',
                        lineHeight: '1em',
                    }}
                />
                AL
            </NavDropdown.Item> */}
        </NavDropdown>
    }

    return (
        <Navbar expanded={expanded} className="menunav" fixed="top" bg="dark" variant="dark" expand="lg">
            <Container fluid >
                <Navbar.Brand as={Link} to="/">
                    <img width="180px" height="auto" className="img-responsive" src={getLogo()} alt="logo" />
                </Navbar.Brand>
                <div className="d-flex main-nav-right-btns">
                    <Navbar.Toggle onClick={() =>history.go(0)}><i className="fa fa-refresh"></i></Navbar.Toggle>
                    <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} id="main-nav-toogler" aria-controls="basic-navbar-nav" />
                </div>

                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav activeKey={window.location.pathname} className="me-auto">


                        <Nav.Link className="desktop-only" active={window.location.pathname == "/"} as={Link} to="/"><i className="fa fa-2x fa-home"></i></Nav.Link>


                        <NavDropdown active={window.location.pathname == "/sales" || window.location.pathname == "/sale"} title={t('DocumentTypes')} >
                            <NavDropdown.Item active={window.location.pathname == "/sale"} onClick={() => setExpanded(false)} as={Link} to="/sale">{t('PublishInvoiceNav')}</NavDropdown.Item>
                            <NavDropdown.Item active={window.location.pathname == "/sales"} onClick={() => setExpanded(false)} as={Link} to="/sales">{t('SalesSearchNav')}</NavDropdown.Item>
                            <NavDropdown.Item active={window.location.pathname == "/presales"} onClick={() => setExpanded(false)} as={Link} to="/presales">{t('PreSalesSearchNav')}</NavDropdown.Item>

                        </NavDropdown>


                        <Nav.Item  >
                            <Nav.Link active={window.location.pathname == "/customers"} onClick={() => setExpanded(false)} as={Link} to="/customers">{t("Customers")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item >
                            <Nav.Link active={window.location.pathname == "/products"} onClick={() => setExpanded(false)} as={Link} to="/products">{t("ProductsServices")}</Nav.Link>
                        </Nav.Item>


                        {managerMenu}
                        {adminMenu}
                        {paymenu}

                    </Nav>
                    <Nav className="right-navbar flex-row-reverse  d-flex" style={{paddingRight:6}}>
                    <Nav.Item >
                            <NavDropdown className="profile-dropdown" title={
                                <img style={{ borderRadius: "50%", width: 30, height: 30 }} alt="new" src={User != null && User.imageUrl != null ? User.imageUrl : pflogo} />
                            } id="profile-nav-dropdown">
                                <LanguageDropDown></LanguageDropDown>
                                <NavDropdown.Item active={window.location.pathname == "/profile"} onClick={() => setExpanded(false)} as={Link} to="/profile">
                                    <i className="fa fa-user fa-2x"></i>
                                </NavDropdown.Item>
                                <NavDropdown.Item href="login" id="logout_btn" onClick={() => { hangleLogout() }}>
                                    <i className="fa  fa-sign-out fa-2x"></i>
                                </NavDropdown.Item>
                            </NavDropdown>

                        </Nav.Item>

                        <Nav.Item >
                            {newNotifications}
                            <Button id="selectCompanyChoiceBtn" variant="primary" style={{ backgroundColor: 'transparent' }} onClick={handleShowCompanyModal}>
                                {selectedCompany.name}
                            </Button>

                        </Nav.Item>
                       
                    </Nav>
                </Navbar.Collapse>
            </Container>
            {/* <Button variant="primary" onClick={handleShowWizard}>
                modal
            </Button> */}

            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showWizard}
                dialogClassName="wizard-modal"
                onHide={handleCloseWizard}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>{t("startWizardTitle")}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="wizard-modal-body">
                    {/* <StartWizard onStepChange={onStepChange} wizardInstance={SW} closeWizard={handleCloseWizard} updateForm={updateForm} closeModal={onCloseModal} setInstance={setInstance} state={state}></StartWizard> */}

                    <form id="wizard_form">
                        <Wizard ref={wizardRef} ></Wizard>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    {/* {(SW) && <InstanceDemo SW={SW} />} */}
                    <Button className="btn-skin btn-lg" id="saveWizardBtn" variant="primary" onClick={handleSaveAndExit}>{t("iamready")}</Button>
                </Modal.Footer>
            </Modal>
            <ChooseCompanyModal handleCloseCompanyModal={handleCloseCompanyModal} showCompanyModal={showCompanyModal}></ChooseCompanyModal>
        </Navbar>
    );
}
