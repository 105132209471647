import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import ApiClient from "../services/api-client"
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { useHistory } from "react-router-dom"
import { useTranslation } from 'react-i18next';



const ActionsRenderer = forwardRef((props, ref) => {


    const history = useHistory();

    const handleInputChange = (e) => {
        e.preventDefault();
        var _id = props.data.id;
        history.push("/usercompany/" + _id);
    }
    useImperativeHandle(ref, () => {
        return {
            refresh(params) {

            }
        };
    });
    return (
        <span><button onClick={handleInputChange} className="btn btn-sm btn-warning"><i className="fa fa-edit"></i></button></span>
        // <span className="editableCell">{branch != null && branch.label != undefined ? branch.label : <span className="addtext"></span>}</span>
    );
});

export default function CompanySearch() {

    const [content, setContent] = React.useState(0);
    const history = useHistory();
    const [gridApi, setGridApi] = useState(null);
    const pagination = true;
    const paginationPageSize = 10;
    const { t } = useTranslation();


    const onGridReady = params => {
        setGridApi(params.api);
        // params.api.sizeColumnsToFit();
        window.addEventListener('resize', function () {
            setTimeout(function () {
                // params.api.sizeColumnsToFit();
            });
        });

        // params.api.sizeColumnsToFit();
    };
    const onQuickFilterChanged = e => {
        gridApi.setQuickFilter(e.target.value);
    };
    const goTo = id => {
        history.push("/usercompany/" + id);
    }

    useEffect(() => {

        ApiClient.getUserCompanies().then(
            response => {
                setContent(response.data);

            },
            error => {
                setContent(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString())
            }
        );

    }, []);

    return (
        <div className="container">
            <header className="jumbotron">
                <div className="row">
                    <div className="col-md-6">
                        <h5 className="subtitle skin">{t('userCompanies')}</h5>
                    </div>

                   
                </div>
            </header>


            <div className="row">
                <div className="alert alert-primary" role="alert">

                {t("companySearchHelp")}

                </div>
            </div>

            <div style={{ width: '100%', height: '100%' }}>
                <div className="row">
                    <div className="offset-md-4 col-md-4">
                        <p className="text-center">
                            <input placeholder={t("Search")} id="searchCompaniesInput" className="form-control" onChange={onQuickFilterChanged} />
                        </p>
                    </div>
                    <div className="col-md-4">
                        <div className="addmodelBtnContainer">
                            <button className="btn btn-skin" href="" onClick={function () { return history.push("/usercompany"); }}>
                                {t("companyAdd")}  <i className="fa  fa-plus"></i>
                            </button>
                        </div>
                    </div>

                </div>






                <div
                    id="myGrid"
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                    className="ag-theme-alpine-dark company-search-grid"
                >
                    <div className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
                        <AgGridReact
                            pagination={pagination}
                            paginationPageSize={paginationPageSize}
                            onGridReady={onGridReady}
                            style={{ width: '100%', height: '100%;' }}
                            onRowDoubleClicked={(e) => goTo(e.data.id)}
                            rowData={content}
                            frameworkComponents={{
                                actionsRenderer: ActionsRenderer,
                            }}
                        >
                            <AgGridColumn flex={3} minWidth={300} headerName={t("Name")} sortable={true} filter="agTextColumnFilter" field="name"></AgGridColumn>
                            <AgGridColumn flex={1} minWidth={150} headerName={t("taxpayerId")} sortable={true} filter="agTextColumnFilter" field="taxpayerId"></AgGridColumn>
                            <AgGridColumn flex={3} minWidth={200} headerName={t("taxOffice")} sortable={true} filter="agTextColumnFilter" field="taxOffice.name"></AgGridColumn>
                            <AgGridColumn flex={2} minWidth={200} headerName={t("Actions")} cellRenderer="actionsRenderer" field="action"></AgGridColumn>

                        </AgGridReact>
                    </div>
                </div>
            </div>
        </div>
    );
}


